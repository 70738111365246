<div fxLayout="column" class="dashboard-header">
    <div class="dashboard-header__content bg--white border--all" ngClass.gt-xs="brblr--8">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="dashboard-header__text px-16 py-8">
            <div fxFlex="70" fxFlex.xs="100" fxLayoutAlign="start center">
                <div fxHide.gt-md fxLayoutAlign="center center" (click)="openMenu(true)">
                    <sf-icon class="cursor--pointer color--primary-60" data-cy="menu">menu</sf-icon>
                </div>
                <p fxFlexFill sfSubtitle1 class="color--black-87 text--wrap" ngClass.lt-lg="font-size-14 mx-12">{{('label_' + header) | uppercase | translate | titlecase}}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="width--full">
                <ng-container *ngIf="isUser">
                    <div aria-label="display notification modal" class="cursor--pointer color--black-60 mt-10" ngClass.xs="s-24" [ngClass]="totalUnreadNotificationCountLength === 1 ? 'mr-2': 'mr-N5' " [ngClass.xs]="totalUnreadNotificationCount ? 'mr-N16' : 'mr-4' " (click)="showNotification()" data-cy="notification">
                        <sf-icon>notifications_none</sf-icon>
                    </div>
                    <p *ngIf="totalUnreadNotificationCount" fxLayoutAlign="center center" class="badge-count bg--primary color--white ml-N8 br--20 px-4 font-size-10" [ngClass]="totalUnreadNotificationCount.toString().length === 1 ? 'mb-10': 'mb-8' " [ngClass.xs]="totalUnreadNotificationCountLength === 1 ? 'mb-10 ml-8 mr-6': 'mb-8 ml-4 mr-8'"> 
                        <span *ngIf="totalUnreadNotificationCountLength <= 2">{{totalUnreadNotificationCount}}</span>
                        <span *ngIf="totalUnreadNotificationCountLength > 2">99+</span>
                    </p>
                </ng-container>
                <span class="header-language-switcher mr-4">
                    <ew-language-switcher></ew-language-switcher>
                </span>

                <div sfMenuSurfaceAnchor #userSelectionAnchor
                     (click)="userSelectionGroup.open = true" ngClass.gt-sm="ml-12"
                     *ngIf="profile$ | async as profile">
                    <ng-container>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="cursor--pointer pt-2"
                             data-cy="profileHeader">
                            <sf-avatar size="sm" class="dashboard-header--profile my-0 ml-0 mr-8">
                                <img [src]="profile?.profile?.url || 'https://images.selise.club/ui-assets/images/avatars/default_rounded_avatar.svg'"
                                     class="dashboard-header--profile" alt="profile picture" sfAvatarImage />
                                <sf-icon sfAvatarBadge class="color--success">circle</sf-icon>
                            </sf-avatar>
                            <div fxHide.lt-sm class="dashboard-header--profile-detail font-size-12 text--left cursor--pointer">
                                <p sfCaption>{{(profile?.profile?.firstName + ' ' + profile?.profile?.lastName) | truncate: 20}} </p>
                            </div>
                            <sf-icon class="color--black-60" ngClass.gt-xs="ml-12">arrow_drop_down</sf-icon>
                        </div>
                    </ng-container>

                    <sf-menu #userSelectionGroup [anchorElement]="userSelectionAnchor" [anchorCorner]="'bottomStart'"
                             class="profile-menu">
                        <ng-container>
                            <div fxLayoutAlign="center" class="bg--primary-20 profile-bg">
                                <sf-avatar size="xxl" class="profile-bg__avatar">
                                    <img [src]="profile?.profile?.url || 'https://images.selise.club/ui-assets/images/avatars/default_rounded_avatar.svg'"
                                         alt="profile picture" sfAvatarImage/>
                                </sf-avatar>
                            </div>
                            <div class="text--center pt-58 px-24 pb-16" [ngClass]="currentUser?.adminRoles?.length > 1 ? 'bg--whisper-white': ''">
                                <sf-list>
                                    <p sfSubtitle1
                                       class="color--black-87">{{(profile?.profile?.firstName + ' ' + profile?.profile?.lastName)| truncate: 20}}</p>
                                </sf-list>
                                <p sfSubtitle1
                                   class="mt-4 color--black-60 font-size-14">{{ roleName ? ( roleName | translate )  : ('LABEL_CLIENT_ID' | translate) + ': ' + profile?.clientNumber}}</p>
                                <hr *ngIf="!currentUser?.adminRoles?.length " class="bg--whisper-white horizontal-line mt-12">
                            </div>
                            <div [ngClass]="currentUser?.adminRoles?.length > 1 ? 'bg--whisper-white': ''">
                                <ng-container *ngIf="currentUser?.adminRoles?.length > 1">
                                    <div class="accordain-container">
                                        <sf-accordion >
                                            <sf-accordion-item>
                                                <sf-accordion-item-header>
                                                    {{'LABEL_SWITCH_ACCOUNTS' | translate}}
                                                </sf-accordion-item-header>
                                                <ng-container *ngFor="let userRole of currentUserAdminRoleOptions; let i = index">
                                                    <div class="px-24 py-12 mt-4 cursor--pointer" (click)="switchRole(userRole)">
                                                        <div fxLayout="row">
                                                            <div class="text--left" fxLayoutAlign="center center" fxLayout="row">
                                                                <sf-avatar size="sm" class="admin-profile-avatar my-0 ml-0 mr-8">
                                                                    <img [src]="profile?.profile?.url || 'https://images.selise.club/ui-assets/images/avatars/default_rounded_avatar.svg'"
                                                                        class="admin-profile-avatar" alt="profile picture" sfAvatarImage />
                                                                </sf-avatar>
                                                                <p sfSubtitle1
                                                                class="color--black-87 font-size-14">{{'LABEL_' + userRole?.label | translate }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="bg--whisper-white horizontal-line">
                                                </ng-container>
                                            </sf-accordion-item>
                                        </sf-accordion>
                                    </div>
                                </ng-container>
                                <div fxLayoutAlign="center" [ngClass]="currentUser?.adminRoles?.length > 1 ? 'button-container': ''" class="py-16 bg--white">
                                    <button sf-button outlined primary class="width--full mx-24" (click)="logout()">
                                        <sf-icon class="font-size-20">logout</sf-icon>
                                        <span sfButtonLabel>{{'BTN_LOGOUT' | uppercase | translate}}</span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </sf-menu>
                </div>
            </div>
        </div>
        <ng-container *ngIf="header !== 'product_configurator'; else breadcrumbForPC">
            <div class="dashboard-header--breadcrumb px-16 pt-12"
                 [fxHide]="!el?.breadcrumbs?.length || el?.breadcrumbs?.length === 1">
                <sf-breadcrumb *ngIf="translateBreadcrumb()" class="breadcrumb" #el></sf-breadcrumb>
            </div>
        </ng-container>
        <ng-template #breadcrumbForPC>
            <ng-container *ngIf="breadCrumbs | async as breadcrumbs">
                <div class="dashboard-header--breadcrumb px-16 py-12" *ngIf="breadcrumbs?.length > 1">
                    <div fxLayoutAlign="start center">
                        <a *ngFor="let eachCrumb of breadcrumbs; last as isLast"
                           [ngClass]="{'color--primary': !isLast}"
                           fxLayoutAlign="start center">
			<span [ngClass]="{'cursor--pointer': !isLast}" (click)="navigate(eachCrumb)"
                  routerLinkActive="router-link-active">
			  {{eachCrumb.label}}
			</span>
                            <sf-icon *ngIf="!isLast" class="mx-4">
                                keyboard_arrow_right
                            </sf-icon>
                        </a>
                    </div>
                </div>
            </ng-container>
        </ng-template>
      </div>      
</div>