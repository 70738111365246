import {LevelTypeEnum} from '@ew/shared/services';
import {Level, LevelRule, Maybe, Scalars, DynamicColumn} from '../../graphql';
import {MultiLanguages} from '../../shared.model';
import {LevelRuleModel} from './level-rule.model';

export class LevelModel implements Level {
	configuratorId: string = null;
	name: MultiLanguages = new MultiLanguages();
	description: MultiLanguages = new MultiLanguages();
	id: string = null;
	type: LevelTypeEnum = null;
	levelRule: LevelRule = new LevelRuleModel();
	meta: Maybe<Scalars['JSON']> = {};
	rank: number = null;
	selectionRule: boolean = false;
	imageable: boolean = false;
	dynamicColumns: DynamicColumn[] = [];
}
