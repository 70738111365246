import {
  AfterContentInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil, startWith} from 'rxjs/operators';

import {SfFormFieldControl} from './form-field-control';
import {SfHelperText} from './helper-text.component';

@Component({
  selector: 'sf-form-field',
  exportAs: 'sfFormField',
  host: {
    '[class.sf-form-field--fluid]': 'fluid',
    '[class.mdc-form-field--align-end]': 'alignEnd',
    'class': 'sf-mdc-form-field'
  },
  template: `<ng-content></ng-content>
  <ng-content select="[sfHelperText, sf-helper-text]"></ng-content>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfFormFieldComponent implements AfterContentInit, OnDestroy {
  /** Emits whenever the component is destroyed. */
  private _destroy = new Subject<void>();

  public label?: HTMLElement;

  @Input()
  get fluid(): boolean { return this._fluid; }
  set fluid(value: boolean) {
    this._fluid = coerceBooleanProperty(value);
  }
  private _fluid = false;

  @Input()
  get alignEnd(): boolean { return this._alignEnd; }
  set alignEnd(value: boolean) {
    this._alignEnd = coerceBooleanProperty(value);
  }
  private _alignEnd = false;

  @ContentChild(SfFormFieldControl, {static: false}) _control!: SfFormFieldControl<any>;
  @ContentChildren(SfHelperText, { descendants: true }) assistiveElements!: QueryList<SfHelperText>;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone,
    public elementRef: ElementRef<HTMLElement>) { }

  ngAfterContentInit(): void {
    if (this._control) {
      const control = this._control.elementRef.nativeElement;

      if (control.nextElementSibling) {
        if (control.nextElementSibling.tagName === 'LABEL') {
          this.label = control.nextElementSibling;
          if (this.label && this._control.inputId) {
            // tslint:disable-next-line:no-non-null-assertion
            this.label!.setAttribute('for', this._control.inputId);
            this._loadListeners();
          }
        }
      }
    }

    // When assistive elements change, initialize foundation
    this.assistiveElements.changes.pipe(startWith(null), takeUntil(this._destroy))
      .subscribe(() => {
        (this.assistiveElements).forEach(helperText =>
          this._initHelperTextFoundation(helperText));
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private _initHelperTextFoundation(helperText: SfHelperText): void {
    const control = this._control;

    if (control && control.controlType) {
      control.helperText = helperText;
      this._changeDetectorRef.markForCheck();
    }
  }

  private _loadListeners(): void {
    this._ngZone.runOutsideAngular(() =>
      // tslint:disable-next-line:no-non-null-assertion
      fromEvent<MouseEvent>(this.label!, 'click').pipe(takeUntil(this._destroy))
        .subscribe(() => this._ngZone.run(() => {
          // tslint:disable-next-line:no-non-null-assertion
          this._control.ripple!.activateRipple();

          if (typeof requestAnimationFrame !== 'undefined') {
            // tslint:disable-next-line:no-non-null-assertion
            requestAnimationFrame(() => this._control.ripple!.deactivateRipple());
          }
        })));
  }
}
