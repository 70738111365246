import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SfAvatarBadgeDirective,
  SfAvatarComponent,
  SfAvatarIconBadgeDirective,
  SfAvatarImageDirective, SfAvatarGroupDirective
} from './avatar.component';
import { SfIconModule } from '../../../icon/src/index';

const DECLARATIONS = [SfAvatarComponent,
  SfAvatarBadgeDirective,
  SfAvatarImageDirective,
  SfAvatarIconBadgeDirective,
  SfAvatarGroupDirective
];

@NgModule({
  imports: [CommonModule, SfIconModule],
  exports: DECLARATIONS,
  declarations: DECLARATIONS
})
export class SfAvatarModule {
}
