import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { SfStepperDirective } from '../stepper.component';
import { DOCUMENT } from '@angular/common';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'sf-horizontal-stepper',
  exportAs: 'sfHorizontalStepper',
  templateUrl: './horizontal-stepper.component.html',
  host: {
    'class': 'sf-horizontal-stepper',
    '[class.sf-stepper-label-position-end]': 'labelPosition == "end"',
    '[class.sf-stepper-label-position-bottom]': 'labelPosition == "bottom"',
    'aria-orientation': 'horizontal',
    'role': 'tablist'

  },
  providers: [
    { provide: SfStepperDirective, useExisting: SfHorizontalStepperComponent },
    { provide: CdkStepper, useExisting: SfHorizontalStepperComponent }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfHorizontalStepperComponent extends SfStepperDirective implements OnInit {
  /** Whether the label should display in bottom or end position. */
  @Input()
  labelPosition: 'bottom' | 'end' = 'end';

  @Input()
  selectedIndex: number;

  /** Whether the validity of previous steps should be checked or not. */
  @Input()
  linear: boolean;

  constructor(
    @Optional() dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    elementRef?: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) _document?: any) {
    super(dir, changeDetectorRef, elementRef, _document);
  }

  ngOnInit(): void {
  }
}
