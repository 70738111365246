import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChildren,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  Provider,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {MDCRippleFoundation, MDCRippleAdapter} from '@material/ripple';

import {MDCComponent} from '../../../base/src/index';
import {SfRippleService, SfRippleCapableSurface} from '../../../ripple/src/index';
import {SfIconComponent} from '../../../icon/src/index';

import {MDCIconButtonToggleFoundation, MDCIconButtonToggleAdapter} from '@material/icon-button';

export const SF_ICON_BUTTON_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SfIconButtonComponent),
  multi: true
};

/** Change event object emitted by SfIconButton. */
export class SfIconButtonChange {
  constructor(
    public source: SfIconButtonComponent,
    public value: any) {}
}

let nextUniqueId = 0;

@Directive({
  selector: '[sfIconOn]',
  host: {'class': 'mdc-icon-button__icon--on'}
})
export class SfIconOn {}

@Component({
  selector: '[sf-icon-button], button[sfIconButton], a[sfIconButton]',
  exportAs: 'sfIconButton',
  host: {
    '[id]': 'id',
    'class': 'mdc-icon-button sf-icon-button',
    '[class.mdc-icon-button--on]': 'on',
    'attr.aria-pressed': 'false',
    '[class.sf-icon--primary]': 'primary',
    '[class.sf-icon--secondary]': 'secondary',
    '(click)': 'handleClick()'
  },
  template: `
  <sf-icon *ngIf="icon">{{icon}}</sf-icon>
  <ng-content *ngIf="!icon"></ng-content>`
  ,
  providers: [
    SF_ICON_BUTTON_CONTROL_VALUE_ACCESSOR,
    SfRippleService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SfIconButtonComponent extends MDCComponent<MDCIconButtonToggleFoundation> implements AfterContentInit,
  ControlValueAccessor, OnDestroy, SfRippleCapableSurface {
  private _uniqueId = `sf-icon-button-${++nextUniqueId}`;

  _root!: Element;


  private _primary = false;

  @Input()
  get primary(): boolean {
    return this._primary;
  }

  set primary(value: boolean) {
    this._primary = coerceBooleanProperty(value);
  }
  private _secondary = false;

  @Input()
  get secondary(): boolean {
    return this._secondary;
  }

  set secondary(value: boolean) {
    this._secondary = coerceBooleanProperty(value);
  }

  @Input() id: string = this._uniqueId;
  get inputId(): string {
    return `${this.id || this._uniqueId}`;
  }

  @Input() name: string | null = null;
  @Input() icon: string | null = null;

  @Input()
  get on(): boolean {
    return this._on;
  }
  set on(value: boolean) {
    this.setOn(value);
  }
  private _on = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this.setDisabled(value);
  }
  private _disabled = false;

  @Output() readonly changed: EventEmitter<SfIconButtonChange> =
    new EventEmitter<SfIconButtonChange>();

  @ContentChildren(SfIconComponent, {descendants: true}) icons!: QueryList<SfIconComponent>;

  /** Subscription to changes in icons. */
  private _changeSubscription: Subscription | null = null;

  _onChange: (value: any) => void = () => {};
  _onTouched = () => {};

  getDefaultFoundation() {
    const adapter: MDCIconButtonToggleAdapter = {
      addClass: (className: string) => this._getHostElement().classList.add(className),
      removeClass: (className: string) => this._getHostElement().classList.remove(className),
      hasClass: (className: string) => this._getHostElement().classList.contains(className),
      setAttr: (name: string, value: string) => this._getHostElement().setAttribute(name, value),
      notifyChange: (evtData: {isOn: boolean}) => {
        this.changed.emit(new SfIconButtonChange(this, evtData.isOn));
        this._onChange(this._foundation.isOn());
      }
    };
    return new MDCIconButtonToggleFoundation(adapter);
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    public ripple: SfRippleService) {
    super(elementRef);
    this._root = this.elementRef.nativeElement;
    this.ripple = this._createRipple();
    this.ripple.init();
  }

  ngAfterContentInit(): void {
    this._foundation.init();
    this._foundation.toggle(this._on || this._foundation.isOn());
    this._changeDetectorRef.detectChanges();

    // When the icons change, re-subscribe
    this._changeSubscription = this.icons.changes.pipe(startWith(null)).subscribe(() => {
      this.icons.forEach((icon: SfIconComponent) => {
        icon.elementRef.nativeElement.classList.add('mdc-icon-button__icon');
        icon.tabIndex = null;
        icon.role = null;
      });
    });
  }

  ngOnDestroy(): void {
    if (this._changeSubscription) {
      this._changeSubscription.unsubscribe();
    }

    this.ripple.destroy();
    this._foundation.destroy();
  }

  writeValue(value: boolean): void {
    this._onChange(value);
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  toggle(isOn?: boolean): void {
    this.on = isOn ? coerceBooleanProperty(isOn) : !this.on;
    this._foundation.toggle(this.on);
  }

  setOn(on: boolean): void {
    this._on = coerceBooleanProperty(on);
    this._foundation.toggle(this.on);

    this._changeDetectorRef.markForCheck();
  }

  /** Sets the button disabled state */
  setDisabled(disabled: boolean): void {
    this._disabled = coerceBooleanProperty(disabled);
    this.disabled ? this._getHostElement().setAttribute('disabled', '') :
      this._getHostElement().removeAttribute('disabled');
    this._changeDetectorRef.markForCheck();
  }

  handleClick(): void {
    if (this.icons.length === 1) {
      return;
    }

    this.on = !this.on;
    this._foundation.handleClick();
  }

  private _createRipple(): SfRippleService {
    const adapter: MDCRippleAdapter = {
      ...SfRippleService.createAdapter(this),
      isUnbounded: () => true
    };
    return new SfRippleService(this.elementRef, new MDCRippleFoundation(adapter));
  }

  private _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
