import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { ControlOptions, DataTypeEnum, LevelFormBuilder, DYNAMIC_DATA_TYPES } from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';

@Component({
    selector: 'ew-dynamic-columns-form',
    templateUrl: './dynamic-columns-form.component.html',
    styleUrls: ['./dynamic-columns-form.component.scss'],
})
export class DynamicColumnsFormComponent extends FormBaseComponent {
  readonly dataTypeList: string[] = DYNAMIC_DATA_TYPES;
  readonly translatableFieldConfigs: ControlOptions[] = [
  	{
  		label: 'Input Name',
  		key: 'name',
  		type: 'input',
  	},
  ];

  @Input() parentFormGroupRef: FormGroup;

  constructor() {
  	super();
  }

  dynamicOptionsAttribute(index: number): FormArray {
  	return this.dynamicColumnsFormArray.at(index)?.get(
  		'dynamicOptions'
  	) as FormArray;
  }

  addDynamicField(): void {
  	this.dynamicColumnsFormArray.push(
  		this.formBuilder(LevelFormBuilder.dynamicColumnInitializer())
  	);
  }

  removeDynamicField(index: number, selectedControl: AbstractControl): void {
  	selectedControl.value?.id
  	// eslint-disable-next-line @typescript-eslint/naming-convention
  		? selectedControl.patchValue({ _destroy: true })
  		: this.dynamicColumnsFormArray.removeAt(index);
  }

  addOptionField(index: number): void {
  	this.dynamicOptionsAttribute(index).push(
  		this.formBuilder(LevelFormBuilder.dynamicOptionInitializer())
  	);
  }

  removeOptionField(
  	firstIndex: number,
  	index: number,
  	selectedControl: AbstractControl
  ): void {
  	selectedControl.value?.id
  	// eslint-disable-next-line @typescript-eslint/naming-convention
  		? selectedControl.patchValue({ _destroy: true })
  		: this.dynamicOptionsAttribute(firstIndex).removeAt(index);
  }

  dataTypeChanged(colIndex: number): void {
  	if (this.dynamicColumnsFormArray.at(colIndex).get('dataType').value !== DataTypeEnum.Options) {
  		this.dynamicOptionsAttribute(colIndex).controls.forEach((optCtrl, i) => this.dynamicOptionsAttribute(colIndex).removeAt(i));
  	}
  }

  get dynamicColumnsFormArray(): FormArray {
  	return this.parentFormGroupRef.get('dynamicColumns') as FormArray;
  }

}
