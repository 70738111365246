import {NgModule} from '@angular/core';
import {ApolloLink, from} from 'apollo-link';
import {FragmentDefinitionNode, OperationDefinitionNode} from 'graphql';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
import {APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS} from 'apollo-angular';
import {getMainDefinition} from '@apollo/client/utilities';
import {ErrorGraphqlInterceptor} from './interceptors/error-graphql.interceptor';
import {InMemoryCache} from '@apollo/client/core';

const omitDeep = require('omit-deep-lodash');

const cleanTypeName: ApolloLink = new ApolloLink((operation, forward) => {
    const keysToOmit: string[] = ['__typename'];
    const def: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(operation.query);
    if (def && def['operation'] === 'mutation') {
        operation.variables = omitDeep(operation.variables, keysToOmit);
    }
    return forward ? forward(operation) : null;
});


export const createApollo = (httpLink: HttpLink) => ({
    link: from([cleanTypeName, httpLink.create({uri: '/graphql', withCredentials: true})]),
    cache: new InMemoryCache()
});

export const createNamedApollo = (httpLink: HttpLink) => ({
    pc: {
        cache: new InMemoryCache({addTypename: false}),
        link: from([cleanTypeName, httpLink.create({uri: '/pc/graphql', withCredentials: true})])
    }
});

@NgModule({
    imports: [HttpClientModule, HttpLinkModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createNamedApollo,
            deps: [HttpLink],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorGraphqlInterceptor,
            multi: true
        }
    ]
})
export class SharedApolloGraphqlModule {
}
