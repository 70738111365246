import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfSliderComponent } from './slider.component';
import { SfSliderBaseComponent } from './slider-base.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SfSliderComponent, SfSliderBaseComponent],
  exports: [SfSliderComponent, SfSliderBaseComponent]
})
export class SfSliderModule {
}
