import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { Level } from '@ew/shared/services';

export const LEVELS_QUERY: TypedDocumentNode<{levels: Level[]}> = gql`
  query levels($configuratorId: ID!) {
    levels(configuratorId: $configuratorId) {
      configuratorId
      description
      id
      type
      imageable
      levelRule {
        contractDuration
        delivery
        id
        preorder
        quantitySetting
        stockManagement
      }
      meta
      name
      rank
      selectionRule
      dynamicColumns {
        dataType
        dynamicOptions {
          value
          hexValue
          id
          name
        }
        id
        multilingual
        name
        required
      }
    }
  }`;
