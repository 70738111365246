import {AbstractControl, FormGroup} from '@angular/forms';
import {ReactiveFormSchema} from '../../index';
import {ReactiveFormUtil} from '../misc/form.util';
import {debounceTime, startWith} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
export abstract class FormBaseComponent {
	form: FormGroup;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	state: any;

	configureForm(formSchema: ReactiveFormSchema): void {
	    this.form = ReactiveFormUtil.buildForm(new formSchema.formModel(), formSchema);

	    formSchema?.valueChanges && Object.keys(formSchema.valueChanges).forEach(key => {
	        // eslint-disable-next-line @typescript-eslint/no-unused-vars
	        this.form.get(key).valueChanges.pipe(
	            startWith(this.form.get(key).value as boolean),
	            debounceTime(400),
	            // eslint-disable-next-line @typescript-eslint/no-unused-vars
	            untilDestroyed(this)).subscribe(value => {
	            ReactiveFormUtil.evaluationValueChange(formSchema, key, this.form, this.state);
	        });
	    });
	}

	formBuilder(formSchema: ReactiveFormSchema): FormGroup {
	    return ReactiveFormUtil.buildForm(new formSchema.formModel(), formSchema);
	}

	props(key: string): AbstractControl {
	    return this.form.get(key);
	}
}
