import {Component, OnInit, ViewChild} from '@angular/core';
import {version} from '../../../../../../../package.json';
import {SidebarFacadeService} from '../services/sidebar-facade.service';
import {SfDrawerComponent} from '@ew/shared/safire/components';
import {BreakpointObserver} from '@angular/cdk/layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ICON_CLASS} from '../misc/navigation.constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {NavItem} from '../../../../../services/src/lib/models/shared.model';
import { NavigationEnd, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {SharedFacadeService, SharedStoreStateEnum} from '@ew/shared/services';

@UntilDestroy()
@Component({
    selector: 'ew-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    currentYear = new Date().getFullYear();
    appVersion = version;
    header: string;
    open: boolean;
    expand = true;
    isMediumDevice: boolean;
    isDesktopView: boolean;
    @ViewChild('drawer', {static: true}) drawer: SfDrawerComponent;

    iconClass: { class: string, url: string }[] = ICON_CLASS;
    navItems: NavItem[] = this.sidebarFacade.navigation;

    constructor(private sidebarFacade: SidebarFacadeService,
                private sharedFacade: SharedFacadeService,
                private router: Router, private media: BreakpointObserver
    ) {
    }
    navigate(navItem: NavItem): void {
        !navItem?.children && this.isMediumDevice && (this.drawer.open = false);
        this.sidebarFacade.updateNavItem(navItem);
    }

    ngOnInit(): void {
        this.media.observe('(max-width: 1279px)').pipe(untilDestroyed(this)).subscribe(value => {
            this.isMediumDevice = value.matches;
        });

        this.media.observe('(min-width: 1279px)').pipe(untilDestroyed(this)).subscribe(value => {
            this.isDesktopView = value.matches;
        });
        this.setActiveNav();
    }

    setActiveNav(): void {
        this.router.events.pipe(
            untilDestroyed(this),
            tap((route) => {
                if(route instanceof NavigationEnd) {
                    this.navItems = this.sidebarFacade.navigation
                }
            })).subscribe()
    }

    toggleMenu(event: boolean):void {
        this.drawer.open = event;
    }

    toggleDrawer(event: boolean): void {
        this.drawer.open = !event;
        this.sharedFacade.updateSpecificState({open: !event}, SharedStoreStateEnum.SIDEBAR_STATE);
    }
}
