import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ControlParams, PackageRegionEnum} from '@ew/shared/services';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'ew-pc-filter',
    templateUrl: './pc-filter.component.html',
    styleUrls: ['./pc-filter.component.scss']
})
export class PcFilterComponent  implements OnInit {

    dynamicCheckboxOptions: {label: string, value: PackageRegionEnum, selected: boolean}[] = [];

	@Input() filterFormControl: FormControl;
	@Input() filterLabel: string;
	@Input() filterIcon: string;
	@Input() controlParams: ControlParams[];
    @Input() checkboxOptions: {label: string, value: PackageRegionEnum}[];
    @Input() checkboxOptionControl: FormControl;
    @Input() displayCheckboxFilter = false;

    ngOnInit(): void {
        this.initializer();
    }

    initializer(): void {
        const formControlValue = this.checkboxOptionControl.value;
        this.dynamicCheckboxOptions = this.checkboxOptions.map(checkbox => {
            return { ...checkbox, selected: formControlValue?.length ? formControlValue.includes(checkbox?.value) : true}
        });
        this.setCheckboxFormControlValue();
    }

    checkboxCheck(event: {checked: boolean}, checboxBox: string): void {
        this.dynamicCheckboxOptions.find(dynamicCheckboxOption => 
            dynamicCheckboxOption.value === checboxBox).selected = event.checked;
        this.setCheckboxFormControlValue();
    }

    private setCheckboxFormControlValue(): void {
        const checkboxOptions = this.dynamicCheckboxOptions.filter(dynamicCheckboxOption => 
            dynamicCheckboxOption.selected).map(dynamicCheckboxOption => dynamicCheckboxOption.value);
        this.checkboxOptionControl.patchValue(checkboxOptions);  
    }
}
