import {
  ChangeDetectionStrategy,
  Component, HostListener,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import {SfCharacterCounterComponent} from '../../../forms/src/index';
import {SfInputComponent} from './input.component';

@Component({
  selector: 'sf-textarea',
  exportAs: 'sfTextarea',
  host: {
    'class': 'mdc-text-field sf-text-field',
    '[class.mdc-text-field--textarea]': 'true',
    '[class.mdc-text-field--no-label]': '!label',
    '[class.mdc-text-field--dense]': 'dense',
    '[class.mdc-text-field--fullwidth]': 'fullwidth',
    '[class.mdc-text-field--invalid]': 'errorState'
  },
  template: `
  <div sfCharacterCounter *ngIf="characterCounter"></div>
  <textarea #inputElement class="mdc-text-field__input"
    [id]="id"
    [rows]="rows"
    [cols]="cols"
    [tabindex]="tabIndex"
    [attr.aria-invalid]="errorState"
    [attr.maxlength]="maxlength"
    [attr.minlength]="minlength"
    [attr.placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    [readonly]="readonly"
    (focus)="onFocus()"
    (change)="onChange($event)"
    (blur)="onBlur()"></textarea>
  <sf-notched-outline [label]="label" [for]="id"></sf-notched-outline>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SfTextareaComponent extends SfInputComponent {
  @Input() rows?: number;
  @Input() cols?: number;



  @ViewChild(SfCharacterCounterComponent, {static: false}) _characterCounterElement!: SfCharacterCounterComponent;

  protected characterCounterFoundation(): any {
    return this.characterCounter ? this._characterCounterElement.getDefaultFoundation() : undefined;
  }
}
