import {Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {SfDialogService} from '@ew/shared/safire/components';
import {
    LevelModel,
    MenuConfig,
    PCBreadcrumb,
    ProductStateEnum,
    ProductTree,
    ProductTreeList,
} from '@ew/shared/services';

import {PRODUCT_MENU_CONFIG} from '../../../constants/products.constant';
import {ProductFacadeService} from '../../../services/product-facade.service';
import {
    ProductConfirmationModalComponent,
} from '../../product/product-confirmation-modal/product-confirmation-modal.component';
import {ServiceCreateModalComponent} from '../service-create-modal/service-create-modal.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ew-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss'],
})

export class ServiceListComponent {
    isEmpty: boolean;
    @Input() currentLevel: LevelModel;
    @Input() products: ProductTreeList[];
    @Input() currentProductAncestry: string;
    @Input() currentAncestryDepth: number;
    @ViewChild('dialog', {static: true}) simpleDialog: TemplateRef<unknown>;
    productNodeMenuConfig: MenuConfig[] = PRODUCT_MENU_CONFIG;
    viewMoreProducts: boolean;
	openedMenuModal!: ProductTreeList;

	constructor(private dialogService: SfDialogService, private productsFacadeService: ProductFacadeService,
		private route: Router, private translate: TranslateService) {
	}

	enterInsideLevel(id: string): void {
	    this.productsFacadeService.updateSpecificState<PCBreadcrumb[]>(
	        [{label: this.currentLevel.name[this.translate.currentLang], url: this.route.url}],ProductStateEnum.BREADCRUMBS);
    	this.route.navigateByUrl(`/product-configurator/product/${id}`);
	}

	emitAction(event: { actionKey: string; row: unknown | ProductTree }): void {
    	['delete', 'deleteMultiple', 'changeStatus'].includes(event.actionKey)
    		? this.appendAction(event)
    		: this[`${event.actionKey}Product`](event.actionKey, event.row);
	}

	cloneProduct(actionKey: string, product: ProductTree): void {
    	this.productsFacadeService.cloneProductById(product?.id).subscribe(() => {
    		this.productsFacadeService.getProductTressByAncestryData(product?.ancestry, product?.ancestryDepth);
    	});
	}

	editProduct(actionKey: string, product: ProductTree): void {
    	this.openServiceModal(actionKey, product);
	}

	appendAction(event: { actionKey: string; row: unknown | ProductTree | ProductTree[] }): void {
    	this.dialogService.open(ProductConfirmationModalComponent, {
    		severity: 'primary',
    		width: 500,
    		data: this.getConfirmationModalData(event),
    	});
	}

    private getConfirmationModalData = (event: {
        actionKey: string;
        row: unknown | ProductTree | ProductTree[];
    }): Record<string, unknown> => ({
    	title: ['delete', 'deleteMultiple'].includes(event.actionKey)
    		? 'LABEL_DELETE_CATEGORY'
    		: event.row['active']
    			? 'LABEL_DEACTIVATE_CATEGORY'
    			: 'LABEL_ACTIVATE_CATEGORY',
    	message: ['delete', 'deleteMultiple'].includes(event.actionKey)
    		? 'HINT_DELETE_CATEGORY_PERMANENTLY'
    		: event.row['active']
    			? 'HINT_DEACTIVATE_CATEGORY'
    			: 'HINT_ACTIVATE_CATEGORY',
    	button: ['delete', 'deleteMultiple'].includes(event.actionKey)
    		? 'BTN_DELETE' : event.row['active'] ? 'BTN_DEACTIVATE' : 'BTN_ACTIVATE',
    	productData: event.row as ProductTree,
    	multipleProductsData: event.row as ProductTree[],
    	actionType: event.actionKey,
    	productType: 'Product',
    	currentProductAncestry: this.currentProductAncestry,
    	currentLevelState: this.currentLevel,
    });

    openServiceModal(type: string, product: ProductTree): void {
    	this.dialogService.open(ServiceCreateModalComponent, {
    		width: 700,
            panelClass: 'common-modal--width',
    		severity: 'primary',
    		data: {
    			productData: product,
    			actionType: type,
    			currentLevelState: this.currentLevel,
    			currentProductAncestry: this.currentProductAncestry,
    			productType: 'Product',
    		},
    	});
    }
}
