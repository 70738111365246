import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfButtonToggleComponent, SfButtonToggleGroup } from './button-toggle.component';
import { SfIconModule } from '../../../icon/src/index';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SfIconModule, FormsModule],
  exports: [
    SfButtonToggleComponent,
    SfButtonToggleGroup
  ],
  declarations: [SfButtonToggleComponent, SfButtonToggleGroup]
})
export class SfButtonToggleModule {
}
