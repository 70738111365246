import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfAutocompleteComponent } from "./autocomplete.component";
import { SfFormsModule } from '../../../forms/src/index';
import { SfInputModule } from '../../../input/src/index';
import { SfMenuModule } from '../../../menu/src/index';
import { SfAvatarModule } from '../../../avatar/src/index';
import {HighlightPipe} from './highlight.pipe';
import {FormsModule} from "@angular/forms";
import { SfIconModule } from '../../../icon/src/index';

@NgModule({
    imports: [
        CommonModule,
        SfFormsModule,
        SfInputModule,
        SfMenuModule,
        SfAvatarModule,
        FormsModule,
        SfIconModule
    ],
  exports: [SfAutocompleteComponent, HighlightPipe],
  declarations: [SfAutocompleteComponent, HighlightPipe],
})
export class SfAutocompleteModule {}
