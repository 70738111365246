<sf-card class="list p-24" sfElevation="0">
  <ng-container *ngIf="levels$ | async as levels">
    <ng-container *ngIf="!levels.length && !newLevel">
      <div fxLayout="row wrap" class="list__guide">
        <div fxFlex="65" fxFlex.lt-md="100">
          <p sfHeadline5 class="bold color--black-87">{{'LABEL_WELCOME_PRODUCT_CONFIGURATOR' | translate}}</p>
          <p sfSubtitle2 class="mt-8">
            {{'HINT_PRODUCT_CONFIGURATOR' | translate}}
          </p>
        </div>
        <div fxLayout="row wrap" fxFlex="100">
          <div fxLayout="row wrap" fxFlex="60" fxFlex.xs="100">
            <div fxFlex="5" fxFlex.lt-md="12" class="mr-8 mt-24">
              <sf-icon class="font-size-28 color--primary">check_circle</sf-icon>
            </div>
            <div fxFlex="90" fxFlex.lt-md="84" class="mt-24">
              <p sfSubtitle1 class="bold color--black-87">{{'LABEL_DEFINE_CATEGORY_LEVEL' | translate}}</p>
              <p sfSubtitle2 class="mt-12">
                {{'HINT_DEFINE_CATEGORY_LEVEL' | translate}}
              </p>
            </div>

            <div fxFlex="5" fxFlex.lt-md="12" class="mr-8 mt-24">
              <sf-icon class="font-size-28 color--primary">check_circle</sf-icon>
            </div>
            <div fxFlex="90" fxFlex.lt-md="84" class="mt-24">
              <p sfSubtitle1 class="bold color--black-87">{{'LABEL_ADD_PRODUCT' | translate}}</p>
              <p sfSubtitle2 class="mt-12">
                {{'HINT_ADD_PRODUCT' | translate}}
              </p>
            </div>

            <div fxFlex="5" fxFlex.lt-md="12" class="mr-8 mt-24">
              <sf-icon class="font-size-28 color--primary">check_circle</sf-icon>
            </div>
            <div fxFlex="90" fxFlex.lt-md="84" class="mt-24">
              <p sfSubtitle1 class="bold color--black-87">{{'LABEL_CONFIGURATOR_RULES' | translate}}</p>
              <p sfSubtitle2 class="mt-12">
                {{'HINT_CONFIGURATOR_RULES' | translate}}
              </p>
            </div>
          </div>

          <div fxFlex="40" fxFlex.xs="100" fxLayoutAlign="center center">
            <img src="https://images.selise.club/ui-assets/images/icons/configure.svg" alt="Configurator Settings" />
          </div>

          <div fxFlex="60" fxFlex.lt-md="100" class="mt-24">
            <button sf-button primary class="list__setup" (click)="createCategoryLevel()" data-cy="setupProductConfig">
              <sf-icon>settings</sf-icon>
              {{'BTN_SETUP_PRODUCT_CONFIGURATOR' | translate}}
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="levels.length || newLevel">

      <h6 sfHeadline6 class="color--black-87 bold">{{'BTN_SETUP_PRODUCT_CONFIGURATOR' | translate}}!</h6>
      <p sfSubtitle2 class="mt-8 color--black-60">
        {{'HINT_PRODUCT_CONTEXT' | translate}}
      </p>

      <div fxFlex="100" fxLayoutAlign="end" fxLayoutGap="8px" class="my-12">
        <button sf-button outlined (click)="configureAddon(levels)" ngClass.xs="width--full" data-cy="configureAddon">
          <sf-icon>add</sf-icon>
          {{'BTN_CONFIGURE_ADDON' | translate}}
        </button>
        <button sf-button outlined (click)="setConfiguration()" ngClass.xs="width--full" data-cy="SetupPricing">
          <sf-icon>add</sf-icon>
          {{'BTN_SETUP_PRICING' | translate}}
        </button>
      </div>

      <sf-accordion class="list__accordion" [multi]="true">
        <sf-accordion-item *ngFor="let level of levels; let i=index">
          <sf-accordion-item-header [attr.data-cy]="level.name">
            <button sfIconButton class="list__delete pull--right mr-32 cursor--pointer" (click)="deleteLevel(level); $event.stopPropagation()">
              <sf-icon>delete_outlined</sf-icon>
            </button>
<!--            <sf-icon class="pc-list__accordion&#45;&#45;delete pull&#45;&#45;right mt-4 mr-32 cursor&#45;&#45;pointer" (click)="deleteLevel(level)">delete</sf-icon>-->
            <div fxLayoutAlign.gt-xs="start center">
              <sf-chip class="mr-20">{{i + 1}}</sf-chip>
              <div class="list__accordion-header">
                <p sfSubtitle1 class="bold color--black-87">
                  {{level.name | translateName}}
                </p>
                <!--To be shown when there is data inside level and delete button should be disabled-->
                <p sfSubtitle2 class="color--black-60 mt-4" *ngIf="level.levelRule">
                  {{level.levelRule.contractDuration ? 'Contract Durations' + (level.type === levelType.Addon ? ' |' : '') : ''}}
                  {{level.type === levelType.Addon ? 'AddOn' : ''}}
                </p>
              </div>
            </div>
          </sf-accordion-item-header>
          <div fxLayout="row wrap" class="mt-20">
            <ew-level-create [levelData]="level" (saveState)="saveLevelState($event)"
                             (saveEvent)="$event && save()"></ew-level-create>
          </div>
        </sf-accordion-item>
      </sf-accordion>

      <div fxLayout="row" class="mt-20" *ngIf="!newLevel && checkLastLevel(levels)">
        <button sf-button primary (click)="createCategoryLevel()" fxFlex.xs="100" data-cy="addNextCategoryLevel">
          <sf-icon>add</sf-icon>
          {{'BTN_ADD_CATEGORY_LEVEL' | translate}}
        </button>
      </div>

      <div class="list-category mt-24" *ngIf="newLevel">
        <div fxLayout="row wrap" fxLayoutAlign="space-between" class="px-16">
          <p sfSubtitle1 class="bold color--black-87">
            <sf-chip class="mr-20 list-category__level-chip">{{levels.length + 1}}</sf-chip>
            {{'LABEL_CATEGORY_LEVEL' | translate}} {{levels.length + 1}}
          </p>
        </div>
        <sf-card outlined fxLayout="row wrap" class="p-24 mt-20">
          <ew-level-create (saveState)="saveLevelState($event)" (saveEvent)="$event && save()"></ew-level-create>
        </sf-card>
      </div>
    </ng-container>
  </ng-container>
</sf-card>
