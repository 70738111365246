import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { EmailAlias } from '../../models/graphql';

export class EmailFormValidator {
    static createValidator(emails: EmailAlias[]): AsyncValidatorFn {
        return (formControl: AbstractControl): Observable<ValidationErrors | null> => {
            const emailExist: boolean = emails.some((email) => (email.user + '@' + email.domain) === formControl.value);
            return of(emailExist ? { emailAlreadyExists: true } : null);
        };
    }
}

export class PasswordValueValidator {
    static asciiCodeValidator(): AsyncValidatorFn {
        return (formControl: AbstractControl): Observable<ValidationErrors | null> => {
            const unacceptedCharacterInPasswordFound: boolean = (/[^\x20-\x7F]/g).test(formControl.value);
            return of(unacceptedCharacterInPasswordFound ? { unacceptedCharacterFound: true } : null);
        };
    }
}