import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfRadioComponent } from './radio.component';
import { SfRadioGroupComponent } from './radio-group.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    SfRadioComponent, SfRadioGroupComponent
  ],
  declarations: [SfRadioComponent, SfRadioGroupComponent]
})
export class SfRadioModule {
}
