import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfIconModule} from '../../../icon/src/index';
import {SfChipComponent, SfChipText, SfChipIcon, SfChipCheckmark} from './chip.component';
import {SfChipSetComponent} from './chip-set.component';

export const CHIP_DECLARATIONS = [
  SfChipComponent,
  SfChipCheckmark,
  SfChipIcon,
  SfChipSetComponent,
  SfChipText
];

@NgModule({
  imports: [CommonModule, SfIconModule],
  exports: CHIP_DECLARATIONS,
  declarations: CHIP_DECLARATIONS
})
export class SfChipsModule { }
