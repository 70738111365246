import { GroupUserSortEnum, UserSortEnum } from '../models/graphql';
import {AdminRoleEnumConstType, AdminRoleType, MobileFleetListingSortOptionsType, MobileUserListingSortOptionsType, 
    NavItem, NotificationSortOptionValue, PasswordCriteria, 
    StatusType, GoogleAnalyticsCookieType } from '../models/shared.model';

export const TOKEN_KEY: string = `App.token`;

export const APP_NAVIGATION: NavItem[] = [
    {
        label: 'User_listing',
        header: 'User Listing',
        icon: 'supervisor_account',
        type: 'item',
        url: '/user-listing',
        permissionKeys: 'user_listing-read'
    },
    {
        label: 'dashboard',
        header: 'Dashboard',
        icon: 'dashboard',
        type: 'item',
        url: '/dashboard',
        permissionKeys: 'dashboard-read'
    },
    {
        label: 'Profile',
        header: 'Profile',
        icon: 'person_outline',
        type: 'item',
        url: '/profile',
        permissionKeys: 'profile-read'
    },
    {
        label: 'services',
        type: 'group',
        icon: 'my_location',
        permissionKeys: 'services-read',
        children: [
            {
                label: 'installation_address',
                type: 'item',
                header: 'Installation Address',
                icon: 'person-outline',
                permissionKeys: 'installation_address-read',
                url: '/installation-address'
            },
            {
                label: 'billing_address',
                type: 'item',
                header: 'Billing Address',
                icon: 'person-outline',
                permissionKeys: 'billing_address-read',
                url: '/billing-address'
            }
        ]
    },
    {
        label: 'product_configurator',
        icon: 'miscellaneous_services',
        type: 'item',
        permissionKeys: 'product_configurator-read',
        url: '/product-configurator/product'
    },
    {
        label: 'products',
        header: 'Products',
        type: 'item',
        icon: 'category',
        permissionKeys: 'product-read',
        url: '/products'
    },
    {
        label: 'banners',
        header: 'Banners',
        type: 'item',
        icon: 'category',
        permissionKeys: 'banner-read',
        url: '/banners'
    },
    {
        label: 'my_activity',
        header: 'My Activity',
        type: 'item',
        icon: 'list_alt',
        permissionKeys: 'my_activity-read',
        url: '/my-activity'
    },
    {
        label: 'billing',
        header: 'Billing',
        type: 'item',
        icon: 'description',
        permissionKeys: 'billing-read',
        url: '/billings'
    },
    {
        label: 'ticketing',
        header: 'Ticketing',
        type: 'item',
        icon: 'credit_card',
        permissionKeys: 'ticketing-read',
        url: '/tickets'
    },
    {
        label: 'groups',
        header: 'Groups',
        type: 'item',
        icon: 'supervisor_account',
        permissionKeys: 'group-read',
        url: '/groups'
    },    
    {
        label: 'network_status',
        header: 'Network Status',
        type: 'item',
        icon: 'rss_feed',
        permissionKeys: 'network_status-read',
        url: '/network-status'
    },
];


export const PASSWORD_CRITERIA: PasswordCriteria[] = [
    {hint: 'HINT_EIGHT_CHARACTERS', value: null, status: false, id: 'length'},
    {hint: 'HINT_UPPERCASE_CHARACTER', value: '(A-Z)', status: false, id: 'uppercase'},
    {hint: 'HINT_LOWERCASE_CHARACTER', value: '(a-z)', status: false, id: 'lowercase'},
    {hint: 'HINT_ONE_DIGIT', value: '(0-9)', status: false, id: 'digit'},
    {hint: 'HINT_ONE_SYMBOL', value: '(? . , ! - ~ $ % + =)', status: false, id: 'symbol'},
];

export const CHAR_MATCHER_REGEX = {
    lowercase: /[a-z]/,
    uppercase: /[A-Z]/,
    digit: /\d/,
    symbol: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
}

export const ESSENTIAL_COOKIE_CONST: GoogleAnalyticsCookieType[] = [
    {
        name: '_ga',
        expiryDate: 'MONTHS',
        description: 'ESSENTIAL_ANALYTICS_COOKIE'
    }
]

export const GOOGLE_ANALYTICS_COOKIES_CONST: GoogleAnalyticsCookieType[] = [
    {
        name: 'ad_personalization',
        expiryDate: 'MONTHS',
        description: 'AD_PERSONALIATION_COOKIE'
    },
    {
        name: 'ad_storage',
        expiryDate: 'MONTHS',
        description: 'AD_STORAGE_COOKIE'
    },
    {
        name: 'ad_user_data',
        expiryDate: 'MONTHS',
        description: 'AD_USER_DATA_COOKIE'
    },
    {
        name: 'analytics_storage',
        expiryDate: 'MONTHS',
        description: 'ANALYTICS_STORAGE_COOKIE'
    },
    {
        name: 'functionality_storage',
        expiryDate: 'MONTHS',
        description: 'FUNCTIONALITY_STORAGE_COOKIE'
    },
    {
        name: 'personalization_storage',
        expiryDate: 'MONTHS',
        description: 'PERSONALIZAION_STORAGE_COOKIE'
    },
    {
        name: 'security_storage',
        expiryDate: 'MONTHS',
        description: 'SECURITY_STORAGE_COOKIE'
    },
]

export const COOKIE_TABLE_HEADER_CONST: StatusType[] = [
    { details: 'LABEL_NAME'},
    { details: 'LABEL_EXPIRY'},
    { details: 'LABEL_DESCRIPTION'},
];

export const MOBILE_FLEET_LISTING_SORT_OPTIONS: MobileFleetListingSortOptionsType[] = [
    {
        sortHeader: 'LABEL_NAME',
        sortValueOptions: [
            { direction: 'LOWEST_TO_HIGHEST', sortDirectionValue: 'asc', sortHeader: GroupUserSortEnum.FullName, id: 'nameAsc' },
            { direction: 'HIGHEST_TO_LOWEST', sortDirectionValue: 'desc', sortHeader: GroupUserSortEnum.FullName, id: 'nameDesc' },
        ]
    },       
    {
        sortHeader: 'LABEL_CLIENT_ID',
        sortValueOptions: [
            {direction: 'LOWEST_TO_HIGHEST', sortDirectionValue: 'asc', sortHeader: GroupUserSortEnum.ClientNumber,  id: 'clientIdAsc'},
            {direction: 'HIGHEST_TO_LOWEST', sortDirectionValue: 'desc', sortHeader: GroupUserSortEnum.ClientNumber,  id: 'clientIdDesc'},
        ]
    }, 
    {
        sortHeader: 'LABEL_PHONE',
        sortValueOptions: [
            { direction: 'LOWEST_TO_HIGHEST', sortDirectionValue: 'asc', sortHeader: GroupUserSortEnum.MobileNumber, id: 'numberAsc' },
            { direction: 'HIGHEST_TO_LOWEST', sortDirectionValue: 'desc', sortHeader: GroupUserSortEnum.MobileNumber, id: 'numberDesc' },
        ]
    },
]


export const MOBILE_USER_LISTING_SORT_OPTIONS: MobileUserListingSortOptionsType[] = [
    {
        sortHeader: 'LABEL_CLIENT_ID',
        sortValueOptions: [
            {direction: 'LOWEST_TO_HIGHEST', sortDirectionValue: 'asc', sortHeader: UserSortEnum.ClientNumber,  id: 'clientIdAsc'},
            {direction: 'HIGHEST_TO_LOWEST', sortDirectionValue: 'desc', sortHeader: UserSortEnum.ClientNumber,  id: 'clientIdDesc'},
        ]
    },
    {
        sortHeader: 'LABEL_NAME',
        sortValueOptions: [
            { direction: 'LOWEST_TO_HIGHEST', sortDirectionValue: 'asc', sortHeader: UserSortEnum.Name, id: 'nameAsc' },
            { direction: 'HIGHEST_TO_LOWEST', sortDirectionValue: 'desc', sortHeader: UserSortEnum.Name, id: 'nameDesc' },
        ]
    },        {
        sortHeader: 'LABEL_PHONE',
        sortValueOptions: [
            { direction: 'LOWEST_TO_HIGHEST', sortDirectionValue: 'asc', sortHeader: UserSortEnum.MobileNumber, id: 'numberAsc' },
            { direction: 'HIGHEST_TO_LOWEST', sortDirectionValue: 'desc', sortHeader: UserSortEnum.MobileNumber, id: 'numberDesc' },
        ]
    },
]

export const AMDIN_ROLE_ENUM_CONST: AdminRoleEnumConstType[] = [
    {
        variable: 'isAdmin',
        key: 'ADMIN_ROLE'
    },
    {
        variable: 'isGroupAdmin',
        key: 'GROUP_ADMIN'
    }, {
        variable: 'isSuperAdmin',
        key: 'SUPER_ADMIN'
    },
    {
        variable: 'isAdmin',
        key: 'PRODUCT_ADMIN'
    },    
    {
        variable: 'isAdmin',
        key: 'MARKETING_ADMIN'
    },    
    {
        variable: 'isAdmin',
        key: 'ADMIN_GROUP'
    },
]



export const ADMIN_ROLE_SELECT_CONST: AdminRoleType[] = [
    {
        label: 'MARKETING_ADMIN',
        id: 'marketing_admin',
        selected: undefined
    },
    {
        label: 'PRODUCT_ADMIN',
        id: 'product_admin',
        selected: undefined
    },        
    {
        label: 'ADMIN_GROUP',
        id: 'admin_group',
        selected: undefined
    },
] 
export const NOTIFICAION_SORT_OPTION_VALUE: NotificationSortOptionValue = {
    all: undefined,
    read: true,
    unread: false
}

export enum RoleEnum {
    ADMIN_ROLE = 'admin',
    GROUP_ADMIN = 'group_admin',
    USER = 'user',
    SUPER_ADMIN = 'super_admin',
    PRODUCT_ADMIN = 'product_admin',
    MARKETING_ADMIN = 'marketing_admin',
    ADMIN_GROUP = 'admin_group'
}