import { Component, Input, TemplateRef } from '@angular/core';
import { StepState } from '@angular/cdk/stepper';

@Component({
  selector: 'sf-step-indicator',
  template: ''
})
export class SfStepIndicatorComponent {
  /** Name of the icon to be overridden. */
    // tslint:disable-next-line:no-input-rename
  @Input('sfStepperIcon') name: StepState;

  constructor(public templateRef: TemplateRef<SfStepIndicatorContext>) {
  }
}

export interface SfStepIndicatorContext {
  /** Index of the step. */
  index: number;
  /** Whether the step is currently active. */
  active: boolean;
  /** Whether the step is optional. */
  optional: boolean;
}
