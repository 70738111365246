import { TypedDocumentNode } from '@apollo/client/core';
import { ProductItem, ProductItemsConnection } from '@ew/shared/services';
import { gql } from 'apollo-angular';

export const PRODUCT_ITEM_QUERY: TypedDocumentNode<{
  productItem: ProductItem;
}> = gql`
  query productItem($id: ID!) {
    productItem(id: $id) {
      id
      active
      name
      images {
        id
        urls
      }
      prices {
        id
        countryId
        comparative
        mrc
        otc
        quantity
      }
      packageId
      dynamicValues {
        dynamicColumnId
        dynamicOptionId
        id
        name
        value
        valuableType
        valuableId
      }
      specifications {
        id
        productItemId
        title
        value
      }
    }
  }
`;

export const PRODUCT_ITEMS_QUERY: TypedDocumentNode<{
  productItems: ProductItemsConnection;
}> = gql`
  query productItems(
    $active: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $packageId: ID
    $query: String
    $skip: Int
  ) {
    productItems(
      active: $active
      after: $after
      before: $before
      first: $first
      last: $last
      packageId: $packageId
      query: $query
      skip: $skip
    ) {
      nodes {
        active
        images
        name
        prices
        packageId
        id
      }
      totalCount
      pageInfo {
        endCursor
      }
    }
  }
`;
