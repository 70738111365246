<sf-card fxLayout="row wrap" fxLayoutAlign="start center" class="product-base p-24" ngClass.gt-xs="my-32" ngClass.xs="mb-8" sfElevation="0" *ngIf="currentLevel">
  <label fxLayoutAlign="center center" class="product-base__icon mr-16">
    <ng-container *ngIf="currentLevel?.rank === 0">
      <sf-icon>card_travel</sf-icon>
    </ng-container>
    <ng-container *ngIf="currentLevel?.rank !== 0">
      <img *ngIf="currentProduct?.package?.images && currentProduct?.package?.images[0]?.urls[0]" [src]="currentProduct?.package?.images[0]?.urls[0]" alt="package_image">
      <sf-icon *ngIf="!(currentProduct?.package?.images && currentProduct?.package?.images[0]?.urls[0])">dashboard_customize</sf-icon>
    </ng-container>
  </label>
  <div>
    <h6 sfHeadline6>{{(((currentLevel?.rank === 0 ? currentLevel?.name : currentProduct?.package.name) | translateName) | titlecase) | translate}}</h6>
    <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxHide.lt-sm class="mt-8">
    <ng-container *ngIf="currentLevel?.rank === 0">
      <ng-container *ngFor="let level of allLevel; let i=index">
        <sf-chip class="product-base__list mr-8">{{level?.name | translateName}}</sf-chip>
      </ng-container>
    </ng-container>

        <ng-container *ngIf="currentLevel?.rank !== 0">
      <span sfSubtitle2
            class="color--black-60 mr-12">{{((currentLevel?.name | translateName) + ' category') | titlecase}}</span>
      <sf-chip class="service-details__list mr-8"
               [ngClass]="{'active': currentProduct?.active, 'inactive': !currentProduct?.active}">
        {{(currentProduct?.active ? "LABEL_ACTIVE" : "LABEL_INACTIVE") | translate}}
      </sf-chip>
    </ng-container>
  </div>
  </div>
  <p sfSubtitle2 fxFlex="100" class="mt-24 color--black-60" *ngIf="currentLevel?.rank === 0">
    {{'HINT_PRODUCT_CONTEXT' | translate}}
  </p>
</sf-card>
<sf-card class="product-list p-24" sfElevation="0">
  <div class="tab-content">
          <ng-container [ngTemplateOutlet]="searchFilter"></ng-container>
          <ew-service-list [currentLevel]="currentLevel" [products]='productState?.collection'
                           [currentProductAncestry]="currentProduct?.ancestry"
                           [currentAncestryDepth]="currentProduct?.ancestryDepth || currentLevel.rank"></ew-service-list>
  </div>
</sf-card>
<ng-template #searchFilter>
    <ew-search-filter [currentLevel]="currentLevel"
                      [type]="'ProductTrees'"
                      [buttonMenu]="currentLevel.name | translateName"
                      [currentProduct]="currentProduct"
                      [currentProductAncestry]="currentProductAncestry"
                      [currentAncestryDepth]="currentAncestryDepth"
                      [queryForm]="form">
    </ew-search-filter>
</ng-template>
