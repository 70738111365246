import { Component, ComponentRef, EmbeddedViewRef, ViewChild } from '@angular/core';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { SfDialogConfig } from '../dialog-config';

@Component({
  selector: 'sf-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  host: {
    class: 'sf-full-width'
  }
})
export class DialogContainer extends BasePortalOutlet {
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet: CdkPortalOutlet;
  id: string;

  constructor(public sfDialog: SfDialogConfig) {
    super();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this._portalOutlet.hasAttached()) {
      throw new Error('Already attached');
    }
    return this._portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    if (this._portalOutlet.hasAttached()) {
      throw new Error('Already attached');
    }
    return this._portalOutlet.attachTemplatePortal(portal);
  }
}
