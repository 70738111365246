import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { ProductItem } from '@ew/shared/services';

export const DELETE_PRODUCT_ITEM_MUTATION: TypedDocumentNode<{ deleteProductItem: ProductItem }> = gql`
  mutation deleteProductItem($input: DeleteProductItemInput!) {
    deleteProductItem(input: $input) {
      id
    }
  }
`;
