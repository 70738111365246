import {
    DeliveryTime,
    DeliveryTimeAttributes,
    DynamicColumn,
    DynamicValue,
    ImagesAttributes,
    Package,
    Preorder,
    PreorderAttributes,
} from '../../graphql';

import { ContractDurationModel, MultiLanguages, ProductLimitModel } from '../../shared.model';

export class PackageModel implements Package {
  name: MultiLanguages;
  id: string;
  type: string;
  configuratorPriceTypeId: string;
  preorder: Preorder;
  deliveryTime: DeliveryTime;
  packageContractDurations: ContractDurationModel[];
  productLimit: ProductLimitModel;
  imageIds: string[];
  images: ImagesAttributes[];
  dynamicColumns: DynamicColumn[];
  dynamicValues: DynamicValue[];

  constructor() {
  	this.id = this.type = this.configuratorPriceTypeId = undefined;
  	this.name = new MultiLanguages();
  	this.preorder = new PreorderModel();
  	this.deliveryTime = new DeliveryTimeModel();
  	this.productLimit = new ProductLimitModel();
  	this.packageContractDurations = [];
  	this.dynamicColumns = [];
  	this.imageIds = [];
  	this.images = [];
  }
}

export class PreorderModel implements PreorderAttributes {
  id: string;
  releaseDate: Date;
  startDate: Date;
  _destroy: boolean;

  constructor() {
  	this.id = this.releaseDate = this.startDate = this._destroy = undefined;
  }
}

export class DeliveryTimeModel implements DeliveryTimeAttributes {
  id: string;
  maximum: number;
  minimum: number;
  _destroy: boolean;

  constructor() {
  	this.id = this.minimum = this.maximum = this._destroy = undefined;
  }
}
