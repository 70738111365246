import { ComponentFactoryResolver, InjectionToken, Injector, ViewContainerRef } from '@angular/core';
import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Direction } from '@angular/cdk/bidi';

/** Possible values for horizontalPosition on SfSnackBarConfig. */
export type SfSnackBarHorizontalPosition = 'start' | 'center' | 'end' | 'left' | 'right';

/** Possible values for verticalPosition on SfSnackBarConfig. */
export type SfSnackBarVerticalPosition = 'top' | 'bottom';

export type SfSnackbarType = 'info' | 'success' | 'error' | 'warning';

export class SfSnackBarConfig<D = any> {
  id?: string;
  resolver?: ComponentFactoryResolver;
  injector?: Injector;
  width?: number | string = '';
  /** The politeness level for the SfAriaLiveAnnouncer announcement. */
  politeness?: AriaLivePoliteness = 'assertive';

  /**
   * Message to be announced by the LiveAnnouncer. When opening a snackbar without a custom
   * component or template, the announcement message will default to the specified message.
   */
  announcementMessage? = '';

  /** The view container to place the overlay for the snack bar into. */
  viewContainerRef?: ViewContainerRef;

  /** The length of time in milliseconds to wait before automatically dismissing the snack bar. */
  duration = 0;

  /** Extra CSS classes to be added to the snack bar container. */
  panelClass? = '';

  /** Text layout direction for the snack bar. */
  direction?: Direction;

  /** Data being injected into the child component. */
  data?: D | null = null;

  /** The horizontal position to place the snack bar. */
  horizontalPosition?: SfSnackBarHorizontalPosition = 'center';

  /** The vertical position to place the snack bar. */
  verticalPosition?: SfSnackBarVerticalPosition = 'bottom';

  /** Type of snackbar */

  severity?: SfSnackbarType = 'info';
}

/** Injection token that can be used to access the data that was passed in to a snack bar. */
export const SF_SNACK_BAR_DATA = new InjectionToken<any>('SfSnackBarData');
