import { Component, EventEmitter, Output } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SfFormUtil } from '@ew/shared/safire/angular-utils';
import { EventData, ProductFormBuilder } from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';

@Component({
    selector: 'ew-product-base',
    templateUrl: './product-base.component.html',
    styleUrls: ['./product-base.component.scss'],
})
export class ProductBaseComponent extends FormBaseComponent {
  triedSubmit: boolean;
  stepperLength = 3;
  @Output() save: EventEmitter<EventData> = new EventEmitter<EventData>();

  constructor() {
  	super();
  	this.configureForm(ProductFormBuilder.packageForm());
  }

  markAsTouched(): void {
  	SfFormUtil.markAllFormFieldsAsTouched(this.form);
  	SfFormUtil.focusOnErrorElement();
  }

  valueChange(): void {
  	this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
  		this.saveData();
  	});
  }

  proceed(value: string, stepIndex: number): void {
  	this.triedSubmit = true;
  	if (value === 'NEXT') {
  		SfFormUtil.markAllFormFieldsAsTouched(this.form);
  		SfFormUtil.focusOnErrorElement();
  		this.form.valid && this.saveData(value, stepIndex);
  	} else {
  		this.saveData(value, stepIndex);
  	}
  }

  saveData(clickType?: string, stepIndex?: number): void {
  	const data: EventData = {
  		value: this.form.value,
  		valid: this.form.valid,
  		clickType,
  		stepIndex,
  	};
  	this.save.emit(data);
  }
}
