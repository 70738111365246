<div fxLayout="row wrap" fxLayoutAlign.lt-md="space-between center"
     class="package" *ngIf="viewMode">
    <sf-card fxFlexFill outlined fxFlex.xs="100" fxFlex.lt-md="0 0 calc(50% - 12px)" fxFlex.lt-xl="0 0 calc((100% / 3) - 16px)"
             fxFlex.gt-lg="0 0 calc(25% - 18px)"
             class="p-12 mb-26 cursor--pointer package__card" ngClass.gt-sm="mr-24"
             *ngFor="let product of modifiedProductTreeListWithRegion?.collection"
             (click)="enterInsideLevel(product.id); $event.stopPropagation()" [attr.data-cy]="product?.packageName | translateName">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="row" class="ml-N12">
                <div *ngIf="product?.images[0]?.urls[0] as imgUrl" class="package__image">
                    <img [src]="imgUrl" alt="product" class="ml-4" />
                </div>
                <p class="pt-10 pl-12">{{ product?.packageName | translateName }}</p>
            </div>
            <sf-chip class="package__status"
                     [ngClass]="product?.active ? 'package__status--active' : 'package__status--inactive'">{{ 'LABEL_' + (product?.active ? 'ACTIVE' : 'INACTIVE') | translate }}
            </sf-chip>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div [ngClass]="!product?.images[0]?.urls[0] ? 'mt-32' : 'mt-18'" data-cy="status">
                <ng-container *ngIf="product?.regions">
                    {{ 'LABEL_REGION' | translate }}: {{ (product?.regions) | truncate: 35 }}
                </ng-container>
            </div>
            <div fxLayoutAlign="end end" class="mt-16">
                <ew-menu-modal [row]="product" (action)="emitAction($event)"
                               [config]="productMenuConfig"></ew-menu-modal>
            </div>
        </div>
    </sf-card>
</div>
<div *ngIf="!viewMode" class="width--full">
  <ew-tabular-view
    [dataSource]="modifiedProductTreeListWithRegion"
    [tableConfig]="productTableConfig" [setPageEvent]="searchParam?.page - 1"
    [customRoute]="'/product-configurator/product/item-listing/'"
    (updateSearchParam)="updateSearchParam($event)"
    [showAllData]="showAllData"
    (action)="emitAction($event)" [menuConfig]="productMenuConfig"></ew-tabular-view>
</div>
