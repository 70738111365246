import { TypedDocumentNode } from '@apollo/client/core';
import {  ProductTree } from '@ew/shared/services';
import { gql } from 'apollo-angular';

export const ADD_PACKAGE_TO_PRODUCT_TREES: TypedDocumentNode<{addPackageToProductTrees: ProductTree}> = gql`
    mutation addPackageToProductTree($input: AddPackageToProductTreeInput!) {
      addPackageToProductTree(input: $input) {
        id
        packageId
      }
    }
`
