<sf-select [formControl]="dynamicValueFormGroup.get('dynamicOptionId')" [placeholder]="dynamicColumn.name" [outlined]="true" class="width--full">
  <sf-menu>
    <sf-list>
      <sf-list-item *ngFor="let dynamicOption of dynamicColumn.dynamicOptions" [value]="dynamicOption.id">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <p>
            {{dynamicColumn.multilingual ?  (dynamicOption.name | translateName) : dynamicOption.value}}
          </p>
          <div class="color-box" [ngStyle]="{'background-color': dynamicOption.hexValue}"></div>
        </div>
      </sf-list-item>
    </sf-list>
  </sf-menu>
</sf-select>
<!--<mat-error sfCaption class="mt-4 custom-error" *ngIf="dynamicValueFormGroup.get('dynamicOptionId')?.hasError('required') && dynamicValueFormGroup.get('dynamicOptionId').touched">-->
<!--  <span> {{'ERROR_REQUIRED' | translate}} </span>-->
<!--</mat-error>-->
