<div class="constant-preference color--black-60 pt-16" data-cy="constantPreferences">
    <div class="mx-24">
        <img src="/assets/images/logo.svg" alt="rii seez net logo" class="ew-logo"/>
        <p class="color--black-87 mt-20 font-size-20" ngClass.xs="font-size-16">{{ 'LABEL_CONSTANT_PREFERENCE' | translate }}</p>
        <p class="mb-20 mt-4">{{ 'HINT_CUSTOMIZE_CONSTANT_PREFERENCES' | translate }}</p>
    </div>
    <div class="mx-24">
        <sf-accordion displayMode="flat">
            <div class="accordan-container">
                <sf-accordion-item>
                    <sf-accordion-item-header>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="color--black-87 mb-4" ngClass.gt-xs="font-size-16" ngClass.xs="ml-30">
                            <p ngClass.xs="mt-4">{{ 'LABEL_ESSENTIALS_COOKIES' | translate }}</p>
                            <p class="color--success" ngClass.gt-xs="mr-32">{{ 'LABEL_ALWAYS_ACTIVE' | translate }}</p>
                          <!--  <p fxHide.xs class="mr-32 mt-4">{{ 'LABEL_SHOW_COOKIES' | translate }}
                                <span>({{essentialCookie?.length}})</span>
                            </p>-->
                        </div>
<!--                        <p class="font-size-12">{{ 'HINT_OPERATE_DELIVER_SERVICES' | translate }}</p>-->
                    </sf-accordion-item-header>
                    <p class="font-size-12">{{ 'HINT_OPERATE_DELIVER_SERVICES' | translate }}</p>
                    <!--  TODO MIGHT NEED IN FUTURE-->
               <!--  <div fxHide.xs class="accordan-container p-16">
                        <div fxLayout="row" class="accordan-container__border pb-12 color&#45;&#45;black-87 font-size-16">
                            <p fxFlex="25">{{ 'LABEL_NAME' | translate}}</p>
                            <p fxFlex="14">{{ 'LABEL_EXPIRY' | translate}}</p>
                            <p fxFlex="61">{{ 'LABEL_DESCRIPTION' | translate}}</p>
                        </div>
                     <ng-container *ngFor="let item of essentialCookie">
                         <div fxLayout="row" fxLayoutGap="30px" class="pt-12">
                             <p fxFlex="20">{{ item.name }}</p>
                             <p fxFlex="10">{{ 'HINT_' + item.expiryDate | translate }}</p>
                             <p fxFlex="70">{{ 'HINT_' + item.description | translate}}</p>
                         </div>
                     </ng-container>
                 </div>
                 <div fxHide.gt-xs *ngFor="let item of essentialCookie" fxHide.gt-xs
                 class="my-8">
                    <div class="bg&#45;&#45;black-05 color&#45;&#45;black-87 p-8 br&#45;&#45;4">
                        <p>{{ 'LABEL_NAME' | translate}}:<span class="color&#45;&#45;black-60 font-size-12 ml-8">{{ item.name }}</span></p>
                        <p class="my-4">{{ 'LABEL_EXPIRY' | translate}}:<span class="color&#45;&#45;black-60 font-size-12 ml-8">{{ 'HINT_' + item.expiryDate | translate }}</span></p>
                        <p>{{ 'LABEL_DESCRIPTION' | translate}}:<span class="color&#45;&#45;black-60 font-size-12 ml-8">{{ 'HINT_' + item.description | translate }}</span></p>
                 </div>
                </div>-->
                </sf-accordion-item>
            </div>
            <div class="accordan-container mt-16">
                <sf-accordion-item>
                    <sf-accordion-item-header>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="color--black-87 mb-4" ngClass.gt-xs="font-size-16" ngClass.xs="ml-30">
                            <p ngClass.xs="mt-4">{{ 'LABEL_GOOGLE_ANALYTICS' | translate }}</p>
                            <div ngClass.gt-xs="mr-32" class="accordan-container__switch">
                                <sf-form-field>
                                    <sf-switch [checked]="googleAnalyticsPermissionGranted" (changed)="gACookieStateChange($event)"></sf-switch>
                                </sf-form-field>
                            </div>
                           <!-- <p fxHide.xs class="mr-32 mt-4">{{ 'LABEL_SHOW_COOKIES' | translate}}
                                <span>({{googleAnalyticsCookies?.length}})</span>
                            </p>-->
                        </div>
<!--                        <p class="font-size-12">{{ 'HINT_HELP_CUSTOMER_TO_UNDERSTAND' | translate }}</p>-->
                    </sf-accordion-item-header>
                    <p class="font-size-12">{{ 'HINT_HELP_CUSTOMER_TO_UNDERSTAND' | translate }}</p>
                  <!--  TODO MIGHT NEED IN FUTURE-->
                   <!-- <div fxHide.xs class="accordan-container p-16">
                        <div fxLayout="row" class="accordan-container__border pb-12 color&#45;&#45;black-87 font-size-16">
                            <p fxFlex="25">{{ 'LABEL_NAME' | translate}}</p>
                            <p fxFlex="14">{{ 'LABEL_EXPIRY' | translate}}</p>
                            <p fxFlex="61">{{ 'LABEL_DESCRIPTION' | translate}}</p>
                        </div>
                        <ng-container *ngFor="let item of googleAnalyticsCookies">
                            <div fxLayout="row" fxLayoutGap="30px" class="pt-12">
                                <p fxFlex="20">{{ item.name }}</p>
                                <p fxFlex="10">{{ 'HINT_' + item.expiryDate | translate }}</p>
                                <p fxFlex="70">{{ 'HINT_' + item.description | translate }}</p>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngFor="let item of googleAnalyticsCookies" fxHide.gt-xs
                         class="my-8">
                        <div class="bg&#45;&#45;black-05 color&#45;&#45;black-87 p-8 br&#45;&#45;4">
                            <p>{{ 'LABEL_NAME' | translate}}:<span class="color&#45;&#45;black-60 font-size-12 ml-8">{{ item.name }}</span></p>
                            <p class="my-4">{{ 'LABEL_EXPIRY' | translate}}:<span class="color&#45;&#45;black-60 font-size-12 ml-8">{{ 'HINT_' + item.expiryDate | translate }}</span></p>
                            <p>{{ 'LABEL_DESCRIPTION' | translate}}:<span class="color&#45;&#45;black-60 font-size-12 ml-8">{{'HINT_' +  item.description | translate }}</span></p>
                        </div>
                    </div>-->
                </sf-accordion-item>
            </div>
        </sf-accordion>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end" fxLayout.xs="column" class="m-24">
        <button aria-label="go back to main page" sf-button outlined data-cy="goBack" ngClass.xs="width--full mb-12" (click)="closeModal()">{{ 'BTN_GO_BACK' | translate }}
        </button>
        <button aria-label="save and continue" sf-button primary class="ml-12" data-cy="saveAndContinue"
                ngClass.xs="width--full" [disabled]="!googleAnalyticsSelected" (click)="savePreferences()">{{ 'BTN_SAVE' | translate }}
        </button>
    </div>
    <div class="width--full bg--black-10">
        <p class="px-16 py-4">{{ 'HINT_QUERY_RELATION' | translate }}
            <span class="color--cyan-blue cursor--pointer" (click)="redirectToExternalContactUsPage()">{{ 'LABEL_CONTACT_US' | translate }}</span>
        </p>
    </div>
</div>