import {omit} from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace graphqlutil {
    // eslint-disable-next-line @typescript-eslint/ban-types
    export function formatPaginationParams(attributes: Object): Object {
        attributes = {
            ...attributes,
            first: attributes?.['perPage'],
            skip: (attributes?.['page'] - 1) * attributes?.['perPage']
        };
        attributes = omit(attributes, ['perPage', 'page']);

        return attributes;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-explicit-any
    export function formatResponse(data: any, attributes: Object): Object {
        return {
            collection: data.nodes,
            pageInfo: data.pageInfo,
            metaData: {total: data.totalCount + ((attributes?.['page'] ?? 1) - 1) * (attributes?.['perPage'] || 10)}
        };
    }
}

export {graphqlutil as graphqlutil};
