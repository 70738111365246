import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfListDivider} from './list-divider';
import {
  SfListComponent,
  SfListGroup,
  SfListGroupSubheader
} from './list.component';
import {
  SfListItem,
  SfListItemGraphic,
  SfListItemMeta,
  SfListItemSecondary,
  SfListItemText
} from './list-item.component';

const LIST_DECLARATIONS = [
  SfListComponent,
  SfListDivider,
  SfListGroup,
  SfListGroupSubheader,
  SfListItem,
  SfListItemGraphic,
  SfListItemMeta,
  SfListItemSecondary,
  SfListItemText
];

@NgModule({
  imports: [CommonModule],
  exports: LIST_DECLARATIONS,
  declarations: LIST_DECLARATIONS,
})
export class SfListModule { }
