<ng-container *ngIf="levelState$ | async as state">
  <form [formGroup]="form" autocomplete="off" fxLayout.gt-xs="row wrap">
    <div fxFlex.gt-xs="70" fxFlex.lt-md="100">
      <div fxLayout="column" class="pc-create">
        <p sfSubtitle1 class="color--black-87">{{'LABEL_CATEGORY_NAME_DESCRIPTIONS' | translate}}</p>
        <p sfSubtitle2 class="mt-8 color--black-60">
          {{'HINT_CATEGORY_NAME_DESCRIPTIONS' | translate}}
        </p>

        <p sfCaption class="mt-24 color--black-60">
          {{'HINT_SELECT_LANGUAGE' | translate}}
        </p>
        <ew-multi-lang [copyControls]="copyControls" [form]="form"></ew-multi-lang>

        <!--Show this only when Category is created-->
        <div *ngIf="isConfigured" fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="end" class="mt-20">
          <button *ngIf="!editMode" sf-button outlined (click)="editChange(true)" data-cy="Edit">Edit</button>
          <ng-container *ngIf="editMode">
            <button sf-button outlined (click)="editChange(false)" data-cy="Cancel">{{'BTN_CANCEL' | translate}}</button>
            <button sf-button primary ngClass.gt-xs="ml-12" ngClass.xs="mt-12"
                    (click)="save()" data-cy="SAVE">{{'BTN_SAVE' | translate}}</button>
          </ng-container>
        </div>
        <!--End-->

        <div [ngClass]="{'pc-create__not-editable': isConfigured}"
                  [ngStyle]="{'pointer-events': isConfigured ? 'none' : 'all', 'display': 'contents'}">

          <p sfSubtitle1 class="mt-40 color--black-87">{{'LABEL_CATEGORY_LEVEL_SETTING' | translate}}</p>
          <p sfSubtitle2 class="mt-8 color--black-60">
            {{'HINT_CATEGORY_LEVEL_SETTING' | translate}}
          </p>

          <sf-form-field>
            <sf-checkbox class="pc-create__checkbox" formControlName="imageable" [attr.data-cy]="'LABEL_HAVE_IMAGES' | translate"></sf-checkbox>
            <label sfSubtitle2>{{'LABEL_HAVE_IMAGES' | translate }}</label>
          </sf-form-field>

          <ew-dynamic-columns-form [parentFormGroupRef]="form"></ew-dynamic-columns-form>

          <p sfSubtitle1 class="mt-24 color--black-87">{{'QUEST_SELECTION_RULE' | translate}}</p>
          <p sfSubtitle2 class="mt-8 color--black-60">
            {{'HINT_SELECTION_RULE' | translate}}
          </p>

          <sf-chip-set choice class="pc-create__primary-chip mt-8" formControlName="selectionRule" data-cy="categoryRule">
            <sf-chip [value]="true">
              <sf-chip-text>{{'BTN_YES' | translate}}</sf-chip-text>
            </sf-chip>
            <sf-chip [value]="false">
              <sf-chip-text>{{'BTN_NO' | translate}}</sf-chip-text>
            </sf-chip>
          </sf-chip-set>

          <ng-container *ngIf="!(levelData?.id || levelData?.type === levelType.Addon)">
            <p sfSubtitle1 class="mt-24 color--black-87">{{'QUEST_LAST_CATEGORY_LEVEL' | translate}}</p>
            <p sfSubtitle2 class="mt-8 color--black-60">
              {{'HINT_lAST_CATEGORY_LEVEL' | translate}}
            </p>

            <sf-chip-set choice class="pc-create__primary-chip mt-8" formControlName="type" data-cy="categoryLevel">
              <sf-chip [value]="levelType.Product">
                <sf-chip-text>{{'BTN_YES' | translate}}</sf-chip-text>
              </sf-chip>
              <sf-chip [value]="levelType.ProductNode">
                <sf-chip-text>{{'BTN_NO' | translate}}</sf-chip-text>
              </sf-chip>
            </sf-chip-set>
          </ng-container>

          <div formGroupName="levelRule">
            <ng-container *ngIf="form.get('type').value === levelType.Product">

              <p sfSubtitle1 class="mt-24 color--black-87">
                {{'QUEST_ITEMS_CONTRACT_DURATION' | translate}}
              </p>
              <p sfSubtitle2 class="mt-8 color--black-60">
                {{'HINT_ITEMS_CONTRACT_DURATION' | translate}}
              </p>
              <sf-chip-set choice class="pc-create__primary-chip mt-8" formControlName="contractDuration" data-cy="contactDuration">
                <sf-chip [value]="true">
                  <sf-chip-text>{{'BTN_YES' | translate}}</sf-chip-text>
                </sf-chip>
                <sf-chip [value]="false">
                  <sf-chip-text>{{'BTN_NO' | translate}}</sf-chip-text>
                </sf-chip>
              </sf-chip-set>
            </ng-container>

            <!-- REVISIT_DAKPA: Sept 6, 2021 - Not required, but if required in future, uncomment -->
            <!-- <ng-container *ngIf="levelData?.type === levelType.Addon">
              <h5 sfSubtitle1 class="mt-24 color--black-87">
                {{'QUEST_ADDONS_RULES' | translate}}
              </h5>
              <p sfSubtitle2 class="mt-8 color--black-60">
                {{'HINT_ADDONS_RULES' | translate}}
              </p>
              <sf-chip-set choice class="pc-create__primary-chip mt-8" formControlName="addonRule" data-cy="addOnsRule">
                <sf-chip [value]="true">
                  <sf-chip-text>{{'BTN_YES' | translate}}</sf-chip-text>
                </sf-chip>
                <sf-chip [value]="false">
                  <sf-chip-text>{{'BTN_NO' | translate}}</sf-chip-text>
                </sf-chip>
              </sf-chip-set>
            </ng-container> -->

            <ng-container *ngIf="form.get('type').value === levelType.Product || form.get('type').value === levelType.Addon">
              <h5 sfSubtitle1 class="mt-24 color--black-87">
                {{'QUEST_ADDITIONAL_INFORMATION' | translate}} </h5>
              <p sfSubtitle2 class="my-8 color--black-60">
                {{'HINT_CHOOSE_ADDITIONAL_INFORMATION' | translate}}
              </p>
              <div fxLayout="column"  data-cy="additionalInfo">
                <sf-form-field *ngFor="let info of additionalInfo">
                  <sf-checkbox class="pc-create__checkbox" [formControlName]="info"></sf-checkbox>
                  <label sfSubtitle2>{{('LABEL_' + (info | pascalToUppercase)) | translate}}</label>
                </sf-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isConfigured && !hideActionButtons" fxFlex.gt-xs="100" fxLayout.gt-xs="row" fxLayout.xs="column-reverse" fxLayoutAlign.gt-xs="end" class="mt-20">
      <button sf-button outlined (click)="cancel()" ngClass.xs="mt-12" data-cy="Cancel">{{'BTN_CANCEL' | translate}}</button>

      <button sf-button primary ngClass.gt-xs="ml-12" (click)="save()" data-cy="SAVE">{{'BTN_SAVE' | translate}}</button>
    </div>
  </form>
</ng-container>
