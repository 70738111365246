/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'translateName',
    pure: false
})
export class TranslateNamePipe implements PipeTransform {

    constructor(private  translateService : TranslateService) {
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: any, args?: any): any {
        return value && typeof value === 'object' ? value[this.translateService.currentLang] : value;
    }

}
