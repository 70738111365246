import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

const tinycolor = require('tinycolor2');

export interface ThemeConfig {
  primaryColor?: string;
  extraProperties?: PropertyType[];
}

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export interface PropertyType {
  key: string;
  value: string;
  type?: 'string' | 'url';
}

@Injectable({
    providedIn: 'root'
})
export class ThemingService {
  primaryColor = '#0166B3'; //fallback color
  primaryColorPalette: Color[] = [];
  /* eslint-disable @typescript-eslint/ban-types */
  private subject = new BehaviorSubject<Object>(undefined);

  setPrimaryPaletteColor(themeConfig?: ThemeConfig) {
      themeConfig = themeConfig || {};
      this.primaryColorPalette = this.computeColors(themeConfig.primaryColor || this.primaryColor);

      for (const color of this.primaryColorPalette) {
          const key1 = `--theme-primary-${color.name}`;
          const value1 = color.hex;
          const key2 = `--theme-secondary-contrast-${color.name}`;
          const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
          document.documentElement.style.setProperty(key1, value1);
          document.documentElement.style.setProperty(key2, value2);
      }

      // tslint:disable-next-line:no-unused-expression
      themeConfig.extraProperties && this.setExtraPropertyList(themeConfig.extraProperties);
  }

  setExtraPropertyList(extraPropertyList?: PropertyType[]) {
      if (extraPropertyList && extraPropertyList.length) {
          extraPropertyList.forEach(property => {
              document.documentElement.style.setProperty(property.key,
                  property.type === 'url' ? `url(${property.value})` : property.value);
          });
      }
  }

  computeColors(hex: string): Color[] {
      return [
          this.getColorObject(hex, ''),
          this.getColorObject(tinycolor(hex).lighten(52), '50'),
          this.getColorObject(tinycolor(hex).lighten(37), '100'),
          this.getColorObject(tinycolor(hex).lighten(26), '200'),
          this.getColorObject(tinycolor(hex).lighten(12), '300'),
          this.getColorObject(tinycolor(hex).lighten(6), '400'),
          this.getColorObject(tinycolor(hex), '500'),
          this.getColorObject(tinycolor(hex).darken(6), '600'),
          this.getColorObject(tinycolor(hex).darken(12), '700'),
          this.getColorObject(tinycolor(hex).darken(18), '800'),
          this.getColorObject(tinycolor(hex).darken(24), '900'),
          this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
          this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
          this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
          this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
      ];
  }

  getColorObject(value: string, name: string): Color {
      const c = tinycolor(value);
      return {
          name: name,
          hex: c.toHexString(),
          darkContrast: c.isLight()
      };
  }

  setChangeHandler(themeObj: Object) {
      this.subject.next(themeObj);
  }

  getChangeHandler() {
      return this.subject.asObservable();
  }
}


