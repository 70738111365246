import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  SfDrawerComponent,
  SfDrawerHeader,
} from './drawer.component';
import {
  SfDrawerAppContent,
  SfDrawerContent,
  SfDrawerSubtitle,
  SfDrawerTitle
} from './drawer.directives';

const DRAWER_DECLARATIONS = [
  SfDrawerComponent,
  SfDrawerAppContent,
  SfDrawerContent,
  SfDrawerHeader,
  SfDrawerSubtitle,
  SfDrawerTitle
];

@NgModule({
  imports: [CommonModule],
  exports: [DRAWER_DECLARATIONS],
  declarations: [DRAWER_DECLARATIONS]
})
export class SfDrawerModule {}
