export const CONDITION_FNS: { [key: string]: string } = {
    '===': 'eq',
    '!==': 'neq',
    '>': 'gt',
    '>=': 'gte',
    '<': 'lt',
    '<=': 'lte',
    '&&': 'AND',
    '||': 'OR'
};
