import { MultiLanguages } from '../shared.model';
import { DynamicValuesAttributes } from '../graphql';

export class DynamicValueModel implements DynamicValuesAttributes {
  id?: string;
  dynamicColumnId: string;
  dynamicOptionId?: string;
  name?: MultiLanguages;
  valuableId?: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _destroy?: boolean;

  constructor() {
  	this.dynamicColumnId = undefined;
  	this.dynamicOptionId = undefined;
  	this.id = undefined;
  	this.name = new MultiLanguages();
  	this.valuableId = undefined;
  	this.value = undefined;
  	this._destroy = undefined;
  }
}
