import { DynamicValue, Image, Prices, ProductItem, Specifications } from '../../graphql';
import { MultiLanguages } from '../../shared.model';

export class ProductItemModel implements ProductItem {
  id: string = undefined;
  active: boolean = undefined;
  dynamicValues: DynamicValue[] = [];
  packageId: string = undefined;
  images: Image[] = [];
  imageIds: string[] = [];
  name = new MultiLanguages();
  prices: Prices[] = [];
  specifications: Specifications[] = [];
}
