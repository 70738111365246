import { ElementRef } from '@angular/core';
import { SfHelperText } from './helper-text.component';
import { SfRippleService } from '../../../ripple/src/index';

export abstract class SfFormFieldControl<T> {
  /** The value of the control. */
  value?: T | null = null;

  /** The element ID for this control. */
  readonly id!: string;

  /** The element ID for this control's hidden input. */
  readonly inputId?: string;

  readonly elementRef!: ElementRef;

  readonly ripple?: SfRippleService;

  readonly controlType?: string;

  helperText?: SfHelperText | null;
}
