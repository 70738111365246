import { DynamicOption } from '../graphql';
import { MultiLanguages } from '../shared.model';

export class DynamicOptionModel implements DynamicOption {
  hexValue: string = undefined;
  id: string = undefined;
  meta?: Record<string, any> = {};
  name?: MultiLanguages = new MultiLanguages();
  value: string = undefined;
  _destroy: boolean = undefined;
}
