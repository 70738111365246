import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SfDialogService } from '@ew/shared/safire/components';
import {
    LevelModel, LevelTypeEnum,
    MenuConfig, PCBreadcrumb,
    ProductStateEnum,
    ProductTreeList,
    ProductTreesQueryModel,
    TableConfig, TableDataSource
} from '@ew/shared/services';
import { tap } from 'rxjs/operators';

import { pcRootPath, PRODUCT_MENU_CONFIG, PRODUCT_TABLE_LIST_CONFIG } from '../../../constants/products.constant';
import { ProductFacadeService } from '../../../services/product-facade.service';
import { ProductConfirmationModalComponent } from '../../product/product-confirmation-modal/product-confirmation-modal.component';
import {TranslateService} from '@ngx-translate/core';

interface ModifiedProductTreeList extends ProductTreeList {
	regions: string
}

@UntilDestroy()
@Component({
    selector: 'ew-package-list',
    templateUrl: './package-list.component.html',
    styleUrls: ['./package-list.component.scss'],
})
export class PackageListComponent implements OnInit {
  readonly levelType: typeof LevelTypeEnum = LevelTypeEnum;

  productTableConfig: TableConfig[] = PRODUCT_TABLE_LIST_CONFIG;
  productMenuConfig: MenuConfig[] = PRODUCT_MENU_CONFIG;
  searchParam: ProductTreesQueryModel;
  rootPath = pcRootPath;
  modifiedProductTreeListWithRegion!: TableDataSource<ModifiedProductTreeList>;

  @Input() showAllData: boolean;
  @Input() viewMode: boolean;
  @Input() productTree: ProductTreeList;
  @Input() subProductTreesConnection: TableDataSource<ProductTreeList>;
  @Input() level: LevelModel;
  @Input() currentProductAncestry: string;
  @Input() currentAncestryDepth: number;

  @ViewChild('dialog', { static: true }) simpleDialog: TemplateRef<unknown>;

  constructor(private router: Router, private productFacadeService: ProductFacadeService,
			  private dialogService: SfDialogService, private translate: TranslateService) {}

  ngOnInit(): void {
      this.modifyProductTreeList(this.subProductTreesConnection);
  	  this.productFacadeService.specificStateChange<TableDataSource<ProductTreeList>>(ProductStateEnum.PRODUCT_TREES)
  		.subscribe(value => {
  			this.subProductTreesConnection = value;
              this.modifyProductTreeList(value);
          });
  }

  modifyProductTreeList(productTreeList: TableDataSource<ProductTreeList>): void {
      this.modifiedProductTreeListWithRegion = this.productFacadeService.mapProductTreeListWithRegions(productTreeList, this.level);
  }

  updateSearchParam(searchParamModel?: ProductTreesQueryModel): void {
  	const productTreesQueryPayload = { ...this.searchParam, ...searchParamModel };
  	this.productFacadeService.updateSpecificState<ProductTreesQueryModel>(productTreesQueryPayload, ProductStateEnum.PRODUCT_TREES_QUERY);
  	this.productFacadeService.getProductTressByAncestryData(this.currentProductAncestry, this.currentAncestryDepth);
  }

  enterInsideLevel(id: string): void {
	  const packageDetail= this.subProductTreesConnection?.collection?.find(level => level.id === id)
	  const existingBreadcrumb: PCBreadcrumb[] = this.productFacadeService.getSpecificState<PCBreadcrumb[]>(ProductStateEnum.BREADCRUMBS);
	  existingBreadcrumb?.push({url: `${pcRootPath}item-listing/${id}`,
		  label: packageDetail?.packageName?.[this.translate.currentLang]});
	  this.productFacadeService.updateSpecificState<PCBreadcrumb[]>(existingBreadcrumb, ProductStateEnum.BREADCRUMBS)
  	this.router.navigateByUrl(`/product-configurator/product/item-listing/${id}`);
  }

  emitAction(event: { actionKey: string; row: unknown | ProductTreeList }): void {
  	['delete', 'deleteMultiple', 'changeStatus'].includes(event.actionKey)
  		? this.appendAction(event)
  		: this[`${event.actionKey}Product`](event.row);
  }

  cloneProduct(product: ProductTreeList): void {
  	this.productFacadeService
  		.cloneProductById(product?.id)
  		.pipe(
  			untilDestroyed(this),
  			tap(() => this.productFacadeService.getProductTressByAncestryData(this.currentProductAncestry, this.currentAncestryDepth))
  		)
  		.subscribe();
  }

  editProduct(row: unknown | ProductTreeList): void {
  	const isAddon = this.level?.type === LevelTypeEnum.Addon;
  	const addonAncestry: string[] = row['ancestry'].split('/');
  	const addonParentId: string = addonAncestry[addonAncestry.length - 1];
  	this.router.navigate([`${this.rootPath}product-create/${row['id']}`],
  		{ queryParams: { addon: isAddon, edit: true, parentId: isAddon ? addonParentId : this.productTree?.id } });
  }

  appendAction(event: { actionKey: string; row: unknown | ProductTreeList | ProductTreeList[] }): void {
  	this.dialogService.open(ProductConfirmationModalComponent, {
  		severity: 'primary',
  		maxWidth: 500,
  		data: this.getModalData(event),
  	});
  }

  private getModalData = (event: { actionKey: string; row: unknown | ProductTreeList | ProductTreeList[] }): Record<string, unknown> => ({
  	title: ['delete', 'deleteMultiple'].includes(event.actionKey)
  		? 'LABEL_DELETE_CATEGORY'
  		: event.row['active']
  			? 'LABEL_DEACTIVATE_CATEGORY'
  			: 'LABEL_ACTIVATE_CATEGORY',
  	message: ['delete', 'deleteMultiple'].includes(event.actionKey)
  		? 'HINT_DELETE_CATEGORY_PERMANENTLY'
  		: event.row['active']
  			? 'HINT_DEACTIVATE_CATEGORY'
  			: 'HINT_ACTIVATE_CATEGORY',
  	button: ['delete', 'deleteMultiple'].includes(event.actionKey) ? 'BTN_DELETE' : event.row['active'] ? 'BTN_DEACTIVATE' : 'BTN_ACTIVATE',
  	productData: event.row,
  	multipleProductsData: event.row,
  	actionType: event.actionKey,
  	productType: 'Product',
  });
}
