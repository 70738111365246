import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SfBreadcrumbService } from './services/breadcrumb.service';
import { filter } from 'rxjs/operators';
import { BreadcrumbConfig } from './models/config';

@Component({
  selector: 'sf-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class SfBreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: BreadcrumbConfig[] = [];
  history = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: SfBreadcrumbService
  ) {
  }

  ngOnInit() {
    this.intializer();
  }

  ngOnDestroy() {
  }

  intializer(): void {
    // subscribe to the NavigationEnd event
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        setTimeout(() => this.service.updateBreadCrumb(), 400);
      });
    if (this.breadcrumbs && this.breadcrumbs.length <= 0) {
      // Trigger only once on login.
      this.service.updateBreadCrumb();
    }
    this.service.breadcrumb.subscribe(
      (breadcrumb: BreadcrumbConfig[]) => {
        //update breadcrumbs
        this.breadcrumbs = breadcrumb;
      });
  }

  goToRoute(breadcrumb: BreadcrumbConfig): void {
    this.router.navigate([breadcrumb.url, breadcrumb.param]);
  }
}
