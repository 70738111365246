import { Subject } from 'rxjs';

import { DialogContainer } from './dialog-container/dialog-container.component';
import { OverlayRef } from '@angular/cdk/overlay';

export interface DialogCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data?: R;
}

let uniqueId = 0;

export class DialogRef<T, R = any> {
  disableClose = false;
  componentInstance: T;
  private readonly _afterClosed$ = new Subject<DialogCloseEvent<R>>();

  constructor(
    private overlayRef: OverlayRef, private dialogInstance: DialogContainer,
    readonly id: string = `sf-dialog-${uniqueId++}`) {

    this.dialogInstance.id = id;
    overlayRef.backdropClick().subscribe(() => this._close('backdropClick'));
  }

  get afterClosed() {
    return this._afterClosed$.asObservable();
  }

  close(data?: R) {
    this._close('close', data);
  }

  private _close(type: 'backdropClick' | 'close', data?: R) {
    this._afterClosed$.next({ type, data });
    this._afterClosed$.complete();
    this.overlayRef.detachBackdrop();
    this.overlayRef.dispose();
  }
}
