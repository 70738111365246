import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translateMonth'
})
export class TranslateMonthPipe implements PipeTransform {

  //TODO: Move this to Constant File in the future(Zangphel: 18-06-2024)
  monthsInEnglish: string[] = ['January', 
      'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  monthsInGerman: string[] = ['Januar', 'Februar', 'März', 
      'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

  transform(value: string, type: string, currentLanguage: string): string {
      type.includes('billings') && (currentLanguage === 'de') && this.monthsInEnglish.forEach((month, index) => {
          if (value.includes(month)) value = value.replace(month, this.monthsInGerman[index]);
      });
      return value;
  }
}