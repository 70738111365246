import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';


/** Stepper data that is required for internationalization. */
@Injectable({ providedIn: 'root' })
export class SfStepperIntlService {
  /**
   * Stream that emits whenever the labels here are changed. Use this to notify
   * components if the labels have changed after initialization.
   */
  readonly changes: Subject<void> = new Subject<void>();

  /** Label that is rendered below optional steps. */
  optionalLabel = 'Optional';
}


/** @docs-private */
export function SF_STEPPER_INTL_PROVIDER_FACTORY(parentIntl: SfStepperIntlService) {
  return parentIntl || new SfStepperIntlService();
}

/** @docs-private */
export const SF_STEPPER_INTL_PROVIDER = {
  provide: SfStepperIntlService,
  deps: [[new Optional(), new SkipSelf(), SfStepperIntlService]],
  useFactory: SF_STEPPER_INTL_PROVIDER_FACTORY
};
