import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountPipe, DestroyFilterPipe, FileExtensionPipe, FileSizePipe } from './pipes';
import {
    FileDropperDirective,
    FileLinkDirective,
    ImagePreviewDirective,
    ImageViewDirective,
    LocationDirective,
    NumberOnlyDirective
} from './directives';

const DECLARATIONS = [
    DestroyFilterPipe,
    FileExtensionPipe,
    FileSizePipe,
    AmountPipe,
    FileLinkDirective,
    ImageViewDirective,
    LocationDirective,
    FileDropperDirective,
    ImagePreviewDirective,
    NumberOnlyDirective];

@NgModule({
    imports: [CommonModule],
    declarations: DECLARATIONS,
    exports: DECLARATIONS
})
export class SfAngularUtilsModule {
}
