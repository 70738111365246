<div class="sf-stepper">
  <ng-container *ngFor="let step of steps; let i = index; let isLast = last">
    <sf-step-header (click)="step.select()"
                    (keydown)="_onKeydown($event)"
                    [tabIndex]="_getFocusIndex() === i ? 0 : -1"
                    [id]="_getStepLabelId(i)"
                    [attr.aria-posinset]="i + 1"
                    [attr.aria-setsize]="steps.length"
                    [attr.aria-controls]="_getStepContentId(i)"
                    [attr.aria-selected]="selectedIndex == i"
                    [attr.aria-label]="step.ariaLabel || null"
                    [attr.aria-labelledby]="(!step.ariaLabel && step.ariaLabelledby) ? step.ariaLabelledby : null"
                    [index]="i"
                    [state]="_getIndicatorType(i, step.state)"
                    [label]="step.stepLabel || step.label"
                    [selected]="selectedIndex === i"
                    [active]="step.completed || selectedIndex === i || !linear"
                    [optional]="step.optional"
                    [errorMessage]="step.errorMessage"
                    [iconOverrides]="_iconOverrides"
                    [disableRipple]="disableRipple">
    </sf-step-header>
    <div *ngIf="!isLast"  class="sf-stepper__connector-line"></div>
  </ng-container>
</div>

<div class="sf-step-content">
  <div *ngFor="let step of steps; let i = index"
       [attr.tabindex]="selectedIndex === i ? 0 : null"
       class="sf-step-content__remove-outline" role="tabpanel"
       [id]="_getStepContentId(i)"
       [attr.aria-labelledby]="_getStepLabelId(i)"
       [attr.aria-expanded]="selectedIndex === i">
    <ng-container [ngTemplateOutlet]="step.content" *ngIf="selectedIndex===i"></ng-container>
  </div>
</div>
