import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'pascalToUppercase',
    pure: true
})
export class PascalToUppercasePipe implements PipeTransform {

    transform(value: unknown): string | unknown {
        if (typeof value !== 'string') return value;
        return value.replace(/[A-Z]/g, (capitalLetters) => '_' + capitalLetters).toUpperCase();
    }

}
