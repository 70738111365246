import { Component } from '@angular/core';
import { SfIconComponent } from '../../../icon/src/index';

@Component({
  selector: '[sfSelectIcon]',
  exportAs: 'sfSelectIcon',
  host: { 'class': 'mdc-select__icon' },
  template: ''
})
export class SfSelectIconDirective extends SfIconComponent {
}
