import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ConfiguratorPrice, Level, ProductsStoreState, StepConfig } from '@ew/shared/services';
import { Observable } from 'rxjs';

import { ProductFacadeService } from '../../../../services/product-facade.service';
import { ProductBaseComponent } from '../../../product-base/product-base.component';

@Component({
    selector: 'ew-pricing-details-step',
    templateUrl: './pricing-details-step.component.html',
    styleUrls: ['./pricing-details-step.component.scss'],
})
export class PricingDetailsStepComponent extends ProductBaseComponent implements OnInit {
  state$: Observable<ProductsStoreState> = this.productsFacadeService.stateChange();
  @Input() config: StepConfig;
  @Input() length: number;
  @Input() form: FormGroup;
  @Input() level: Level;

  priceTypes: ConfiguratorPrice[];

  constructor(private productsFacadeService: ProductFacadeService) {
  	super();
  }

  ngOnInit(): void {
  	this.getPriceType();
  	this.valueChange();
  }

  getPriceType(): void {
  	this.productsFacadeService
  		.getConfiguration()
  		.pipe(untilDestroyed(this))
  		.subscribe((configurators) => {
  			this.priceTypes = configurators[0].configuratorPriceTypes;
  		});
  }
}
