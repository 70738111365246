import { TypedDocumentNode } from '@apollo/client/core';
import { Package } from '@ew/shared/services';
import { gql } from 'apollo-angular';

export const UPDATE_PACKAGE_MUTATION: TypedDocumentNode<{
  updatePackage: Package;
}> = gql`
  mutation updatePackage($input: UpdatePackageInput!) {
    updatePackage(input: $input) {
      id
      levelId
      name
      type
    }
  }
`;
