import { AfterViewInit, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SfTabActivatedEvent, SfTabBarComponent } from '../tab-bar.component';

@Directive({
  selector: '[sfTabContent], sf-tab-content'
})
export class SfTabContent implements OnInit, AfterViewInit {
  /*
    @Input('sfTabContentTab') tab: SfTabComponent;
  */
  @Input('sfTabContent') tabBar: SfTabBarComponent;
  // tslint:disable-next-line:no-input-rename
  @Input('sfTabContentTabId') tabId: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.tabBar.activated.subscribe((event: SfTabActivatedEvent) => {
      if (this.tabId === event.tab.id) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }else {
        this.viewContainerRef.detach();
      }
    });

  }
}
