import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfIconModule } from '../../../icon/src/index';
import { SfIconButtonModule } from '../../../icon-button/src/index';
import { SfElevationModule } from '../../../elevation/src/index';
import { SfSnackBarContainerComponent } from './snack-bar-container/snack-bar-container.component';
import { SfDefaultSnackBarComponent } from './default-snack-bar/default-snack-bar.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { SfButtonModule } from '../../../button/src/index';

@NgModule({
  imports: [CommonModule, SfIconModule, SfIconButtonModule, SfElevationModule,
    OverlayModule,
    PortalModule, SfButtonModule],
  declarations: [SfSnackBarContainerComponent, SfDefaultSnackBarComponent],
  entryComponents: [SfSnackBarContainerComponent, SfDefaultSnackBarComponent]
})
export class SfSnackBarModule {
}
