import { ContentChild, Directive, ElementRef, Input } from '@angular/core';
import {
  CdkCell,
  CdkCellDef,
  CdkColumnDef,
  CdkFooterCell,
  CdkFooterCellDef,
  CdkHeaderCell,
  CdkHeaderCellDef
} from '@angular/cdk/table';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SfCheckboxComponent } from '../../../checkbox/src/index';

/**
 * Cell definition for the sf-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[sfTableCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: SfTableCellDefDirective }]
})
export class SfTableCellDefDirective extends CdkCellDef {
}

/**
 * Header cell definition for the sf-table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[sfTableHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: SfTableHeaderCellDefDirective }]
})
export class SfTableHeaderCellDefDirective extends CdkHeaderCellDef {
}

/**
 * Footer cell definition for the sf-table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[sfTableFooterCellDef]',
  providers: [{ provide: CdkFooterCellDef, useExisting: SfTableFooterCellDefDirective }]
})
export class SfTableFooterCellDefDirective extends CdkFooterCellDef {
}

/**
 * Column definition for the sf-table.
 * Defines a set of cells available for a table column.
 */
@Directive({
  selector: '[sfTableColumnDef]',
  providers: [
    { provide: CdkColumnDef, useExisting: SfTableColumnDefDirective },
    { provide: 'SF_SORT_HEADER_COLUMN_DEF', useExisting: SfTableColumnDefDirective }
  ]
})
export class SfTableColumnDefDirective extends CdkColumnDef {
  /** Unique name for this column. */
  @Input('sfTableColumnDef') name: string;

  /** Whether this column should be sticky positioned at the start of the row */
  @Input() sticky: boolean;

  /** Whether this column should be sticky positioned on the end of the row */
  @Input() stickyEnd: boolean;
}

/** Header cell template container that adds the right classes and role. */
@Directive({
  selector: 'th[sf-table-header-cell]',
  host: {
    'class': 'mdc-data-table__header-cell sf-table__header-cell',
    '[class.mdc-data-table__header-cell--checkbox]': '!!_checkbox',
    '[class.mdc-data-table__header-cell--numeric]': 'numeric',
    'role': 'columnheader',
    'scope': 'col'
  }
})
export class SfTableHeaderCellDirective extends CdkHeaderCell {
  @ContentChild(SfCheckboxComponent, { static: false }) _checkbox?: SfCheckboxComponent;

  constructor(columnDef: SfTableColumnDefDirective,
              elementRef: ElementRef<HTMLElement>) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(`sf-column-${columnDef.cssClassFriendlyName}`);
  }

  private _numeric = false;

  @Input()
  get numeric(): boolean {
    return this._numeric;
  }

  set numeric(value: boolean) {
    this._numeric = coerceBooleanProperty(value);
  }
}

/** Footer cell template container that adds the right classes and role. */
@Directive({
  selector: 'td[sf-table-footer-cell]',
  host: {
    'class': 'sf-table__footer-cell',
    'role': 'gridcell'
  }
})
export class SfTableFooterCellDirective extends CdkFooterCell {
  constructor(columnDef: SfTableColumnDefDirective,
              elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(`sf-column-${columnDef.cssClassFriendlyName}`);
  }
}

/** Cell template container that adds the right classes and role. */
@Directive({
  selector: 'td[sf-table-cell]',
  exportAs: 'sfTableCell',
  host: {
    'role': 'gridcell',
    'class': 'mdc-data-table__cell sf-table__cell',
    '[class.mdc-data-table__cell--checkbox]': 'checkbox',
    '[class.mdc-data-table__cell--numeric]': 'numeric'
  }
})
export class SfTableCellDirective extends CdkCell {
  constructor(columnDef: SfTableColumnDefDirective,
              elementRef: ElementRef<HTMLElement>) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(`sf-column-${columnDef.cssClassFriendlyName}`);
  }

  private _checkbox = false;

  @Input()
  get checkbox(): boolean {
    return this._checkbox;
  }

  set checkbox(value: boolean) {
    this._checkbox = coerceBooleanProperty(value);
  }

  private _numeric = false;

  @Input()
  get numeric(): boolean {
    return this._numeric;
  }

  set numeric(value: boolean) {
    this._numeric = coerceBooleanProperty(value);
  }
}

