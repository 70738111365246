import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, Input, isDevMode, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { SortDirection } from './sort-direction';
import {
  getSortDuplicateSortableIdError,
  getSortHeaderMissingIdError,
  getSortInvalidDirectionError
} from './sort-errors';

/** Interface for a directive that holds sorting state consumed by `SfSortHeaderComponent`. */
export interface SfSortable {
  /** The id of the column being sorted. */
  id: string;

  /** Starting sort direction. */
  start: 'asc' | 'desc';

  /** Whether to disable clearing the sorting state. */
  disableClear: boolean;
}

/** The current sort state. */
export interface SortEvent {
  /** The id of the column being sorted. */
  active: string;

  /** The sort direction. */
  direction: SortDirection;
}

/** Container for SfSortables to manage the sort state and provide default sort parameters. */
@Directive({
  selector: '[sfTableSort]',
  exportAs: 'sfRTableSort'
})
export class SfTableSortDirective
  implements OnChanges, OnDestroy, OnInit {

  @Input() disabled: boolean;

  /** Collection of all registered sortables that this directive manages. */
  sortables = new Map<string, SfSortable>();

  /** Used to notify any child components listening to state changes. */
  readonly _stateChanges = new Subject<void>();

  /** The id of the most recently sorted SfSortable. */
    // tslint:disable-next-line:no-input-rename
  @Input('sfSortActive') active: string;

  /**
   * The direction to set when an SfSortable is initially sorted.
   * May be overridden by the SfSortable's sort start.
   */
    // tslint:disable-next-line:no-input-rename
  @Input('sfSortStart') start: 'asc' | 'desc' = 'asc';
  /** Event emitted when the user changes either the active sort or sort direction. */
    // tslint:disable-next-line:no-output-rename
  @Output('sfSortChange') readonly sortChange: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();

  private _direction: SortDirection = '';

  /** The sort direction of the currently active SfSortable. */
  @Input('sfSortDirection')
  get direction(): SortDirection {
    return this._direction;
  }

  set direction(direction: SortDirection) {
    if (isDevMode() && direction && direction !== 'asc' && direction !== 'desc') {
      throw getSortInvalidDirectionError(direction);
    }
    this._direction = direction;
  }

  private _disableClear: boolean;

  /**
   * Whether to disable the user from clearing the sort by finishing the sort direction cycle.
   * May be overriden by the SfSortable's disable clear input.
   */
  @Input('sfSortDisableClear')
  get disableClear(): boolean {
    return this._disableClear;
  }

  set disableClear(v: boolean) {
    this._disableClear = coerceBooleanProperty(v);
  }

  /**
   * Register function to be used by the contained SfSortables. Adds the SfSortable to the
   * collection of SfSortables.
   */
  register(sortable: SfSortable): void {
    if (!sortable.id) {
      throw getSortHeaderMissingIdError();
    }

    if (this.sortables.has(sortable.id)) {
      throw getSortDuplicateSortableIdError(sortable.id);
    }
    this.sortables.set(sortable.id, sortable);
  }

  /**
   * Unregister function to be used by the contained SfSortables. Removes the SfSortable from the
   * collection of contained SfSortables.
   */
  deregister(sortable: SfSortable): void {
    this.sortables.delete(sortable.id);
  }

  /** Sets the active sort id and determines the new sort direction. */
  sort(sortable: SfSortable): void {
    if (this.active !== sortable.id) {
      this.active = sortable.id;
      this.direction = sortable.start ? sortable.start : this.start;
    } else {
      this.direction = this.getNextSortDirection(sortable);
    }

    this.sortChange.emit({ active: this.active, direction: this.direction });
  }

  /** Returns the next sort direction of the active sortable, checking for potential overrides. */
  getNextSortDirection(sortable: SfSortable): SortDirection {
    if (!sortable) {
      return '';
    }

    // Get the sort direction cycle with the potential sortable overrides.
    const disableClear = sortable.disableClear != null ? sortable.disableClear : this.disableClear;
    const sortDirectionCycle = getSortDirectionCycle(sortable.start || this.start, disableClear);

    // Get and return the next direction in the cycle
    let nextDirectionIndex = sortDirectionCycle.indexOf(this.direction) + 1;
    if (nextDirectionIndex >= sortDirectionCycle.length) {
      nextDirectionIndex = 0;
    }
    return sortDirectionCycle[nextDirectionIndex];
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this._stateChanges.next();
  }

  ngOnDestroy() {
    this._stateChanges.complete();
  }
}

/** Returns the sort direction cycle to use given the provided parameters of order and clear. */
function getSortDirectionCycle(start: 'asc' | 'desc',
                               disableClear: boolean): SortDirection[] {
  const sortOrder: SortDirection[] = ['asc', 'desc'];
  if (start === 'desc') {
    sortOrder.reverse();
  }
  if (!disableClear) {
    sortOrder.push('');
  }

  return sortOrder;
}
