import { Injectable } from '@angular/core';
import {Permission, User} from '@ew/shared/services';

@Injectable({
    providedIn: 'root'
})
export class SecurityBlService {
    private policyMatrices: Permission[];

    constructor(private window: Window) { }

    isGuardRoutes(): boolean {
        return !this.window.location.pathname.includes('auth');
    }

    hasPermission(params: string, policyMatrices: Permission[]): boolean {
        let valid = !params;
        const accessKeys: string[] = params?.split(',');

        this.setPolicyMatrices(policyMatrices);

        !valid && accessKeys?.forEach(accessKey => {
            if (valid) return;
            if (accessKey.charAt(0) === '#') {
                valid = this.getAnyAuthorisedKey(accessKey.substring(1));
            } else if (accessKey.indexOf('-') > -1) {
                valid = this.isAuthorisedKey(accessKey);
                return;
            } else if (typeof JSON.parse(accessKey) === 'boolean') {
                valid = JSON.parse(accessKey);
            }
        });

        return valid;
    }

    setPolicyMatrices(policyMatrices: Permission[]): void {
        this.policyMatrices = policyMatrices;
    }

    private getAnyAuthorisedKey(accessKey: string): boolean {
        const resource: { [key: string]: string } = this.getResource(accessKey)?.actions as { [key: string]: string };
        return !!Object.keys(resource).find(key => resource[key]);
    }

    private isAuthorisedKey(accessKey: string): boolean {
        const keys: string[] = accessKey.split('-');
        return !!(this.getResource(keys[0])?.actions || {})[keys[1]];
    }

    private getResource(resource: string): Permission {
        return (this.policyMatrices ? this.policyMatrices.find(matrix => matrix.resource === resource) || {} : {});
    }

    isOtpRoutes = (): boolean  => this.window.location.pathname.includes('otp');

    getValueFromLS(key: string): string {
        return JSON.parse(localStorage.getItem(key) || sessionStorage.getItem(key))
    }

    getActivePolicies = (user: User): Permission[] => user.role.permissions;

    isPreviouslyLoggedIn = (): boolean => !!localStorage.getItem('role_name');
}
