import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DynamicField} from '../../models/dynamic-field';
import {DynamicColumn} from '@ew/shared/services';
import {FormGroup} from '@angular/forms';
import {SfSelectChange} from '@ew/shared/safire/components';

@Component({
    selector: 'ew-dynamic-options-field',
    templateUrl: './dynamic-options-field.component.html',
    styleUrls: ['./dynamic-options-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DynamicOptionsFieldComponent implements DynamicField {
    @Input() dynamicColumn: DynamicColumn;
    @Input() dynamicValueFormGroup: FormGroup;

    valueSelectedStyle = false;

    onSelect(event: SfSelectChange): void {
        this.valueSelectedStyle = true;
    }
}
