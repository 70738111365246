/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-useless-escape */
export declare type google = any;
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Utils {
  export const googleAddressComponents = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
  };

  export interface GoogleAddressType {
    route?: string;
    street_number?: string | number;
    postal_code?: string | number;
    locality?: string;
    administrative_area_level_1?: string;
    country?: string;
  }

  /**
   * @description Check if the file is an Image File
   * @param file - A File object
   * @returns boolean*/

  export function isImageFile(file: File): boolean {
      /*tslint:disable:curly*/
      if (!file) return false;
      const fileExt = (file.name || '').split('.').pop() as string;
      const ext = ['png', 'jpg', 'jpeg'];
      const regx = new RegExp(`^image\/(${ext.join('|')})$`);
      return (
          regx.test(file.type) && new RegExp(`^(${ext.join('|')})$`).test(fileExt)
      );
  }

  /**
   * @description Check if a number is positive
   * @param number - A number represented as integer or string
   * @returns boolean
   * */
  export function isPositiveNumber(number: string | number): boolean {
      return /^(?!0+$)\d+$/.test(number.toString());
  }

  /**
   * @description Convert bytes to human readable text upto PB( Peta Bytes)
   * @param bytes - Bytes in number
   * @param  precision( default = 2) - Number of digits after decimal
   * @returns string
   * */
  export function humanReadableFileSize(
      bytes: number,
      precision: number = 2
  ): string {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      /*tslint:disable:curly*/
      if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
      let unit = 0;

      while (bytes >= 1024) {
          bytes /= 1024;
          unit++;
      }

      return bytes.toFixed(+precision) + ' ' + this.units[unit];
  }

  /**
   * @description Formats a @link GoogleAddressType to readable address
   * @param address_components : @link GeocoderAddressComponent
   * @param name @optional - a location name
   * @returns string
   * */
  export function formatDisplayAddress(
      // @ts-ignore
      address_components: google.maps.GeocoderAddressComponent[],
      name?: string
  ) {
      const addressData: GoogleAddressType = {};
      for (let i = 0; i < address_components.length; i++) {
          const addressType = address_components[i].types[0];
          if (googleAddressComponents[addressType]) {
              addressData[addressType] =
          address_components[i][googleAddressComponents[addressType]];
          }
      }

      return [
          `${(name && name + ',') || ''}`,
          `${addressData.route || ''} ${(addressData.street_number &&
        addressData.street_number + ',') ||
      ''}`,
          addressData.postal_code || '',
          `${(addressData.locality || addressData.administrative_area_level_1) +
      ','}`,
          addressData.country
      ]
          .join(' ')
          .replace(/\s{2,}/g, ' ');
  }
}
