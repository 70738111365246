import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import {
  CDK_ROW_TEMPLATE,
  CdkFooterRow,
  CdkFooterRowDef,
  CdkHeaderRow,
  CdkHeaderRowDef,
  CdkRow,
  CdkRowDef
} from '@angular/cdk/table';
import { SfCheckboxComponent } from '../../../checkbox/src/index';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

let uniqueIdCounter = 0;


/**
 * Header row definition for the sf-table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
@Directive({
  selector: '[sfTableHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: SfHeaderRowDefDirective }]
})
export class SfHeaderRowDefDirective extends CdkHeaderRowDef {
  @Input('sfTableHeaderRowDef') columns: Iterable<string>;
  @Input('sfTableHeaderRowDefSticky') sticky: boolean;
}

/**
 * Footer row definition for the sf-table.
 * Captures the footer row's template and other footer properties such as the columns to display.
 */
@Directive({
  selector: '[sfTableFooterRowDef]',
  providers: [{ provide: CdkFooterRowDef, useExisting: SfFooterRowDefDirective }]
})
export class SfFooterRowDefDirective extends CdkFooterRowDef {
  @Input('sfTableFooterRowDef') columns: Iterable<string>;
  @Input('sfTableFooterRowDefSticky') sticky: boolean;
}

/**
 * Data row definition for the sf-table.
 * Captures the data row's template and other properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
@Directive({
  selector: '[sfTableRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: SfTableRowDefDirective }]
})
export class SfTableRowDefDirective<T> extends CdkRowDef<T> {
  @Input('sfTableRowDefColumns') columns: Iterable<string>;
  @Input('sfTableRowDefWhen') when: (index: number, rowData: T) => boolean;
}

/** Header template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'tr[sf-table-header-row]',
  template: CDK_ROW_TEMPLATE,
  exportAs: 'sfTableHeaderRow',
  host: { 'class': 'mdc-data-table__header-row sf-table__header-row', 'role': 'row' },
  providers: [{ provide: CdkHeaderRow, useExisting: SfTableHeaderRowComponent }]
})
export class SfTableHeaderRowComponent extends CdkHeaderRow {
  constructor(public elementRef: ElementRef<HTMLElement>) {
    super();
  }
}

/** Footer template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'tr[sf-table-footer-row]',
  template: CDK_ROW_TEMPLATE,
  host: {
    'class': 'sf-footer-row',
    'role': 'row'
  },
  exportAs: 'sfTableFooterRow',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CdkFooterRow, useExisting: SfTableFooterRowComponent }]
})
export class SfTableFooterRowComponent extends CdkFooterRow {
}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'tr[sf-table-row]',
  exportAs: 'sfTableRow',
  host: {
    'role': 'row',
    '[attr.data-row-id]': 'id',
    'class': 'mdc-data-table__row sf-table__row',
    '[class.mdc-data-table__row-checkbox]': '!!_checkbox',
    '[class.mdc-data-table__row--selected]': 'selected'
  },
  template: CDK_ROW_TEMPLATE,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkRow, useExisting: SfTableRowComponent }]
})
export class SfTableRowComponent extends CdkRow{
  @ContentChild(SfCheckboxComponent, { static: false }) _checkbox?: SfCheckboxComponent;

  constructor(public elementRef: ElementRef<HTMLElement>) {
    super();
  }

  private _id = `sf-table-row-${uniqueIdCounter++}`;

  /** The unique ID of the row. */
  get id(): string {
    return this._id;
  }

  private _selected = false;

  @Input()
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = coerceBooleanProperty(value);
  }

  getNativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}


/** Row that can be used to display a message when no data is shown in the table. */
@Directive({
  selector: 'ng-template[sfTableNoDataRow]',
})
export class SfNoDataRowDirective {
  constructor(public templateRef: TemplateRef<any>) {
  }
}
