import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreadcrumbConfig } from '../models/config';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class SfBreadcrumbService {
  $breadcrumb = new BehaviorSubject<BreadcrumbConfig[]>([]);

  constructor(private activatedRoute: ActivatedRoute) {
  }

  /**
   * Run when route changes
   * Updates breadcrumb state
   **/

  get breadcrumb(): Observable<BreadcrumbConfig[]> {
    return this.$breadcrumb.asObservable();
  }

  updateBreadCrumb() {
    this.getBreadcrumbs(this.activatedRoute.root);
  }

  setBreadCrumb(crumbs: BreadcrumbConfig[]): void {
    this.$breadcrumb.next(crumbs);
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: BreadcrumbConfig[] = []
  ) {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) return this.setBreadCrumb(breadcrumbs);

    let label, param, isParent, hasDataLabel, header;
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      param = Object.assign({}, child.snapshot.params);

      if (!(child.snapshot.url.map(segment => segment.path).length))
        return this.getBreadcrumbs(child, url, breadcrumbs);

      const labelData = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      label = labelData || routeURL;
      isParent = labelData ? false : child.snapshot.data['isParent'];
      hasDataLabel = !!labelData;
      header = child.snapshot.data['header'] || header;

      if (!child.snapshot.data['skipBreadcrumb'] && label) {
        // append route URL to URL
        url += `/${routeURL}`;
        const breadcrumb: BreadcrumbConfig = { label, param, url, isParent, hasDataLabel, header };
        get(child.snapshot.data, 'includedParams', []).forEach((routeParam: string) =>
          (breadcrumb.label += child.snapshot.params[routeParam] || ''));
        breadcrumbs.push(breadcrumb);
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
