export const MAPPED_VALIDATIONS = {
    required: 'ERROR_REQUIRED',
    numbersOnly: 'NUMBERS_ONLY_ERROR',
    atLeastOne: 'AT_LEAST_ONE_ERROR',
    email: 'ERROR_INVALID_EMAIL',
    invalidPhone: 'ERROR_INVALID_PHONE',
    alreadyExists: 'ALREADY_EXISTS_ERROR',
    invalidSelection: 'SELECT_FROM_LIST_ERROR',
    password: 'ERROR_MISMATCH_PASSWORD'
};

export interface BillingInterface {
    notification: boolean,
    userId?: string,
    startDate?: string,
    endDate?: string
}

export const MONTH_TRANSLATION = {
    January: 'LABEL_JANUARY',
    February: 'LABEL_FEBRUARY',
    March: 'LABEL_MARCH',
    April: 'LABEL_APRIL',
    May: 'LABEL_MAY',
    June: 'LABEL_JUNE',
    July: 'LABEL_JULY',
    August: 'LABEL_AUGUST',
    September: 'LABEL_SEPTEMBER',
    October: 'LABEL_OCTOBER',
    November: 'LABEL_NOVEMBER',
    December: 'LABEL_DECEMBER'
}

export const PROFILE_ROLE_NAME = {
    admin: 'LABEL_ADMINISTRATOR',
    group_admin: 'LABEL_GROUP_ADMIN',
    super_admin: 'LABEL_SUPER_ADMIN',
    marketing_admin: 'LABEL_MARKETING_ADMIN',
    product_admin: 'LABEL_PRODUCT_ADMIN',
    admin_group: 'LABEL_ADMIN_GROUP'
}

export const UNIT_TYPE = {
    volumen: 'GB',
    minuten: 'Min.',
    events: 'SMS'
}