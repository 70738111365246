import {Injectable} from '@angular/core';
import {Route, Routes} from '@angular/router';
import {ShellComponent} from '@ew/app/shell/shell.component';
import {AuthGuard} from '@ew/shared/checkpoint';

@Injectable({
    providedIn: 'root'
})
export class Shell{

    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellComponent,
            children: routes,
            canActivate: [AuthGuard],
            data: {reuse: true}
        };
    }
}
