import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[ewNonDigitRemoval]'
})
export class NonDigitRemovalDirective {

  @HostListener('input', ['$event'])
    onInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        input.value = input?.value?.replace(/\D/g, '');
    }
}
