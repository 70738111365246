import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizedDirective} from './directives/authorized.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        AuthorizedDirective
    ],
    exports: [
        AuthorizedDirective
    ]
})
export class SharedSecurityModule {
}
