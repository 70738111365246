import { NgModule } from '@angular/core';

import { SfTabScrollerComponent } from './tab-scroller.component';

@NgModule({
  exports: [SfTabScrollerComponent],
  declarations: [SfTabScrollerComponent]
})
export class SfTabScrollerModule {
}
