<h6 sfHeadline6 class="my-20">{{!(isEdit) ? 'Add Option Set' : 'Edit Option Set'}}</h6>

<form [formGroup]="form" autocomplete="off" fxLayout.gt-xs="row wrap">
  <sf-dialog-content>
    <p sfSubtitle2 class="mb-12 color--black-60">
      {{'HINT_CREATE_VARIANT' | translate}}
    </p>

    <p sfCaption class="mb-12 color--black-60">
      {{'HINT_SELECT_LANGUAGE' | translate}}
    </p>
    <div formArrayName="dynamicColumns" *ngFor="let control of copyControls">
      <div [formGroup]="dynamicColumnForm">
        <div [ngClass]="{'pc-create__not-editable': isConfigured}"
                  [ngStyle]="{'pointer-events': isConfigured ? 'none' : 'all', 'display': 'contents'}">
          <sf-chip-set choice class="mt-12 language-chip">
            <sf-chip *ngFor="let language of languages" (click)="selectLanguage(language)"
                     [selected]="language === selectedLanguageControl.value">
              <sf-chip-text>
                <sf-icon class="ver-text-top font-size-18">watch_later</sf-icon>
                {{language | uppercase}}
              </sf-chip-text>
            </sf-chip>
            <sf-icon class="cursor--pointer mt-8 ml-12 color--primary" (click)="copyContent()">
              content_copy
            </sf-icon>
          </sf-chip-set>
        </div>


        <div class="mt-12" formGroupName="name">
          <sf-form-field *ngFor="let language of languages">
            <ng-container *ngIf="language === selectedLanguageControl.value">
              <sf-input [label]="'LABEL_NAME_OPTION_SET' | translate" [formControlName]="language" outlined
                        class="width--full my-20">
              </sf-input>
            </ng-container>
          </sf-form-field>
          <ng-container *ngIf="errorLanguages(control.key, dynamicColumnForm).length">
            <p sfCaption class="custom-error">
              <span>
                {{'ERROR_EMPTY_IN' | translate}}
                <span *ngFor="let language of errorLanguages(control.key, dynamicColumnForm); let last=last">
              {{('LABEL_' + language | uppercase) | translate}}{{!last ? ',' : ''}}
            </span>
              </span>
            </p>
          </ng-container>
        </div>

          <sf-form-field class="my-12">
            <sf-checkbox (changed)="onChangeSetColorOption($event)"
                          [checked]=" dynamicColumnForm.get('dataType').value === 'color'"
                          class="option-set__checkbox"></sf-checkbox>
            <div fxLayout="column">
              <label sfSubtitle2>{{'LABEL_OPTION_TYPE_COLOR' | translate}}</label>
              <label sfCaption>{{'HINT_OPTION_FORMAT_COLOR' | translate}}</label>
            </div>
          </sf-form-field>

        <!-- <div class="my-16" >
          <sf-select formControlName="dataType" [placeholder]="'LABEL_DATA_TYPE' | translate" fxFlex="80" class="nb-select" outlined>
            <sf-menu>
              <sf-list>
                <sf-list-item *ngFor="let dataTypeValue of dataTypeValues" [value]="dataTypeValue">
                  {{ dataTypeValue | translate}}
                </sf-list-item>
              </sf-list>
            </sf-menu>
          </sf-select>
        </div> -->

        <!-- <sf-form-field class="mb-16">
          <sf-checkbox formControlName="multilingual"></sf-checkbox>
          <label sfSubtitle2>{{'LABEL_OPTIONS_MULTILINGUAL' | translate}}</label>
        </sf-form-field> -->

        <p sfSubtitle1 class="my-12 color--black-87">
          {{'LABEL_OPTIONS' | translate}}
        </p>

        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-20 width--full">
          <ng-container formArrayName="dynamicOptions">
            <ng-container *ngFor="let optionControl of dynamicOptionsControls.controls; let j= index"
                          [formGroup]="optionControl">
              <div fxLayout="row wrap" fxLayoutAlign=" center" class="width--full">
                <div fxFlex="82" fxLayout="column" formGroupName="name">
                  <sf-form-field fxFlex="100" *ngFor="let language of languages">
                    <ng-container *ngIf="language === selectedLanguageControl.value">

                      <sf-input [formControlName]="language" [label]="'LABEL_OPTION' | translate : {index: j + 1}"
                                outlined
                                class="width--full my-16"></sf-input>
                    </ng-container>
                  </sf-form-field>
                  <p sfCaption class="custom-error" *ngIf="errorLanguages(control.key, optionControl).length">
                    <span>
                      {{'ERROR_EMPTY_IN' | translate}}
                      <span
                        *ngFor="let language of errorLanguagesForOptions(control.key, optionControl); let lastOption=last">
                        {{('LABEL_' + language | uppercase) | translate}}{{!lastOption ? ',' : ''}}
                      </span>
                    </span>
                  </p>
                </div>

                <ng-container *ngFor="let language of languages">
                  <ng-container *ngIf="language === selectedLanguageControl.value">
                    <ng-container>
                      <div fxFlex="8" fxLayoutAlign="end center" class="pt-2"
                           *ngIf="optionControl.get('hexValue').value">
                        <div class="color-box" [ngStyle]="{'background': optionControl.get('hexValue').value}"></div>
                      </div>
                    </ng-container>
                    <div fxFlex="10" fxLayoutAlign="end center" class="pl-12">
                      <button sf-icon-button icon="delete_outline" data-cy="delete" (click)="removeOption(j)"></button>
                    </div>

                    <ng-container *ngIf="dynamicColumnForm.get('dataType').value === 'color'">
                      <div fxFlex="60" class="pt-12">
                        <sf-color-picker [colors]="colorSets"
                                         (colorChanged)="onColorChangeSetHexValue(j, $event.color.hex)"></sf-color-picker>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <p sfCaption *ngIf="isShowDynamicOptionError" class="custom-error">
            <span>{{'ERROR_ADD_DYNAMIC_OPTION' | translate}}</span>
          </p>
          <button sf-button (click)="buildOptionColumnAttributes()" class="mt-16" data-cy="addOption">
            <sf-icon>add</sf-icon>
            {{'BTN_ADD_OPTION' | translate}}
          </button>
        </div>
      </div>
    </div>

  </sf-dialog-content>
  <sf-dialog-action fxLayoutAlign="end" ngClass.gt-xs="pb-20 width--full" ngClass.xs="common-action__button">
      <button sf-button outlined data-cy="cancel" (click)="cancel()"> {{'BTN_CANCEL' | translate}}</button>
      <button sf-button primary [attr.data-cy]="(!(isEdit) ? 'BTN_ADD_OPTION_SET' : 'BTN_UPDATE_OPTION_SET')| translate"
              (click)=" !(isEdit) ? addDynamicOptionSet(): editDynamicOptionSet()"
              class="ml-12">{{(!(isEdit) ? 'BTN_ADD_OPTION_SET' : 'BTN_UPDATE_OPTION_SET')| translate}}</button>
  </sf-dialog-action>
</form>

