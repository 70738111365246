import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { ProductTree } from '@ew/shared/services';

export const MAP_PRODUCT_MUTATION: TypedDocumentNode<{mapPackageToProductTree: ProductTree}> = gql`
    mutation mapPackageToProductTree($input: MapPackageToProductTreeInput!) {
      mapPackageToProductTree(input: $input) {
        active
        id
      }
    }
`;
