export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date with time zone */
  Date: any;
  File: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
}

/** Autogenerated input type of AddAdmin */
export interface AddAdminInput {
  attributes?: Maybe<ProfileAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<AdminRoleEnum>>;
}

/** Autogenerated return type of AddAdmin */
export interface AddAdminPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface AddGroupAttributes {
  groupAttributes: GroupAttributes;
  profileAttributes: Array<GroupProfile>;
}

/** Autogenerated input type of AddGroup */
export interface AddGroupInput {
  attributes?: Maybe<AddGroupAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  groupAdminIds?: Maybe<Array<Scalars['ID']>>;
  groupMemberIds?: Maybe<Array<Scalars['ID']>>;
}

/** Autogenerated return type of AddGroup */
export interface AddGroupPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface AddMemberAttributes {
  groupId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
}

/** Autogenerated input type of AddMember */
export interface AddMemberInput {
  attributes: AddMemberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of AddMember */
export interface AddMemberPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface AddPackage {
  meta?: Maybe<Scalars['JSON']>;
  package?: Maybe<PackageAttributes>;
  parentId?: Maybe<Scalars['ID']>;
}

/** Autogenerated input type of AddPackageToProductTree */
export interface AddPackageToProductTreeInput {
  attributes: AddPackage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface Addon {
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<ImageEw>>;
  name?: Maybe<Scalars['String']>;
  productItems?: Maybe<Array<AddonItem>>;
}

export interface AddonItem {
  dynamicValues?: Maybe<Array<DynamicValueEw>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<ImageEw>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  specifications?: Maybe<Array<SpecificationsEw>>;
  subscriptionStatus?: Maybe<StatusEnum>;
}

export interface Address {
  additionalInformation?: Maybe<Scalars['String']>;
  apartmentNo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  relocationDate?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workNotes?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export enum AddressEnum {
  BillingAddress = 'BillingAddress',
  InstallationAddress = 'InstallationAddress'
}

export interface AdminList {
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
  invitationAcceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** roles */
  roles?: Maybe<Array<Maybe<Scalars['JSON']>>>;
}

/** The connection type for AdminList. */
export interface AdminListConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AdminListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AdminList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface AdminListEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AdminList>;
}

export interface AdminRole {
  id: Scalars['ID'];
  roleId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  roleName?: Maybe<Scalars['String']>;
}

export enum AdminRoleEnum {
  AdminGroup = 'admin_group',
  MarketingAdmin = 'marketing_admin',
  ProductAdmin = 'product_admin'
}

export interface Anschrift {
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  relocationDate?: Maybe<Scalars['ISO8601DateTime']>;
  status: StatusEnum;
  streetName?: Maybe<Scalars['String']>;
  streetNo?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export interface AuthAttributes {
  logIn?: Maybe<Scalars['Boolean']>;
}

export interface Banners {
  active: Scalars['Boolean'];
  allBusiness?: Maybe<Scalars['Boolean']>;
  allPrivate?: Maybe<Scalars['Boolean']>;
  bannerButtonText?: Maybe<Scalars['String']>;
  bannerDescription: Scalars['String'];
  bannerTitle: Scalars['String'];
  buttonText?: Maybe<Scalars['JSON']>;
  createdBy: Scalars['String'];
  serviceCode: Scalars['String'];
  description: Scalars['JSON'];
  endDate?: Maybe<Scalars['Date']>;
  externalButtonLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalButtonLink?: Maybe<InternalButtonLink>;
  packageName?: Maybe<Scalars['String']>;
  productItemIds: Array<Scalars['ID']>;
  startDate: Scalars['Date'];
  target: Array<TargetsEnum>;
  title: Scalars['JSON'];
  url: Scalars['String'];
}

/** The connection type for Banners. */
export interface BannersConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannersEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Banners>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface BannersEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Banners>;
}

export interface Bill {
  amount: Scalars['String'];
  creationDate: Scalars['ISO8601DateTime'];
  dueDate: Scalars['ISO8601DateTime'];
  invoiceNumber: Scalars['String'];
  invoiceUrl?: Maybe<Scalars['String']>;
}

/** The connection type for Bill. */
export interface BillConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BillEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Bill>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface BillEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Bill>;
}

export interface CallDetail {
  callCost?: Maybe<Scalars['Float']>;
  callDuration?: Maybe<Scalars['String']>;
  callTime?: Maybe<Scalars['Date']>;
  calledFrom?: Maybe<Scalars['String']>;
  calledTo?: Maybe<Scalars['String']>;
  dataConsumption?: Maybe<Scalars['Float']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface CallDetailAttachment {
  downloadUrl?: Maybe<Scalars['String']>;
}

/** The connection type for CallDetail. */
export interface CallDetailConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CallDetailEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CallDetail>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface CallDetailEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CallDetail>;
}

export enum CallDetailEnum {
  Mobile = 'mobile',
  Voice = 'voice'
}

export enum CallDetailTypeEnum {
  LandlineCall = 'landline_call',
  MobileCall = 'mobile_call',
  MobileData = 'mobile_data'
}

export interface CallRecordsAttachment {
  downloadUrl?: Maybe<Scalars['String']>;
}

export interface CancelSubscriptionAttributes {
  cancellationReason?: Maybe<Scalars['String']>;
  mobileSubscriptionId?: Maybe<Scalars['ID']>;
  productItemId: Scalars['ID'];
}

/** Autogenerated input type of CancelSubscription */
export interface CancelSubscriptionInput {
  attributes?: Maybe<CancelSubscriptionAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum CategoryEnum {
  SupportTicket = 'support_ticket',
  SystemTicket = 'system_ticket'
}

export interface ChangePermissionAttributes {
  auth?: Maybe<AuthAttributes>;
  comment?: Maybe<CommentAttributes>;
  product?: Maybe<ProductAttributes>;
}

/** Autogenerated input type of ChangePermission */
export interface ChangePermissionInput {
  attributes: ChangePermissionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of ChangePermission */
export interface ChangePermissionPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export enum ChargeTypeEnum {
  Mrc = 'mrc',
  MrcOtc = 'mrc_otc',
  Otc = 'otc'
}

/** Autogenerated input type of CloneProductItem */
export interface CloneProductItemInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of CloneProductTree */
export interface CloneProductTreeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

export enum ColumnCodeEnum {
  BundleType = 'bundle_type',
  LandlineType = 'landline_type'
}

export enum CombiPackageTypeEnum {
  Internet = 'internet',
  Iptv = 'iptv',
  Mobile = 'mobile',
  Voice = 'voice'
}

export interface Comment {
  createdAt: Scalars['ISO8601DateTime'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  userAvatarUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}

export interface CommentAttributes {
  create?: Maybe<Scalars['Boolean']>;
}

/** The connection type for Comment. */
export interface CommentConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface CommentEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Comment>;
}

export interface Configurator {
  chargeType?: Maybe<ChargeTypeEnum>;
  configuratorPriceTypes?: Maybe<Array<ConfiguratorPrice>>;
  configured?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<Country>>;
  dynamicColumns?: Maybe<Array<DynamicColumn>>;
  id?: Maybe<Scalars['ID']>;
}

export interface ConfiguratorPrice {
  id?: Maybe<Scalars['ID']>;
  priceTypeDescription?: Maybe<Scalars['JSON']>;
  priceTypeId?: Maybe<Scalars['ID']>;
  priceTypeName?: Maybe<Scalars['JSON']>;
  pricingType?: Maybe<Scalars['String']>;
}

export interface ConfiguratorPriceAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  priceTypeId?: Maybe<Scalars['ID']>;
}

export interface ContractDuration {
  configuratorId: Scalars['ID'];
  id: Scalars['ID'];
  period: ContractPeriodEnum;
  value: Scalars['Int'];
}

export enum ContractPeriodEnum {
  Month = 'month',
  Week = 'week'
}

export interface Country {
  countryCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
}

export interface CountryAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
}

export interface CountryList {
  countryCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface CreateAddressAttributes {
  additionalInformation?: Maybe<Scalars['String']>;
  apartmentNo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  relocationDate?: Maybe<Scalars['ISO8601DateTime']>;
  street: Scalars['String'];
  streetNo: Scalars['String'];
  type: AddressEnum;
  zip: Scalars['String'];
}

/** Autogenerated input type of CreateAddress */
export interface CreateAddressInput {
  attributes: CreateAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateBanner {
  active?: Maybe<Scalars['Boolean']>;
  allBusiness?: Maybe<Scalars['Boolean']>;
  allPrivate?: Maybe<Scalars['Boolean']>;
  buttonText?: Maybe<Scalars['JSON']>;
  description: Scalars['JSON'];
  endDate?: Maybe<Scalars['Date']>;
  externalButtonLink?: Maybe<Scalars['String']>;
  file: Scalars['File'];
  internalButtonLink?: Maybe<InternalButtonLinkAttributes>;
  productItemIds?: Maybe<Array<Scalars['ID']>>;
  startDate: Scalars['Date'];
  target: Array<TargetsEnum>;
  title: Scalars['JSON'];
}

/** Autogenerated input type of CreateBanner */
export interface CreateBannerInput {
  attributes: CreateBanner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateCommentAttributes {
  ticketHistoryId: Scalars['ID'];
  value: Scalars['String'];
}

/** Autogenerated input type of CreateComment */
export interface CreateCommentInput {
  attributes: CreateCommentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateContractDuration {
  configuratorId: Scalars['ID'];
  period: ContractPeriodEnum;
  value: Scalars['Int'];
}

export interface CreateEmailAccountAttributes {
  eMail: Scalars['String'];
  emailDomainId: Scalars['ID'];
  password: Scalars['String'];
}

/** Autogenerated input type of CreateEmailAccount */
export interface CreateEmailAccountInput {
  attributes: CreateEmailAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateEmailAliasAttributes {
  emailAccountId: Scalars['ID'];
  emailAliases: EmailAliasAttributes;
}

/** Autogenerated input type of CreateEmailAliases */
export interface CreateEmailAliasesInput {
  attributes: CreateEmailAliasAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of CreateEmailAliases */
export interface CreateEmailAliasesPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of Create */
export interface CreateInput {
  attributes: CreateContractDuration;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateLevel {
  configuratorId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['JSON']>;
  dynamicColumns?: Maybe<Array<DynamicColumnAttributes>>;
  imageable?: Maybe<Scalars['Boolean']>;
  levelRule?: Maybe<LevelRuleAttributes>;
  meta?: Maybe<Scalars['JSON']>;
  name: Scalars['JSON'];
  selectionRule?: Maybe<Scalars['Boolean']>;
  type?: Maybe<LevelTypeEnum>;
}

/** Autogenerated input type of CreateLevel */
export interface CreateLevelInput {
  attributes: CreateLevel;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateProductItemAttributes {
  dynamicValues?: Maybe<Array<DynamicValuesAttributes>>;
  imageIds?: Maybe<Array<Scalars['ID']>>;
  images?: Maybe<Array<ImagesAttributes>>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  packageId: Scalars['ID'];
  prices?: Maybe<Array<PriceAttributes>>;
  specifications?: Maybe<Array<SpecificationsAttributes>>;
}

/** Autogenerated input type of CreateProductItem */
export interface CreateProductItemInput {
  attributes: CreateProductItemAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateServiceNowTicket {
  attachment?: Maybe<Scalars['File']>;
  description: Scalars['String'];
  title: Scalars['String'];
}

/** Autogenerated input type of CreateServiceNowTicket */
export interface CreateServiceNowTicketInput {
  attributes: CreateServiceNowTicket;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum DataTypeEnum {
  Boolean = 'boolean',
  Checkbox = 'checkbox',
  Color = 'color',
  Date = 'date',
  Datetime = 'datetime',
  Decimal = 'decimal',
  Integer = 'integer',
  Options = 'options',
  String = 'string',
  Text = 'text'
}


/** Autogenerated input type of DeleteAdmin */
export interface DeleteAdminInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of DeleteAdmin */
export interface DeleteAdminPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of DeleteBanner */
export interface DeleteBannerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of DeleteBanner */
export interface DeleteBannerPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of DeleteEmailAccount */
export interface DeleteEmailAccountInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of DeleteEmailAccount */
export interface DeleteEmailAccountPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface DeleteEmailAliasAttributes {
  id: Scalars['ID'];
}

/** Autogenerated input type of DeleteEmailAlias */
export interface DeleteEmailAliasInput {
  attributes: DeleteEmailAliasAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of DeleteEmailAlias */
export interface DeleteEmailAliasPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface DeleteGroupAttributes {
  groupId: Scalars['ID'];
}

/** Autogenerated input type of DeleteGroup */
export interface DeleteGroupInput {
  attributes: DeleteGroupAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of DeleteGroup */
export interface DeleteGroupPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of Delete */
export interface DeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of DeleteLevel */
export interface DeleteLevelInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of DeleteLevel */
export interface DeleteLevelPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface DeleteMemberAttributes {
  groupId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
}

/** Autogenerated input type of DeleteMember */
export interface DeleteMemberInput {
  attributes: DeleteMemberAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of DeleteMember */
export interface DeleteMemberPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated return type of Delete */
export interface DeletePayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of DeleteProductItem */
export interface DeleteProductItemInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of DeleteProductTree */
export interface DeleteProductTreeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of DeleteProductTree */
export interface DeleteProductTreePayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of DeleteUser */
export interface DeleteUserInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of DeleteUser */
export interface DeleteUserPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface DeliveryTime {
  id?: Maybe<Scalars['ID']>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
}

export interface DeliveryTimeAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
}

export interface Device {
  browserName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceType?: Maybe<DeviceEnum>;
  id?: Maybe<Scalars['ID']>;
  remindMe?: Maybe<Scalars['Boolean']>;
  saved?: Maybe<Scalars['Boolean']>;
}

/** The connection type for Device. */
export interface DeviceConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeviceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Device>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface DeviceEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Device>;
}

export enum DeviceEnum {
  Desktop = 'desktop',
  Mobile = 'mobile'
}

export interface DevicesAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  saved?: Maybe<Scalars['Boolean']>;
}

export enum DirectionOptionsEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export enum DirectionsEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export interface DisableAllAttributes {
  all: Scalars['Boolean'];
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  userIds?: Maybe<Array<Scalars['ID']>>;
}

/** Autogenerated input type of DisableAll */
export interface DisableAllInput {
  attributes: DisableAllAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of DisableAll */
export interface DisableAllPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  state: Scalars['Boolean'];
}

export interface DownloadCallDetailAttributes {
  calledFrom?: Maybe<Array<Scalars['String']>>;
  dateTime?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  fileFormat: FileFormatEnum;
  searchQuery?: Maybe<Scalars['String']>;
  type: CallDetailTypeEnum;
}

/** Autogenerated input type of DownloadCallDetail */
export interface DownloadCallDetailInput {
  attributes: DownloadCallDetailAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of DownloadCallDetail */
export interface DownloadCallDetailPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface DynamicColumn {
  dataType?: Maybe<DataTypeEnum>;
  dynamicOptions?: Maybe<Array<DynamicOption>>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  multilingual?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['Boolean']>;
}

export interface DynamicColumnAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  dataType?: Maybe<DataTypeEnum>;
  dynamicOptions?: Maybe<Array<DynamicOptionAttributes>>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  multilingual?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['JSON']>;
  required?: Maybe<Scalars['Boolean']>;
}

export interface DynamicOption {
  hexValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
}

export interface DynamicOptionAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  hexValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['String']>;
}

export interface DynamicOptionEw {
  hexValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface DynamicValue {
  dynamicColumnId?: Maybe<Scalars['ID']>;
  dynamicOptionId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['JSON']>;
  valuableId?: Maybe<Scalars['ID']>;
  valuableType?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface DynamicValueEw {
  dynamicColumnCode?: Maybe<Scalars['String']>;
  dynamicColumnName?: Maybe<Scalars['String']>;
  dynamicOption?: Maybe<DynamicOptionEw>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface DynamicValuesAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  dynamicColumnId?: Maybe<Scalars['ID']>;
  dynamicOptionId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['JSON']>;
  valuableId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
}

export interface EmailAccount {
  disabled?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  emailAliases?: Maybe<Array<EmailAlias>>;
  emailDomainId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  password?: Maybe<Scalars['String']>;
  spamFilter?: Maybe<Scalars['String']>;
  spamProtector?: Maybe<Scalars['String']>;
  virusFilter?: Maybe<Scalars['String']>;
}

export interface EmailAlias {
  domain: Scalars['String'];
  id: Scalars['ID'];
  user: Scalars['String'];
}

export interface EmailAliasAttributes {
  domain: Scalars['String'];
  user: Scalars['String'];
}

export interface EmailDomain {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}

export interface EnableAllAttributes {
  all: Scalars['Boolean'];
  userIds?: Maybe<Array<Scalars['ID']>>;
}

/** Autogenerated input type of EnableAll */
export interface EnableAllInput {
  attributes: EnableAllAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of EnableAll */
export interface EnableAllPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  state: Scalars['Boolean'];
}


export enum FileFormatEnum {
  Pdf = 'pdf',
  Xls = 'xls'
}

/** Autogenerated input type of ForgetDevices */
export interface ForgetDevicesInput {
  attributes: Array<DevicesAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ForgetDevices */
export interface ForgetDevicesPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface GroupAttributes {
  name: Scalars['String'];
}

export interface GroupList {
  /** groups */
  groupAdmin?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  groupName: Scalars['String'];
  id: Scalars['ID'];
  supportGroup: Scalars['Boolean'];
  totalMember?: Maybe<Scalars['Int']>;
}

/** The connection type for GroupList. */
export interface GroupListConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<GroupList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface GroupListEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<GroupList>;
}

export interface GroupMember {
  clientNumber: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
  subscribedPackages?: Maybe<Array<SubscribedPackage>>;
  userId: Scalars['ID'];
  billPaymentNotification?: Maybe<Scalars['Boolean']>;
  offDayEnd?: Maybe<Scalars['ISO8601DateTime']>;
  offDayStart?: Maybe<Scalars['ISO8601DateTime']>;
}

/** The connection type for GroupMember. */
export interface GroupMemberConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupMemberEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<GroupMember>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface GroupMemberEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<GroupMember>;
}

export enum GroupUserSortEnum {
  ClientNumber = 'client_number',
  FullName = 'full_name',
  MobileNumber = 'mobile_number'
}

export interface GroupProfile {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
}

export enum IdmsServiceEnum {
  Internet = 'internet',
  Iptv = 'iptv',
  Mobile = 'mobile',
  Voice = 'voice'
}

export interface Image {
  id?: Maybe<Scalars['ID']>;
  urls?: Maybe<Array<Scalars['String']>>;
}

export interface ImageEw {
  id?: Maybe<Scalars['ID']>;
  urls?: Maybe<Array<Scalars['String']>>;
}

export interface ImagesAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
}

export interface InclusiveUnit {
  amount?: Maybe<Scalars['Float']>;
  available?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  unitType?: Maybe<UnitTypeEnum>;
  descriptionDetails?: Maybe<Scalars['JSON']>;
}

/** The connection type for InclusiveUnit. */
export interface InclusiveUnitConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InclusiveUnitEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InclusiveUnit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface InclusiveUnitEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InclusiveUnit>;
}

export interface InternalButtonLink {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  productTreeId?: Maybe<Scalars['ID']>;
}

export interface InternalButtonLinkAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  productTreeId?: Maybe<Scalars['ID']>;
}


export interface Level {
  configuratorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  dynamicColumns?: Maybe<Array<DynamicColumn>>;
  id?: Maybe<Scalars['ID']>;
  imageable?: Maybe<Scalars['Boolean']>;
  levelRule?: Maybe<LevelRule>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  rank?: Maybe<Scalars['Int']>;
  selectionRule?: Maybe<Scalars['Boolean']>;
  type?: Maybe<LevelTypeEnum>;
}

export interface LevelRule {
  contractDuration?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  preorder?: Maybe<Scalars['Boolean']>;
  quantitySetting?: Maybe<Scalars['Boolean']>;
  stockManagement?: Maybe<Scalars['Boolean']>;
}

export interface LevelRuleAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  contractDuration?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  preorder?: Maybe<Scalars['Boolean']>;
  quantitySetting?: Maybe<Scalars['Boolean']>;
  stockManagement?: Maybe<Scalars['Boolean']>;
}

export enum LevelTypeEnum {
  Addon = 'addon',
  Product = 'product',
  ProductNode = 'product_node'
}

export interface ListPackage {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  productItems?: Maybe<Array<ProductItemEw>>;
}

/** Autogenerated input type of MapPackageToProductTree */
export interface MapPackageToProductTreeInput {
  attributes: ProductTreeCreate;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface MobileSubscription {
  cost?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inclusiveUnits?: Maybe<Array<InclusiveUnit>>;
  number: Scalars['String'];
  subscribedPackages: Array<SubscribedPackage>;
  view?: Maybe<Scalars['Boolean']>;
}

/** The connection type for MobileSubscription. */
export interface MobileSubscriptionConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MobileSubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MobileSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface MobileSubscriptionEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MobileSubscription>;
}

export interface MonthlyCost {
  download?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  mobile?: Maybe<Scalars['Float']>;
  mobileDataCost?: Maybe<Scalars['Float']>;
  subscribedPackageId?: Maybe<Scalars['ID']>;
  upload?: Maybe<Scalars['Float']>;
  voice?: Maybe<Scalars['Float']>;
}

export interface MultiAuth {
  medium: Scalars['JSON'];
}

export interface Mutation {
  addPackageToProductTree?: Maybe<ProductTree>;
  cloneProductItem?: Maybe<ProductItem>;
  cloneProductTree?: Maybe<ProductTree>;
  createContractDuration?: Maybe<ContractDuration>;
  createLevel?: Maybe<Level>;
  createProductItem?: Maybe<ProductItem>;
  deleteContractDuration?: Maybe<DeletePayload>;
  deleteLevel?: Maybe<DeleteLevelPayload>;
  deleteProductItem?: Maybe<ProductItem>;
  deleteProductTree?: Maybe<DeleteProductTreePayload>;
  /** It maps the similar kind of package to be used in different product tree. */
  mapPackageToProductTree?: Maybe<ProductTree>;
  updateConfigurator?: Maybe<Configurator>;
  updateContractDuration?: Maybe<ContractDuration>;
  updateLevel?: Maybe<Level>;
  updatePackage?: Maybe<Package>;
  updateProductItem?: Maybe<ProductItem>;
  updateProductTree?: Maybe<ProductTree>;
  uploadImage?: Maybe<Image>;
  /** Add group and invite the admin */
  addGroup?: Maybe<AddGroupPayload>;
  /** Add users to a group. */
  addMember?: Maybe<AddMemberPayload>;
  cancelSubscription?: Maybe<Scalars['Boolean']>;
  /** Permission management for fleet. */
  changePermission?: Maybe<ChangePermissionPayload>;
  /** 'Create a new billing or installation address for the current user' */
  createAddress?: Maybe<Address>;
  createBanner?: Maybe<Banners>;
  createComment?: Maybe<Comment>;
  /** 'Creates a new email account for the current user' */
  createEmailAccount?: Maybe<EmailAccount>;
  createEmailAliases?: Maybe<CreateEmailAliasesPayload>;
  /** Register client instance. */
  createRegistrationToken?: Maybe<RegistrationToken>;
  createServiceNowTicket?: Maybe<ServiceNowTicket>;
  deleteBanner?: Maybe<DeleteBannerPayload>;
  /** 'Deletes the email account of the current user' */
  deleteEmailAccount?: Maybe<DeleteEmailAccountPayload>;
  /** delete an email alias */
  deleteEmailAlias?: Maybe<DeleteEmailAliasPayload>;
  /** Delete a group by passing in group ID. */
  deleteGroup?: Maybe<DeleteGroupPayload>;
  /** Remove a user from group */
  deleteMember?: Maybe<DeleteMemberPayload>;
  /** delete registration token.	 */
  deleteRegistrationToken?: Maybe<RegistrationToken>;
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Disable bill notification for all user or for specific users. */
  disableAll?: Maybe<DisableAllPayload>;
  /** Download call detail based on filter */
  downloadCallDetail?: Maybe<DownloadCallDetailPayload>;
  /** soft delete a notification */
  discardNotification?: Maybe<Scalars['Boolean']>;
  /** Download call detail based on filter */
  /** Enable bill notification for all user. */
  enableAll?: Maybe<EnableAllPayload>;
  forgetDevices?: Maybe<ForgetDevicesPayload>;
  /** mark all notification as read for current user. */
  markAllAsRead?: Maybe<Scalars['Boolean']>;
  /** update the read status of a notification for current user to true */
  markAsRead?: Maybe<Scalars['Boolean']>;
  notificationSetting?: Maybe<NotificationSettingPayload>;
  popupForOldSubscribedPackages?: Maybe<PopupForOldSubscribedPackagesPayload>;
  resendConfirmationToken?: Maybe<ResendConfirmationTokenPayload>;
  resendPasswordResetToken?: Maybe<ResendPasswordResetTokenPayload>;
  resendSwitchMediumConfirmationToken?: Maybe<ResendSwitchMediumConfirmationTokenPayload>;
  sentToAnalytics?: Maybe<SentToAnalyticsPayload>;
  /** set current locale for user */
  setCurrentLocale?: Maybe<Scalars['Boolean']>;
  subscribeAddons?: Maybe<SubscribedPackage>;
  subscribePackage?: Maybe<SubscribedPackage>;
  subscriptionStatus?: Maybe<SubscriptionStatusPayload>;
  switchAuthenticationMedium?: Maybe<User>;
  /** Sync landline call record with IDMS for current month */
  syncLandlineCallRecord?: Maybe<SyncLandlineCallRecordPayload>;
  /** Sync mobile call record with IDMS for current month. */
  syncMobileCallRecord?: Maybe<SyncMobileCallRecordPayload>;
  /** 'Sync subscribed packages with IDMS for the given user id' */
  syncSubscribedPackage?: Maybe<SyncSubscribedPackagePayload>;
  syncTicketingModule?: Maybe<Scalars['Boolean']>;
  /** 'Sync profile, billing address and installation address with IDMS for the given user id' */
  syncUserData?: Maybe<SyncUserDataPayload>;
  /** Turn on and off notification for bills by user */
  toggleBillNotification?: Maybe<ToggleBillNotificationPayload>;
  toggleTwoFactorAuthentication?: Maybe<ToggleTwoFactorAuthenticationPayload>;
  /** Turn off and off the bills payment notification for particular user by admin on certain date range */
  toggleUserBillPayment?: Maybe<ToggleUserBillPaymentPayload>;
  updateBanner?: Maybe<Banners>;
  /** Send in the mobile subscription id. */
  updateDefaultMobileSubscription?: Maybe<UpdateDefaultMobileSubscriptionPayload>;
  /** 'Creates a new email account for the current user' */
  updateEmailAccount?: Maybe<EmailAccount>;
  /** update an existing email alias */
  updateEmailAlias?: Maybe<EmailAlias>;
  /** Update group and invite the admin */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** 'Update basic profile attributes like first name, last name, etc... for the current user' */
  updateProfile?: Maybe<Profile>;
  /** 'Update mobile number or email of the current user' */
  updateUserEmailOrMobile?: Maybe<User>;
  updateUserStatus?: Maybe<UpdateUserStatusPayload>;
  uploadAvatar?: Maybe<UploadAvatarPayload>;
  restrictGroupAdmin?: Maybe<RestrictGroupAdminPayload>;
  /** Add admin and invite the admin */
  addAdmin?: Maybe<AddAdminPayload>;
  /** Update admin role */
  updateAdminRole?: Maybe<UpdateAdminRolePayload>;
  /** Delete a admin by passing in user ID. */
  deleteAdmin?: Maybe<DeleteAdminPayload>;
  switchRole?: Maybe<SwitchRolePayload>;
  /** Update 2fa medium of user */
  updateMultiAuth?: Maybe<UpdateMultiAuthPayload>;
}


export interface MutationAddPackageToProductTreeArgs {
  input: AddPackageToProductTreeInput;
}


export interface MutationCloneProductItemArgs {
  input: CloneProductItemInput;
}


export interface MutationCloneProductTreeArgs {
  input: CloneProductTreeInput;
}


export interface MutationCreateContractDurationArgs {
  input: CreateInput;
}


export interface MutationCreateLevelArgs {
  input: CreateLevelInput;
}


export interface MutationCreateProductItemArgs {
  input: CreateProductItemInput;
}


export interface MutationDeleteContractDurationArgs {
  input: DeleteInput;
}


export interface MutationDeleteLevelArgs {
  input: DeleteLevelInput;
}


export interface MutationDeleteProductItemArgs {
  input: DeleteProductItemInput;
}


export interface MutationDeleteProductTreeArgs {
  input: DeleteProductTreeInput;
}


export interface MutationMapPackageToProductTreeArgs {
  input: MapPackageToProductTreeInput;
}


export interface MutationUpdateConfiguratorArgs {
  input: UpdateConfiguratorInput;
}


export interface MutationUpdateContractDurationArgs {
  input: UpdateInput;
}


export interface MutationUpdateLevelArgs {
  input: UpdateLevelInput;
}


export interface MutationUpdatePackageArgs {
  input: UpdatePackageInput;
}


export interface MutationUpdateProductItemArgs {
  input: UpdateProductItemInput;
}


export interface MutationUpdateProductTreeArgs {
  input: UpdateProductTreeInput;
}


export interface MutationUploadImageArgs {
  input: UploadImageInput;
}


export interface MutationAddGroupArgs {
  input: AddGroupInput;
}


export interface MutationAddMemberArgs {
  input: AddMemberInput;
}


export interface MutationCancelSubscriptionArgs {
  input: CancelSubscriptionInput;
}


export interface MutationChangePermissionArgs {
  input: ChangePermissionInput;
}


export interface MutationCreateAddressArgs {
  input: CreateAddressInput;
}


export interface MutationCreateBannerArgs {
  input: CreateBannerInput;
}


export interface MutationCreateCommentArgs {
  input: CreateCommentInput;
}


export interface MutationCreateEmailAccountArgs {
  input: CreateEmailAccountInput;
}


export interface MutationCreateEmailAliasesArgs {
  input: CreateEmailAliasesInput;
}


export interface MutationCreateRegistrationTokenArgs {
  input: CreateRegistrationTokenInput;
}


export interface MutationCreateServiceNowTicketArgs {
  input: CreateServiceNowTicketInput;
}


export interface MutationDeleteBannerArgs {
  input: DeleteBannerInput;
}


export interface MutationDeleteEmailAccountArgs {
  input: DeleteEmailAccountInput;
}


export interface MutationDeleteEmailAliasArgs {
  input: DeleteEmailAliasInput;
}


export interface MutationDeleteGroupArgs {
  input: DeleteGroupInput;
}


export interface MutationDeleteMemberArgs {
  input: DeleteMemberInput;
}


export interface MutationDeleteRegistrationTokenArgs {
  input: DeleteRegistrationTokenInput;
}


export interface MutationDeleteUserArgs {
  input: DeleteUserInput;
}


export interface MutationDisableAllArgs {
  input: DisableAllInput;
}


export interface MutationDownloadCallDetailArgs {
  input: DownloadCallDetailInput;
}


export interface MutationDiscardNotificationArgs {
  input: DiscardNotificationInput;
}


export interface MutationEnableAllArgs {
  input: EnableAllInput;
}


export interface MutationForgetDevicesArgs {
  input: ForgetDevicesInput;
}


export interface MutationMarkAllAsReadArgs {
  input: MarkAllAsReadInput;
}


export interface MutationMarkAsReadArgs {
  input: MarkAsReadInput;
}


export interface MutationNotificationSettingArgs {
  input: NotificationSettingInput;
}


export interface MutationPopupForOldSubscribedPackagesArgs {
  input: PopupForOldSubscribedPackagesInput;
}


export interface MutationResendConfirmationTokenArgs {
  input: ResendConfirmationTokenInput;
}


export interface MutationResendPasswordResetTokenArgs {
  input: ResendPasswordResetTokenInput;
}


export interface MutationResendSwitchMediumConfirmationTokenArgs {
  input: ResendSwitchMediumConfirmationTokenInput;
}


export interface MutationSentToAnalyticsArgs {
  input: SentToAnalyticsInput;
}


export interface MutationSetCurrentLocaleArgs {
  input: SetCurrentLocaleInput;
}


export interface MutationSubscribeAddonsArgs {
  input: SubscribeAddonsInput;
}


export interface MutationSubscribePackageArgs {
  input: SubscribePackageInput;
}


export interface MutationSubscriptionStatusArgs {
  input: SubscriptionStatusInput;
}


export interface MutationSwitchAuthenticationMediumArgs {
  input: SwitchAuthenticationMediumInput;
}


export interface MutationSyncLandlineCallRecordArgs {
  input: SyncLandlineCallRecordInput;
}


export interface MutationSyncMobileCallRecordArgs {
  input: SyncMobileCallRecordInput;
}


export interface MutationSyncSubscribedPackageArgs {
  input: SyncSubscribedPackageInput;
}


export interface MutationSyncTicketingModuleArgs {
  input: SyncTicketingModuleInput;
}


export interface MutationSyncUserDataArgs {
  input: SyncUserDataInput;
}


export interface MutationToggleBillNotificationArgs {
  input: ToggleBillNotificationInput;
}


export interface MutationToggleTwoFactorAuthenticationArgs {
  input: ToggleTwoFactorAuthenticationInput;
}


export interface MutationToggleUserBillPaymentArgs {
  input: ToggleUserBillPaymentInput;
}


export interface MutationUpdateBannerArgs {
  input: UpdateBannerInput;
}


export interface MutationUpdateDefaultMobileSubscriptionArgs {
  input: UpdateDefaultMobileSubscriptionInput;
}


export interface MutationUpdateEmailAccountArgs {
  input: UpdateEmailAccountInput;
}


export interface MutationUpdateEmailAliasArgs {
  input: UpdateEmailAliasInput;
}


export interface MutationUpdateGroupArgs {
  input: UpdateGroupInput;
}


export interface MutationUpdateProfileArgs {
  input: UpdateProfileInput;
}


export interface MutationUpdateUserEmailOrMobileArgs {
  input: UpdateUserEmailOrMobileInput;
}


export interface MutationUpdateUserStatusArgs {
  input: UpdateUserStatusInput;
}


export interface MutationUploadAvatarArgs {
  input: UploadAvatarInput;
}


export interface MutationRestrictGroupAdminArgs {
  input: RestrictGroupAdminInput;
}


export interface MutationUpdateMultiAuthArgs {
  input: UpdateMultiAuthInput;
}


export interface MutationAddAdminArgs {
  input: AddAdminInput;
}


export interface MutationUpdateAdminRoleArgs {
  input: UpdateAdminRoleInput;
}


export interface MutationDeleteAdminArgs {
  input: DeleteAdminInput;
}


export interface MutationSwitchRoleArgs {
  input: SwitchRoleInput;
}

export enum NotificationModeEnum {
  Mail = 'mail',
  Off = 'off',
  Portal = 'portal'
}

export interface NotificationSettingAttributes {
  language: Scalars['String'];
  mode: NotificationModeEnum;
}

/** Autogenerated input type of NotificationSetting */
export interface NotificationSettingInput {
  attributes: NotificationSettingAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of NotificationSetting */
export interface NotificationSettingPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface NotificationStats {
  /** Total number of read and unread notifications. */
  all?: Maybe<Scalars['Int']>;
  /** Number of notifications that are marked as read. */
  read?: Maybe<Scalars['Int']>;
  /** Number of notifications that are marked as unread. */
  unread?: Maybe<Scalars['Int']>;
}

export interface OldSubscribedPackage {
  dataSentToAnalytics?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  idmsService?: Maybe<IdmsServiceEnum>;
  name?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['JSON']>;
  userId?: Maybe<Scalars['ID']>;
}

export interface Package {
  configuratorPriceTypeId?: Maybe<Scalars['ID']>;
  deliveryTime?: Maybe<DeliveryTime>;
  dynamicColumns?: Maybe<Array<DynamicColumn>>;
  dynamicValues?: Maybe<Array<DynamicValue>>;
  id?: Maybe<Scalars['ID']>;
  imageIds?: Maybe<Array<Scalars['ID']>>;
  images?: Maybe<Array<Image>>;
  levelId?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  packageContractDurations?: Maybe<Array<PackageContractDurations>>;
  preorder?: Maybe<Preorder>;
  productLimit?: Maybe<ProductLimit>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface PackageAttributes {
  configuratorPriceTypeId?: Maybe<Scalars['ID']>;
  deliveryTime?: Maybe<DeliveryTimeAttributes>;
  dynamicColumns?: Maybe<Array<DynamicColumnAttributes>>;
  dynamicValues?: Maybe<Array<DynamicValuesAttributes>>;
  imageIds?: Maybe<Array<Scalars['ID']>>;
  images?: Maybe<Array<ImagesAttributes>>;
  levelId?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  packageContractDurations?: Maybe<Array<PackageContractDurationAttributes>>;
  preorder?: Maybe<PreorderAttributes>;
  productLimit?: Maybe<ProductLimitAttributes>;
  type?: Maybe<Scalars['String']>;
}

export interface PackageCollection {
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<ImageEw>>;
  products?: Maybe<Array<ListPackage>>;
  serviceName?: Maybe<Scalars['String']>;
}


export interface PackageCollectionProductsArgs {
  productType: ProductTypeEnum;
}

/** The connection type for PackageCollection. */
export interface PackageCollectionConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PackageCollectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PackageCollection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface PackageCollectionEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PackageCollection>;
}

export interface PackageContractDurationAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  contractDurationId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
}

export interface PackageContractDurations {
  contractDurationId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
}

export enum PackageRegionEnum {
  Bbcs = 'bbcs',
  RsnCoreArea = 'rsn_core_area',
  RsnGebietserweiterung = 'rsn_gebietserweiterung'
}

export interface Packet {
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  package: Scalars['String'];
  price: Scalars['Float'];
  service: Scalars['String'];
  status: StatusEnum;
}

/** Information about pagination in a connection. */
export interface PageInfo {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
}

/** Information about pagination in a connection. */
export interface PageInfoEw {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
}

export interface Permission {
  accessorId?: Maybe<Scalars['ID']>;
  accessorType?: Maybe<Scalars['String']>;
  actions?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  resource?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of PopupForOldSubscribedPackages */
export interface PopupForOldSubscribedPackagesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of PopupForOldSubscribedPackages */
export interface PopupForOldSubscribedPackagesPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface Preorder {
  id?: Maybe<Scalars['ID']>;
  releaseDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
}

export interface PreorderAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  releaseDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
}

export interface Price {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  pricingType?: Maybe<Scalars['String']>;
}

export interface PriceAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  comparative?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  mrc?: Maybe<Scalars['Float']>;
  otc?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
}

export interface Prices {
  comparative?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  mrc?: Maybe<Scalars['Float']>;
  otc?: Maybe<Scalars['Float']>;
  productItemId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
}

export interface Product {
  active?: Maybe<Scalars['Boolean']>;
  addons?: Maybe<Array<Addon>>;
  code?: Maybe<Scalars['String']>;
  dynamicValues?: Maybe<Array<DynamicValueEw>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<ImageEw>>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['ID']>;
  productItems?: Maybe<Array<ProductItemEw>>;
  subscribedAddons?: Maybe<Array<SubscribedAddon>>;
}

export interface ProductAttributes {
  update?: Maybe<Scalars['Boolean']>;
}

/** The connection type for Product. */
export interface ProductConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface ProductEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
}

export interface ProductItem {
  active?: Maybe<Scalars['Boolean']>;
  dynamicValues?: Maybe<Array<DynamicValue>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Image>>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  packageId?: Maybe<Scalars['ID']>;
  prices?: Maybe<Array<Prices>>;
  specifications?: Maybe<Array<Specifications>>;
}

export interface ProductItemEw {
  dynamicValues?: Maybe<Array<DynamicValueEw>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<ImageEw>>;
  monthlyCost?: Maybe<MonthlyCost>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  specifications?: Maybe<Array<SpecificationsEw>>;
  subscribedPackageId?: Maybe<Scalars['ID']>;
  subscriptionStatus?: Maybe<StatusEnum>;
}


export interface ProductItemEwMonthlyCostArgs {
  mobileSubscriptionId?: Maybe<Scalars['ID']>;
}


export interface ProductItemEwNumberArgs {
  mobileSubscriptionId?: Maybe<Scalars['ID']>;
}


export interface ProductItemEwSubscribedPackageIdArgs {
  mobileSubscriptionId?: Maybe<Scalars['ID']>;
}


export interface ProductItemEwSubscriptionStatusArgs {
  mobileSubscriptionId?: Maybe<Scalars['ID']>;
}

/** The connection type for ProductItemsList. */
export interface ProductItemsConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductItemsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductItemsList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ProductItemsEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductItemsList>;
}

export interface ProductItemsList {
  active?: Maybe<Scalars['Boolean']>;
  dynamicValues?: Maybe<Array<Scalars['JSON']>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['JSON']>>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  packageId?: Maybe<Scalars['ID']>;
  prices?: Maybe<Array<Scalars['JSON']>>;
  specifications?: Maybe<Array<Scalars['JSON']>>;
}

export interface ProductLimit {
  id?: Maybe<Scalars['ID']>;
  maximum?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  minimum?: Maybe<Scalars['Int']>;
}

export interface ProductLimitAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  maximum?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  minimum?: Maybe<Scalars['Int']>;
}

export interface ProductTree {
  active?: Maybe<Scalars['Boolean']>;
  ancestry?: Maybe<Scalars['String']>;
  ancestryDepth?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
}

export interface ProductTreeCreate {
  meta?: Maybe<Scalars['JSON']>;
  packageId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
}

export interface ProductTreeList {
  active?: Maybe<Scalars['Boolean']>;
  ancestry?: Maybe<Scalars['String']>;
  ancestryDepth?: Maybe<Scalars['Int']>;
  deliveryTime?: Maybe<Scalars['JSON']>;
  dynamicColumns?: Maybe<Array<Scalars['JSON']>>;
  dynamicValues?: Maybe<Array<Scalars['JSON']>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['JSON']>>;
  meta?: Maybe<Scalars['JSON']>;
  packageConfiguratorPriceTypeId?: Maybe<Scalars['ID']>;
  packageContractDurations?: Maybe<Array<Scalars['JSON']>>;
  packageId?: Maybe<Scalars['ID']>;
  packageLevelId?: Maybe<Scalars['ID']>;
  packageMeta?: Maybe<Scalars['JSON']>;
  packageName?: Maybe<Scalars['JSON']>;
  packageSource?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  preorder?: Maybe<Scalars['JSON']>;
  productLimit?: Maybe<Scalars['JSON']>;
}

export interface ProductTreeUpdate {
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
}

/** The connection type for ProductTreeList. */
export interface ProductTreesConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductTreesEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductTreeList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ProductTreesEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductTreeList>;
}

export enum ProductTypeEnum {
  Business = 'business',
  Private = 'private'
}

export interface Profile {
  companyName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  installationAddressesToBilling?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface ProfileAttributes {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
}

export interface Query {
  configurators?: Maybe<Array<Configurator>>;
  contractDurations?: Maybe<Array<ContractDuration>>;
  countries?: Maybe<Array<CountryList>>;
  level?: Maybe<Level>;
  levels?: Maybe<Array<Level>>;
  priceTypes?: Maybe<Array<Price>>;
  productItem?: Maybe<ProductItem>;
  productItems: ProductItemsConnection;
  productTree?: Maybe<ProductTree>;
  productTrees: ProductTreesConnection;
  banner?: Maybe<Banners>;
  banners?: Maybe<BannersConnection>;
  bills: BillConnection;
  callDetail: CallDetailConnection;
  /** Reverse geo coding */
  reverseGeoCoding?: Maybe<Scalars['JSON']>;
  callRecordsAttachment: CallRecordsAttachment;
  comments: CommentConnection;
  devices: DeviceConnection;
  dynamicOptions: Array<DynamicOptionEw>;
  emailAccounts: Array<EmailAccount>;
  emailDomains: Array<EmailDomain>;
  /** Get list of user. */
  groupUsers?: Maybe<GroupMemberConnection>;
  groups: GroupListConnection;
  inclusiveUnits: InclusiveUnitConnection;
  mobileSubscriptions?: Maybe<MobileSubscriptionConnection>;
  /** query `all`, `read`, `unread` data */
  notificationStats?: Maybe<NotificationStats>;
  /** Query notifications for current user */
  notifications: NotificationTypeConnection;
  packages: PackageCollectionConnection;
  product: Product;
  products: ProductConnection;
  roles: Array<Role>;
  searchProducts: ProductConnection;
  services?: Maybe<Array<Service>>;
  ticketHistories: TicketHistoryConnection;
  uniqueProduct: Array<UniqueProduct>;
  ticketHistory: TicketHistory;
  uniqueCalledFromNumbers?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<User>;
  users: UserListConnection;
  admins: AdminListConnection;
}


export interface QueryContractDurationsArgs {
  configuratorId: Scalars['ID'];
}


export interface QueryLevelArgs {
  id: Scalars['ID'];
}


export interface QueryLevelsArgs {
  configuratorId: Scalars['ID'];
}


export interface QueryProductItemArgs {
  id: Scalars['ID'];
}


export interface QueryProductItemsArgs {
  active?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  packageId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryProductTreeArgs {
  id: Scalars['ID'];
}


export interface QueryProductTreesArgs {
  active?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['String']>;
  ancestry?: Maybe<Scalars['String']>;
  ancestryDepth?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionOptionsEnum>;
  filter?: Maybe<Array<PackageRegionEnum>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortOptionsEnum>;
  uniqueProductTrees?: Maybe<Scalars['Int']>;
}


export interface QueryBannerArgs {
  id: Scalars['ID'];
}


export interface QueryBannersArgs {
  active?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryBillsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateRange?: Maybe<Array<Scalars['ISO8601Date']>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryCallDetailArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  calledFromFilter?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<Array<Scalars['ISO8601Date']>>;
  direction?: Maybe<DirectionsEnum>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  mobileData?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEnum>;
  type?: Maybe<CallDetailEnum>;
}


export interface QueryReverseGeoCodingArgs {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
}


export interface QueryCommentsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  ticketHistoryId?: Maybe<Scalars['ID']>;
}


export interface QueryDevicesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  savedDevices?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryDynamicOptionsArgs {
  columnCode?: Maybe<ColumnCodeEnum>;
}


export interface QueryGroupUsersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionsEnum>;
  first?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<GroupUserSortEnum>;
}


export interface QueryGroupsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryInclusiveUnitsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  mobileSubscription?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryMobileSubscriptionsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  mobileNumber?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryNotificationsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  readStatus?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryPackagesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryProductArgs {
  id: Scalars['ID'];
}


export interface QueryProductsArgs {
  after?: Maybe<Scalars['String']>;
  ancestry?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dynamicOptionId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  subscriptionStatus?: Maybe<StatusEnum>;
}


export interface QuerySearchProductsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}


export interface QueryTicketHistoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  state?: Maybe<StateEnum>;
}


export interface QueryTicketHistoryArgs {
  id: Scalars['ID'];
}


export interface QueryUniqueCalledFromNumbersArgs {
  type?: Maybe<CallDetailTypeEnum>;
}


export interface QueryUsersArgs {
  active?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionsEnum>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Scalars['String']>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<UserSortEnum>;
}


export interface QueryAdminsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionsEnum>;
  filter?: Maybe<Array<AdminRoleEnum>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}

export interface RegistrationToken {
  id: Scalars['ID'];
  token: Scalars['String'];
}

/** Autogenerated input type of ResendConfirmationToken */
export interface ResendConfirmationTokenInput {
  attributes: UserToken;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ResendConfirmationToken */
export interface ResendConfirmationTokenPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ResendPasswordResetToken */
export interface ResendPasswordResetTokenInput {
  attributes: UserToken;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ResendPasswordResetToken */
export interface ResendPasswordResetTokenPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ResendSwitchMediumConfirmationToken */
export interface ResendSwitchMediumConfirmationTokenInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ResendSwitchMediumConfirmationToken */
export interface ResendSwitchMediumConfirmationTokenPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface Role {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
  /** Get the first N(as set in the ENV or 10) user's avatar_url with names for each role. */
  users?: Maybe<Array<UserList>>;
  usersCount?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of SentToAnalytics */
export interface SentToAnalyticsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packageIds: Array<Scalars['ID']>;
}

/** Autogenerated return type of SentToAnalytics */
export interface SentToAnalyticsPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface Service {
  code?: Maybe<Scalars['String']>;
  dynamicValues?: Maybe<Array<DynamicValueEw>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['JSON']>>;
  name?: Maybe<Scalars['String']>;
  subscribedPackages?: Maybe<Array<SubscribedPackage>>;
}

export interface ServiceNowTicket {
  category: CategoryEnum;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  stateCategory?: Maybe<Scalars['String']>;
  subscribedPackage?: Maybe<Array<SubscribedPackage>>;
  ticketId: Scalars['String'];
  ticketNumber: Scalars['String'];
  ticketableId?: Maybe<Scalars['String']>;
  ticketableType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
}

export interface SetCurrentLocaleAttributes {
  locale: Scalars['String'];
}

export enum SortEnum {
  CallCost = 'call_cost',
  CallDuration = 'call_duration',
  CallTime = 'call_time',
  DataConsumption = 'data_consumption'
}

export enum SortOptionsEnum {
  CreatedAt = 'created_at',
  PackageName = 'package_name',
  UpdatedAt = 'updated_at'
}

export interface Specifications {
  id?: Maybe<Scalars['ID']>;
  productItemId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['JSON']>;
}

export interface SpecificationsAttributes {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['JSON']>;
}

export interface SpecificationsEw {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export enum StateEnum {
  InProgressState = 'in_progress_state',
  PendingState = 'pending_state',
  ResolvedState = 'resolved_state'
}

export enum StatusEnum {
  Cancelled = 'cancelled',
  Current = 'current',
  Past = 'past',
  Upcoming = 'upcoming'
}

export interface SubscribeAddonAttributes {
  price: Scalars['Float'];
  productItemId: Scalars['ID'];
}

export interface SubscribeAddonsAttributes {
  id: Scalars['ID'];
  subscribedAddons: Array<SubscribeAddonAttributes>;
}

/** Autogenerated input type of SubscribeAddons */
export interface SubscribeAddonsInput {
  attributes: SubscribeAddonsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubscribePackageAttributes {
  mobileSubscriptionId?: Maybe<Scalars['ID']>;
  price: Scalars['Float'];
  productItemId: Scalars['ID'];
}

/** Autogenerated input type of SubscribePackage */
export interface SubscribePackageInput {
  attributes: SubscribePackageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubscribedAddon {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  productItemId?: Maybe<Scalars['ID']>;
  status?: Maybe<StatusEnum>;
}

export interface SubscribedPackage {
  code?: Maybe<Scalars['String']>;
  combiPackageTypes?: Maybe<Array<CombiPackageTypeEnum>>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id?: Maybe<Scalars['ID']>;
  monthlyCost?: Maybe<MonthlyCost>;
  packageName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  productItemId?: Maybe<Scalars['ID']>;
  productTreeId?: Maybe<Scalars['ID']>;
  serviceName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  status?: Maybe<StatusEnum>;
  subscribedAddons?: Maybe<Array<SubscribedAddon>>;
  subscriptionStatus?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of SubscriptionStatus */
export interface SubscriptionStatusInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packageIds: Array<Scalars['ID']>;
}

/** Autogenerated return type of SubscriptionStatus */
export interface SubscriptionStatusPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface SwitchAuthenticationMedium {
  unconfirmedEmail?: Maybe<Scalars['String']>;
  unconfirmedMobile?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of SwitchAuthenticationMedium */
export interface SwitchAuthenticationMediumInput {
  attributes: SwitchAuthenticationMedium;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of SwitchRole */
export interface SwitchRoleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the role user wants to switch to. Any user with multiple user roles can switch. */
  roleId: Scalars['ID'];
}

/** Autogenerated return type of SwitchRole */
export interface SwitchRolePayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of SyncSubscribedPackage */
export interface SyncSubscribedPackageInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of SyncSubscribedPackage */
export interface SyncSubscribedPackagePayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of SyncUserData */
export interface SyncUserDataInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of SyncUserData */
export interface SyncUserDataPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export enum TargetsEnum {
  Business = 'business',
  Private = 'private'
}

export interface TicketHistory {
  addresses?: Maybe<Array<Anschrift>>;
  category: CategoryEnum;
  comments?: Maybe<Array<Comment>>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  stateCategory?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['JSON']>;
  subscribedPackages?: Maybe<Array<Packet>>;
  ticketId: Scalars['String'];
  ticketNumber: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

/** The connection type for TicketHistory. */
export interface TicketHistoryConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TicketHistoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TicketHistory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface TicketHistoryEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TicketHistory>;
}

export interface ToggleBillNotificationAttributes {
  state: Scalars['Boolean'];
}

/** Autogenerated input type of ToggleBillNotification */
export interface ToggleBillNotificationInput {
  attributes: ToggleBillNotificationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ToggleBillNotification */
export interface ToggleBillNotificationPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of ToggleTwoFactorAuthentication */
export interface ToggleTwoFactorAuthenticationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ToggleTwoFactorAuthentication */
export interface ToggleTwoFactorAuthenticationPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface ToggleUserBillPaymentAttributes {
  dateRange?: Maybe<Array<Scalars['Date']>>;
  status: Scalars['Boolean'];
  userId: Scalars['ID'];
}

/** Autogenerated input type of ToggleUserBillPayment */
export interface ToggleUserBillPaymentInput {
  attributes: ToggleUserBillPaymentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ToggleUserBillPayment */
export interface ToggleUserBillPaymentPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface UniqueProduct {
  productPackages: Array<UniqueProductTree>;
  productService: UniqueProductTree;
}

export interface UniqueProductTree {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}

export enum UnitTypeEnum {
  Events = 'events',
  Minuten = 'minuten',
  Volumen = 'volumen'
}

/** Autogenerated input type of UpdateAdminRole */
export interface UpdateAdminRoleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<AdminRoleEnum>>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of UpdateAdminRole */
export interface UpdateAdminRolePayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface UpdateBanner {
  active?: Maybe<Scalars['Boolean']>;
  allBusiness?: Maybe<Scalars['Boolean']>;
  allPrivate?: Maybe<Scalars['Boolean']>;
  buttonText?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['JSON']>;
  endDate?: Maybe<Scalars['Date']>;
  externalButtonLink?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  internalButtonLink?: Maybe<InternalButtonLinkAttributes>;
  productItemIds?: Maybe<Array<Scalars['ID']>>;
  startDate?: Maybe<Scalars['Date']>;
  target?: Maybe<Array<TargetsEnum>>;
  title?: Maybe<Scalars['JSON']>;
}

/** Autogenerated input type of UpdateBanner */
export interface UpdateBannerInput {
  attributes: UpdateBanner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateConfigurator {
  chargeType?: Maybe<ChargeTypeEnum>;
  configuratorPriceTypes?: Maybe<Array<ConfiguratorPriceAttributes>>;
  configured?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<CountryAttributes>>;
  dynamicColumns?: Maybe<Array<DynamicColumnAttributes>>;
  id: Scalars['ID'];
}

/** Autogenerated input type of UpdateConfigurator */
export interface UpdateConfiguratorInput {
  attributes: UpdateConfigurator;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateContractDuration {
  id: Scalars['ID'];
  period?: Maybe<ContractPeriodEnum>;
  value?: Maybe<Scalars['Int']>;
}

export interface UpdateDefaultMobileAttributes {
  mobileSubscriptionId: Scalars['ID'];
}

/** Autogenerated input type of UpdateDefaultMobileSubscription */
export interface UpdateDefaultMobileSubscriptionInput {
  attributes: UpdateDefaultMobileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateDefaultMobileSubscription */
export interface UpdateDefaultMobileSubscriptionPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

export interface UpdateEmailAccountAttributes {
  fwdActive?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  spamFilter?: Maybe<Scalars['String']>;
  spamProtector?: Maybe<Scalars['String']>;
  virusFilter?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateEmailAccount */
export interface UpdateEmailAccountInput {
  attributes: UpdateEmailAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateEmailAliasAttributes {
  domain: Scalars['String'];
  id: Scalars['ID'];
  user: Scalars['String'];
}

/** Autogenerated input type of UpdateEmailAlias */
export interface UpdateEmailAliasInput {
  attributes: UpdateEmailAliasAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateGroup */
export interface UpdateGroupInput {
  attributes?: Maybe<Array<GroupProfile>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  groupName?: Maybe<Scalars['String']>;
  groupUserIds?: Maybe<Array<Scalars['ID']>>;
  removeGadminIds?: Maybe<Array<Scalars['ID']>>;
}

/** Autogenerated return type of UpdateGroup */
export interface UpdateGroupPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of Update */
export interface UpdateInput {
  attributes: UpdateContractDuration;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateLevel {
  description?: Maybe<Scalars['JSON']>;
  dynamicColumns?: Maybe<Array<DynamicColumnAttributes>>;
  id: Scalars['ID'];
  imageable?: Maybe<Scalars['Boolean']>;
  levelRule?: Maybe<LevelRuleAttributes>;
  meta?: Maybe<Scalars['JSON']>;
  name: Scalars['JSON'];
  selectionRule?: Maybe<Scalars['Boolean']>;
  type?: Maybe<LevelTypeEnum>;
}

/** Autogenerated input type of UpdateLevel */
export interface UpdateLevelInput {
  attributes: UpdateLevel;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateMultiAuthAttributes {
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateMultiAuth */
export interface UpdateMultiAuthInput {
  attributes: UpdateMultiAuthAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateMultiAuth */
export interface UpdateMultiAuthPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface UpdatePackage {
  configuratorPriceTypeId?: Maybe<Scalars['ID']>;
  deliveryTime?: Maybe<DeliveryTimeAttributes>;
  dynamicColumns?: Maybe<Array<DynamicColumnAttributes>>;
  dynamicValues?: Maybe<Array<DynamicValuesAttributes>>;
  id: Scalars['ID'];
  imageIds?: Maybe<Array<Scalars['ID']>>;
  images?: Maybe<Array<ImagesAttributes>>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  packageContractDurations?: Maybe<Array<PackageContractDurationAttributes>>;
  preorder?: Maybe<PreorderAttributes>;
  productLimit?: Maybe<ProductLimitAttributes>;
}

/** Autogenerated input type of UpdatePackage */
export interface UpdatePackageInput {
  attributes: UpdatePackage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateProductItemAttributes {
  active?: Maybe<Scalars['Boolean']>;
  dynamicValues?: Maybe<Array<DynamicValuesAttributes>>;
  id: Scalars['ID'];
  imageIds?: Maybe<Array<Scalars['ID']>>;
  images?: Maybe<Array<ImagesAttributes>>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['JSON']>;
  prices?: Maybe<Array<PriceAttributes>>;
  specifications?: Maybe<Array<SpecificationsAttributes>>;
}

/** Autogenerated input type of UpdateProductItem */
export interface UpdateProductItemInput {
  attributes: UpdateProductItemAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateProductTree */
export interface UpdateProductTreeInput {
  attributes: ProductTreeUpdate;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateProfileAttributes {
  companyName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  designation?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateProfile */
export interface UpdateProfileInput {
  attributes: UpdateProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateUserEmailOrMobile */
export interface UpdateUserEmailOrMobileInput {
  attributes: UserEmailOrMobileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateUserStatus */
export interface UpdateUserStatusInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of UpdateUserStatus */
export interface UpdateUserStatusPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of UploadAvatar */
export interface UploadAvatarInput {
  avatar: Scalars['File'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of UploadAvatar */
export interface UploadAvatarPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface UploadImage {
  file: Scalars['File'];
  variants?: Maybe<Array<Scalars['String']>>;
}

/** Autogenerated input type of UploadImage */
export interface UploadImageInput {
  attributes: UploadImage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface User {
  adminRoles?: Maybe<Array<AdminRole>>;
  active?: Maybe<Scalars['Boolean']>;
  billPaymentNotificationState?: Maybe<Scalars['Boolean']>;
  billingAddresses?: Maybe<Array<Address>>;
  clientNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  financeState?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  impersonated?: Maybe<Scalars['Boolean']>;
  installationAddresses?: Maybe<Array<Address>>;
  medium: MultiAuth;
  mobileNumber?: Maybe<Scalars['String']>;
  mobileSubscriptionCount?: Maybe<Scalars['Int']>;
  mobileSubscriptions?: Maybe<Array<MobileSubscription>>;
  oldSubscribedPackages?: Maybe<Array<OldSubscribedPackage>>;
  notificationLanguage?: Maybe<Scalars['String']>;
  notificationSetting: Scalars['JSON'];
  otpRequiredForLogin?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Permission>>;
  profile?: Maybe<Profile>;
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
  subscribedPackages?: Maybe<Array<SubscribedPackage>>;
  supportGroupAdmin?: Maybe<Scalars['Boolean']>;
  supportTicketCount?: Maybe<Scalars['Int']>;
  systemTicketCount?: Maybe<Scalars['Int']>;
  unconfirmedEmail?: Maybe<Scalars['String']>;
  unconfirmedMobile?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
}

export interface UserEmailOrMobileAttributes {
  unconfirmedEmail?: Maybe<Scalars['String']>;
  unconfirmedMobile?: Maybe<Scalars['String']>;
}

export interface UserList {
  active?: Maybe<Scalars['Boolean']>;
  billPaymentNotification?: Maybe<Scalars['Boolean']>;
  clientNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invitationAcceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  offDayEnd?: Maybe<Scalars['ISO8601DateTime']>;
  offDayStart?: Maybe<Scalars['ISO8601DateTime']>;
  permissions?: Maybe<Array<Permission>>;
  profileEmail?: Maybe<Scalars['String']>;
  profileMobileNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

/** The connection type for UserList. */
export interface UserListConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfoEw;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface UserListEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserList>;
}

export enum UserSortEnum {
  ClientNumber = 'client_number',
  Name = 'name',
  MobileNumber = 'mobile_number'
}

export interface UserToken {
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
}

export interface UserType {
  business?: Maybe<Scalars['Boolean']>;
  combi?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
}

/** Autogenerated input type of createComment */
export interface CreateCommentInput {
  attributes: CreateCommentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of createComment */
export interface CreateCommentPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  value: Scalars['String'];
}

/** Attributes for creating registration token. */
export interface CreateRegTokenAttributes {
  token: Scalars['String'];
}

/** Autogenerated input type of createRegistrationToken */
export interface CreateRegistrationTokenInput {
  attributes: CreateRegTokenAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Attributes for deleting registration token.	 */
export interface DeleteRegTokenAttributes {
  token: Scalars['String'];
}

/** Autogenerated input type of deleteRegistrationToken	 */
export interface DeleteRegistrationTokenInput {
  attributes: DeleteRegTokenAttributes;
  /** A unique identifier for the client performing the mutation.	 */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface DiscardNotificationAttributes {
  id: Scalars['ID'];
}

/** Autogenerated input type of discardNotification */
export interface DiscardNotificationInput {
  attributes: DiscardNotificationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of markAllAsRead */
export interface MarkAllAsReadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface MarkAsReadAttributes {
  id: Scalars['ID'];
}

/** Autogenerated input type of markAsRead */
export interface MarkAsReadInput {
  attributes: MarkAsReadAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** notification type */
export interface NotificationType {
  createdAt?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  notification: Scalars['String'];
  readStatus: Scalars['Boolean'];
}

/** The connection type for notificationType. */
export interface NotificationTypeConnection {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NotificationType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
}

/** An edge in a connection. */
export interface NotificationTypeEdge {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NotificationType>;
}

/** Autogenerated input type of restrictGroupAdmin */
export interface RestrictGroupAdminInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  userId: Scalars['ID'];
}

/** Autogenerated return type of restrictGroupAdmin */
export interface RestrictGroupAdminPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
}

/** Autogenerated input type of setCurrentLocale */
export interface SetCurrentLocaleInput {
  attributes: SetCurrentLocaleAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of syncLandlineCallRecord */
export interface SyncLandlineCallRecordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of syncLandlineCallRecord */
export interface SyncLandlineCallRecordPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of syncMobileCallRecord */
export interface SyncMobileCallRecordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of syncMobileCallRecord */
export interface SyncMobileCallRecordPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of syncTicketingModule */
export interface SyncTicketingModuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of syncLandlineCallRecord */
export interface SyncLandlineCallRecordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of syncLandlineCallRecord */
export interface SyncLandlineCallRecordPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of syncMobileCallRecord */
export interface SyncMobileCallRecordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of syncMobileCallRecord */
export interface SyncMobileCallRecordPayload {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}
