import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SfListComponent, SfListItem, SfListItemAction } from '../../../list/src/index';
import { SfMenuSurfaceBase } from '../../../menu-surface/src/index';

import { closest } from '@material/dom/ponyfill';
import { cssClasses, DefaultFocusState, MDCMenuFoundation, strings } from '@material/menu';
import { Platform } from '@angular/cdk/platform';

export class SfMenuSelectedEvent {
  constructor(
    public index: number,
    public source: SfListItem) {
  }
}

let nextUniqueId = 0;

export type SfMenuFocusState = 'none' | 'list' | 'firstItem' | 'lastItem';

const DEFAULT_FOCUS_STATE_MAP = {
  none: DefaultFocusState.NONE,
  list: DefaultFocusState.LIST_ROOT,
  firstItem: DefaultFocusState.FIRST_ITEM,
  lastItem: DefaultFocusState.LAST_ITEM
};

@Directive({
  selector: '[sfMenuSelectionGroup], sf-menu-selection-group',
  host: { 'class': 'mdc-menu__selection-group' },
  exportAs: 'sfMenuSelectionGroup'
})
export class SfMenuSelectionGroup {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Directive({
  selector: '[sfMenuSelectionGroupIcon], sf-menu-selection-group-icon',
  host: { 'class': 'mdc-menu__selection-group-icon' },
  exportAs: 'sfMenuSelectionGroupIcon'
})
export class SfMenuSelectionGroupIcon {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Component({
  selector: 'sf-menu',
  exportAs: 'sfMenu',
  host: {
    '[id]': 'id',
    'class': 'mdc-menu mdc-menu-surface sf-menu'
  },
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfMenu extends SfMenuSurfaceBase implements AfterContentInit, OnDestroy {

  @Output() readonly selected: EventEmitter<SfMenuSelectedEvent> = new EventEmitter<SfMenuSelectedEvent>();
  @ContentChild(SfListComponent, { static: false }) _list!: SfListComponent;
  @ContentChildren(SfListItem, { descendants: true }) listItems!: QueryList<SfListItem>;
  /** Emits whenever the component is destroyed. */
  private _destroyed = new Subject<void>();
  private _uniqueId = `${cssClasses.ROOT}-${++nextUniqueId}`;
  @Input() id: string = this._uniqueId;
  private _menuFoundation: {
    destroy(): void,
    handleKeydown(evt: KeyboardEvent): void,
    handleItemAction(listItem: HTMLElement): void,
    handleMenuSurfaceOpened(): void,
    setDefaultFocusState(focusState: DefaultFocusState): void
  } = new MDCMenuFoundation(this._createAdapter());

  constructor(public changeDetectorRef: ChangeDetectorRef,
              public platform: Platform,
              @Optional() public ngZone: NgZone,
              public elementRef: ElementRef<HTMLElement>) {
    super(changeDetectorRef, platform, ngZone, elementRef);
  }

  private _wrapFocus = false;

  @Input()
  get wrapFocus(): boolean {
    return this._wrapFocus;
  }

  set wrapFocus(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._wrapFocus) {
      this._wrapFocus = newValue;
      this._list.wrapFocus = newValue;
    }
  }

  private _closeSurfaceOnSelection = true;

  @Input()
  get closeSurfaceOnSelection(): boolean {
    return this._closeSurfaceOnSelection;
  }

  set closeSurfaceOnSelection(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._closeSurfaceOnSelection) {
      this._closeSurfaceOnSelection = newValue;
    }
  }

  private _defaultFocusState: SfMenuFocusState = 'list';

  @Input()
  get defaultFocusState(): SfMenuFocusState {
    return this._defaultFocusState;
  }

  set defaultFocusState(value: SfMenuFocusState) {
    if (value !== this._defaultFocusState) {
      this._defaultFocusState = value;
      this._menuFoundation.setDefaultFocusState(DEFAULT_FOCUS_STATE_MAP[this._defaultFocusState]);
    }
  }

  ngAfterContentInit(): void {
    this.initMenuSurface();
    this._initList();
    this._listenForListItemActions();

    this.opened.pipe(takeUntil(this._destroyed))
      .subscribe(() => this._menuFoundation.handleMenuSurfaceOpened());
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();

    this.destroyMenuSurface();
    this._menuFoundation.destroy();
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(evt: KeyboardEvent): void {
    this._menuFoundation.handleKeydown(evt);
  }

  private _createAdapter() {
    return Object.assign({
      addClassToElementAtIndex: (index: number, className: string) =>
        this.listItems.toArray()[index].getListItemElement().classList.add(className),
      removeClassFromElementAtIndex: (index: number, className: string) =>
        this.listItems.toArray()[index].getListItemElement().classList.remove(className),
      addAttributeToElementAtIndex: (index: number, attr: string, value: string) =>
        this.listItems.toArray()[index].getListItemElement().setAttribute(attr, value),
      removeAttributeFromElementAtIndex: (index: number, attr: string) =>
        this.listItems.toArray()[index].getListItemElement().removeAttribute(attr),
      elementContainsClass: (element: HTMLElement, className: string) => element.classList.contains(className),
      closeSurface: (skipRestoreFocus: boolean) =>
        this.closeSurfaceOnSelection ? this._foundation.close(skipRestoreFocus) : {},
      getElementIndex: (element: HTMLElement) =>
        this.listItems.toArray().findIndex(_ => _.getListItemElement() === element),
      notifySelected: (evtData: { index: number }) =>
        this.selected.emit(new SfMenuSelectedEvent(evtData.index, this.listItems.toArray()[evtData.index])),
      getMenuItemCount: () => this.listItems.toArray().length,
      focusItemAtIndex: (index: number) => this.listItems.toArray()[index].focus(),
      focusListRoot: () => (this.elementRef.nativeElement.querySelector(strings.LIST_SELECTOR) as HTMLElement).focus(),
      isSelectableItemAtIndex: (index: number) =>
        !!closest(this.listItems.toArray()[index].getListItemElement(), `.${cssClasses.MENU_SELECTION_GROUP}`),
      getSelectedSiblingOfItemAtIndex: (index: number) => {
        const selectionGroupEl = closest(this.listItems.toArray()[index].getListItemElement(),
          `.${cssClasses.MENU_SELECTION_GROUP}`) as HTMLElement;
        const selectedItemEl = selectionGroupEl.querySelector<HTMLElement>(`.${cssClasses.MENU_SELECTED_LIST_ITEM}`);
        return selectedItemEl ? this.listItems.toArray().findIndex(_ =>
          _.elementRef.nativeElement === selectedItemEl) : -1;
      }
    });
  }

  private _initList(): void {
    if (!this._list) {
      return;
    }

    this._list.setRole('menu');
    this._list.wrapFocus = this._wrapFocus;
    this._list.setTabIndex(-1);

    // When the list items change, re-subscribe
    this._list.items.changes.pipe(takeUntil(this._destroyed))
      .subscribe(() => this._list.items.forEach(item => item.setRole('menuitem')));
  }

  /** Listens to action events on each list item. */
  private _listenForListItemActions(): void {
    this._list.actionEvent.pipe(takeUntil(this._destroyed))
      .subscribe((event: SfListItemAction) =>
        this._menuFoundation.handleItemAction(this.listItems.toArray()[event.index].getListItemElement()));
  }
}
