import {Injectable} from '@angular/core';
import {BaseFacadeService} from '../base/base-facade.service';
import {SharedStateService} from './shared-state.service';
import {SharedStoreState, SharedStoreStateEnum} from '../../models/shared-store.state';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SharedApiService} from './shared-api.service';
import {Address, CreateAddressAttributes, CreateRegTokenAttributes,
    DiscardNotificationAttributes,
    NotificationTypeConnection,
    User,
    UserToken, SentToAnalyticsInput, SubscriptionStatusInput,
    NotificationStats, MarkAsReadAttributes, DeleteRegTokenAttributes,
    QueryReverseGeoCodingArgs,
    SetCurrentLocaleAttributes,
    NotificationSettingAttributes,
    UserEmailOrMobileAttributes,
    ToggleUserBillPaymentAttributes
} from '../../models/graphql';
import {SharedBlService} from './shared-bl.service';
import {BannerTableSearchParam, CleanService, DeviceInfo, 
    GoogleAnalyticsEcommerceEventType
    , NotificationListingParam} from '../../models/shared.model';
import { Router, UrlSegment } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { GAConsentPermissionEnum, SubscriptionTypeEnum } from '../../enum/shared.enum';


@Injectable({
    providedIn: 'root'
})
export class SharedFacadeService extends BaseFacadeService<SharedStateService, SharedStoreState> {
    constructor(private sharedState: SharedStateService,
                private sharedBl: SharedBlService,
                private router: Router,
                private sharedApi: SharedApiService) {
        super(sharedState);
    }

    logout(showNotificationSuccessMessage?: boolean): Observable<boolean> {
        this.initialize();
        this.sharedBl.clearLocalStorage();
        return this.sharedApi.logout(showNotificationSuccessMessage);
    }

    getUserDetails(): Observable<User> {
        return this.sharedApi.getUserDetails().pipe(
            tap(resp => this.updateSpecificState(resp, SharedStoreStateEnum.PROFILE_STATE))
        );
    }

    getOutdatedPackages(): Observable<boolean> {
        return this.sharedApi.getUserDetails().pipe(
            tap(({oldSubscribedPackages}) => this.updateSpecificState(oldSubscribedPackages, SharedStoreStateEnum.OUTDATED_PACKAGE)),
            mapTo(true)
        );
    }

    checkPermission = (user: User, url: UrlSegment[]): boolean => this.sharedBl.checkForPermission(user, url);

    getServices(): Observable<CleanService[]> {
        return this.sharedApi.getServices().pipe(
            //TODO: Might need in the future
            // map(service => this.sharedBl.moveCombiToLast(this.sharedBl.mapServices(service))),
            tap(services => this.updateSpecificState(services, SharedStoreStateEnum.SERVICES)),
            map(services => this.sharedBl.removeInternalProductWithNoSubsPackage(services)),
            map(service => this.sharedBl.mapServices(service)),
            tap(resp => this.updateSpecificState(resp, SharedStoreStateEnum.SERVICE)),
            tap(resp => {this.updateSpecificState(
                this.sharedBl.arrangeServicesWithSubscribedFirst(resp), SharedStoreStateEnum.DASHBOARD_SERVICE);   
            })
        );
    }

    getBanners = (): Observable<boolean> => {
        const args = this.getSpecificState<BannerTableSearchParam>(SharedStoreStateEnum.BANNER_SEARCH_PARAM);
        return this.sharedApi.getBanners(args).pipe(
            tap(banners => this.updateSpecificState(banners, SharedStoreStateEnum.BANNER_LIST)),
            mapTo(true));
    };

    createAddress(attributes: CreateAddressAttributes): Observable<Address> {
        return this.sharedApi.createAddress(attributes);
    }

    createFirebaseToken(attributes: CreateRegTokenAttributes): Observable<boolean> {
        return this.sharedApi.createFirebaseToken(attributes).pipe(mapTo(true));
    }

    deleteFirebaseToken(attributes: DeleteRegTokenAttributes): Observable<boolean> {
        return this.sharedApi.deleteFirebaseToken(attributes).pipe(mapTo(true));
    }

    confirmation(confirmation_token: string): Observable<boolean> {
        return this.sharedApi.confirmation(confirmation_token);
    }

    getRoleId = (): string => this.getSpecificState<User>(SharedStoreStateEnum.PROFILE_STATE).roleId;

    doNotShowNewDevicePopUp = (device: DeviceInfo): Observable<boolean> => this.sharedApi.doNotShowNewDevicePopUp(device);

    getLocalStorageValue = (key: string): string => this.sharedBl.removeQuoteFromLS(this.sharedBl.getLocalStorageValue(key));

    popupForOldSubscribedPackages = (): Observable<boolean> => this.sharedApi.popupForOldSubscribedPackages();

    resendOTPCode = (attributes: UserToken): Observable<boolean> => this.sharedApi.resendOTPCode(attributes);

    ImpersonationEnd(): Observable<boolean> {
        return this.sharedApi.endImpersonate().pipe(
            untilDestroyed(this),
            switchMap(() => this.router.navigate(['groups'])),
            mapTo(true)
        )
    }

    sentCurrentSubscriptionPackagesToAnalytics(input: SubscriptionStatusInput): Observable<boolean> {
        return this.sharedApi.changeSubscriptionStatus(input).pipe(mapTo(true));
    }

    sentOldSubscriptionPackagesToAnalytics(input: SentToAnalyticsInput): Observable<boolean> {
        return this.sharedApi.sentOldSubscriptionPackagesToAnalytics(input).pipe(mapTo(true));
    }

    mapGoogleAnalyticsEcommerceEvent(user: User, type: SubscriptionTypeEnum): GoogleAnalyticsEcommerceEventType[] {
        return this.sharedBl.mapGoogleAnalyticsEcommerceEvent(user, type);
    }

    getTotalRevenue = (user: User, type: SubscriptionTypeEnum): number => this.sharedBl.getTotalRevenue(user, type);

    getTransactionId = (user: User, type: SubscriptionTypeEnum): string => this.sharedBl.getTransactionId(user, type);

    updateGoogleAnalyticsConsentData(permission: GAConsentPermissionEnum): void {
        this.sharedApi.updateGoogleAnalyticsConsentData(permission);
    }

    getAnalyticsCookieExpiryDate = (): Date => this.sharedBl.getAnalyticsCookieExpiryDate();

    getLocations(query: string): Observable<boolean> {
        return this.sharedApi.getLocations(query).pipe(
            tap(locations => this.sharedState.updateSpecificState(locations?.results, SharedStoreStateEnum.GEOLOCATIONS)),
            mapTo(true)
        )
    }

    reverseGeoCoding(attributes: QueryReverseGeoCodingArgs): Observable<boolean> {
        return this.sharedApi.getReverseGeoCoding(attributes).pipe(
            tap(locations => this.sharedState.updateSpecificState(locations, SharedStoreStateEnum.SELECTED_LOCATION)),
            mapTo(true)
        )
    }

    getNotifications(attributes: NotificationListingParam): Observable<NotificationTypeConnection>{
        return this.sharedApi.getNotifications(attributes);
    }

    deleteNotification(attributes: DiscardNotificationAttributes): Observable<boolean> {
        return this.sharedApi.deleteNotification(attributes).pipe(mapTo(true))
    }

    readNotification(attributes: MarkAsReadAttributes): Observable<boolean> {
        return this.sharedApi.readNotification(attributes).pipe(mapTo(true))
    }

    getNotificationsCount(): Observable<NotificationStats> {
        return this.sharedApi.getNotificationsCount().pipe(
            tap(
                notificationCount => {
                    this.updateSpecificState(this.sharedBl.getNotificationCount(notificationCount),
                        SharedStoreStateEnum.NOTIFICATIONS_LISTS);
                }
            )
        )
    }

    saveFirebaseToken = (token: string): void => this.sharedBl.saveFirebaseToken(token);

    getFirebaseToken = (): string => this.sharedBl.getFirebaseToken();

    removeFirebaseToken = (): void => this.sharedBl.removeFirebaseToken();

    readAllNotifications(): Observable<boolean> {
        return this.sharedApi.readAllNotifications().pipe(mapTo(true));
    }

    switchRole(roleId: string): Observable<boolean> {
        return this.sharedApi.switchRole(roleId)
    }

    setPushNotificationLanguagePreference(attributes: SetCurrentLocaleAttributes): Observable<boolean> {
        return this.sharedApi.setPushNotificationLanguagePreference(attributes);
    }

    setNotificationSettings(attributes: NotificationSettingAttributes): Observable<boolean> {
        return this.sharedApi.setNotificationSettings(attributes).pipe(mapTo(true))
    }

    updateUserEmailOrPhone(input: UserEmailOrMobileAttributes): Observable<User> {
        return this.sharedApi.updateUserEmailOrPhone(input);
    }

    updateUserBillingNotification = (
        attr: ToggleUserBillPaymentAttributes
    ):  Observable<boolean> => this.sharedApi.updateUserBillingNotification(attr);

    commentDisable = ( attrib: { disable: boolean, uid: string }): Observable<boolean> => this.sharedApi.changePermissionLogin(
        this.sharedBl.commentPermissionSetter( attrib ), true);
}
