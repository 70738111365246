import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DynamicField } from '../../models/dynamic-field';
import { DynamicColumn } from '@ew/shared/services';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ew-dynamic-date-field',
    templateUrl: './dynamic-date-field.component.html',
    styleUrls: ['./dynamic-date-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicDateFieldComponent implements DynamicField {
  @Input() dynamicColumn: DynamicColumn;
  @Input() dynamicValueFormGroup: FormGroup;


}
