/* eslint-disable @typescript-eslint/ban-types */
import { Inject, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { I18N_CONFIG, I18nConfig } from './models';
import { take } from 'rxjs/operators';

const languageKey = 'language';

@Injectable({
    providedIn: 'root'
})
export class I18nService {

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService, @Inject(I18N_CONFIG) private config: I18nConfig) {
      this.init();
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
      return this.translateService.currentLang;
  }
  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
      language =
      language ||
      localStorage.getItem(languageKey) ||
      this.translateService.getBrowserCultureLang();
      /** To maintain backward compatibility we check both the arrays */
      const isSupportedLanguage = this.config.supportedLanguages || this.config.languages.map(lang => lang.name).find(
          lang => lang === language
      );

      // Fallback if language is not supported
      if (!isSupportedLanguage) {
          language = this.config.defaultLanguage;
      }

      this.translateService.setDefaultLang(this.config.defaultLanguage);
      this.translateService.use(language);
  }

  /**
   * Language change event
   * @returns Observable<LangChangeEvent>
   */
  get languageChanged(): Observable<LangChangeEvent> {
      return this.translateService.onLangChange;
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   */
  init() {
      this.language = this.config.defaultLanguage;
      // Warning: this subscription will always be alive for the app's lifetime
      this.langChangeSubscription = this.translateService.onLangChange.subscribe(
          (event: LangChangeEvent) => {
              localStorage.setItem(languageKey, event.lang);
          }
      );
  }

  /** Module translation loading method*/
  loadTranslations(args: Object): void {
      Object.keys(args).forEach(key => {
          this.translateService.setTranslation(key, args[key], true);
      });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
      this.langChangeSubscription.unsubscribe();
  }

  /**
   * Switch the languages
   * @param  lang the language to change to
   * @returns Observable<string>
   */
  useLanguage(lang: string): Observable<string> {
      if (this.config.supportedLanguages.includes(lang)) {
          return this.translateService.use(lang).pipe(take(1));
      } else {
          return EMPTY;
      }
  }
}
