import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  QueryList
} from '@angular/core';

export enum AvatarSizes {
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl
}

export type AvatarSizeClasses = keyof typeof AvatarSizes;

export const DefaultAvatarSizeClasses: { [key in AvatarSizeClasses]: string } = {
  xxs: 'sf-avatar--s24',
  xs: 'sf-avatar--s32',
  sm: 'sf-avatar--s40',
  md: 'sf-avatar--s48',
  lg: 'sf-avatar--s56',
  xl: 'sf-avatar--s72',
  xxl: 'sf-avatar--s100'
};

export const AVATAR_SIZE_CLASSES = new InjectionToken<AvatarSizeClasses>('AVATAR_SIZE_CLASSES');


@Directive({
  selector: 'sf-avatar-badge'
})
export class SfAvatarBadgeDirective implements OnInit {
  ngOnInit(): void {
  }
}

@Directive({
  selector: '[sfAvatarBadge]',
  host: {
    class: 'sf-avatar__item sf-avatar__icon'
  }
})
export class SfAvatarIconBadgeDirective extends SfAvatarBadgeDirective implements OnInit {
  ngOnInit(): void {
  }
}

@Directive({
  selector: 'img[sfAvatarImage]',
  host: {
    'class': 'sf-avatar__img'
  }
})
export class SfAvatarImageDirective implements OnInit {

  ngOnInit(): void {
  }
}

@Component({
  selector: 'sf-avatar',
  template: `
      <div class="sf-avatar__text">
          <ng-content></ng-content>
      </div>
      <ng-content select="img[sfAvatarImage]"></ng-content>
          <sf-icon class="sf-avatar__item sf-avatar__icon" *ngIf="badge">
              <ng-content select="sf-avatar-badge"></ng-content>
          </sf-icon>
          <ng-content select="[sfAvatarBadge]"></ng-content>
  `,
  providers: [{ provide: AVATAR_SIZE_CLASSES, useValue: DefaultAvatarSizeClasses }]
})
export class SfAvatarComponent implements OnInit {
  @ContentChild(SfAvatarBadgeDirective, { static: true }) badge: SfAvatarBadgeDirective;

  constructor(@Inject(AVATAR_SIZE_CLASSES) private sizeClasses) {

  }

  private _sizeClass = DefaultAvatarSizeClasses[AvatarSizes.md];

  @HostBinding('class')
  get sizeClass(): string | string[] {
    return `sf-avatar ${this._sizeClass}`;
  }

  /**
   * The size of the avatar.
   * example: `sm`
  * */
  @Input()
  set size(size: AvatarSizeClasses) {
    this._sizeClass = (this.sizeClasses[size]) || DefaultAvatarSizeClasses[AvatarSizes.md];
  }

  ngOnInit() {
  }
}

@Directive({
  selector: 'sf-avatar-group',
  host: {
    class: 'sf-avatar-group'
  }
})
export class SfAvatarGroupDirective implements OnInit, AfterViewInit {
  @ContentChildren(SfAvatarComponent, { descendants: true, read: ElementRef }) items: QueryList<ElementRef>;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.items.forEach(item => {
      (item.nativeElement as HTMLElement).classList.add('sf-avatar-group__avatar');
    });
  }
}
