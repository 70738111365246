import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfTabIndicatorComponent} from './tab-indicator.component';

@NgModule({
  imports: [CommonModule],
  exports: [SfTabIndicatorComponent],
  declarations: [SfTabIndicatorComponent]
})
export class SfTabIndicatorModule { }
