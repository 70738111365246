import { Injectable } from '@angular/core';
import { BaseStateService, ItemsQueryModel, ProductsStoreState } from '@ew/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ProductStateService extends BaseStateService<ProductsStoreState> {
    constructor() {
        super();
        this.initialState();
    }

    initialState(): void {
        const initialState: ProductsStoreState = {
            productTrees: undefined,
            levels: undefined,
            level: undefined,
            queryProductTrees: undefined,
            package: undefined,
            productTree: undefined,
            configurator: undefined,
            price: undefined,
            contractDurations: undefined,
            productItem: undefined,
            queryItems: new ItemsQueryModel(),
            productItems: undefined,
            breadcrumbs: undefined
        };
        this.setState(initialState, 'INIT_STATE');
    }

    get allStates(): ProductsStoreState {
        return { ...this.getState() };
    }
}
