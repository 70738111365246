<form [formGroup]="form">
  <div class="width--full">
    <sf-chip-set choice class="mt-12 pc-multi__language-chip" data-cy="languageSelect">
      <sf-chip *ngFor="let lang of languages" (click)="selectLang(lang)" [selected]="lang === selectedLang?.value">
        <sf-chip-text>
          <sf-icon class="ver-text-top font-size-18 material-icons-outlined">watch_later</sf-icon>
          {{lang | uppercase}}
        </sf-chip-text>
      </sf-chip>
      <button sfIconButton primary class="pc-multi__copy cursor--pointer ml-12 mb-8">
        <sf-icon (click)="copyContent()" data-cy="contentCopy">content_copy
        </sf-icon>
      </button>
    </sf-chip-set>

    <div *ngFor="let control of copyControls" [formGroupName]="control.key" class="mt-20">
      <ng-container *ngFor="let lang of languages">
        <ng-container *ngIf="lang === selectedLang?.value">
          <sf-form-field data-cy="name" fluid>
            <sf-input *ngIf="control.type === 'input'" [label]="control.label | translate" outlined
                      [formControlName]="lang" [max]="20" class="mb-16"></sf-input>
            <sf-textarea *ngIf="control.type === 'textarea'" [label]="control.label" outlined rows="2"
                         class="mb-16"
                         [formControlName]="lang"></sf-textarea>
          </sf-form-field>
        </ng-container>
      </ng-container>
      <p sfCaption class="custom-error">
        <span class="pt-6" *ngIf="errorLanguages(control.key).length">{{'ERROR_EMPTY_IN' | translate}}
          <span *ngFor="let lang of errorLanguages(control.key); let last=last">
        {{('LABEL_' + lang | uppercase) | translate}}{{!last ? ',' : ''}}</span>
      </span>
      </p>
    </div>
  </div>
</form>
