import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountOfTimePassedPipe } from './amount-of-time-passed.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        AmountOfTimePassedPipe
    ],
    exports: [
        AmountOfTimePassedPipe
    ]
})
export class SharedPipeAmountOfTimePassedModule {}
