import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreRoutingModule} from './core-routing.module';
import {I18N_CONFIG, I18nModule} from '@ew/shared/safire/i18n';
import {TranslateModule} from '@ngx-translate/core';
import {environment} from '@ew/env/environment';
import {HttpClientModule} from '@angular/common/http';
import {ShellModule} from '../shell/shell.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        I18nModule.forRoot(),
        TranslateModule.forRoot(),
        HttpClientModule,
        ShellModule,
        CoreRoutingModule
    ],
    providers: [
        {
            provide: I18N_CONFIG,
            useValue: {
                languages: environment.supportedLanguages,
                defaultLanguage: environment.defaultLanguage
            }
        }
    ],
    exports: [TranslateModule, I18nModule]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }
}
