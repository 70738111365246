import {Component, Input, OnInit} from '@angular/core';
import {
    LevelModel, PCBreadcrumb,
    ProductFormBuilder, ProductStateEnum,
    ProductTree, ProductTreeList,
    ProductTreesQueryModel, TableDataSource
} from '@ew/shared/services';
import {FormBaseComponent} from '@ew/shared/utils/form-utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ProductFacadeService} from '../../../services/product-facade.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'ew-service-details',
    templateUrl: './service-details.component.html',
    styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent extends FormBaseComponent implements OnInit {

  productTreesViewMode = true;
  isLoading!: boolean;

  @Input() currentLevel: LevelModel;
  @Input() allLevel: LevelModel[];
  @Input() currentProductTreesConnection: TableDataSource<ProductTreeList>;
  @Input() currentProductId: string;
  @Input() currentProductAncestry: string;
  @Input() currentProduct: ProductTree;
  @Input() currentAncestryDepth: number;

  constructor(private productFacadeService: ProductFacadeService, private route: Router, private translate: TranslateService) {
  	super();
  	this.configureForm(ProductFormBuilder.queryFormInitializer());
  }

  ngOnInit(): void {
      this.isLoading = true;
      setTimeout(() => this.isLoading = false, 500);
  	this.queryChanges();
      this.setupBreadcrumb();
  }

  setupBreadcrumb(): void {
      const existingBreadcrumb: PCBreadcrumb[] = this.productFacadeService.getSpecificState<PCBreadcrumb[]>(ProductStateEnum.BREADCRUMBS);
      if (existingBreadcrumb?.findIndex(crumb => crumb.url === this.route.url) >= 0) {
          return
      } else {
          existingBreadcrumb?.push({url: this.route.url,
              label: this.currentProduct?.package?.name?.[this.translate.currentLang]});
          this.productFacadeService.updateSpecificState<PCBreadcrumb[]>(existingBreadcrumb, ProductStateEnum.BREADCRUMBS);
      }
  }

  queryChanges(): void {
  	this.props('query').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
  		this.productFacadeService.updateSpecificState<ProductTreesQueryModel>(
  			{query: value, ancestry: this.currentProductAncestry, ancestryDepth: this.currentAncestryDepth || 0},
  			ProductStateEnum.PRODUCT_TREES_QUERY);
  	});
  }

  setGridView(value: boolean): void {
  	this.productTreesViewMode = value;
  }
}
