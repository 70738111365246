import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SfFormUtil } from '@ew/shared/safire/angular-utils';
import {
    ControlOptions,
    LevelRuleModel,
    LevelModel,
    LevelFormBuilder,
    LEVEL_RULE_KEYS,
    LevelsStoreState,
    LevelTypeEnum,
    LevelStateEnum
} from '@ew/shared/services';
import { cloneDeep, keys } from 'lodash-es';
import { Observable } from 'rxjs';

import { LevelFacadeService } from '../../services/level-facade.service';
import { LevelBaseComponent } from '../level-base/level-base.component';

@Component({
    selector: 'ew-level-create',
    templateUrl: './level-create.component.html',
    styleUrls: ['./level-create.component.scss']
})
export class LevelCreateComponent extends LevelBaseComponent implements OnInit {

  readonly levelType: typeof LevelTypeEnum = LevelTypeEnum;

  readonly copyControls: ControlOptions[] = [
  	{
  		label: 'Name',
  		key: 'name',
  		type: 'input'
  	},
  	{
  		label: 'Description',
  		key: 'description',
  		type: 'input'
  	}
  ];

  levelRules: string[] = keys(new LevelRuleModel());
  levelState$: Observable<LevelsStoreState>;
  isConfigured: boolean;
  editMode: boolean;
  formData: LevelModel;
  additionalInfo: string[] = LEVEL_RULE_KEYS;

  @Input() levelData: LevelModel;
  @Input() hideActionButtons: boolean;
  @Output() saveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private levelFacadeService: LevelFacadeService) {
  	super();
  	this.levelState$ = this.levelFacadeService.stateChange();
  }

  ngOnInit(): void {
  	this.initialize();
  }

  initialize(): void {
  	this.onEdit();
  	this.valueChange();
  }

  onEdit(): void {
  	if (this.levelData) {
  		this.initFormArrays();
  		this.form.patchValue({
  			...this.levelData
  		});
  		this.checkForConfigured();
  	} 
  	if (!this.form.get('configuratorId').value) {
  		this.form.get('configuratorId').setValue(this.levelFacadeService.levelConfig.id);
  	}
  }

  initFormArrays(): void {
  	this.levelData.dynamicColumns?.forEach((dynamicColumn, columnIndex) => {
  		this.dynamicColumnsFormArray.push(this.formBuilder(LevelFormBuilder.dynamicColumnInitializer()));
  		dynamicColumn.dynamicOptions.forEach((dynamicOption, i) => {
  			this.dynamicOptionsArrayAtDynamicColumn(columnIndex).push(this.formBuilder(LevelFormBuilder.dynamicOptionInitializer()));
  			this.dynamicOptionsArrayAtDynamicColumn(columnIndex).at(i).patchValue(dynamicOption);
  		});
  	});
  }

  checkForConfigured(): void {
  	if (this.levelFacadeService.levelConfig?.configured) {
  		this.isConfigured = true;
  		this.form.disable();
  	}
  	this.form.updateValueAndValidity();
  }

  editChange(edit: boolean): void {
  	this.editMode = edit;
  	['id', 'name', 'description'].forEach(key => {
  		this.form.get(key)[edit ? 'enable' : 'disable']();
  	});
  	edit ? this.formData = cloneDeep(this.form.value) as LevelModel : this.form.patchValue(this.formData);
  }

  save(): void {
  	SfFormUtil.markAllFormFieldsAsTouched(this.form);
  	SfFormUtil.focusOnErrorElement('mat-error');
  	if (this.form.valid) {
  		this.levelFacadeService.updateSpecificState(this.form.value, LevelStateEnum.level);
  		this.levelFacadeService.createUpdateLevel().pipe(untilDestroyed(this)).subscribe(() => {
  			this.editMode = false;
  			this.saveEvent.emit(true);
  		});
  	}
  }

  cancel = (): void => {
  	this.levelData && (this.levelData['open'] = false);
  	this.levelFacadeService.updateNewLevelState(false);
  	this.saveEvent.emit(false);
  }

  dynamicOptionsArrayAtDynamicColumn(dynamicColumnIndex: number): FormArray {
  	return this.dynamicColumnsFormArray.at(dynamicColumnIndex).get('dynamicOptions') as FormArray;
  }

  getLevelRuleAttr = (control: string): FormControl => this.form.get(`levelRule.${control}`) as FormControl;

  get dynamicColumnsFormArray(): FormArray {
  	return this.form.get('dynamicColumns') as FormArray;
  }

}
