import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfIconModule } from '../../../icon/src/index';
import { SfIconButtonModule } from '../../../icon-button/src/index';
import { SfFormsModule } from '../../../forms/src/index';
import { SfSelectModule } from '../../../select/src/index';
import { SfListModule } from '../../../list/src/index';
import { SfPaginationComponent } from './pagination.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SfIconModule,
    SfIconButtonModule,
    SfFormsModule,
    SfSelectModule,
    SfListModule,
    FormsModule
  ],
  declarations: [SfPaginationComponent],
  exports: [SfPaginationComponent]
})
export class SfPaginationModule {
}
