<ng-container *ngIf="dynamicColumn.multilingual">
  <ng-container *ngFor="let lang of langKeys">
    <sf-form-field *ngIf="activeLangKey === lang" data-cy="description" fluid>
      <sf-input class="mt-16" [label]="dynamicColumn.name | translateName" [formControl]="dynamicValueFormGroup.get('name')?.get(activeLangKey)" type="text" outlined></sf-input>
    </sf-form-field>
  </ng-container>
</ng-container>
<ng-container *ngIf="!dynamicColumn.multilingual">
  <sf-form-field [attr.data-cy]="dynamicColumn.name | translateName" fluid>
    <sf-input class="mt-16" [label]="dynamicColumn.name | translateName" [formControl]="dynamicValueFormGroup.get('value')" type="text" outlined [disabled]="isFieldDisabled"></sf-input>
  </sf-form-field>
</ng-container>
