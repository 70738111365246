import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    AddPackage,
    BaseFacadeService,
    Configurator,
    CreateProductItemAttributes,
    DeleteProductTreePayload, DynamicColumn, DynamicValue, DynamicValueModel, DynamicValuesAttributes,
    EventData,
    Image,
    Level,
    LevelModel,
    Package,
    PackageModel,
    Price,
    ProductItem,
    ProductsStoreState,
    ProductStateEnum,
    ProductTree,
    ProductTreeList,
    QueryProductItemsArgs,
    QueryProductTreesArgs, TableDataSource,
    UpdatePackage,
    UpdateProductItemAttributes,
} from '@ew/shared/services';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ProductApiService } from './product-api.service';
import { ProductBlService } from './product-bl.service';
import { ProductStateService } from './product-state.service';
import { ModifiedProductTreeListWithRegions, ModifiedProductTreeWithRegions } from '../models/package.model';

@Injectable({
    providedIn: 'root',
})
export class ProductFacadeService extends BaseFacadeService<ProductStateService, ProductsStoreState> {
    constructor(
    private productsApiService: ProductApiService,
    private productsStateService: ProductStateService,
    private productsBlService: ProductBlService
    ) {
        super(productsStateService);
    }

    get allState(): ProductsStoreState {
        return this.productsStateService.allStates;
    }

    get packageState(): Package {
        return this.getSpecificState(ProductStateEnum.PACKAGE);
    }

    initializeState(): void {
        return this.productsStateService.initialState();
    }

    copyContent(form: FormGroup, copyType: string, selectedLangKey: string): void {
        this.productsBlService.copyContent(form, copyType, selectedLangKey);
    }

    getLevel(levelId: string): Observable<LevelModel> {
        return this.productsApiService.getLevel(levelId).pipe(
            tap((response) => {
                this.updateSpecificState(response, ProductStateEnum.LEVEL);
            })
        );
    }

    getLevels(): Observable<LevelModel[]> {
        const configuratorId = this.getSpecificState<Configurator[]>(ProductStateEnum.CONFIGURATOR)[0].id;
        return this.productsApiService.getLevels(configuratorId).pipe(
            tap((levels) => {
                this.updateSpecificState(levels, ProductStateEnum.LEVELS);
            })
        );
    }

    getProductItems(queryProductItemArgs: QueryProductItemsArgs): Observable<TableDataSource<ProductItem>> {
        return this.productsApiService.getProductItems(queryProductItemArgs).pipe(tap(response =>
            this.updateSpecificState<TableDataSource<ProductItem>>(response, ProductStateEnum.PRODUCT_ITEMS)));
    }

    // eslint-disable-next-line max-len
    mapPackageToProductTrees(currentProductId: string, packageId: string, newName?: string, existingName?: string): Observable<ProductTree> {
        return this.productsApiService.mapProductToProductTrees(
            {
                packageId: packageId,
                parentId: currentProductId,
                meta: {},
            },
            newName,
            existingName
        );
    }

    getProductTrees(productTreesArgs: QueryProductTreesArgs, affectState = true): Observable<TableDataSource<ProductTreeList>> {
        return this.productsApiService
            .getProductTrees(productTreesArgs)
            .pipe(tap((productsConnection) =>
                affectState && this.updateSpecificState(productsConnection, ProductStateEnum.PRODUCT_TREES)
            ));
    }

    getProductTreeById(productTreeId: string, updateState = true): Observable<ProductTree> {
        return this.productsApiService
            .getProductTreeById(productTreeId)
            .pipe(tap((productTree) => updateState && this.updateSpecificState(productTree, ProductStateEnum.PRODUCT)));
    }

    fetchProductItemCreationDeps(productTreeId: string, productItemId: string): Observable<any> {
        return this.getProductTreeById(productTreeId).pipe(
            switchMap(() => this.getConfiguration()),
            switchMap(() => this.getLevelForCurrentProduct()),
            switchMap(() => (productItemId ? this.getProductItemById(productItemId) : of(true)))
        );
    }

    createProduct(
        packageFormValue: PackageModel,
        level: Level,
        currentProductId: string,
        configuratorPriceTypeId: string,
        isAddon = false
    ): Observable<ProductTree> {
        return this.productsApiService.addPackageToProductTrees(
            this.productsBlService.createProduct(packageFormValue, level, currentProductId, configuratorPriceTypeId, isAddon)
        );
    }

    updateProductActiveStatus(productTree: ProductTreeList): Observable<ProductTree> {
        return this.productsApiService.updateProductTreeById({
            id: productTree.id,
            meta: productTree.meta,
            active: productTree.active,
        });
    }

    addPackageToProductTree(packageData: AddPackage): Observable<ProductTree> {
        return this.productsApiService.addPackageToProductTrees(packageData);
    }

    updatePackage(packageData: UpdatePackage): Observable<Package> {
        return this.productsApiService.updatePackage(packageData);
    }

    cloneProductById(packageId: string): Observable<ProductTree> {
        return this.productsApiService.cloneProductById(packageId);
    }

    cloneProductItemById(itemId: string): Observable<ProductItem> {
        return this.productsApiService.cloneProductItemById(itemId);
    }

    setProductItemActiveStatus(itemId: string, activeStatus: boolean): Observable<boolean> {
        return this.productsApiService.updateProductItem({
            attributes: {
                id: itemId,
                active: !activeStatus,
            },
        });
    }

    updateProductItem(updateProductItemAttributes: UpdateProductItemAttributes): Observable<boolean> {
        return this.productsApiService.updateProductItem({
            attributes: updateProductItemAttributes
        });
    }

    deleteProductById(packageId: string): Observable<DeleteProductTreePayload> {
        return this.productsApiService.deleteProductById(packageId);
    }

    removeProductItem(itemId: string): Observable<boolean> {
        return this.productsApiService.removeProductItemById(itemId);
    }

    uploadImage(file: File): Observable<Image> {
        return this.productsApiService.uploadImage(file);
    }

    getPriceTypes(): Observable<Price[]> {
        return this.productsApiService.getPriceTypes().pipe(
            tap((priceTypes) => {
                this.updateSpecificState(priceTypes, ProductStateEnum.PRICE);
            })
        );
    }

    modifyPatchFormData(productInfo: ProductTreeList, formInfo: Package): UpdatePackage {
        return this.productsBlService.modifyPatchFormData(productInfo, formInfo);
    }

    setCurrentLevel(currentLevelId: string): Observable<LevelModel> {
        return this.getLevelById(currentLevelId);
    }

    getLevelById(currentLevelId?: string): Observable<LevelModel> {
        return this.productsApiService
            .getLevel(currentLevelId)
            .pipe(tap((response) => this.updateSpecificState(response, ProductStateEnum.LEVEL)));
    }

    getConfiguration(): Observable<Configurator[]> {
        return this.productsApiService
            .getConfiguration()
            .pipe(tap((config) => this.updateSpecificState(config, ProductStateEnum.CONFIGURATOR)));
    }

    generatePackagePayload(packageFormValue: Package, level: LevelModel, packageId: string): Observable<ProductTree> {
        const addPackagePayload: AddPackage = this.productsBlService.mapToAddPackagePayload(packageFormValue, level, packageId);
        return this.productsApiService.addPackageToProductTrees(addPackagePayload);
    }

    setPackageAttributes(event: { value: Package }): void {
        const packageAttribute = this.productsBlService.setPackageAttributes(this.allState, event);
        this.updateSpecificState(packageAttribute, ProductStateEnum.PACKAGE);
    }

    getProductTressByAncestryData(ancestry: string, ancestryDepth: number): void {
        const queryPayload: QueryProductTreesArgs = {
            ...this.getSpecificState(ProductStateEnum.PRODUCT_TREES_QUERY),
            ancestryDepth: ancestryDepth,
            ancestry: ancestry,
        };
        this.productsApiService.getProductTrees(queryPayload).subscribe((productTreesConnection) => {
            this.updateSpecificState(productTreesConnection, ProductStateEnum.PRODUCT_TREES);
        });
    }

    getLevelForCurrentProduct(): Observable<Level> {
        return this.getLevel(this.getSpecificState<ProductTree>(ProductStateEnum.PRODUCT).package.levelId);
    }

    updateAllProductState(event: EventData): void {
        this.updateSpecificState(this.productsBlService.updateAllProductState(this.allState, event).productState, ProductStateEnum.PRODUCT);
    }

    createProductItem(createProductItemAttributes: CreateProductItemAttributes): Observable<ProductItem> {
        return this.productsApiService.createProductItem(createProductItemAttributes);
    }

    getProductItemById(id: string): Observable<ProductItem> {
        return this.productsApiService.getProductItemById(id).pipe(
            tap((productItem) => {
                this.updateSpecificState(productItem, ProductStateEnum.PRODUCT_ITEM);
            })
        );
    }

    // [REVISIT Neeraj: 12/10/2021] Use only when contract duration is required for CRUD operations
    // fetchContractDurations(): Observable<ContractDuration[]> {
    //   const configuratorId = this.getSpecificState<Configurator[]>(ProductStateEnum.CONFIGURATOR)[0].id;
    //   return this.productsApiService
    //     .fetchContractDurations(configuratorId)
    //     .pipe(tap((contractDurations) => this.updateSpecificState(contractDurations, ProductStateEnum.CONTRACT_DURATIONS)));
    // }
    //
    // createUpdateContractDuration(contractDuration: ContractDuration): Observable<ContractDuration> {
    //   return contractDuration.id
    //     ? this.productsApiService.updateContractDuration({
    //         id: contractDuration.id,
    //         period: contractDuration.period,
    //         value: contractDuration.value,
    //       })
    //     : this.productsApiService.createContractionDuration(contractDuration);
    // }
    //
    // removeContractDuration(contractDurationId: string): Observable<DeletePayload> {
    //   return this.productsApiService.removeContractDurationById(contractDurationId);
    // }

    modifyDynamicValues(dynamicValues: DynamicValue[]): DynamicValuesAttributes[] {
        return this.productsBlService.modifyDynamicValues(dynamicValues);
    }

    assignDynamicArrayValues(dynamicColumn: DynamicColumn, columnIndex: number, dynamicValuesFormArray: FormGroup): DynamicValueModel {
        return this.productsBlService.assignDynamicArrayValues(dynamicColumn, columnIndex, dynamicValuesFormArray);
    }

    openErrorNotification(message: string): void {
        this.productsBlService.openErrorNotification(message);
    }

    mapProductTreeListWithRegions(productTreeList: 
        TableDataSource<ProductTreeList>, level: Level): TableDataSource<ModifiedProductTreeListWithRegions> {
        return this.productsBlService.mapProductTreeListWithRegions(productTreeList, level)
    }

    mapProductPackageWithRegion(productPackage: ProductTree, label: LevelModel): ModifiedProductTreeWithRegions {
        return this.productsBlService.mapProductPackageWithRegion(productPackage, label);
    }
}
