import { InjectionToken } from '@angular/core';

export interface LanguageType {
  name: string;
  displayName?: string;
  icon?: string;
}

export class I18nConfig {
  defaultLanguage = 'en';
  /**
   * @deprecated
   * Will be removed in v2.0.0.
   * Use `I18nConfig.languages` instead
   */
  supportedLanguages?: string[] = ['en'];
  languages?: LanguageType[] = [
      { name: 'en', displayName: 'English' },
      { name: 'de', displayName: 'German' },
      { name: 'fr', displayName: 'French' }
  ];
}

export const I18N_CONFIG = new InjectionToken<I18nConfig>('I18nConfig', {
    factory: () => new I18nConfig()
});
