import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'amountOfTimePassed'
})
export class AmountOfTimePassedPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(value: Date): string {
        if (!value) return '';
        const currentDate = new Date();
        const convertedDate = new Date(value);
        const diffInSeconds = Math.floor((currentDate.getTime() - convertedDate.getTime()) / 1000);
        if (diffInSeconds < 60) return `${this.translateTimePassed(diffInSeconds !== 1 ? 'SECONDS' : 'SECOND',diffInSeconds)}`;

        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) return `${this.translateTimePassed(diffInMinutes !== 1 ? 'MINUTES' : 'MINUTE',diffInMinutes)}`;

        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) return `${this.translateTimePassed(diffInHours !== 1 ? 'HOURS' : 'HOUR',diffInHours)}`;

        const diffInDays = Math.floor(diffInHours / 24);
        return `${this.translateTimePassed(diffInDays !== 1 ? 'DAYS' : 'DAY',diffInDays)}`;
    }

    translateTimePassed = (timeLabelValue: string, timeValue: number): string => 
        this.translate.instant('LABEL_'+ timeLabelValue, {timeValue});
        
}
