import { ControlParams, PackageRegionEnum } from '@ew/shared/services';

export const activeStatusParams: ControlParams[] = [
    {
        label: 'LABEL_ACTIVE',
        value: true,
    },
    {
        label: 'LABEL_INACTIVE',
        value: false,
    },
];


export const PACKAGE_REGION_CHECKBOX_OPTIONS: {label: string, value: PackageRegionEnum}[] = [
    {label: 'RSN Core Area', value: PackageRegionEnum.RsnCoreArea},
    {label: 'RSN Gebietserweiterung', value: PackageRegionEnum.RsnGebietserweiterung},
    {label: 'BBCS', value: PackageRegionEnum.Bbcs}
];