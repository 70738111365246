import { NgModule } from '@angular/core';
import { SfMenuSurfaceAnchor } from './menu-surface-anchor';
import { SfMenuSurfaceComponent } from './menu-surface.component';

const MENU_SURFACE_DECLARATIONS = [
  SfMenuSurfaceAnchor,
  SfMenuSurfaceComponent
];

@NgModule({
  exports: MENU_SURFACE_DECLARATIONS,
  declarations: MENU_SURFACE_DECLARATIONS
})
export class SfcMenuSurfaceModule {
}
