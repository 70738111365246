export const ICON_CLASS = [
    {
        class: 'fb',
        url: 'https://www.facebook.com/riiseeznet/'
    },
    {
        class: 'insta',
        url: 'https://www.instagram.com/riiseeznet/'
    },
    {
        class: 'linkd',
        url: 'https://ch.linkedin.com/company/riiseeznet'
    }
]
