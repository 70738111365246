import {Component, OnInit} from '@angular/core';
import { COOKIE_TABLE_HEADER_CONST, ESSENTIAL_COOKIE_CONST, 
    GAConsentPermissionEnum, 
    GOOGLE_ANALYTICS_COOKIES_CONST, GoogleAnalyticsCookieType, 
    SharedFacadeService, 
    StatusType } from '@ew/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@ew/shared/safire/components';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'ew-constant-preference',
    templateUrl: './constant-preference.component.html',
    styleUrls: ['./constant-preference.component.scss']
})
export class ConstantPreferenceComponent implements OnInit {

    currentLang!: string;
    googleAnalyticsSelected!: GAConsentPermissionEnum;
    googleAnalyticsPermissionGranted!: boolean;
    status: StatusType[] = COOKIE_TABLE_HEADER_CONST;
    essentialCookie: GoogleAnalyticsCookieType[] = ESSENTIAL_COOKIE_CONST;
    googleAnalyticsCookies: GoogleAnalyticsCookieType[] = GOOGLE_ANALYTICS_COOKIES_CONST;

    constructor(private dialogRef: DialogRef<string>, private lang: TranslateService,
        private cookieService: CookieService, private sharedFacde: SharedFacadeService) {
    }

    ngOnInit(): void {
        this.currentLang = this.lang.currentLang;
        this.googleAnalyticsPermissionGranted = !(this.cookieService.get('_google_analytics_accepted') === GAConsentPermissionEnum.denied);
    }

    closeModal(): void {
        this.closeDialog();
    }

    savePreferences(): void {
        this.cookieService.set('_google_analytics_accepted', this.googleAnalyticsSelected, this.sharedFacde.getAnalyticsCookieExpiryDate());
        this.closeDialog();
    }

    closeDialog = (): void => this.dialogRef.close();

    redirectToExternalContactUsPage(): void {
        window.open('https://ewbuchs.ch/kontakt/', '_blank');
    }

    gACookieStateChange(event: {checked: boolean}): void {
        this.googleAnalyticsSelected = event.checked ? GAConsentPermissionEnum.granted : GAConsentPermissionEnum.denied;
    }
}
