<div class="pc-product__menu">
<span sfMenuSurfaceAnchor #demoAnchor>
  <sf-icon class="cursor--pointer" (click)=" demo.open = !demo.open ; $event.stopPropagation();" data-cy="more">more_horiz</sf-icon>
  <sf-menu #demo [anchorElement]="demoAnchor">
    <sf-list>
      <ng-container *ngFor="let menuConfig of config">
      <sf-list-item (click)="action.emit({ actionKey: menuConfig.actionKey, row: row }); demo.open = !demo.open; $event.stopPropagation();" data-cy="{{menuConfig.cypressKey}}">
        <sf-icon class="mr-8">{{menuConfig.icon}}</sf-icon>
        {{(menuConfig.conditionBased ? (row[menuConfig.conditionalKey] ?
        menuConfig.negationLabel : menuConfig.label) : menuConfig.label) | translate}}
      </sf-list-item>
      </ng-container>
    </sf-list>
  </sf-menu>
</span>
</div>
