import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DynamicField } from '../../models/dynamic-field';
import { DynamicColumn } from '@ew/shared/services';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ew-dynamic-boolean-field',
    templateUrl: './dynamic-boolean-field.component.html',
    styleUrls: ['./dynamic-boolean-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicBooleanFieldComponent implements DynamicField {
  private dynamicValueFormGroupRef: FormGroup;

  @Input() dynamicColumn: DynamicColumn;

  @Input() set dynamicValueFormGroup(formGroup: FormGroup) {
  	this.dynamicValueFormGroupRef = formGroup;
  	this.dynamicValueFormGroup.get('value').setValue('false');
  }
  get dynamicValueFormGroup(): FormGroup {
  	return this.dynamicValueFormGroupRef;
  }

}
