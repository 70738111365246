import { Component, ElementRef, OnDestroy } from '@angular/core';
import { MDCTextFieldCharacterCounterAdapter, MDCTextFieldCharacterCounterFoundation } from '@material/textfield';
import { MDCComponent } from '../../../base/src/index';

@Component({
  selector: '[sfCharacterCounter]',
  exportAs: 'sfCharacterCounter',
  host: { 'class': 'mdc-text-field-character-counter' },
  template: '<ng-content></ng-content>'
})
export class SfCharacterCounterComponent extends MDCComponent<MDCTextFieldCharacterCounterFoundation> implements OnDestroy {
  constructor(public elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }

  getDefaultFoundation() {
    const adapter: MDCTextFieldCharacterCounterAdapter = {
      setContent: (content: string) => this.elementRef.nativeElement.textContent = content
    };
    return new MDCTextFieldCharacterCounterFoundation(adapter);
  }

  ngOnDestroy(): void {
    this.getDefaultFoundation().destroy();
  }
}
