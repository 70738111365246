// eslint-disable-next-line @typescript-eslint/no-namespace
namespace SharedHelper {
    // eslint-disable-next-line @typescript-eslint/ban-types
    export function jsonParser(data: object): object {
        Object.keys(data).forEach((key: string) => data[key] = data[key]['default']);
        return data;
    }

    export function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
        return (fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
            })
        );
    }

    export function removeProp(obj: Record<string, unknown>, propToDelete: string): Record<string, unknown> {
        for (const property in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(property)) {
                if (property === propToDelete) {
                    delete obj[property];
                }
            }
        }
        return obj;
    }


    export function readFileDataUrl(file: File, onRead: (file: string | ArrayBuffer) => void): void {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = (event): void => {
            onRead(event.target.result);
            // this.cd.markForCheck();
        };
        fileReader.readAsDataURL(file);
    }

    export function spaceRemoval(value: string): string {
        //Rmeove space before and after a value(but not the space that exists in the value)
        return value.replace(/^\s+|\s+$/g, '');
    }
}

export {SharedHelper as SharedHelper};
