<div fxLayoutAlign="center center" class="language-switcher">
    <div sfMenuSurfaceAnchor #languageSwitcher>
        <button sf-button class="color--black-60" ngClass.xs="custom-language-button" (click)="language.open = !language.open" data-cy="lanDropDown">
            <sf-icon ngClass.xs="s-20">language</sf-icon>
            <span fxHide.lt-sm sfButtonLabel>{{'LABEL_'+currentLang | uppercase | translate}}</span>
            <span fxHide.gt-xs sfButtonLabel class="color--black-87">{{currentLang}}</span>
            <sf-icon fxHide.xs>arrow_drop_down</sf-icon>
        </button>

        <sf-menu #language [anchorElement]="languageSwitcher" anchorCorner="bottomStart" (selected)="toggleLang($event)">
            <sf-list>
                <ng-container *ngFor="let lang of languages">
                    <sf-list-item [value]="lang.name" [attr.data-cy]="(('LABEL_'+lang.displayName | uppercase) | translate) | titlecase">
                        <img [src]="lang.icon" alt="county flag" class="sf-language-switcher__flag mr-8">
                        {{(('LABEL_'+lang.displayName | uppercase) | translate) | titlecase}}
                    </sf-list-item>
                </ng-container>
            </sf-list>
        </sf-menu>
    </div>
</div>
