import { NgModule } from '@angular/core';

import { SfcMenuSurfaceModule } from '../../../menu-surface/src/index';
import { SfMenu, SfMenuSelectionGroup, SfMenuSelectionGroupIcon } from './menu.component';
import { SfListModule } from '../../../list/src/index';

const MENU_DECLARATIONS = [
  SfMenu,
  SfMenuSelectionGroup,
  SfMenuSelectionGroupIcon
];

@NgModule({
  imports: [SfcMenuSurfaceModule],
  exports: [
    ...MENU_DECLARATIONS,
    SfcMenuSurfaceModule,
    SfListModule
  ],
  declarations: [MENU_DECLARATIONS]
})
export class SfMenuModule {
}
