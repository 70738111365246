<form [formGroup]="form">
  <div title fxLayoutAlign.xs="start center">
    <sf-icon fxHide.gt-xs class="s-16 cursor--pointer mr-12 color--black-60" data-cy="back" (click)="cancel()">arrow_back_ios_new</sf-icon>
    <h6 sfHeadline6 class="my-20">
      {{productInfo?.currentLevelState?.name | translateName}}</h6>
  </div>
    <sf-dialog-content>
    <p sfSubtitle2 class="mb-12">
      {{'HINT_ADD_SERVICE' | translate}}
    </p>
    <p sfCaption class="my-12">
      {{'HINT_SELECT_LANGUAGE' | translate}}
    </p>
    <sf-chip-set choice class="mt-12 language-chip" data-cy="languageSelect" [formControl]="selectedLanguageControl" standalone>
      <sf-chip *ngFor="let language of languages" [value]="language" (click)="setLanguage(language)">
        <sf-chip-text >
          <sf-icon class="ver-text-top font-size-18">watch_later</sf-icon>
          {{language | uppercase}}
        </sf-chip-text>
      </sf-chip>
      <sf-icon class="cursor--pointer mt-8 ml-12 color--primary" (click)="copyContent()" data-cy="contentCopy">content_copy</sf-icon>
    </sf-chip-set>
    <ng-container *ngFor="let language of languages">
      <ng-container *ngIf="language === selectedLanguageControl.value">
        <sf-form-field formGroupName="name" data-cy="name" fluid>
            <sf-input [label]="'LABEL_NAME' | translate" outlined class="mt-16"
                      [formControlName]="language"></sf-input>
        </sf-form-field>
      </ng-container>
    </ng-container>
    <p sfCaption class="custom-error  mt-12" *ngIf="errorLanguages('name').length">
        <span>
          {{'ERROR_EMPTY_IN' | translate}}
          <span *ngFor="let lang of errorLanguages('name'); let last=last">
            {{('LABEL_' + lang | uppercase) | translate}}{{!last ? ',' : ''}}
          </span>
        </span>
    </p>

    <div fxLayout="row wrap" class="mt-4">
      <ng-container *ngFor="let dynamicValueFormGroup of dynamicValuesFormArray.controls; let i = index">
        <div fxFlex="100">
          <ew-dynamic-field
            [dynamicColumn]="productInfo.currentLevelState.dynamicColumns[i]"
            [dynamicValueFormGroup]="dynamicValueFormGroup"
            [activeLangKey]="selectedLanguageControl.value">
          </ew-dynamic-field>
          <p sfCaption class="custom-error" *ngIf="dynamicValueLanguageErrors(i).length">
            <span>
              {{'ERROR_EMPTY_IN' | translate}}
              <span *ngFor="let lang of dynamicValueLanguageErrors(i); let last=last">
                {{('LABEL_' + lang | uppercase) | translate}}{{!last ? ',' : ''}}
              </span>
            </span>
          </p>
        </div>
      </ng-container>
    </div>

    <p sfCaption class="custom-error" *ngIf="errorLanguages('description').length">
        <span>
          {{'ERROR_EMPTY_IN' | translate}} <span *ngFor="let lang of errorLanguages('description'); let last=last">
            {{('LABEL_' + lang | uppercase) | translate}}{{!last ? ',' : ''}}
          </span>
        </span>
    </p>

    <div class=" pt-8 pb-16" *ngIf="productInfo.currentLevelState.imageable">
      <h6 sfSubtitle1 class="mt-24 color--black-87">{{'Images' | translate}}</h6>
      <p sfCaption class="mt-4 mb-20">{{'HINT_CATEGORY_IMAGES' | translate}}</p>
      <ew-image-picker [uploadFileFn]="uploadImage.bind(this)" [pickMultiple]="true" [previewImageUrls]="previewImageUrls"
                       (fileRemoved)="removeImageAtIndex($event)" [isRowLayout]="false"></ew-image-picker>
    </div>
  </sf-dialog-content>
  <sf-dialog-action fxLayoutAlign="end" ngClass.gt-xs="pb-20" ngClass.xs="common-action__button">
    <button ngClass.lt-sm="width--full mb-16" ngClass.gt-md="mr-16" sf-button outlined (click)="cancel()" data-cy="cancel"> {{'BTN_CANCEL' | translate}}</button>
    <button ngClass.lt-sm="width--full" sf-button primary (click)="isEdit ? updateService() : createService()"
            ngClass.lt-md="ml-12" [attr.data-cy]="((isEdit ? 'BTN_UPDATE': 'BTN_ADD') | translate) + ' ' + productInfo?.currentLevelState?.name[this.translate?.currentLang]">{{ ((isEdit ? "BTN_UPDATE" : "BTN_ADD") | translate) + ' ' + productInfo?.currentLevelState?.name[this.translate?.currentLang] }}</button>
  </sf-dialog-action>
</form>
