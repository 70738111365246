import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Query, User, USER_DETAILS_QUERY} from '@ew/shared/services';

@Injectable({
    providedIn: 'root'
})
export class SecurityApiService {

    constructor(private apollo: Apollo) {
    }

    getUserPermissionMatrices(): Observable<User> {
        return this.apollo.query<Query>({
            query: USER_DETAILS_QUERY,
            variables: {},
            fetchPolicy: 'no-cache'
        }).pipe(map(response => response.data.user));
    }
}
