import { Directive } from '@angular/core';

@Directive({
  selector: `sf-dialog-content, [sfDialogContent]`,
  host: {'class': 'sf-dialog__content'}})
export class SfDialogContent {}
@Directive({
  selector: `sf-dialog-action, [sfDialogAction]`,
  host: {'class': 'sf-dialog__action'}})
export class SfDialogActions {}


@Directive({
  selector: '[sfDialogTitle]',
  exportAs: 'sfDialogTitle',
  host: {
    'class': 'sf-dialog__title',
  },
})
export class SfDialogTitle {}

