import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Sf_ACCORDION, SfAccordionBase, SfAccordionTogglePosition } from './accordion-base';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { distinctUntilChanged, filter, startWith, take } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';


/** SfAccordionItem's states. */
export type MatExpansionPanelState = 'expanded' | 'collapsed';

/** Counter for generating unique element ids. */
let uniqueId = 0;

/**
 * Object that can be used to override the default options
 * for all of the expansion panels in a module.
 */
export interface SfAccordionItemDefaultOptions {
  /** Height of the header while the item is expanded. */
  expandedHeight: string;

  /** Height of the header while the item is collapsed. */
  collapsedHeight: string;

  /** Whether the toggle indicator should be hidden. */
  hideToggle: boolean;
}

/**
 * Injection token that can be used to configure the defaul
 * options for the expansion item component.
 */
export const SF_ACCORDION_ITEM_DEFAULT_OPTIONS =
  new InjectionToken<SfAccordionItemDefaultOptions>('SF_ACCORDION_ITEM_DEFAULT_OPTIONS');


@Directive({
  selector: 'ng-template[sfAccordionItemLazyContent]'
})
export class SfAccordionItemLazyContent {
  constructor(public _template: TemplateRef<any>) {
  }
}

/**
 * `<sf-accordion-item-content>`
 *
 * This directive is to be used inside of the SfAccordionItem component.
 */
@Directive({
  selector: 'sf-accordion-item-content',
  host: {
    class: 'sf-accordion-item__content',
    '[class.sf-accordion-item__content_expanded]': 'expanded'
  }
})
export class SfAccordionItemContent {
  @Input() expanded = false;
}


@Component({
  selector: 'sf-accordion-item',
  template:
      `
    <div class="sf-accordion-item">
      <ng-content select="sf-accordion-item-header"></ng-content>
      <sf-accordion-item-content [expanded]="expanded">
        <ng-content></ng-content>
      </sf-accordion-item-content>
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class SfAccordionItemComponent extends CdkAccordionItem implements AfterContentInit, OnChanges,
  OnDestroy {

  @Input() expanded = false;
  /** An event emitted after the body's expansion animation happens. */
  @Output() afterExpand = new EventEmitter<void>();
  /** An event emitted after the body's collapse animation happens. */
  @Output() afterCollapse = new EventEmitter<void>();
  /** Stream that emits for changes in `@Input` properties. */
  readonly _inputChanges = new Subject<SimpleChanges>();
  /** Optionally defined accordion the expansion item belongs to. */
  accordion: SfAccordionBase;
  /** Content that will be rendered lazily. */
  @ContentChild(SfAccordionItemLazyContent, { static: false }) _lazyContent: SfAccordionItemLazyContent;
  /** Element containing the item's user-provided content. */
  @ViewChild('body', { static: false }) _body: ElementRef<HTMLElement>;
  /** Portal holding the user's content. */
  _portal: TemplatePortal;
  /** ID for the associated header element. Used for a11y labelling. */
  _headerId = `sf-accordion-item-header-${uniqueId++}`;
  /** Stream of body animation done events. */
  _bodyAnimationDone = new Subject<AnimationEvent>();
  private _document: Document;

  constructor(@Optional() @SkipSelf() @Inject(Sf_ACCORDION) accordion: SfAccordionBase,
              _changeDetectorRef: ChangeDetectorRef,
              _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
              private _viewContainerRef: ViewContainerRef,
              @Inject(DOCUMENT) _document: any,
              @Optional() @Inject(ANIMATION_MODULE_TYPE) public _animationMode: string,
              @Inject(SF_ACCORDION_ITEM_DEFAULT_OPTIONS) @Optional()
                defaultOptions?: SfAccordionItemDefaultOptions) {
    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    this.accordion = accordion;
    this._document = _document;

    // We need a Subject with distinctUntilChanged, because the `done` event
    // fires twice on some browsers. See https://github.com/angular/angular/issues/24084
    this._bodyAnimationDone.pipe(distinctUntilChanged((x, y) => {
      return x.fromState === y.fromState && x.toState === y.toState;
    })).subscribe(event => {
      if (event.fromState !== 'void') {
        if (event.toState === 'expanded') {
          this.afterExpand.emit();
        } else if (event.toState === 'collapsed') {
          this.afterCollapse.emit();
        }
      }
    });

    if (defaultOptions) {
      this.hideToggle = defaultOptions.hideToggle;
    }
  }

  private _hideToggle = false;

  /** Whether the toggle indicator should be hidden. */
  @Input()
  get hideToggle(): boolean {
    return this._hideToggle || (this.accordion && this.accordion.hideToggle);
  }

  set hideToggle(value: boolean) {
    this._hideToggle = coerceBooleanProperty(value);
  }

  private _togglePosition: SfAccordionTogglePosition;

  /** The position of the expansion indicator. */
  @Input()
  get togglePosition(): SfAccordionTogglePosition {
    // @ts-ignore
    return this._togglePosition || (this.accordion && this.accordion.togglePosition);
  }

  set togglePosition(value: SfAccordionTogglePosition) {
    this._togglePosition = value;
  }

  /** Determines whether the expansion item should have spacing between it and its siblings. */
  _hasSpacing(): boolean {
    if (this.accordion) {
      // We don't need to subscribe to the `stateChanges` of the parent accordion because each time
      // the [displayMode] input changes, the change detection will also cover the host bindings
      // of this expansion item.
      return (this.expanded ? this.accordion.displayMode : this._getExpandedState()) === 'default';
    }
    return false;
  }

  /** Gets the expanded state string. */
  _getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  ngAfterContentInit() {
    if (this._lazyContent) {
      // Render the content as soon as the item becomes openPreview.
      this.opened.pipe(
        startWith(null!),
        filter(() => this.expanded && !this._portal),
        take(1)
      ).subscribe(() => {
        this._portal = new TemplatePortal(this._lazyContent._template, this._viewContainerRef);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this._inputChanges.next(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._bodyAnimationDone.complete();
    this._inputChanges.complete();
  }

  /** Checks whether the expansion item's content contains the currently-focused element. */
  _containsFocus(): boolean {
    if (this._body) {
      const focusedElement = this._document.activeElement;
      const bodyElement = this._body.nativeElement;
      return focusedElement === bodyElement || bodyElement.contains(focusedElement);
    }

    return false;
  }

}

@Directive({
  selector: 'sf-accordion-item-action-row',
  host: {
    class: 'sf-accordion-item-action-row'
  }
})
export class SfAccordionItemActionRow {
}


