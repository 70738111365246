import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {SfDialogService} from '@ew/shared/safire/components';
import {
    LevelModel,
    LevelTypeEnum,
    ProductStateEnum,
    ProductTreeList,
    ProductTreesQueryModel
} from '@ew/shared/services';
import {debounceTime, switchMap} from 'rxjs/operators';
import {pcRootPath} from '../../../constants/products.constant';
import {ProductFacadeService} from '../../../services/product-facade.service';
import {ServiceCreateModalComponent} from '../../service/service-create-modal/service-create-modal.component';
import {ProductFilterModalComponent} from '../product-filter-modal/product-filter-modal.component';
import {ProductNodeListModalComponent} from '../../service/product-node-list-modal/product-node-list-modal.component';

@UntilDestroy()
@Component({
    selector: 'ew-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
})

export class SearchFilterComponent implements OnInit, OnDestroy {
  readonly levelType: typeof LevelTypeEnum = LevelTypeEnum;

  select = true;
  levelState: unknown;
  rootPath = pcRootPath;
  addonsDisplay: boolean;

  @ViewChild('dialog', { static: true }) simpleDialog: TemplateRef<unknown>;
  @Input() buttonMenu: string;
  @Input() type: string;
  @Input() currentLevel: LevelModel;
  @Input() currentProduct: ProductTreeList;
  @Input() queryForm: FormGroup;
  @Output() gridViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() currentProductAncestry: string;
  @Input() currentAncestryDepth: number;
  @Input() displayAddAddonsButton: boolean;
  @Input() shouldDisplayCheckboxFilter: boolean;

  constructor(
    private dialogService: SfDialogService,
    public translateService: TranslateService,
    private productFacadeService: ProductFacadeService,
    private route: Router
  ) {
  	this.productFacadeService.specificStateChange(ProductStateEnum.LEVELS).subscribe((level) => (this.levelState = level));
  }

  ngOnInit(): void {
  	this.initialize();
  }

  ngOnDestroy(): void {
      //Note: Clear the formcontrol value so that productTrees query are not filtered and fetches all packages.
      ['query', 'filter', 'active'].forEach(formControl => this.queryForm.get(formControl).setValue(null));
  }

  initialize(): void {
  	this.updateFormValue();
  	this.valueChanges();
  	this.productFacadeService
  		.specificStateChange(ProductStateEnum.PRODUCT_TREES_QUERY)
  		.pipe(
  			debounceTime(200), untilDestroyed(this),
  			switchMap((queryValue) => this.productFacadeService.getProductTrees(queryValue))
  		)
  		.subscribe();
      this.addonsDisplay = this.currentLevel.type === LevelTypeEnum.Addon;
  }

  valueChanges(): void {
  	this.queryForm.get('query').valueChanges.pipe(untilDestroyed(this), debounceTime(200))
  		.subscribe(() => {
  			const queryPayload = {
  				...this.productFacadeService.getSpecificState<ProductTreesQueryModel>(ProductStateEnum.PRODUCT_TREES_QUERY),
  				...this.updateFormValue(),
  			};
  			this.productFacadeService.updateSpecificState(queryPayload, ProductStateEnum.PRODUCT_TREES_QUERY);
  		});
  }

  updateFormValue(): ProductTreesQueryModel {
  	this.queryForm.patchValue({
  		ancestry: this.currentProductAncestry,
  		ancestryDepth: this.currentLevel.type === LevelTypeEnum.Addon ? this.currentLevel.rank : this.currentAncestryDepth || 0,
  	});
  	return this.queryForm.value;
  }

  viewConfig(value: boolean): void {
  	this.gridViewChange.emit(value);
  	this.select = !this.select;
  }

  openServiceModal(type: string): void {
  	this.dialogService.open(ServiceCreateModalComponent, {
          panelClass: 'pc-modal--width',
  		maxWidth: 700,
  		severity: 'primary',
  		data: {
  			currentLevelState: this.currentLevel,
  			currentProductAncestry:
          this.currentLevel?.type === LevelTypeEnum.Addon ? String(this.currentLevel?.rank) : this.currentProduct?.ancestry,
  			currentProductId: this.currentLevel.rank ? this.currentProduct?.id : null,
  			productData: this.currentLevel.rank ? this.currentProduct : null,
  			actionType: type,
  		},
  	});
  }

  openFiltersModal(): void {
  	this.dialogService.open(ProductFilterModalComponent, {
  		panelClass: 'filter-modal',
  		data: {
  			form: this.queryForm,
              shouldDisplayCheckboxFilter: this.shouldDisplayCheckboxFilter
  		},
  	});
  }

  // [REVISIT: Neeraj 20/10/2021] Use when choose from existing part is activated
  openPackageListModal(): void {
      this.dialogService.open(ProductNodeListModalComponent, {
          maxWidth: 700,
          minWidth: 700,
          severity: 'primary',
          panelClass: 'modal-width--full',
          data: {
              currentLevel: this.currentLevel,
              currentProduct: this.currentProduct,
          },
      });
  }

  createPackage(): void {
  	const isAddon = this.currentLevel.type === LevelTypeEnum.Addon;
  	this.route.navigateByUrl(`${this.rootPath}product-create/${this.currentProduct?.id || ''}?addon=${isAddon}`);
  }
}
