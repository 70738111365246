import { Banners, OldSubscribedPackage, Service, User, UserToken } from './graphql';
import { BannerTableSearchParam, CleanService, GeoLocationDetail, GeoLocationValueType, 
    NotificationSortHeader, TableDataSource, UserInfo } from './shared.model';

export interface SharedStoreState {
    profileState?: User;
    tokenState: string;
    serviceState: Service[];
    outDatedPackages: OldSubscribedPackage[];
    dashboardService: CleanService[];
    currentUserState: UserInfo;
    bannerList: TableDataSource<Banners>;
    bannerSearchParam: BannerTableSearchParam;
    signUp: UserToken;
    services: Service[];
    sidebarState: {open: boolean};
    cookieBarModalDisplay: boolean;
    currentFleetUserSortValue: string;
    geoLocations: GeoLocationDetail[];
    selectedLocation: GeoLocationValueType;
    notificationsList: NotificationSortHeader[];
    newInAppNotificationReceived: boolean;
    currentAdminRoleFilter: string[];
}

export enum SharedStoreStateEnum {
    TOKEN = 'tokenState',
    USER = 'currentUserState',
    PROFILE_STATE = 'profileState',
    SERVICE = 'serviceState',
    OUTDATED_PACKAGE = 'outDatedPackages',
    DASHBOARD_SERVICE = 'dashboardService',
    BANNER_LIST = 'bannerList',
    BANNER_SEARCH_PARAM = 'bannerSearchParam',
    SIGNUP = 'signUp',
    SERVICES = 'services',
    SIDEBAR_STATE = 'sidebarState',
    CURRENT_FLEET_USER_LISTING_SORT_VALUE = 'currentFleetUserSortValue',
    NOTIFICATIONS_LISTS = 'notificationsList',
    NEW_IN_APP_NOTIFICATION_RECEIVED = 'newInAppNotificationReceived',
    COOKIE_BAR_MODAL_DISPLAY = 'cookieBarModalDisplay',
    GEOLOCATIONS = 'geoLocations',
    SELECTED_LOCATION = 'selectedLocation',
    CURRENT_ADMIN_ROLE_FILTER = 'currentAdminRoleFilter'
}

export const INITIAL_SHARED_STORE_STATE: SharedStoreState = {
    tokenState: undefined,
    serviceState: undefined,
    profileState: undefined,
    outDatedPackages: undefined,
    currentUserState: undefined,
    dashboardService: undefined,
    bannerList: undefined,
    bannerSearchParam: undefined,
    signUp: undefined,
    services: undefined,
    sidebarState: undefined,
    cookieBarModalDisplay: undefined,
    currentFleetUserSortValue: undefined,
    geoLocations: undefined,
    selectedLocation: undefined,
    notificationsList: undefined,
    newInAppNotificationReceived: undefined,
    currentAdminRoleFilter: undefined
};
