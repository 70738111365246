import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RollbarErrorHandlerService, rollBarFactory, RollBarService} from './services/rollbar-error-handler.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        {provide: ErrorHandler, useClass: RollbarErrorHandlerService},
        {provide: RollBarService, useFactory: rollBarFactory}
    ]
})
export class SharedRollbarModule {
}
