import {DocumentNode} from 'graphql';
import {TypedDocumentNode} from '@graphql-typed-document-node/core';
import gql from 'graphql-tag';

export const CREATE_FIREBASE_TOKEN: (DocumentNode | TypedDocumentNode) = gql`
    mutation createRegistrationToken ($input: createRegistrationTokenInput!) {
        createRegistrationToken (input: $input) {
            id
            token
        }
    }
`

export const DELETE_FIREBASE_TOKEN: (DocumentNode | TypedDocumentNode) = gql`
    mutation deleteRegistrationToken ($input: deleteRegistrationTokenInput!) {
        deleteRegistrationToken (input: $input) {
            id
            token
        }
    }
`

export const MARK_NOTIFICATION_AS_READ_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation markAsRead ($input: markAsReadInput!) {
        markAsRead (input: $input)
    }
`

export const DELETE_NOTIFICATION_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation discardNotification ($input: discardNotificationInput!) {
        discardNotification (input: $input)
    }
`

export const MARK_READ_ALL_NOTIFICATION_AS_READ_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation markAllAsRead ($input: markAllAsReadInput!) {
        markAllAsRead (input: $input)
    }
`

export const SWITCH_ROLE_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation switchRole ($input: SwitchRoleInput!) {
        switchRole (input: $input) {
            clientMutationId
            status
        }
    }
`

export const  SET_CURRENT_LOCALE_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation setCurrentLocale ($input: setCurrentLocaleInput!) {
        setCurrentLocale (input: $input)
    }
`

export const SET_NOTIFICATION_SETTINGS_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation notificationSetting ($input: NotificationSettingInput!) {
        notificationSetting (input: $input) {
            clientMutationId
            status
        }
    }
`

export const UPDATE_USER_EMAIL_MOBILE_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation updateUserEmailOrMobile ($input: UpdateUserEmailOrMobileInput!) {
        updateUserEmailOrMobile (input: $input) {
            active
            billingAddresses {
                city
                id
                relocationDate
                status
                street
                streetNo
                type
                zip
            }
            clientNumber
            email
            id
            installationAddresses {
                city
                id
                relocationDate
                status
                street
                streetNo
                type
                zip
            }
            mobileNumber
            oldSubscribedPackages {
                id
                idmsService
                name
                response
                userId
            }
            otpRequiredForLogin
            profile {
                dateOfBirth
                designation
                email
                firstName
                id
                lastName
                mobileNumber
                salutation
                url
            }
            role {
                id
                name
                users {
                    active
                    clientNumber
                    email
                    id
                    mobileNumber
                    name
                    profileEmail
                    profileMobileNumber
                    url
                }
                usersCount
            }
            roleId
            subscribedPackages {
                endDate
                id
                packageName
                price
                productItemId
                serviceName
                startDate
                status
                subscribedAddons {
                    id
                    name
                    price
                    productItemId
                    status
                }
            }
            unconfirmedEmail
            unconfirmedMobile
            userType {
                business
                combi
                id
            }
        }
    }
`;

export const TOGGLE_USER_BILLING_PAYMENT: (DocumentNode | TypedDocumentNode) = gql`
    mutation toggleUserBillPayment ($input: ToggleUserBillPaymentInput!) {
        toggleUserBillPayment (input: $input) {
            clientMutationId
            status
        }
    }
`;