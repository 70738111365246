/* eslint-disable no-useless-escape */
import { Observable, Subscriber } from 'rxjs';
import Compressor from 'compressorjs';

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace FileUtil {
  export function downloadBlobResponse(
      response: any,
      fileType?: any,
      fileName?: string,
      newTab?: boolean
  ) {
      const a = document.createElement('a');
      const blob = new Blob([response], { type: fileType || 'application/pdf' });
      const url = window.URL['createObjectURL'](blob);

      if (newTab) {
          window.open(url, '_blank');
      } else {
          a['href'] = url;
          a['download'] = fileName;
          document.body.appendChild(a);
          click(a);

          setTimeout(function() {
              document.body.removeChild(a);
              window.URL['revokeObjectURL'](url);
          }, 100);
      }
  }

  export function compressImage(file: File | Blob): Observable<Blob> {
      return new Observable((observer: Subscriber<Blob>) => {
          const compress = new Compressor(file, {
              quality: 0.7,
              maxWidth: 3840,
              maxHeight: 2160,
              success(blob: Blob): void {
                  observer.next(blob);
                  observer.complete();
              },
              error(error: Error): void {
                  observer.error(error);
              }
          });
      });
  }

  export function click(node) {
      try {
          node.dispatchEvent(new MouseEvent('click'));
      } catch (e) {
          const evt = document.createEvent('MouseEvents');
          evt.initMouseEvent(
              'click',
              true,
              true,
              window,
              0,
              0,
              0,
              80,
              20,
              false,
              false,
              false,
              false,
              0,
              null
          );
          node.dispatchEvent(evt);
      }
  }

  export function imageSizeValidation(
      file: File,
      size: number
  ): boolean | void {
      return file && file['size'] < size;
  }

  export function validateImageFile(
      file: File,
      extensions?: string[]
  ): boolean {
      /*tslint:disable:curly*/
      if (!file) return false;
      const ext = extensions || ['png', 'jpg', 'jpeg'];
      const regx = new RegExp(`^image\/(${ext.join('|')})$`);
      return (
          regx.test(file.type) &&
      new RegExp(`^(${ext.join('|')})$`).test(file.name.split('.').pop())
      );
  }
}

export { FileUtil as SfFileUtil };
