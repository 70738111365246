
	const FIREBASE_CONFIGURATION = {
		apiKey: 'AIzaSyDXe7G9URpJDV81F5-pwFHYFb6pevbcxnk',
		authDomain: 'ews-staging.firebaseapp.com',
		projectId: 'ews-staging',
		storageBucket: 'ews-staging.appspot.com',
		messagingSenderId: '195627680848',
		appId: '1:195627680848:web:c585ed44570a48264c7d55',
	}
	
	module.exports = {
		FIREBASE_CONFIGURATION
	};
