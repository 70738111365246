import { ChangeDetectionStrategy, Component, Input, Type } from '@angular/core';
import { DataTypeEnum, DynamicColumn } from '@ew/shared/services';
import { FormGroup } from '@angular/forms';
import { DynamicStringFieldComponent } from './components/dynamic-string-field/dynamic-string-field.component';
import { DynamicBooleanFieldComponent } from './components/dynamic-boolean-field/dynamic-boolean-field.component';
import { DynamicDateFieldComponent } from './components/dynamic-date-field/dynamic-date-field.component';
import { DynamicOptionsFieldComponent } from './components/dynamic-options-field/dynamic-options-field.component';
import { DynamicColorFieldComponent } from './components/dynamic-color-field/dynamic-color-field.component';
import { DynamicNumberFieldComponent } from './components/dynamic-integer-field/dynamic-number-field.component';
import { DynamicField } from './models/dynamic-field';
import { DynamicRichTextFieldComponent } from './components/dynamic-rich-text-field/dynamic-rich-text-field.component';
import { DynamicCheckboxFieldComponent } from './components/dynamic-checkbox-field/dynamic-checkbox-field.component';

@Component({
    selector: 'ew-dynamic-field',
    templateUrl: './dynamic-field.component.html',
    styleUrls: ['./dynamic-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFieldComponent implements DynamicField {

  readonly dynamicFieldsRegistry: Record<DataTypeEnum, Type<any>> = {
  	  [DataTypeEnum.Boolean]: DynamicBooleanFieldComponent,
  	  [DataTypeEnum.Color]: DynamicColorFieldComponent,
  	  [DataTypeEnum.Options]: DynamicOptionsFieldComponent,
  	  [DataTypeEnum.String]: DynamicStringFieldComponent,
  	  [DataTypeEnum.Date]: DynamicDateFieldComponent,
  	  [DataTypeEnum.Datetime]: DynamicDateFieldComponent,
  	  [DataTypeEnum.Integer]: DynamicNumberFieldComponent,
  	  [DataTypeEnum.Decimal]: DynamicNumberFieldComponent,
  	  [DataTypeEnum.Text]: DynamicRichTextFieldComponent,
      [DataTypeEnum.Checkbox]: DynamicCheckboxFieldComponent
  };

  @Input() activeLangKey: string;
  @Input() dynamicColumn: DynamicColumn;
  @Input() dynamicValueFormGroup: FormGroup;
}
