import {
  AfterContentInit,
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MDCRippleAdapter, MDCRippleFoundation } from '@material/ripple';

import { MDCComponent } from '../../../base/src/index';
import { SfRippleCapableSurface, SfRippleService } from '../../../ripple/src/index';
import { SF_ICON_LOCATION, SfIconComponent, SfIconLocation, SfIconRegistry } from '../../../icon/src/index';

import { chipCssClasses, MDCChipAdapter, MDCChipFoundation } from '@material/chips';

export interface SfChipInteractionEvent {
  detail: {
    chipId: string
  };
}

export interface SfChipSelectionEvent extends SfChipInteractionEvent {
  isUserInput?: boolean;
  detail: {
    chipId: string,
    selected: boolean,
    value: any
  };
}

export interface SfChipRemovedEvent extends SfChipInteractionEvent {
  detail: {
    chipId: string,
    root: SfChipComponent
  };
}

/**
 * Describes a parent SfChipSet component.
 * Contains properties that SfChip can inherit.
 */
export interface SfChipSetParentComponent {
  input: boolean;
  filter: boolean;
  choice: boolean;
}

/**
 * Injection token used to provide the parent SfChipSet component to SfChip.
 */
export const SF_CHIPSET_PARENT_COMPONENT =
  new InjectionToken<SfChipSetParentComponent>('SF_CHIPSET_PARENT_COMPONENT');

let nextUniqueId = 0;

@Component({
  selector: 'sf-chip-checkmark',
  exportAs: 'sfChipCheckmark',
  template: `
    <div class="mdc-chip__checkmark">
      <svg
        class="mdc-chip__checkmark-svg"
        viewBox="-2 -3 30 30"
        focusable="false">
        <path class="mdc-chip__checkmark-path" fill="none" stroke="black" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
      </svg>
    </div>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfChipCheckmark {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Directive({
  selector: 'sf-chip-text, [sfChipText]',
  host: { 'class': 'mdc-chip__text' }
})
export class SfChipText {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Component({
  selector: 'sf-chip',
  exportAs: 'sfChip',
  host: {
    '[id]': 'id',
    '[attr.tabindex]': 'disabled ? null : 0',
    'class': 'mdc-chip sf-mdc-chip',
    '[class.sf-mdc-chip--primary]': 'primary',
    '[class.sf-mdc-chip--secondary]': 'secondary'
  },
  template: `
    <ng-content select="sf-chip-icon[leading]"></ng-content>
    <sf-chip-checkmark *ngIf="filter"></sf-chip-checkmark>
    <div class="mdc-chip__text" *ngIf="label">{{label}}</div>
    <ng-content></ng-content>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SfRippleService]
})
export class SfChipComponent extends MDCComponent<MDCChipFoundation> implements AfterViewInit, OnDestroy,
  SfRippleCapableSurface {
  _root!: Element;
  @Input() label?: string;
  /** Emitted when the chip is selected or deselected. */
  @Output() readonly selectionChange: EventEmitter<SfChipSelectionEvent> =
    new EventEmitter<SfChipSelectionEvent>();
  /** Emitted when the chip icon is interacted with. */
  @Output() readonly trailingIconInteraction: EventEmitter<SfChipInteractionEvent> =
    new EventEmitter<SfChipInteractionEvent>();
  /** Emitted when a chip is to be removed. */
  @Output() readonly removed: EventEmitter<SfChipRemovedEvent> =
    new EventEmitter<SfChipRemovedEvent>();
  @ContentChild(SfChipCheckmark, { static: false }) _checkmark?: SfChipCheckmark;
  @ContentChildren(forwardRef(() => SfChipIcon), { descendants: true }) _icons!: QueryList<SfChipIcon>;
  /** Emits whenever the component is destroyed. */
  private _destroyed = new Subject<void>();

  constructor(
    private _platform: Platform,
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ripple: SfRippleService,
    public elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(SF_CHIPSET_PARENT_COMPONENT) private _parent: SfChipSetParentComponent) {
    super(elementRef);
    this._root = this.elementRef.nativeElement;
    this._ripple = this._createRipple();
    this._ripple.init();
  }

  private _id = `sf-chip-${nextUniqueId++}`;

  /** The unique ID of the chip. */
  get id(): string {
    return this._id;
  }

  get leadingIcon(): SfChipIcon | undefined {
    return this._icons.find((_: SfChipIcon) => _.leading);
  }

  private _selected = false;

  @Input()
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    this._selected = newValue;
    this._foundation.setSelected(newValue);

    if (this.filter && this.leadingIcon) {
      this.leadingIcon.elementRef.nativeElement.classList.remove(chipCssClasses.HIDDEN_LEADING_ICON);
    }
  }

  private _filter = false;

  @Input()
  get filter(): boolean {
    return this._filter;
  }

  set filter(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._filter) {
      this._filter = newValue;
    }
  }

  private _choice = false;

  @Input()
  get choice(): boolean {
    return this._choice;
  }

  set choice(value: boolean) {
    this._choice = coerceBooleanProperty(value);
  }

  private _input = false;

  @Input()
  get input(): boolean {
    return this._input;
  }

  set input(value: boolean) {
    this._input = coerceBooleanProperty(value);
  }

  private _primary = false;

  @Input()
  get primary(): boolean {
    return this._primary;
  }

  set primary(value: boolean) {
    this._primary = coerceBooleanProperty(value);
  }

  private _secondary = false;

  @Input()
  get secondary(): boolean {
    return this._secondary;
  }

  set secondary(value: boolean) {
    this._secondary = coerceBooleanProperty(value);
  }

  private _removable = true;

  /** Determines whether or not the chip displays the remove styling and emits (removed) events. */
  @Input()
  get removable(): boolean {
    return this._removable;
  }

  set removable(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._removable) {
      this._removable = value;
      this._foundation.setShouldRemoveOnTrailingIconClick(this._removable);
    }
  }

  private _disabled = false;

  /** Whether the chip is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disableRipple = false;

  /** Whether the chip ripple is disabled. */
  @Input()
  get disableRipple(): boolean {
    return this._disableRipple;
  }

  set disableRipple(value: boolean) {
    this._disableRipple = coerceBooleanProperty(value);
  }

  protected _value: any;

  /** The value of the chip. Defaults to the content inside `<sf-chip>` tags. */
  @Input()
  get value(): any {
    return this._value !== undefined
      ? this._value
      : this.elementRef.nativeElement.textContent;
  }

  set value(value: any) {
    this._value = value;
  }

  getDefaultFoundation() {
    const adapter: MDCChipAdapter = {
      addClass: (className: string) => this._getHostElement().classList.add(className),
      removeClass: (className: string) => this._getHostElement().classList.remove(className),
      hasClass: (className: string) => this._getHostElement().classList.contains(className),
      addClassToLeadingIcon: (className: string) => {
        if (this.leadingIcon) {
          this.leadingIcon.elementRef.nativeElement.classList.add(className);
        }
      },
      removeClassFromLeadingIcon: (className: string) => {
        if (this.leadingIcon) {
          this.leadingIcon.elementRef.nativeElement.classList.remove(className);
        }
      },
      eventTargetHasClass: (target: HTMLElement, className: string) => target.classList.contains(className),
      notifyInteraction: () => this._emitSelectionChangeEvent(true),
      notifySelection: () => {
      },
      notifyTrailingIconInteraction: () => this.trailingIconInteraction.emit({ detail: { chipId: this.id } }),
      notifyRemoval: () => this.removed.emit({ detail: { chipId: this.id, root: this } }),
      getComputedStyleValue: (propertyName: string) => {
        if (!this._platform.isBrowser) {
          return '';
        }
        return window.getComputedStyle(this._getHostElement()).getPropertyValue(propertyName);
      },
      setStyleProperty: (propertyName: string, value: string) =>
        this._getHostElement().style.setProperty(propertyName, value),
      hasLeadingIcon: () => !!this.leadingIcon,
      setAttr: (name: string, value: string) => this._elementRef.nativeElement.setAttribute(name, value),
      getRootBoundingClientRect: () => this._getHostElement().getBoundingClientRect(),
      getCheckmarkBoundingClientRect: () => this._checkmark ?
        this._checkmark.elementRef.nativeElement.getBoundingClientRect() : null
    };
    return new MDCChipFoundation(adapter);
  }

  ngAfterViewInit(): void {
    this._foundation.init();
    this._setVariantFromChipSet();
    this._loadListeners();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();

    this._ripple.destroy();
    if (this._foundation) {
      this._foundation.destroy();
    }
  }

  /** Selects the chip. */
  select(): void {
    if (!this._selected) {
      this._selected = true;
      this._emitSelectionChangeEvent();
    }
  }

  /** Deselects the chip. */
  deselect(): void {
    if (this._selected) {
      this._selected = false;
      this._emitSelectionChangeEvent();
    }
  }

  /** Select this chip and emit selected event */
  selectViaInteraction(): void {
    if (!this._selected) {
      this._selected = true;
      this._emitSelectionChangeEvent(true);
    }
  }

  /** Allows for programmatic focusing of the chip. */
  focus(): void {
    this._getHostElement().focus();
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown', ['$event'])
  _handleInteraction(evt: KeyboardEvent | MouseEvent): void {
    this._selected = !this._selected;
    this._foundation.handleInteraction(evt);
  }

  _handleTrailingIconInteraction(evt: KeyboardEvent | MouseEvent): void {
    this._foundation.handleTrailingIconInteraction(evt);
  }

  /** Emits the removed event. */
  _emitRemovedEvent(): void {
    this.removed.emit({ detail: { chipId: this.id, root: this } });
  }

  private _createRipple(): SfRippleService {
    const adapter: MDCRippleAdapter = {
      ...SfRippleService.createAdapter(this),
      computeBoundingRect: () => this._foundation.getDimensions(),
      isSurfaceDisabled: () => this._disableRipple
    };
    return new SfRippleService(this.elementRef, new MDCRippleFoundation(adapter));
  }

  private _setVariantFromChipSet(): void {
    if (this._parent) {
      this.input = this._parent.input;
      this.filter = this._parent.filter;
      this.choice = this._parent.choice;

      this._changeDetectorRef.detectChanges();
    }
  }

  private _loadListeners(): void {
    this._ngZone.runOutsideAngular(() =>
      fromEvent<TransitionEvent>(this._getHostElement(), 'transitionend')
        .pipe(takeUntil(this._destroyed))
        .subscribe(evt => this._ngZone.run(() => this._foundation.handleTransitionEnd(evt))));
  }

  /** Emits the selection change event. */
  private _emitSelectionChangeEvent(isUserInput?: boolean): void {
    this.selectionChange.emit({
      isUserInput: isUserInput,
      detail: { chipId: this.id, selected: this._selected, value: this._value }
    });
  }

  /** Retrieves the DOM element of the component host. */
  private _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}

@Component({
  selector: 'sf-chip-icon, [sfChipIcon]',
  exportAs: 'sfChipIcon',
  host: {
    'class': 'mdc-chip__icon ngx-mdc-icon',
    '[attr.role]': 'role',
    '[attr.tabindex]': 'tabIndex',
    '[class.sf-mdc-icon--clickable]': 'clickable',
    '[class.sf-mdc-icon--inline]': 'inline',
    '[class.mdc-chip__icon--leading]': 'leading',
    '[class.mdc-chip__icon--trailing]': 'trailing'
  },
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfChipIcon extends SfIconComponent implements AfterContentInit {
  constructor(
    private _parentChip: SfChipComponent,
    elementRef: ElementRef<HTMLElement>,
    iconRegistry: SfIconRegistry,
    @Attribute('aria-hidden') ariaHidden: string,
    @Inject(SF_ICON_LOCATION) location?: SfIconLocation) {

    super(elementRef, iconRegistry, ariaHidden, location);
  }

  private _leading = false;

  @Input()
  get leading(): boolean {
    return this._leading;
  }

  set leading(value: boolean) {
    this._leading = coerceBooleanProperty(value);
  }

  private _trailing = false;

  @Input()
  get trailing(): boolean {
    return this._trailing;
  }

  set trailing(value: boolean) {
    this._trailing = coerceBooleanProperty(value);
  }

  ngAfterContentInit(): void {
    if (this.trailing) {
      this.tabIndex = 0;
      this.role = 'button';
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown', ['$event'])
  _onIconInteraction(evt: KeyboardEvent | MouseEvent): void {
    if (this.trailing) {
      this._parentChip._handleTrailingIconInteraction(evt);
      if (this._parentChip.removable && this._parentChip.input) {
        this._parentChip._emitRemovedEvent();
      }
    }
  }
}
