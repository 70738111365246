<ng-container *ngIf="productState$  | async as state">
  <ew-product-list *ngIf="(state.level && state.productTrees)"
                   [currentLevel]="state.level"
                   [allLevel]="state.levels"
                   [productState]="state.productTrees"
                   [currentProduct]="state.productTree"
                   [productTreesSearchControl]="productTreesSearchControl"
  ></ew-product-list>
</ng-container>


