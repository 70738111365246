import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
    selector: 'ew-error',
    template: `
        <p class="ew-error font-size-14 ml-8 mt-4" [class.hide]="hide">{{message | uppercase | translate}}</p>
        `,
    styles: [
        '.ew-error {color: var(--sf-error)}'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {
    message: string;
    hide = true;

    @Input() set text(value) {
    	if (value !== this.message) {
    		this.message = value;
    		this.hide = !value;
    		this.cdr.detectChanges();
    	}
    };

    constructor(private cdr: ChangeDetectorRef) {
    }
}
