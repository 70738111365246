import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicField } from '../../models/dynamic-field';
import { DataTypeEnum, DynamicColumn } from '@ew/shared/services';

@Component({
    selector: 'ew-dynamic-integer-field',
    templateUrl: './dynamic-number-field.component.html',
    styleUrls: ['./dynamic-number-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicNumberFieldComponent implements DynamicField {
  private dynamicColumnData: DynamicColumn;

  @Input()
  set dynamicColumn(dynamicColumn: DynamicColumn) {
  	this.dynamicColumnData = dynamicColumn;
  }

  get dynamicColumn(): DynamicColumn {
  	return this.dynamicColumnData;
  }

  @Input() dynamicValueFormGroup: FormGroup;

  setFormValidators(): void {
  	if (this.dynamicColumn?.dataType === DataTypeEnum.Decimal) {
  		// this.dynamicValueFormGroup?.get('value').setValidators(sharedDoubleValidator);
  	}
  }
}
