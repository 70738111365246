import {APP_KEYS} from '../../../../env';
import {Environment} from '@ew/shared/services';

export const base: Partial<Environment> = {
    defaultLanguage: 'de',
    supportedLanguages: [
        {name: 'de', displayName: 'german', icon: '/assets/images/de.png'},
        {name: 'en', displayName: 'english', icon: '/assets/images/en.png'},
    ],
    googleMapsApiKey: APP_KEYS.googleMapsApiKey,
    rollBarApiKey: APP_KEYS.rollBarApiKey,
    application: 'EW-Selfcare',
    applicationShortCode: 'ew',
    notification: {
        horizontal: 'center',
        vertical: 'top',
        duration: 2000
    },
    reCaptchaSiteKey: APP_KEYS.reCaptchaSiteKey,
    externalIframeLink: 'https://riiseeznet.ch/status-portal',
    firebaseConfig: APP_KEYS.firebaseConfig
};
