<ng-container *ngIf="productLevelState$ | async as state">
  <ng-container *ngIf="state?.productTree && state.level">
    <ew-product-list
      *ngIf="state?.level.type === levelType.ProductNode"
      [productState]="state?.productTrees"
      [currentLevel]="state?.level"
      [currentProduct]="currentProduct">
    </ew-product-list>
    <ew-service-details
      *ngIf="currentProduct && state?.level.type === levelType.Product"
      [allLevel]="state.levels"
      [currentProductTreesConnection]='state?.productTrees'
      [currentLevel]="state?.level"
      [currentProductId]="currentProductId"
      [currentProductAncestry]="currentProductAncestry"
      [currentProduct]="currentProduct"
      [currentAncestryDepth]="currentAncestryDepth">
    </ew-service-details>
  </ng-container>
</ng-container>
