import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DynamicCheckboxType, DynamicField} from '../../models/dynamic-field';
import {DynamicColumn, DynamicValue} from '@ew/shared/services';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'ew-dynamic-checkbox-field',
    templateUrl: './dynamic-checkbox-field.component.html',
    styleUrls: ['./dynamic-checkbox-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicCheckboxFieldComponent implements DynamicField, OnInit {
    
    dynamicCheckboxOptions: DynamicCheckboxType[] = [];

    @Input() dynamicColumn: DynamicColumn;
    @Input() dynamicValueFormGroup: FormGroup;

    ngOnInit(): void {
        const dyanmicFormValue = this.dynamicValueFormGroup.get('dynamicOptionId')?.value;
        const parsedFormValue =  !dyanmicFormValue ? [] : JSON.parse(dyanmicFormValue);
        const selectedCheckboxesIds: string[] = parsedFormValue?.length ? 
            parsedFormValue.map((dynamicFormValue: DynamicValue) => dynamicFormValue.dynamicOptionId) : [];
        this.dynamicCheckboxOptions = this.dynamicColumn.dynamicOptions.map(checkbox => {
            return {
                checkBoxName: checkbox?.value,
                optionId: checkbox?.id,
                selected: selectedCheckboxesIds?.length ? !!(selectedCheckboxesIds.find(id => id === checkbox.id)) : 
                    this.dynamicColumn.dynamicOptions[0]?.id === checkbox?.id,
                id: parsedFormValue.find((dynamicFormValue: DynamicValue) => dynamicFormValue.dynamicOptionId === checkbox?.id)?.id
            }
        })
        //When User directlys submits form without toggling checkbox
        this.formControlPatchValue(this.dynamicCheckboxOptions);
    }

    getCheckBoxValue(dynamicColumn: DynamicColumn, optionId: string): string {
        return dynamicColumn.dynamicOptions.find(resp => resp.id === optionId)?.value
    }

    regionCheck(event: {checked: boolean}, checboxId: string): void {
        this.dynamicCheckboxOptions.find((dynamicCheckbox: DynamicCheckboxType) => 
            dynamicCheckbox.optionId === checboxId).selected  = event?.checked;
        this.formControlPatchValue(this.dynamicCheckboxOptions);
    }

    formControlPatchValue(dynamicCheckboxOptions: DynamicCheckboxType[]): void {
        this.dynamicValueFormGroup.get('dynamicOptionId').patchValue(dynamicCheckboxOptions);
    }
}
