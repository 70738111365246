import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfButtonModule } from '../../../button/src/index';
import { SF_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, SfTooltip, SfTooltipComponent } from './tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from './gesture-config';

const DECLARATIONS = [SfTooltipComponent, SfTooltip, SfTooltipComponent];

@NgModule({
  imports: [CommonModule, SfButtonModule, OverlayModule],
  exports: DECLARATIONS,
  declarations: DECLARATIONS,
  entryComponents: [SfTooltipComponent],
  providers: [
    SF_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ]
})
export class SfTooltipModule {
}
