import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SecurityFacadeService} from '../services/security-facade.service';
import {SecurityStoreEnum} from '../models/security-store.state';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate, CanActivateChild {
    constructor(private securityFacade: SecurityFacadeService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.hasRequiredPermission(route.data['auth']);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.hasRequiredPermission(childRoute.data['auth']);
    }

    private hasRequiredPermission(params: string): Observable<boolean> | boolean {
        return this.securityFacade.getSpecificState(SecurityStoreEnum.ACCESS_MATRICES) ?
            this.checkValue(params) :
            this.securityFacade.getPermissionMatrices().pipe(map(() => this.checkValue(params)));
    }

    private checkValue(key: string): boolean {
        const value: boolean = this.securityFacade.hasPermission(key);
        !value && this.router.navigate(['auth/login']);
        return value;
    }
}
