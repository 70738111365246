import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateMonthPipe } from './translate-month.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TranslateMonthPipe
    ],
    exports: [
        TranslateMonthPipe
    ]
})
export class SharedPipeTranslateMonthModule {}
