import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicColumn, MultiLanguages} from '@ew/shared/services';
import {DynamicField} from '../../models/dynamic-field';

@Component({
    selector: 'ew-dynamic-rich-text-field',
    templateUrl: './dynamic-rich-text-field.component.html',
    styleUrls: ['./dynamic-rich-text-field.component.scss']
})
export class DynamicRichTextFieldComponent implements DynamicField {
    readonly langKeys: string[] = Object.keys(new MultiLanguages());

    @Input() activeLangKey: string;
    @Input() dynamicColumn: DynamicColumn;
    @Input() dynamicValueFormGroup: FormGroup;
}
