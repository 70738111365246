<form [formGroup]="form">
  <p sfSubtitle1 class="mt-24 color--black-87">
    {{'HINT_CHOOSE_DEFAULT_CURRENCY' | translate}}
  </p>
  <p sfSubtitle2 class="mt-8 color--black-60">
    {{'HINT_SEARCH_CURRENCY_PRICES' | translate}}
  </p>

  <div class="level-config__autocomplete">
    <sf-form-field class="mt-20">
      <sf-input [label]="'LABEL_SEARCH_COUNTRY_NAME' | translate" [formControl]="countrySearchControl" standalone outlined
                fxFlex.gt-xs="60" data-cy="countryName">
        <sf-icon sfTextFieldIcon leading>search</sf-icon>
      </sf-input>
    </sf-form-field>
    <p sfCaption class="custom-error"
               *ngIf="countryAttribute.getError('isRequired') && countryAttribute?.touched">
      <span> {{'ERROR_REQUIRED' | translate}} </span>
    </p>

    <div class="level-config__autocomplete-block" *ngIf="filteredCountries.length">
      <div class="level-config__autocomplete-items p-8 cursor--pointer"
           *ngFor="let country of filteredCountries | slice:0:10; let i=index"
           (click)="selectCurrency(country)" [attr.data-cy]="country.name">
        {{country.name}} ({{country.currencyCode | uppercase}})
      </div>
    </div>
  </div>

  <div fxLayout="row" class="currency-chips mt-8 mb-12">
    <ng-container *ngFor="let countryAttr of countryAttribute.controls; let c=index">

      <div *ngIf="!countryAttr.get('_destroy').value && countryAttr.get('_destroy')" fxLayoutAlign="space-between"
           class="level-config__country-chip px-8 pt-8 pb-4 mt-20 mr-16" sfCaption [attr.data-cy]="countryAttr.get('name')?.value">

          <span class="mt-4">
            {{ countryAttr.get('name')?.value }} |
            {{countryAttr.get("currencyCode")?.value}}
            <span class="color--primary" *ngIf="countryAttr.get('default')?.value">({{'LABEL_DEFAULT' | translate}}
              )</span></span>

        <span sfMenuSurfaceAnchor #currencyAnchor>
            <sf-icon class="cursor--pointer" (click)="currency.open = !currency.open" data-cy="editMore">more_vert</sf-icon>
            <sf-menu #currency [anchorElement]="currencyAnchor">
              <sf-list>
                <sf-list-item (click)="setDefaultCurrency(countryAttr)">
                  <sf-icon class="mr-8" data-cy="setDefault">check_circle_outline</sf-icon>
                  {{'LABEL_SET_DEFAULT' | translate}}
                </sf-list-item>
                <sf-list-item (click)="removeCurrency(c, countryAttr)">
                  <sf-icon class="mr-8" data-cy="delete">delete_outline</sf-icon>
                  {{'BTN_DELETE' | translate}}
                </sf-list-item>
              </sf-list>
            </sf-menu>
          </span>
      </div>
    </ng-container>
  </div>
</form>
