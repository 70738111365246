import {
  AfterContentInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';


/** Acceptable types for a button toggle. */
export type ToggleType = 'checkbox' | 'radio';

/**
 * Provider Expression that allows sf-button-toggle-group to register as a ControlValueAccessor.
 * This allows it to support [(ngModel)].
 * @docs-private
 */
export const SF_BUTTON_TOGGLE_GROUP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SfButtonToggleGroup),
  multi: true
};
let _uniqueIdCounter = 0;

/** Change event object emitted by SfButtonToggleComponent. */
export class SfButtonToggleChange {
  constructor(
    /** source The SfButtonToggleComponent that emits the event. */
    public source: SfButtonToggleComponent,
    /** value The value assigned to the SfButtonToggleComponent. */
    public value: any) {
  }
}

/**
 * This is a simple button that demonstrates various JSDoc handling in Storybook Docs for Angular.
 *
 * It supports [markdown](https://en.wikipedia.org/wiki/Markdown), so you can embed formatted text,
 * like **bold**, _italic_, and `inline code`.
 *
 * > How you like dem apples?! It's never been easier to document all your components.
 *
 * @string Hello world
 * @link [Example](http://example.com)
 * @code `ThingThing`
 * @html <span class="badge">aaa</span>
 */
@Directive({
  selector: 'sf-button-toggle-group',
  providers: [
    SF_BUTTON_TOGGLE_GROUP_VALUE_ACCESSOR
  ],
  host: {
    'role': 'group',
    'class': 'sf-button-toggle',
    '[attr.aria-disabled]': 'disabled',
    '[class.sf-button-toggle-vertical]': 'vertical'
  },
  exportAs: 'sfButtonToggleGroup'
})
export class SfButtonToggleGroup implements ControlValueAccessor, OnInit, AfterContentInit {
  /**
   * Event emitted when the group value changes.
   * */
    // tslint:disable-next-line:no-output-native
  @Output() readonly change: EventEmitter<SfButtonToggleChange> =
    new EventEmitter<SfButtonToggleChange>();
  /** Child button toggle buttons. */
  @ContentChildren(forwardRef(() => SfButtonToggleComponent)) private _buttonToggles: QueryList<SfButtonToggleComponent>;
  /**
   * Event that emits whenever the value of the group changes.
   * Used to facilitate two-way data binding.
   */
  private readonly valueChange = new EventEmitter<any>();
  private _selectionModel: SelectionModel<SfButtonToggleComponent>;
  /**
   * Reference to the raw value that the consumer tried to assign. The real
   * value will exclude any values from this one that don't correspond to a
   * toggle. Useful for the cases where the value is assigned before the toggles
   * have been initialized or at the same that they're being swapped out.
   */
  private _rawValue: any;

  constructor(
    private _changeDetector: ChangeDetectorRef) {
  }

  private _vertical = false;

  /** Whether the toggle group is vertical. */
  @Input()
  get vertical(): boolean {
    return this._vertical;
  }

  set vertical(value: boolean) {
    this._vertical = coerceBooleanProperty(value);
  }

  private _multiple = false;

  /** Whether multiple button toggles can be selected. */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  private _disabled = false;

  /** Whether button toggle group is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this._buttonToggles) {
      this._buttonToggles.forEach(toggle => toggle._markForCheck());
    }
  }

  private _name = `sf-button-toggle-group-${_uniqueIdCounter++}`;

  /** name attribute for the underlying input element. */
  @Input()
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;

    if (this._buttonToggles) {
      this._buttonToggles.forEach(toggle => {
        toggle.name = this._name;
        toggle._markForCheck();
      });
    }
  }

  /** Value of the toggle group. */
  @Input()
  get value(): any {
    const selected = this._selectionModel ? this._selectionModel.selected : [];

    if (this.multiple) {
      return selected.map(toggle => toggle.value);
    }

    return selected[0] ? selected[0].value : undefined;
  }

  set value(newValue: any) {
    this._setSelectionByValue(newValue);
    this.valueChange.emit(this.value);
  }

  /** Selected button toggles in the group. */
  get selected() {
    const selected = this._selectionModel ? this._selectionModel.selected : [];
    return this.multiple ? selected : (selected[0] || null);
  }

  /**
   * The method to be called in order to update ngModel.
   * Now `ngModel` binding is not supported in multiple selection mode.
   * @ignore
   */
  _controlValueAccessorChangeFn: (value: any) => void = () => {
  };

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor)
   * @ignore
   * */
  _onTouched: () => any = () => {
  };

  ngOnInit() {
    this._selectionModel = new SelectionModel<SfButtonToggleComponent>(this.multiple, undefined, false);
  }

  ngAfterContentInit() {
    this._selectionModel.select(...this._buttonToggles.filter(toggle => toggle.checked));
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value Value to be set to the model.
   * @ignore
   */
  writeValue(value: any) {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  /**
   * Implemented as part of ControlValueAccessor.
   * @ignore
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Implemented as part of ControlValueAccessor.
   * @ignore
   */
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  /**
   * Implemented as part of ControlValueAccessor.
   * @ignore
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Dispatch change event with current selection and group value.
   * @ignore
   * */
  _emitChangeEvent(): void {
    const selected = this.selected;
    const source = Array.isArray(selected) ? selected[selected.length - 1] : selected;
    const event = new SfButtonToggleChange(source!, this.value);
    this._controlValueAccessorChangeFn(event.value);
    this.change.emit(event);
  }

  /**
   * Syncs a button toggle's selected state with the model value.
   * @param toggle Toggle to be synced.
   * @param select Whether the toggle should be selected.
   * @param isUserInput Whether the change was a result of a user interaction.
   * @param deferEvents Whether to defer emitting the change events.
   * @ignore
   */
  _syncButtonToggle(toggle: SfButtonToggleComponent,
                    select: boolean,
                    isUserInput = false,
                    deferEvents = false) {
    // Deselect the currently-selected toggle, if we're in single-selection
    // mode and the button being toggled isn't selected at the moment.
    if (!this.multiple && this.selected && !toggle.checked) {
      (this.selected as SfButtonToggleComponent).checked = false;
    }

    if (this._selectionModel) {
      if (select) {
        this._selectionModel.select(toggle);
      } else {
        this._selectionModel.deselect(toggle);
      }
    } else {
      deferEvents = true;
    }

    // We need to defer in some cases in order to avoid "changed after checked errors", however
    // the side-effect is that we may end up updating the model value out of sequence in others
    // The `deferEvents` flag allows us to decide whether to do it on a case-by-case basis.
    if (deferEvents) {
      Promise.resolve(() => this._updateModelValue(isUserInput));
    } else {
      this._updateModelValue(isUserInput);
    }
  }

  /**
   * Checks whether a button toggle is selected.
   * @ignore
   * */
  _isSelected(toggle: SfButtonToggleComponent) {
    return this._selectionModel && this._selectionModel.isSelected(toggle);
  }

  /**
   * Determines whether a button toggle should be checked on init.
   * @ignore
   * */
  _isPrechecked(toggle: SfButtonToggleComponent) {
    if (typeof this._rawValue === 'undefined') {
      return false;
    }

    if (this.multiple && Array.isArray(this._rawValue)) {
      return this._rawValue.some(value => toggle.value != null && value === toggle.value);
    }

    return toggle.value === this._rawValue;
  }

  /** Updates the selection state of the toggles in the group based on a value. */
  private _setSelectionByValue(value: any | any[]) {
    this._rawValue = value;

    if (!this._buttonToggles) {
      return;
    }

    if (this.multiple && value) {
      if (!Array.isArray(value)) {
        throw Error('Value must be an array in multiple-selection mode.');
      }

      this._clearSelection();
      value.forEach((currentValue: any) => this._selectValue(currentValue));
    } else {
      this._clearSelection();
      this._selectValue(value);
    }
  }

  /** Clears the selected toggles. */
  private _clearSelection() {
    this._selectionModel.clear();
    this._buttonToggles.forEach(toggle => toggle.checked = false);
  }

  /** Selects a value if there's a toggle that corresponds to it. */
  private _selectValue(value: any) {
    const correspondingOption = this._buttonToggles.find(toggle => {
      return toggle.value != null && toggle.value === value;
    });

    if (correspondingOption) {
      correspondingOption.checked = true;
      this._selectionModel.select(correspondingOption);
    }
  }

  /** Syncs up the group's value with the model and emits the change event. */
  private _updateModelValue(isUserInput: boolean) {
    // Only emit the change event for user input.
    if (isUserInput) {
      this._emitChangeEvent();
    }

    // Note: we emit this one no matter whether it was a user interaction, because
    // it is used by Angular to sync up the two-way data binding.
    this.valueChange.emit(this.value);
  }
}


/** Single button inside of a toggle group. */
@Component({
  selector: 'sf-button-toggle',
  templateUrl: 'button-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs: 'sfButtonToggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.sf-button-toggle-standalone]': '!buttonToggleGroup',
    '[class.sf-button-toggle__element_active]': 'checked',
    '[class.sf-button-toggle__element_disabled]': 'disabled',
    'class': 'sf-button-toggle__element',
    // Always reset the tabindex to -1 so it doesn't conflict with the one on the `button`,
    // but can still receive focus from things like cdkFocusInitial.
    '[attr.tabindex]': '-1',
    '[attr.id]': 'id',
    '[attr.name]': 'null',
    '(focus)': 'focus()'
  }
})
export class SfButtonToggleComponent implements OnInit, OnDestroy {

  /**
   * Attached to the aria-label attribute of the host element. In most cases, aria-labelledby will
   * take precedence so this may be omitted.
   */
  @Input('aria-label') ariaLabel: string;
  /**
   * Users can specify the `aria-labelledby` attribute which will be forwarded to the input element
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  @ViewChild('button', { static: false }) _buttonElement: ElementRef<HTMLButtonElement>;
  /** The parent button toggle group (exclusive selection). Optional.
   * @ignore
   * */
  readonly buttonToggleGroup: SfButtonToggleGroup;
  /** The unique ID for this button toggle. */
  @Input() id: string;
  /** HTML's 'name' attribute used to group radios for unique selection. */
  @Input() name: string;
  /** SfButtonToggleGroup reads this to assign its own value. */
  @Input() value: any;
  /** Tabindex for the toggle. */
  @Input() tabIndex: number | null;
  /** Event emitted when the group value changes. */
    // tslint:disable-next-line:no-output-native
  @Output() readonly change: EventEmitter<SfButtonToggleChange> =
    new EventEmitter<SfButtonToggleChange>();
  /** Type of the button toggle. Either 'radio' or 'checkbox'. */
  private _type: ToggleType;
  private _isSingleSelector = false;

  constructor(@Optional() toggleGroup: SfButtonToggleGroup,
              private _changeDetectorRef: ChangeDetectorRef,
              private _elementRef: ElementRef<HTMLElement>,
              private _focusMonitor: FocusMonitor,
              // @breaking-change 8.0.0 `defaultTabIndex` to be made a required parameter.
              @Attribute('tabindex') defaultTabIndex: string) {

    const parsedTabIndex = Number(defaultTabIndex);
    this.tabIndex = (parsedTabIndex || parsedTabIndex === 0) ? parsedTabIndex : null;
    this.buttonToggleGroup = toggleGroup;
  }

  private _checked = false;

  /** Whether the button is checked. */
  @Input()
  get checked(): boolean {
    return this.buttonToggleGroup ? this.buttonToggleGroup._isSelected(this) : this._checked;
  }

  set checked(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._checked) {
      this._checked = newValue;

      if (this.buttonToggleGroup) {
        this.buttonToggleGroup._syncButtonToggle(this, this._checked);
      }

      this._changeDetectorRef.markForCheck();
    }
  }

  /** Unique ID for the underlying `button` element. */
  get buttonId(): string {
    return `${this.id}-button`;
  }

  private _disabled = false;

  /** Whether the button is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled || (this.buttonToggleGroup && this.buttonToggleGroup.disabled);
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  ngOnInit() {
    this._isSingleSelector = this.buttonToggleGroup && !this.buttonToggleGroup.multiple;
    this._type = this._isSingleSelector ? 'radio' : 'checkbox';
    this.id = this.id || `sf-button-toggle-${_uniqueIdCounter++}`;

    if (this._isSingleSelector) {
      this.name = this.buttonToggleGroup.name;
    }

    if (this.buttonToggleGroup && this.buttonToggleGroup._isPrechecked(this)) {
      this.checked = true;
    }

    this._focusMonitor.monitor(this._elementRef, true);
  }

  ngOnDestroy() {
    const group = this.buttonToggleGroup;

    this._focusMonitor.stopMonitoring(this._elementRef);

    // Remove the toggle from the selection once it's destroyed. Needs to happen
    // on the next tick in order to avoid "changed after checked" errors.
    if (group && group._isSelected(this)) {
      group._syncButtonToggle(this, false, false, true);
    }
  }

  /** Focuses the button. */
  focus(options?: FocusOptions): void {
    this._buttonElement.nativeElement.focus(options);
  }

  /**
   * Checks the button toggle due to an interaction with the underlying native button.
   * @ignore
   * */
  _onButtonClick() {
    const newChecked = this._isSingleSelector ? true : !this._checked;

    if (newChecked !== this._checked) {
      this._checked = newChecked;
      if (this.buttonToggleGroup) {
        this.buttonToggleGroup._syncButtonToggle(this, this._checked, true);
        this.buttonToggleGroup._onTouched();
      }
    }
    // Emit a change event when it's the single selector
    this.change.emit(new SfButtonToggleChange(this, this.value));
  }

  /**
   * Marks the button toggle as needing checking for change detection.
   * This method is exposed because the parent button toggle group will directly
   * update bound properties of the radio button.
   * @ignore
   */
  _markForCheck() {
    // When the group value changes, the button will not be notified.
    // Use `markForCheck` to explicit update button toggle's status.
    this._changeDetectorRef.markForCheck();
  }
}

