<sf-accordion displayMode="flat" class="filter-content__accordion">
    <sf-accordion-item [expanded]="[true, false].includes(filterFormControl.value)">
        <sf-accordion-item-header>
            <div fxLayout="row" fxLayoutAlign="start center">
                <sf-icon class="color--primary mr-12 s-20">verified_user</sf-icon>
                <span>{{'LABEL_STATUS' | translate}}</span>
            </div>
        </sf-accordion-item-header>
        <sf-radio-group fxLayout="column" [formControl]="filterFormControl">
            <sf-form-field *ngFor="let param of controlParams" [attr.data-cy]="param.label | translate">
                <sf-radio name="radio_normal" [value]='param.value' [attr.data-cy]="param.label | translate"></sf-radio>
                <label sfSubtitle2>{{param.label | translate}}</label>
            </sf-form-field>
        </sf-radio-group>
    </sf-accordion-item>
    <sf-accordion-item *ngIf="displayCheckboxFilter">
        <sf-accordion-item-header>
           <div fxLayout="row" fxLayoutAlign="start center">
               <sf-icon class="color--primary mr-12">location_on</sf-icon>
               <span>{{'LABEL_REGION' | translate}}</span>
           </div>
        </sf-accordion-item-header>
        <sf-form-field data-cy="regionOption">
            <div fxLayout="column">
                <ng-container *ngFor="let item of dynamicCheckboxOptions">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <sf-checkbox (changed)="checkboxCheck($event, item.value)" [checked]="item.selected" data-cy="chooseItem"></sf-checkbox>
                        <label>{{ item?.label }}</label>
                    </div>
                </ng-container>
            </div>
        </sf-form-field>
    </sf-accordion-item>
</sf-accordion>
