import { Injectable } from '@angular/core';
import { ChangePermissionInput, NotificationStats, Service, User } from '../../models/graphql';
import { CleanService, GoogleAnalyticsEcommerceEventType, GeoLocations, NotificationSortHeader } from '../../models/shared.model';
import { UrlSegment } from '@angular/router';
import { APP_NAVIGATION } from '../../misc/shared.constants';
import { NotificationSortEnum, ServicesEnum, SubscriptionTypeEnum } from '../../enum/shared.enum';

@Injectable({
    providedIn: 'root'
})
export class SharedBlService {
    mapServices(services: Service[]): CleanService[] {
        return services.map(service => {
            return {
                ...service,
                logo: service.images.map(image => image?.urls[0]),
                subscribedPackages: {
                    ...service.subscribedPackages[0]
                }
            };
        });
    }

    //TODO: Might need in the future
    /*
    moveCombiToLast(services: CleanService[]): CleanService[] {
        const combi: CleanService = services.filter(service => service?.code === 'combi')?.[0];
        combi && (services.push(services.splice(services.indexOf(combi), 1)[0]));
        return services;
    }*/

    arrangeServicesWithSubscribedFirst = (services: CleanService[]): CleanService[] => {
        const arrangedServices: CleanService[] = [];
        services.forEach(service =>
            Object.keys(service.subscribedPackages).length ? arrangedServices.unshift(service) : arrangedServices.push(service));
        return arrangedServices;
    };

    checkForPermission(user: User, url: UrlSegment[]): boolean {
        const userPermission = user?.role?.name === 'admin' ? this.sideBarNav('Admin') : this.sideBarNav('User');
        const urlPath = url.map(el => el.path)[0];
        return userPermission.filter(feature => feature === urlPath).length > 0;
    }

    sideBarNav = (userType: string): string[] => {
        const nav = APP_NAVIGATION.map(nav => {
            if (nav.permissionKeys.includes(userType)) {
                return nav.url ? nav.url : nav.children.map(child => child.url);
            }
        });
        return [].concat(...nav).map(el => el && el.split('/')[1]);
    };

    getLocalStorageValue = (key: string): string => localStorage.getItem(key);

    removeQuoteFromLS = (value: string): string => value?.replace(/['"]+/g, '');

    removeInternalProductWithNoSubsPackage(services: Service[]): Service[] {
        return services.filter(service =>
            service.code === ServicesEnum.basic_household_connection ? (service.subscribedPackages?.length ? service : null) : service)
    }

    clearLocalStorage = (): void  => localStorage.clear();

    mapGoogleAnalyticsEcommerceEvent(user: User, type: SubscriptionTypeEnum): GoogleAnalyticsEcommerceEventType[] {
        return (type === SubscriptionTypeEnum.subscribedPackages) 
            ? this.mapSubscriptionPackages(user) : this.mapOldSubscriptionPackages(user)
    }

  mapSubscriptionPackages(user: User): GoogleAnalyticsEcommerceEventType[] {
      return user?.subscribedPackages?.filter(res => !res?.subscriptionStatus)?.map(productPackage => {
          return {
              item_id: productPackage?.productTreeId,
              item_name: productPackage?.packageName,
              user_id: user?.id,
              package_id: productPackage.id,
              price: productPackage?.price || 0,
              quantity: 1,
              item_category: productPackage?.code,
              item_category2: 'new',
              item_category3: 'latest'
          }
      });
  }

  mapOldSubscriptionPackages(user: User): GoogleAnalyticsEcommerceEventType[] {
      return user?.oldSubscribedPackages?.filter(pack => !pack?.dataSentToAnalytics)?.map(productPackage => {
          return {
              item_id: productPackage?.id,
              item_name: productPackage?.name,
              user_id: productPackage?.userId,
              package_id: productPackage?.id,
              price: productPackage?.response?.['MAIN']?.['PRICE'] || 0,
              quantity: 1,
              item_category: productPackage?.idmsService,
              item_category2: 'old',
          }
      })
  }

  getTotalRevenue(user: User, type: SubscriptionTypeEnum): number {
      const totalRevenueForSubscriptionPackages = user?.subscribedPackages?.length ?
          user.subscribedPackages?.map(productPackage => productPackage?.price)?.reduce((r, s) => r + s) : 0
      const totalRevenueForOldSubscriptionPackages = user?.oldSubscribedPackages?.length ?
          user.oldSubscribedPackages?.map(productPackage => productPackage?.response?.['MAIN']?.['PRICE'])?.reduce((r, s) => r + s) : 0;
      return (type === SubscriptionTypeEnum.subscribedPackages) ?
          totalRevenueForSubscriptionPackages : totalRevenueForOldSubscriptionPackages;
  }

  getTransactionId(user: User, type: SubscriptionTypeEnum) : string {
      const transactionIdForNewSubscription = user?.subscribedPackages.map(res => res?.id)?.join('#');
      const transactionIdForOldSubscription = user?.oldSubscribedPackages.map(res => res?.id)?.join('#');
      return (type === SubscriptionTypeEnum.subscribedPackages) ? transactionIdForNewSubscription : transactionIdForOldSubscription;
  }

    getAnalyticsCookieExpiryDate(): Date {
        const expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 12);
        return expiryDate;
    }

    getLocationsName = (locations: GeoLocations): string[] => (locations?.results)?.map(res => res?.attrs?.detail);

    getNotificationCount(notifications: NotificationStats): NotificationSortHeader[] {
        return([
            {
                totalCount: notifications?.all,
                label: 'LABEL_ALL',
                value: NotificationSortEnum.ALL
            },
            {
                totalCount: notifications?.read,
                label: 'LABEL_READ',
                value: NotificationSortEnum.READ
            },
            {
                totalCount: notifications?.unread,
                label: 'LABEL_UNREAD',
                value: NotificationSortEnum.UNREAD
            }
        ]);
    }

  saveFirebaseToken = (token: string): void  => localStorage.setItem('firebaseToken', token);

  removeFirebaseToken = (): void  => localStorage.removeItem('firebaseToken');

  getFirebaseToken = () : string => localStorage.getItem('firebaseToken');

  commentPermissionSetter(data:  { disable: boolean, uid: string } ): ChangePermissionInput  {
      return {
          attributes: {
              comment: {
                  create: !data?.disable
              }
          },
          userId: data?.uid
      }
  }
}
