import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicFieldComponent} from './dynamic-field.component';
import {SfCheckboxModule, SfFormsModule, SfInputModule, SfSelectModule, SfTypographyModule} from '@ew/shared/safire/components';
import {ReactiveFormsModule} from '@angular/forms';
import {DynamicModule} from 'ng-dynamic-component';
import {DynamicStringFieldComponent} from './components/dynamic-string-field/dynamic-string-field.component';
import {DynamicBooleanFieldComponent} from './components/dynamic-boolean-field/dynamic-boolean-field.component';
import {DynamicDateFieldComponent} from './components/dynamic-date-field/dynamic-date-field.component';
import {DynamicOptionsFieldComponent} from './components/dynamic-options-field/dynamic-options-field.component';
import {DynamicColorFieldComponent} from './components/dynamic-color-field/dynamic-color-field.component';
import {DynamicNumberFieldComponent} from './components/dynamic-integer-field/dynamic-number-field.component';
import {SharedPipeTranslateNameModule} from '@ew/shared/pipe/translate-name';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DynamicRichTextFieldComponent} from './components/dynamic-rich-text-field/dynamic-rich-text-field.component';
import {QuillModule} from 'ngx-quill';
import { ErrorDirectiveModule } from '@ew/shared/directives';
import { DynamicCheckboxFieldComponent } from './components/dynamic-checkbox-field/dynamic-checkbox-field.component';

@NgModule({
    imports: [
        CommonModule,
        SfFormsModule,
        SfInputModule,
        ReactiveFormsModule,
        SfSelectModule,
        SfCheckboxModule,
        SharedPipeTranslateNameModule,
        DynamicModule.withComponents([
            DynamicStringFieldComponent,
            DynamicBooleanFieldComponent,
            DynamicDateFieldComponent,
            DynamicOptionsFieldComponent,
            DynamicColorFieldComponent,
            DynamicNumberFieldComponent,
            DynamicRichTextFieldComponent
        ]),
        TranslateModule,
        FlexLayoutModule,
        QuillModule,
        SfTypographyModule,
        ErrorDirectiveModule
    ],
    declarations: [
        DynamicFieldComponent,
        DynamicStringFieldComponent,
        DynamicBooleanFieldComponent,
        DynamicDateFieldComponent,
        DynamicOptionsFieldComponent,
        DynamicColorFieldComponent,
        DynamicNumberFieldComponent,
        DynamicRichTextFieldComponent,
        DynamicCheckboxFieldComponent,
    ],
    exports: [DynamicFieldComponent],
})
export class SharedComponentsDynamicFieldModule {
}
