import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorDirective} from './error.directive';
import {ErrorComponent} from './error/error.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        ErrorDirective,
        ErrorComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [
        ErrorDirective
    ]
})
export class ErrorDirectiveModule {
}
