import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Inject,
  InjectionToken,
  Input,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  TemplateRef,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {
  CdkStep,
  CdkStepper,
  StepContentPositionState,
  STEPPER_GLOBAL_OPTIONS,
  StepperOptions
} from '@angular/cdk/stepper';
import { SfStepHeaderComponent } from './step-header.component';
import { Subject } from 'rxjs';
import { SfStepIndicatorComponent, SfStepIndicatorContext } from './step-indicator.component';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { ErrorStateMatcher } from '../../../forms/src/index';
import { SfStepLabelDirective } from './step-label.directive';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

@Component({
  selector: 'sf-step',
  template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>`,
  providers: [{ provide: ErrorStateMatcher, useExisting: SfStepComponent }],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'sfStep',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfStepComponent extends CdkStep implements ErrorStateMatcher {
  /** Content for step label given by `<ng-template sfStepLabel>`. */
  @ContentChild(SfStepLabelDirective, { static: false }) stepLabel: SfStepLabelDirective;

  @Input() label: string;
  @Input() stepControl;

  constructor(@Inject(forwardRef(() => SfStepperDirective)) stepper,
              @SkipSelf() private _errorStateMatcher: ErrorStateMatcher,
              @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions) {
    super(stepper, stepperOptions);
  }

  /** Custom error state matcher that additionally checks for validity of interacted form. */
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const originalErrorState = this._errorStateMatcher.isErrorState(control, form);

    // Custom error state checks for the validity of form that is not submitted or touched
    // since user can trigger a form change by calling for another step without directly
    // interacting with the current form.
    const customErrorState = !!(control && control.invalid && this.interacted);

    return originalErrorState || customErrorState;
  }
}

@Directive({
  selector: '[sfStepper]',
  providers: [{ provide: CdkStepper, useExisting: SfStepperDirective }]
})
export class SfStepperDirective extends CdkStepper implements AfterContentInit {
  /** The list of step headers of the steps in the stepper. */
  @ViewChildren(SfStepHeaderComponent) _stepHeader: QueryList<SfStepHeaderComponent>;

  /** Steps that the stepper holds. */
  @ContentChildren(SfStepComponent) _steps: QueryList<SfStepComponent>;

  /** Custom icon overrides passed in by the consumer. */
  @ContentChildren(SfStepIndicatorComponent) _icons: QueryList<SfStepIndicatorComponent>;

  /** Event emitted when the current step is done transitioning in. */
  @Output() readonly animationDone: EventEmitter<void> = new EventEmitter<void>();

  /** Whether ripples should be disabled for the step headers. */
  @Input() disableRipple: boolean;

  /** Consumer-specified template-refs to be used to override the header icons. */
  _iconOverrides: { [key: string]: TemplateRef<SfStepIndicatorContext> } = {};

  /** Stream of animation `done` events when the body expands/collapses. */
  _animationDone = new Subject<AnimationEvent>();

  ngAfterContentInit() {
    this._icons.forEach(({ name, templateRef }) => this._iconOverrides[name] = templateRef);

    // Mark the component for change detection whenever the content children query changes
    this._steps.changes.pipe(takeUntil(this._destroyed)).subscribe(() => this._stateChanged());

    this._animationDone.pipe(
      distinctUntilChanged((x, y) => x.fromState === y.fromState && x.toState === y.toState),
      takeUntil(this._destroyed)
    ).subscribe(event => {
      if ((event.toState as StepContentPositionState) === 'current') {
        this.animationDone.emit();
      }
    });
  }
}


export const SF_STEPPER = new InjectionToken<SfStepperDirective>('SF_STEPPER');
