import { Directive } from '@angular/core';

@Directive({
  selector: '[sf-typography], [sfTypography]',
  host: { 'class': 'mdc-typography' }
})
export class SfTypography { }

@Directive({
  selector: '[sfHeadline1]',
  host: { 'class': 'sf-typography--headline1' }
})
export class SfHeadline1 { }

@Directive({
  selector: '[sfHeadline2]',
  host: { 'class': 'sf-typography--headline2' }
})
export class SfHeadline2 { }

@Directive({
  selector: '[sfHeadline3]',
  host: { 'class': 'sf-typography--headline3' }
})
export class SfHeadline3 { }

@Directive({
  selector: '[sfHeadline4]',
  host: { 'class': 'sf-typography--headline4' }
})
export class SfHeadline4 { }

@Directive({
  selector: '[sfHeadline5]',
  host: { 'class': 'sf-typography--headline5' }
})
export class SfHeadline5 { }

@Directive({
  selector: '[sfHeadline6]',
  host: { 'class': 'sf-typography--headline6' }
})
export class SfHeadline6 { }

@Directive({
  selector: '[sfSubtitle1]',
  host: { 'class': 'sf-typography--subtitle1' }
})
export class SfSubtitle1 { }

@Directive({
  selector: '[sfSubtitle2]',
  host: { 'class': 'sf-typography--subtitle2' }
})
export class SfSubtitle2 { }

@Directive({
  selector: '[sfBody2]',
  host: { 'class': 'sf-typography--body2' }
})
export class SfBody2 { }

@Directive({
  selector: '[sfBody1]',
  host: { 'class': 'sf-typography--body1' }
})
export class SfBody1 { }

@Directive({
  selector: '[sfCaption]',
  host: { 'class': 'sf-typography--caption' }
})
export class SfCaption { }

@Directive({
  selector: '[sfButton]',
  host: { 'class': 'sf-typography--button' }
})
export class SfTypographyButton { }

@Directive({
  selector: '[sfOverline]',
  host: { 'class': 'sf-typography--overline' }
})
export class SfOverline { }
