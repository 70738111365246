import {
    AddressEnum,
    ContractDuration,
    ContractPeriodEnum,
    DirectionOptionsEnum,
    DynamicValueEw, PackageRegionEnum, PageInfo,
    ProductLimitAttributes,
    QueryProductTreesArgs,
    SortOptionsEnum,
    SubscribedPackage
} from './graphql';
import { AbstractControl } from '@angular/forms';
import { SearchparamBase } from './searchparam-base.model';

export interface Environment {
    defaultLanguage: string,
    defaultCurrency: string,
    supportedLanguages: { name: string, icon: string, displayName: string } [],
    googleMapsApiKey: string,
    rollBarApiKey: string,
    rollBarEnable: boolean,
    rollBarEnv: string,
    application: string,
    applicationShortCode: string,
    notification: { horizontal: string, vertical: string, duration: number },
    serverUrl: string,
    baseUrl: string,
    gqlUrl: string,
    production: boolean;
    clientId: string;
    authority: string;
    redirectUri: string;
    azureLogin: string;
    upcLink: string;
    externalIframeLink: string;
    reCaptchaSiteKey: string;
    firebaseConfig: any;
}

export class MultiLanguages {
    en: string;
    de: string;

    constructor() {
    	this.de = this.en = undefined;
    }
}

export class ProductTreesQueryModel implements QueryProductTreesArgs {
    active?: boolean;
    after?: string;
    ancestry: string;
    ancestryDepth: number = 0;
    before?: string;
    direction?: DirectionOptionsEnum = DirectionOptionsEnum.Asc;
    first?: number;
    last?: number;
    query?: string;
    sort?: SortOptionsEnum = SortOptionsEnum.CreatedAt;
    filter?: PackageRegionEnum[];

    constructor() {
    	this.ancestry = this.ancestryDepth = this.filter =
            this.sort = this.direction = this.query = this.active = undefined;
    }
}

export class ItemsQueryModel extends SearchparamBase {
    active?: boolean;
    packageId?: string;
    query?: string;

    constructor() {
    	super();
    	this.query = this.active = this.packageId = undefined;
    }
}

export class ContractDurationModel implements ContractDuration {
    configuratorId: string;
    id: string;
    period: ContractPeriodEnum;
    value: number;

    constructor() {
    	this.configuratorId = this.id = this.period = this.value = undefined;
    }
}

export class ProductLimitModel implements ProductLimitAttributes {
    id: string;
    _destroy: boolean;
    minimum: number;
    maximum: number;

    constructor() {
    	this.id = this._destroy = this.minimum = this.maximum = undefined;
    }
}

export interface ControlParams {
    label: string;
    value?: boolean;
}

export interface DynamicColumnData {
    column: AbstractControl;
    optionList: AbstractControl[];
}

export interface Meta {
    current?: number;
    lastPage?: number;
    nextPage?: number;
    previous?: number;
    total?: number;
}

export interface TableDataSource<T> {
    collection: T[];
    pageInfo: PageInfo;
    metaData: Meta;
}

export interface TableConfig {
    name: string;
    filterName?: string;
    type: string;
    image?: string;
    key?: string[] | string;
    largeText?: string | string[];
    smallText?: string | string[];
    optionalKey?: string[] | string;
    optionalKeyValue?: string[] | string;
    booleanText?: string[];
    suffix?: string;
    search?: boolean;
    dropDownList?: unknown[];
    objectParams?: unknown;
    sort?: {direction?: string, toolTip?: string};
    valueTranslate?: boolean;
    currencyRequired?: boolean;
    currencyCode?: string;
    ignoreHeader?: boolean;
    icons?: { icon: string, text?: string, toolTip?:string, action?: string, key?: string }[];
    actionMethod?: string;
    translate?: boolean | boolean[];
    filterValue?: string | number;
    keyDropDownId?: string;
    keyDropDownName?: string;
    actionMenu?: unknown[];
    currency?: boolean;
    valueClass?: string;
    nextLine?: boolean;
    sortKey?: string;
    conditionalAction?: boolean;
    value?: string;
    multiActionMethod?: unknown[];
    iconDisplay?: boolean;
    secondaryValueType?: string;
    displayTooltip?: boolean;
    secondaryKey?: string;
    toggleDisplay?: boolean;
}

export class AddressRelocation {
    street: string;
    streetNo: string;
    type: AddressEnum;
    zip: string;
    city: string;
    additionalInformation: string;
    apartmentNo: string;

    constructor() {
    	this.street = this.streetNo = this.type = this.zip = this.city = this.additionalInformation = this.apartmentNo = undefined;
    }
}

export class InstallationAddress extends AddressRelocation {
    relocationDate: string;

    constructor() {
    	super();
    	this.relocationDate = undefined;
    }
}

export interface filterOptions {
    label: string,
    value: boolean
}

export const FILTER_RADIO_OPTIONS: filterOptions[] = [
    {
        label: 'active',
        value: true
    },
    {
        label: 'inactive',
        value: false
    }
];

export interface DefaultResponse {
    id: string,
    'email': string,
    'mobile_number': string
    'client_number': string,
    'active': boolean,
    'role_id': string,
    'created_at': string,
    'updated_at': string,
    'encrypted_otp_secret': string,
    'encrypted_otp_secret_iv': string,
    'encrypted_otp_secret_salt': string,
    'consumed_timestep': string,
    'otp_required_for_login': boolean,
    'otp_secret': string
}

export interface ControlOptions {
    type: string;
    label: string;
    key: string;
}

export interface MenuConfig {
    label: string;
    actionKey: string;
    cypressKey?: string;
    icon?: string;
    conditionBased?: boolean;
    negationLabel?: string;
    conditionalKey?: string;
}

export interface CurrentStep {
    currentStep: number;
}

export class DynamicValueModal {
    dynamicOptionId: string;
    id: string;
    name: MultiLanguages;
    valuableId: string;
    valuableType: string;
    value: string;
    _destroy: boolean;

    constructor() {
    	this.dynamicOptionId = this.id = this.valuableId = this.valuableType = this.value = this._destroy = undefined;
    	this.name = new MultiLanguages();
    }
}

export interface CleanService {
    id?: string;
    code?: string;
    dynamicValues?: DynamicValueEw[];
    logo?: string[];
    name?: string;
    subscribedPackages?: SubscribedPackage;
}

export const NotRequiredKeys: string[] = ['status', 'relocationDate', 'id', '__typename', 'type'];
export interface UserInfo {
    mobile_number?: string,
    role_name?: string,
    otp_required_for_login?: string,
    first_time?: boolean,
    fully_authenticated?: boolean,
    new_device?: boolean,
    device_name?: string,
    remind_me?: boolean,
    device_id?: string,
    old_subscribed_packages?: boolean,
    pop_up_email_update?: boolean
}

export class BannerTableSearchParam extends SearchparamBase {
    query?: string;
    active?: boolean;
    constructor() {
        super();
        this.query = this.active = undefined;
    }
}

export interface DeviceInfo {
    id?: string,
    saved?: boolean,
    remind_me?: boolean
}

export interface PaginationParams {
    page: number;
    perPage: number;
}

export class BillSearchParam extends SearchparamBase {
    constructor(public query?: string, public dateRange?: string[], perPage?: number ) {
        super(undefined, perPage);
    }
}

export interface NavItem {
    id?: string;
    label: string;
    icon?: string;
    header?: string;
    url?: string;
    type: 'item' | 'group';
    externalUrl?: boolean;
    openInNewTab?: boolean
    hidden?: boolean;
    exactMatch?: boolean;
    children?: NavItem[];
    expand?: boolean;
    activated?: boolean;
    permissionKeys?: string;
}

export class UserListingSearchParam extends SearchparamBase {
    constructor(public roleIds?: string, public id?: string, public active?: boolean, public query?: string) {
        super();
    }
}

export class NotificationListingParam extends SearchparamBase {
    constructor(public readStatus?: boolean, public first?: number ) {
        super(1, first)
    }
}

export interface OldPackageServiceType {
    packageServiceName: string;
    oldPackageName: string;
}

export interface PasswordCriteria {
    hint: string;
    value: string;
    status: boolean;
    id: string;
}

export class OTPForm {
    multiOtpForm?: MultiOTPForm;
    singleOtpForm?: SingleElementForm;

    constructor() {
        this.multiOtpForm = new MultiOTPForm();
        this.singleOtpForm = new SingleElementForm();
    }
}

export class SingleElementForm {
    formElementControl: string;

    constructor() {
        this.formElementControl = undefined;
    }
}

export class MultiOTPForm {
    val0: number;
    val1: number;
    val2: number;
    val3: number;
    val4: number;
    val5: number;

    constructor() {
    	this.val0 = this.val1 = this.val2 = this.val3 = this.val4 = this.val5 = undefined;
    }
}

export class SetPasswordForm {
    password: string;
    confirm_password: string;

    constructor() {
    	this.password = this.confirm_password = undefined;
    }
}

export interface GoogleAnalyticsEcommerceEventType {
    item_id: string,
    item_name: string,
    user_id: string,
    package_id: string,
    price: number,
    quantity: number,
    item_category: string,
    item_category2: string
}

export interface GoogleAnalyticsCookieType {
    name: string, 
    expiryDate: string,
    description: string
}

export interface StatusType {
    details: string
}

export interface MobileUserListingSortType {
    direction: string, 
    sortDirectionValue: string, 
    sortHeader: string, 
    id: string
}

export interface MobileFleetListingSortOptionsType {
    sortHeader: string,
    sortValueOptions: MobileUserListingSortType[]
}

export interface MobileUserListingSortOptionsType {
    sortHeader: string,
    sortValueOptions: MobileUserListingSortType[]
}

export interface AdminRoleEnumConstType {
    variable: string, 
    key: string
}

export interface AdminRoleType {
    label: string, 
    id: string, 
    selected?: boolean
}

export interface GeoLocations {
    results?: GeoLocationDetail[],
    fuzzy?: string;
}

export interface GeoLocationDetail {
    weight?: number,
    id?: number,
    attrs?: {
        detail?: string,
        geom_quadindex?: string,
        geom_st_box2d?: string,
        label?: string,
        lat?: number,
        lon?: number,
        num?: number,
        objectclass?: string,
        origin?: string,
        rank?: number,
        x?: number,
        y?: number,
        zoomlevel?: number
    }
}

export interface GeoLocationValueType {
    address: string,
    street_name: string,
    city: string,
    state: string,
    zip_code: string,
    country: string,
    street_number: number
}

export interface NotificationSortOptionValue {
    all: undefined,
    read: boolean,
    unread: boolean
}

export interface InAppNotificationType {
    createdAt?: string,
    id?: string, 
    icon?: string, 
    notification?: string, 
    readStatus?: boolean
}

export interface NotificationSortHeader {
    totalCount?: number,
    label?: string,
    value?: string
}

export class NotificaitonSettingForm {
    language: string;
    mode: string;

    constructor() {
        this.language = this.mode = undefined;
    }
}

export class NotificaitonAddEmailForm extends OTPForm {
    unconfirmedEmail: string;

    constructor() {
    	super();
    	this.unconfirmedEmail = undefined;
    }
}
