import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ew-vertical-stepper',
    templateUrl: './vertical-stepper.component.html',
    styleUrls: ['./vertical-stepper.component.scss'],
})
export class VerticalStepperComponent {

    @Output() stepperChange: EventEmitter<StepperSelectionEvent> = new EventEmitter<StepperSelectionEvent>();
    @Input() stepper;

    onStepperChange(event: StepperSelectionEvent): void {
    	this.stepperChange.emit(event);
    }

    stepIcon(index: number): string {
    	return index === 0 ? 'edit' : 'access_time';
    }
}
