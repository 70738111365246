<div class="notification py-16 color--black-60" data-cy="notification">
    <div fxHide.gt-xs fxLayout="row" fxLayoutAlign="space-between center" class="mx-16">
        <div fxLayout="row" fxLayoutAlign="start center">
           <span aria-label="previous page" role="button" (click)="previous()" data-cy="back">
                <sf-icon class="s-16" >arrow_back_ios_new</sf-icon>
           </span>
            <p class="font-size-16 font--600 ml-16">{{ 'LABEL_NOTIFICATIONS' | translate }}</p>
        </div>
        <div fxLayout="row">
            <span aria-label="notification settings modal" role="button" class="cursor--pointer color--primary mr-16" (click)="notificationSetting()" data-cy="settings">
                <sf-icon class="s-20">settings</sf-icon>
            </span>
            <div class="notification-status">
                <span aria-label="mark all as read" role="button" class="cursor--pointer" (click)="notificationStatus()" data-cy="more">
                    <sf-icon class="s-20">more_vert</sf-icon>
                </span>
                <div *ngIf="notification" fxLayout="row" fxLayoutAlign="center center" class="notification-status__content p-6">
                    <sf-icon class="s-20 mr-8">fact_check</sf-icon>
                    <p aria-label="read all notifications" role="button" class="cursor--pointer br--4" (click)="readAllNotifications()" data-cy="readNotification">{{'LABEL_MARK_ALL_AS_READ' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="notification__border px-16">
        <div fxHide.xs fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <p class="font-size-16 font--600">{{'LABEL_NOTIFICATIONS' | translate}}</p>
                <span aria-label="notification settings modal" role="button" class="cursor--pointer ml-10 mt-4 color--primary" (click)="notificationSetting()" data-cy="notificationSetting">
                    <sf-icon class="s-20">settings</sf-icon>
                </span>
            </div>
            <p class="color--primary cursor--pointer" (click)="readAllNotifications()">{{'LABEL_MARK_ALL_AS_READ' | translate}}</p>
        </div>
        <sf-chip-set class="notification__chip mt-8" ngClass.gt-xs="mb-16" choice data-cy="notificationsType" (changed)="filterNotifications($event)">
            <div fxLayout="row" class="ml-N8">
                <sf-chip *ngFor="let item of sortItems" [value]="item?.value" [selected]="item?.value === currentSelectedValue">
                    <sf-chip-text [attr.data-cy]="item.label | translate" [attr.aria-label]="item.label | translate">{{item.label | translate}}</sf-chip-text>
                    <span class="ml-8" [attr.aria-label]="item.totalCount">({{item.totalCount}})</span>
                </sf-chip>
            </div>
        </sf-chip-set>
    </div>
    <ng-container *ngIf="notificationLists?.length">
        <ng-container *ngFor="let notification of notificationLists">
            <div routerLinkActive="router-link-active"  fxLayout="row" class="notification__border p-16 mx-8 notification--container cursor--pointer"
                 [ngClass]="notification.readStatus ? 'bg--white' : 'bg--baby-pink-03'" (click)="readNotification(notification.id, notification.readStatus, notification?.data?.click_action)">
                <sf-icon class="s-16 color--primary p-6 br--50"
                         [ngClass]="notification.readStatus ? 'bg--baby-pink-03' : 'bg--white'" [attr.data-cy]="notification.icon" [attr.aria-label]="notification.icon">{{notification.icon}}</sf-icon>
                <div fxFlex="72" class="mx-16">
                    <p>{{ (notification?.data[currentLanguage]) | translateMonth: notification?.data?.click_action : currentLanguage }}</p>
                    <p class="font-size-12 mt-8">{{notification.createdAt | amountOfTimePassed}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="end">
                    <button aria-label="delete notification" [disabled]="isDeleting" class="notification__button" (click)="deleteNotification(notification.id)" data-cy="delete">
                        <sf-icon class="notification__icon cursor--pointer">delete_outline</sf-icon>
                    </button>
                </div>
            </div>
        </ng-container>
        <div class="mx-16">
            <button *ngIf="stillMoreNotification" sf-button outlined (click)="viewMoreNotificaiton()" class="width--full my-18">
             <span [ngClass]="loading ? 'notification-spinner' : ''">{{(loading ? '' : 'BTN_VIEW_MORE_NOTIFICATIONS') | translate}}</span>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="!notificationLists?.length">
        <div fxLayout="column" fxLayoutAlign="center center" class="notification__empty-state">
            <img src="/assets/images/empty-notification.svg" alt="empty state notification image" />
            <p class="font-size-20 mt-24 text--center">{{'HINT_NO_NOTIFICATION' | translate}}</p>
        </div>
    </ng-container>
</div>

