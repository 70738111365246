import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateNamePipe } from './pipe/translate-name.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        TranslateNamePipe
    ],
    providers: [TranslateNamePipe],
    exports: [TranslateNamePipe]
})
export class SharedPipeTranslateNameModule {}
