import { Directive, ElementRef, Input, OnChanges, SimpleChange
} from '@angular/core';
import {coerceNumberProperty} from '@angular/cdk/coercion';

@Directive({
  selector: '[sfElevation]',
  exportAs: 'sfElevation'
})
export class SfElevationDirective implements OnChanges {
  @Input()
  get sfElevation(): number { return this._sfElevation; }
  set sfElevation(value: number) {
    this._sfElevation = coerceNumberProperty(value);
  }
  private _sfElevation = 0;

  constructor(private elementRef: ElementRef) { }

  public ngOnChanges(changes: { [key: string]: SimpleChange }): void {
    const change = changes['sfElevation'];

    if (change.currentValue < 0 || change.currentValue > 24) {
      throw new Error(`Valid mdc-elevation values are 0 through 24`);
    }

    if (!change.isFirstChange()) {
      this.elementRef.nativeElement.classList.remove(`mdc-elevation--z${change.previousValue}`);
    }
    this.elementRef.nativeElement.classList.add(`mdc-elevation--z${change.currentValue}`);
  }
}


