import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBarComponent } from './cookie-bar/cookie-bar.component';
import {
    SfAccordionModule,
    SfButtonModule,
    SfDialogModule,
    SfFormsModule,
    SfIconModule,
    SfSwitchModule
} from '@ew/shared/safire/components';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import { ConstantPreferenceComponent } from './cookie-bar/constant-preference/constant-preference.component';
import {I18nModule} from '@ew/shared/safire/i18n';

@NgModule({
    imports:
        [
            CommonModule,
            SfIconModule,
            FlexModule,
            SfButtonModule,
            SfDialogModule,
            SfAccordionModule,
            SfFormsModule,
            SfSwitchModule,
            ExtendedModule,
            I18nModule],
    declarations: [
        CookieBarComponent,
        ConstantPreferenceComponent
    ],
    exports: [
        CookieBarComponent
    ]
})

export class SharedLayoutCookiebarModule {}
