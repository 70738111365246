import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SfInputComponent} from '@ew/shared/safire/components';
import {AbstractControl, FormGroup} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

UntilDestroy()
@Component({
    selector: 'ew-one-time-password',
    templateUrl: './one-time-password.component.html',
    styleUrls: ['./one-time-password.component.scss']
})
export class OneTimePasswordComponent implements AfterViewInit, OnInit {
    otpDigits = new Array(6).fill('');
    otpVals: string;
    isSingleFormValid!: boolean;

    @Output() otpNumber = new EventEmitter<string>();
    @Output() submitEvent = new EventEmitter<boolean>();
    @Input() otpForm: FormGroup;

    @ViewChild('otpsEl') otpElement: ElementRef;

    ngOnInit(): void {
        this.otpForm.get('singleOtpForm')?.get('formElementControl').valueChanges.pipe(
            untilDestroyed(this),
            tap(otpValue => this.otpNumber.emit(otpValue))
        ).subscribe();
    }

    ngAfterViewInit(): void {
        const otpValueIndex  = this.otpForm.controls.multiOtpForm.get('val5')?.value ? (this.otpElement?.nativeElement?.length - 1) : 0;
        this.focusOnInputField(this.otpElement?.nativeElement, otpValueIndex , '');
    }
    

    updateOtpDigits(el: HTMLFormElement, i: number, otpEl: SfInputComponent, event: KeyboardEvent): void {
        if (this.otpForm.controls.multiOtpForm.get('val5').value && event.key === 'Enter') this.submitEvent.emit(true);

        //keyup and keydown event is not supported by stock android keyboard
        if (event.key === 'Unidentified') this.focusOnInputField(el, i, '+');

        if (/^[0-9]$/i.test(event.key)) {
            otpEl.value = event.key;
            this.focusOnInputField(el, i, '+');
        }
        if (i === 5) {
            const val5 = this.props('val5')?.value?.toString()?.split('');
            this.props('val5').patchValue(val5?.[val5?.length - 1]);
        }

        if (event.key === 'Backspace') this.focusOnInputField(el, i, '-');

        const {val0, val1, val2, val3, val4, val5} = this.otpForm.controls.multiOtpForm.value;
        this.otpNumber.emit([val0, val1, val2, val3, val4, val5].join(''));
    }

    focusOnInputField(el: HTMLFormElement, i: number, operation: string): void {
        const focusIndexValue = operation === '+' ? (i + 1) : ( operation === '-'  ? ( i  - 1) : i);
        el?.children?.[0]?.children[focusIndexValue]?.children?.[0]?.children?.[0]['focus']();
    }

    props = (form: string): AbstractControl => this.otpForm.controls.multiOtpForm.get(form);

    onPaste(event: ClipboardEvent): void {
        const data = event?.clipboardData?.getData('text');
        this.otpForm.controls.multiOtpForm.get('val0').patchValue(null);
        setTimeout(() => {
            data.match(/\d+/g)?.[0].split('').forEach(
                (value, i) => this.otpForm.controls.multiOtpForm.get(`val${i}`)?.patchValue(+value));
        }, 100);
        this.otpNumber.emit(data);
        this.focusOnInputField(this.otpElement?.nativeElement, this.otpElement?.nativeElement?.length - 1 , '');
    }
}
