import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { DialogContainer } from './dialog-container/dialog-container.component';
import { SfDialogService } from './dialog.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { SfCardModule } from '../../../card/src/index';
import { SfButtonModule } from '../../../button/src/index';
import { SfElevationModule } from '../../../elevation/src/index';
import {
  SfDialogActions,
  SfDialogContent,
  SfDialogTitle
} from './dialog.directive';
import { SfConfirmationComponent } from './confirmation/confirmation.component';
import { SfTypographyModule } from '../../../typography/src/index';

@NgModule({
  declarations: [
    DialogContainer,
    SfDialogContent,
    SfDialogActions,
    SfDialogTitle,
    SfConfirmationComponent
  ],
  entryComponents: [
    DialogContainer,
    SfConfirmationComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    SfCardModule,
    SfButtonModule,
    SfElevationModule,
    SfTypographyModule

  ],
  providers: [SfDialogService],
  exports: [
    SfDialogContent,
    SfDialogContent,
    SfDialogActions,
    SfDialogTitle,
    SfConfirmationComponent
  ]
})
export class SfDialogModule {
}
