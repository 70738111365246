<sf-accordion class="product-details__accordion" *ngIf="config?.showStepper">
  <sf-accordion-item [expanded]="config?.isExpanded" disabled="true">
    <sf-accordion-item-header>
      <div class="product-details__title">
        <div fxLayout="row" class="mb-4">
          <p sfSubtitle1
              class="mr-12 bold">{{(isAddon ? 'LABEL_ADDON_DETAILS' : 'LABEL_PRODUCT_DETAILS') | translate}}</p>
          <!--   <sf-icon class="color--black-60 product-details__done">done</sf-icon> -->
        </div>
        <p class="product-details__basic-details">
          <span sfSubtitle2 class="color--black-60">{{currentProduct.package.name | translateName}} | </span>
          <span sfSubtitle2 class="color--black-60">{{currentProduct.package | translateName}}</span>
        </p>
        <p sfSubtitle2 class="product-details__info color--black-60"> {{'HINT_PRODUCT_DETAILS' | translate}}</p>
      </div>
      <a sfSubtitle2
         class="color--primary product-details__accordion-icon text--uppercase">{{'BTN_CHANGE' | translate}}</a>
    </sf-accordion-item-header>
    <form [formGroup]="form" *ngIf="form.get('name')?.value">
      <p sfCaption class="mb-12 color--black-60">
        {{'HINT_SELECT_LANGUAGE' | translate}}
      </p>
      <sf-chip-set choice class="mt-12 language-chip" data-cy="languageSelect">
        <sf-chip *ngFor="let language of languages" [selected]="language === selectedLanguageControl.value"
                 (click)="setLanguage(language)">
          <sf-chip-text>
            <sf-icon class="ver-text-top font-size-18">watch_later</sf-icon>
            {{language | uppercase}}
          </sf-chip-text>
        </sf-chip>
        <sf-icon class="cursor--pointer mt-8 ml-12 color--primary" (click)="copyContent('productDetail')" data-cy="contentCopy">
          content_copy
        </sf-icon>
      </sf-chip-set>
      <div class="width--full">
        <div fxFlex="46" fxFlex.xs="100">
          <ng-container *ngFor="let control of copyControls">
            <ng-container *ngFor="let language of languages">
              <sf-form-field formGroupName="name" data-cy="name" fluid>
                <ng-container *ngIf="language === selectedLanguageControl.value">
                  <sf-input [label]="'LABEL_NAME' | translate" outlined class="mt-24 mb-20"
                            [formControlName]="language" ></sf-input>
                </ng-container>
              </sf-form-field>
            </ng-container>
            <ng-container *ngIf="errorLanguages(control.key).length">
              <p sfCaption class="custom-error mb-24">
                <span>{{'ERROR_EMPTY_IN' | translate}}
                  <span *ngFor="let language of errorLanguages(control.key); let last=last">
                      {{('LABEL_' + language | uppercase) | translate}}{{!last ? ',' : ''}}
                  </span>
                </span>
              </p>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div fxLayout="row wrap">
        <div fxLayout="row wrap" class="dynamic-field"
             *ngFor="let dynamicColumn of level?.dynamicColumns; let i = index"
             [fxFlex]="dynamicColumn.dataType === 'text' ? '100' : '46'"
             [ngClass]="dynamicColumn.dataType === 'text' ? 'mr-16' : 'mr-12'"
             ngClass.xs="mr-0"
        >
          <ew-dynamic-field fxFlex="100"
            [dynamicColumn]="dynamicColumn"
            [dynamicValueFormGroup]="dynamicValueAttr.at(i)"
            [activeLangKey]="selectedLanguageControl.value" data-cy="select">
          </ew-dynamic-field>
        </div>
      </div>
      <ng-container *ngIf="level.imageable">
        <p sfSubtitle1 class="color--black-60">{{'Images' | translate}}</p>
        <p sfCaption class="mt-4 mb-20">{{'HINT_CATEGORY_IMAGES' | translate}}</p>
        <ew-image-picker [uploadFileFn]="uploadImage.bind(this)"
                         [pickMultiple]="true"
                         [isRowLayout]="false"
                         [previewImageUrls]="previewImageUrls"
                         (fileRemoved)="removeImage($event)">
        </ew-image-picker>
        <p sfCaption class="custom-error" *ngIf="!imageFiles.length && triedSubmit">
          {{'ERROR_REQUIRED' | translate}}
        </p>
      </ng-container>
    </form>

    <div fxLayoutAlign="end center">
      <ew-prev-next [stepIndex]="config.index" [stepperLength]="length" [showSkip]="false"
                    (clickEvent)="proceed($event, config.index)"></ew-prev-next>
    </div>
  </sf-accordion-item>
</sf-accordion>


