import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { CountryList } from '@ew/shared/services';

export const COUNTRIES_QUERY: TypedDocumentNode<{ countries: CountryList[] }> = gql`
  query countries {
    countries {
      countryCode
      currencyCode
      name
    }
  }
`;
