import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { Image } from '@ew/shared/services';

export const UPLOAD_IMAGE_MUTATION: TypedDocumentNode<{uploadImage: Image}> = gql`
  mutation uploadImage($input: UploadImageInput!) {
    uploadImage(input: $input) {
      id
      urls
    }
  }`
