import {Component} from '@angular/core';

@Component({
    selector: 'ew-shell',
    template: `
        <ew-sidebar></ew-sidebar>
	`,
    styles: []
})
export class ShellComponent {

}
