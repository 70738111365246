import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Observable, Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'ew-timer',
    template: `
        <p *ngIf="timeExpired" sfBody2 class="color--black-60 font-size-14 text--center mt-24"> {{'HINT_PIN_EXPIRED' | translate}}</p>
        <p sfBody2 class="color--black-60 text--center mt-16" *ngIf="!timeExpired">
            {{'HINT_EXPIRES' | translate : {minutes: minutes, seconds: seconds} }}</p>
	`,
    styleUrls: ['./timer.component.scss']
})

export class TimerComponent implements OnInit, OnDestroy {

	timeDifference: number;
	private subscription: Subscription;
	private eventSubscription: Subscription;
	timeNow: Date;
	dTime: Date;
	seconds = 0;
	minutes = 10;
	timeExpired = false;

	@Input() timeStartEvent: Observable<void>;

	ngOnInit(): void {
	    this.countDownStart();
	    this.eventSubscription = this.timeStartEvent.pipe(
	        tap(() => this.timeExpired = false),
	        switchMap(async () => this.countDownStart()),
	        untilDestroyed(this)
	    ).subscribe();
	}

	countDownStart(): void {
	    this.dTime = new Date();
	    this.dTime.setMinutes(this.dTime.getMinutes() + 10);
	    this.subscription = interval(1000)
	        .subscribe(() => {
	            this.getTimeDifference();
	            if (this.minutes < 0 && this.seconds < 0) {
	                this.timeExpired = true;
	                this.subscription.unsubscribe();
	            }
	        });
	}

	getTimeDifference(): void {
	    this.timeDifference = this.dTime.getTime() - new Date().getTime();
	    this.seconds = Math.floor((this.timeDifference) / (1000) % 60);
	    this.minutes = Math.floor((this.timeDifference) / (1000 * 60) % 60);
	}

	ngOnDestroy(): void {
	    this.subscription.unsubscribe();
	    this.eventSubscription.unsubscribe();
	}
}
