import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SecurityFacadeService} from '../services/security-facade.service';
import {finalize} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CookieGuard implements CanActivate {
    constructor(private securityFacade: SecurityFacadeService, private router: Router) {
    }

    canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        route: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.securityFacade.cookieGuard().subscribe(() => this.router.navigate(['/profile']));
        return true;
    }

}
