<ng-container *ngIf="state$ | async as state">
  <sf-accordion class="other-details__accordion" *ngIf="config?.showStepper">
    <sf-accordion-item [expanded]="config?.isExpanded" disabled="true">
      <sf-accordion-item-header>
        <div class="other-details__title">
          <div fxLayout="row" class="mb-4">
            <p sfSubtitle1 class="mr-12 bold">{{'LABEL_OTHER_DETAILS' | translate}}</p>
          </div>
          <p sfSubtitle2 class="other-details__info color--black-60">
            {{'HINT_OTHER_DETAILS' | translate}}
          </p>
        </div>
      </sf-accordion-item-header>
      <form [formGroup]="form">
        <div fxLayout="column" *ngIf="level?.levelRule.contractDuration">
          <p sfSubtitle1>
            {{'LABEL_CONTRACT_DURATION_OPTION' | translate}}
          </p>
          <p sfSubtitle2 class="my-12 color--black-60">
            {{'HINT_CONTRACT_DURATION_OPTIONS' | translate}}
          </p>
          <div fxLayout="row">
            <sf-chip-set choice class="choice__chip width--full">
              <sf-chip fxFlex="23" class="selected" [selected]="durationSelected(contractDurationOption.id)" [value]="contractDurationOption.id"
                       (selectionChange)="changePackageContractDuration(contractDurationOption.id, $event.detail.selected)"
                       *ngFor="let contractDurationOption of contractDurationOptions$ | async">
                <sf-chip-text>{{('LABEL_' + contractDurationOption.period + '_COUNT') | uppercase | translate: {count: contractDurationOption.value} }}</sf-chip-text>
                <sf-chip-icon trailing>check_circle_outline</sf-chip-icon>
              </sf-chip>
            </sf-chip-set>
          </div>
        </div>

        <div fxLayout="column" *ngIf="level?.levelRule.delivery">
          <p sfSubtitle1>
            {{'LABEL_DELIVERY' | translate}}
          </p>
          <p sfSubtitle2 class="my-12 color--black-60">
            {{'HINT_DELIVERY_DATES' | translate}}
          </p>

          <div fxLayout="row wrap">
            <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="space-between" formGroupName="deliveryTime"
                 class="mb-16">
              <div fxFlex="49" fxLayout="column">
                <sf-form-field fluid>
                  <sf-input [label]="'LABEL_MINIMUM_DELIVERY' | translate" formControlName="minimum" outlined
                            type="number"
                            class="mb-16">
                  </sf-input>
                </sf-form-field>
                <p sfCaption class="custom-error">
                  <span
                    *ngIf="deliveryTimeAttributes?.get('minimum').hasError('required') && deliveryTimeAttributes?.get('minimum').touched"> {{'ERROR_REQUIRED' | translate}}  </span>
                  <span *ngIf="deliveryTimeAttributes?.getError('isMore')?.controlName === 'minimum' && deliveryTimeAttributes?.get('minimum').touched
                    && deliveryTimeAttributes?.get('maximum').value && !deliveryTimeAttributes?.get('minimum').hasError('required')">{{'ERROR_GREATER_THAN_MAXIMUM' | translate}}</span>
                </p>
              </div>
              <div fxFlex="49">
                <sf-form-field fluid>
                  <sf-input [label]="'LABEL_MAXIMUM_DELIVERY' | translate" formControlName="maximum" outlined
                            type="number"
                            class="mb-16"></sf-input>
                </sf-form-field>
                <p sfCaption class="custom-error">
                  <span
                    *ngIf="deliveryTimeAttributes?.get('maximum').hasError('required') && deliveryTimeAttributes?.get('maximum').touched"> {{'ERROR_REQUIRED' | translate}}  </span>
                  <span *ngIf="deliveryTimeAttributes?.getError('isLess')?.controlName === 'maximum' && deliveryTimeAttributes?.get('maximum').dirty
                  && deliveryTimeAttributes?.get('minimum').value && !deliveryTimeAttributes?.get('maximum').hasError('required')">{{'ERROR_LESS_THAN_MINIMUM' | translate}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="column" *ngIf="level.levelRule?.preorder">
          <p sfSubtitle1 class="mt-16">
            {{'LABEL_PRE_ORDER' | translate}}
          </p>
          <p sfSubtitle2 class="my-12 color--black-60">
            {{'HINT_PRE_ORDER' | translate}}
          </p>

          <div fxLayout="row wrap" class="width--full mb-20">

            <div fxFlex="80" fxLayoutAlign="space-between" formGroupName="preorder">
              <div fxLayout="column" fxFlex="49">
                <sf-form-field class="mb-20" fluid>
                  <div class="custom-input-field">
                    <input [owlDateTimeTrigger]="preOrder" [owlDateTime]="preOrder" required="required"
                           formControlName="startDate" />
                    <label>{{'LABEL_PRE_ORDER_START_DATE' | translate}}</label>
                    <sf-icon class="color--black-60 cursor--pointer" [owlDateTimeTrigger]="preOrder">today</sf-icon>
                  </div>
                  <owl-date-time [pickerType]="'calendar'" #preOrder></owl-date-time>
                </sf-form-field>
                <p sfCaption class="custom-error"
                   *ngIf="preorder?.get('startDate').hasError('isMoreDate') &&
                     preorder?.get('startDate').touched && !preorder?.get('startDate').hasError('required')">
                  <span>{{'Date is greater than the release date.'}}</span>
                </p>
                <p sfCaption class="custom-error"
                   *ngIf="preorder?.get('startDate').hasError('required') && preorder?.get('startDate').touched">
                  <span> {{'ERROR_REQUIRED' | translate}}  </span>
                </p>
              </div>

              <div fxLayout="column" fxFlex="49">
                <sf-form-field class="mb-20" fluid>
                  <div class="custom-input-field">
                    <input [owlDateTime]="release" [owlDateTimeTrigger]="release" required="required"
                           formControlName="releaseDate" />
                    <label>{{'LABEL_RELEASE_DATE' | translate}}</label>
                    <sf-icon class="color--black-60 cursor--pointer" [owlDateTimeTrigger]="release">today</sf-icon>
                  </div>
                  <owl-date-time [pickerType]="'calendar'" #release></owl-date-time>
                </sf-form-field>
                <p sfCaption class="custom-error"
                   *ngIf="preorder?.get('releaseDate').hasError('isLessDate') &&
                     preorder?.get('releaseDate').touched && !preorder?.get('releaseDate').hasError('required')">
                  <span>{{'Date is less than pre-order start date'}}</span>
                </p>
                <p sfCaption class="custom-error"
                   *ngIf="preorder?.get('releaseDate').hasError('required') && preorder?.get('releaseDate').touched">
                  <span> {{'ERROR_REQUIRED' | translate}}  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <ew-prev-next [stepIndex]="config.index" [stepperLength]="length" [showSkip]="false"
                      (clickEvent)="updatePreorderDelivery($event, config.index);"></ew-prev-next>
      </form>
    </sf-accordion-item>
  </sf-accordion>
</ng-container>
