import { ChangeDetectionStrategy, Component, Directive, ElementRef, ViewEncapsulation } from '@angular/core';
import { CdkTable } from '@angular/cdk/table';

@Component({
  selector: 'sf-table',
  exportAs: 'sfDataTable',
  host: { 'class': 'mdc-data-table sf-table' },
  template: `
      <ng-content select="sf-table-header"></ng-content>
      <table sfTableElement>
          <thead>
          <ng-container headerRowOutlet></ng-container>
          </thead>
          <tbody>
          <ng-container rowOutlet></ng-container>
          </tbody>
          <tfoot>
          <ng-container footerRowOutlet></ng-container>
          </tfoot>
      </table>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SfDataTableComponent<T> extends CdkTable<T> {
}

@Directive({
  selector: '[sfTableElement], sf-table-element',
  exportAs: 'sfTableElement',
  host: { 'class': 'mdc-data-table__table sf-table__element' }
})
export class SfTableElementDirective {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Directive({
  selector: 'tbody[sfTableContent], sf-table-content',
  exportAs: 'sfTableContent',
  host: { 'class': 'mdc-data-table__content sf-table__content' }
})
export class SfDataTableContentDirective {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Directive({
  selector: 'sf-table-header, [sfTableHeader]',
  exportAs: 'sfTableHeader',
  host: { 'class': 'sf-table__header' }
})
export class SfTableHeaderDirective {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}
