import { Injectable } from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import { SharedFacadeService } from '../shared/shared-facade.service';
import { SharedStoreStateEnum } from '../../models/shared-store.state';
import { NotificationService } from '../notification/notification.service';
import { mapTo, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PushNotificaionService {
    
    constructor(private firebaseMessaging: AngularFireMessaging, 
        private sharedFacadeService: SharedFacadeService,
        private notify: NotificationService) { }

    //request permission to allow push notification to the website or web application. 
    requestPermission(): void {
        this.firebaseMessaging.requestPermission.subscribe(() => this.getFirebaseToken());
    }

    //requesting and then sending token to backend to get it registered
    getFirebaseToken(): void {
        this.firebaseMessaging.requestToken.pipe(tap(token => this.sendRegistrationFirebaseToken({token}))).subscribe();
    }

    sendRegistrationFirebaseToken(value: {token?: string, mobileFCMToken?: string, isMobileFCMToken?: boolean}): void {
        const token = value?.isMobileFCMToken ? value?.mobileFCMToken.trim() : value?.token.trim();
        const isNewToken = token !== this.sharedFacadeService.getFirebaseToken();
        !!token && isNewToken && this.createFirebaseToken(token);
    }

    createFirebaseToken(token: string): void {
        this.sharedFacadeService.createFirebaseToken({token}).pipe(
            tap(()=> this.saveFirebaseToken(token))
        ).subscribe(() => this.receiveMessage());
    }

    //Updating state so that when new in app notification is received, it will be displayed with current filter in notification landing page
    //calling getNotificaitonsCount so that notifications count will get updated on header
    receiveMessage(): void {
        this.firebaseMessaging.messages.subscribe(() => {
            this.getNotifications();
        }, err => console.error(err))
    }

    getNotifications(): void {
        //fetch the notifications after few seconds so that it will give time for BE to sync the data
        setTimeout(
            () => {
                this.notify.show('SUCCESS_NEW_NOTIFICATION_RECEIVED', 'success', {panelClass: 'notification__snack-bar', duration:5000});
                this.sharedFacadeService.updateSpecificState(true, SharedStoreStateEnum.NEW_IN_APP_NOTIFICATION_RECEIVED);
                this.getNotificaitonsCount();
            }, 1500
        )
    }

    getNotificaitonsCount(): void {
        this.sharedFacadeService.getNotificationsCount().subscribe();
    }

    listenToTokenValidaty(): void {
        this.firebaseMessaging.tokenChanges.pipe(
            tap(token => this.sendRegistrationFirebaseToken({token}))
        ).subscribe();
    }

    deleteRegistrationFirebaseToken(token: string): Observable<boolean> {
        return this.sharedFacadeService.deleteFirebaseToken({token}).pipe(mapTo(true));
    }

    inValidateFirebaseToken(token: string): void {
        this.firebaseMessaging.deleteToken(token).pipe(tap(() => this.sharedFacadeService.removeFirebaseToken())).subscribe();
    }

    private saveFirebaseToken = (token: string): void => this.sharedFacadeService.saveFirebaseToken(token);
}
