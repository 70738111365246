import {
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef
} from '@angular/core';
import { ComponentType, GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { SfSnackBarRef } from './snack-bar-ref';
import { SfSnackBarContainerComponent } from './snack-bar-container/snack-bar-container.component';
import { SF_SNACK_BAR_DATA, SfSnackBarConfig, SfSnackbarType } from './snack-bar-config';
import { SfDefaultSnackBarComponent } from './default-snack-bar/default-snack-bar.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SfSnackBarModule } from './snack-bar.module';


/** Injection token that can be used to specify default snack bar. */
export const SF_SNACK_BAR_DEFAULT_OPTIONS =
  new InjectionToken<SfSnackBarConfig>('sf-snack-bar-default-options', {
    providedIn: 'root',
    factory: SF_SNACK_BAR_DEFAULT_OPTIONS_FACTORY
  });

export function SF_SNACK_BAR_DEFAULT_OPTIONS_FACTORY(): SfSnackBarConfig {
  return new SfSnackBarConfig();
}

@Injectable({ providedIn: SfSnackBarModule })
export class SfSnackBarService implements OnDestroy {

  /**
   * Reference to the current snack bar in the view *at this level* (in the Angular injector tree).
   * If there is a parent snack-bar service, all operations should delegate to that parent
   * via `openedSnackBarRef`.
   */
  private snackBarRefAtThisLevel: SfSnackBarRef<any> | null = null;

  constructor(private overlay: Overlay, private injector: Injector,
              @Inject(SF_SNACK_BAR_DEFAULT_OPTIONS) private defaultSnackBarConfig: SfSnackBarConfig,
              private breakpointObserver: BreakpointObserver, private live: LiveAnnouncer,
              @Optional() @SkipSelf() private parentSnackBar: SfSnackBarService) {

  }

  /** Reference to the currently opened snackbar at *any* level. */
  get openedSnackBarRef(): SfSnackBarRef<any> | null {
    const parent = this.parentSnackBar;
    return parent ? parent.openedSnackBarRef : this.snackBarRefAtThisLevel;
  }

  set openedSnackBarRef(value: SfSnackBarRef<any> | null) {
    if (this.parentSnackBar) {
      this.parentSnackBar.openedSnackBarRef = value;
    } else {
      this.snackBarRefAtThisLevel = value;
    }
  }

  open(message, action = '', severity: SfSnackbarType = 'info', config?: SfSnackBarConfig): SfSnackBarRef<SfDefaultSnackBarComponent> {

    const snackBarConfig = { ...this.defaultSnackBarConfig, severity, ...config };
    // Since the user doesn't have access to the component, we can
    // override the data to pass in our own message and action.
    snackBarConfig.data = { message, action, severity: snackBarConfig.severity };
    if (!snackBarConfig.announcementMessage) snackBarConfig.announcementMessage = message;

    return this.openFromComponent(SfDefaultSnackBarComponent, snackBarConfig);
  }

  /**
   * Creates and dispatches a snack bar with a custom component for the content, removing any
   * currently opened snack bars.
   *
   * @param component Component to be instantiated.
   * @param config Extra configuration for the snack bar.
   */
  openFromComponent<T>(component: ComponentType<T>, config?: SfSnackBarConfig):
    SfSnackBarRef<T> {
    const snackBarConfig = { ...this.defaultSnackBarConfig, ...config };
    const overlayRef = this.createOverlay(snackBarConfig);
    const container = this.attachSnackBarContainer(overlayRef, snackBarConfig);
    return this.attachSnackBarContent(component, container, overlayRef, snackBarConfig) as SfSnackBarRef<T>;
  }

  /**
   * Creates and dispatches a snack bar with a custom template for the content, removing any
   * currently opened snack bars.
   *
   * @param template Template to be instantiated.
   * @param config Extra configuration for the snack bar.
   */
  openFromTemplate<T>(template: TemplateRef<any>, config?: SfSnackBarConfig):
    SfSnackBarRef<EmbeddedViewRef<any>> {
    const snackBarConfig = { ...this.defaultSnackBarConfig, ...config };
    const overlayRef = this.createOverlay(snackBarConfig);
    const container = this.attachSnackBarContainer(overlayRef, snackBarConfig);
    return this.attachSnackBarContent(template, container, overlayRef, snackBarConfig);
  }

  /**
   * Dismisses the currently-visible snack bar.
   */
  dismiss(): void {
    if (this.openedSnackBarRef) {
      this.openedSnackBarRef.dismiss();
    }
  }

  ngOnDestroy() {
    // Only dismiss the snack bar at the current level on destroy.
    if (this.snackBarRefAtThisLevel) {
      this.snackBarRefAtThisLevel.dismiss();
    }
  }

  protected attachSnackBarContainer(overlayRef: OverlayRef, config: SfSnackBarConfig): SfSnackBarContainerComponent {
    const userInjector = config && config.injector;
    const injector = new PortalInjector(userInjector || this.injector,
      new WeakMap([[SfSnackBarConfig, config]]));
    const containerPortal = new ComponentPortal(SfSnackBarContainerComponent, config.viewContainerRef, injector);
    const containerRef: ComponentRef<SfSnackBarContainerComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  protected createPositionStrategy(): GlobalPositionStrategy {
    return this.overlay.position().global();
  }

  private attachSnackBarContent<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    snackBarContainer: SfSnackBarContainerComponent,
    overlayRef: OverlayRef,
    config: SfSnackBarConfig): SfSnackBarRef<T | EmbeddedViewRef<any>> {

    // Create a reference to the snack bar we're creating in order to give the user a handle
    // to modify and close it.
    const snackBarRef = new SfSnackBarRef<T | EmbeddedViewRef<any>>(overlayRef, snackBarContainer, config.id);

    if (componentOrTemplateRef instanceof TemplateRef) {
      snackBarRef.componentInstance = snackBarContainer.attachTemplatePortal(
        new TemplatePortal(componentOrTemplateRef, config.viewContainerRef!, <any>{
          $implicit: config.data,
          snackBarRef
        }));
    } else {
      const injector = this.createInjector(config, snackBarRef);
      const contentRef = snackBarContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, undefined, injector, config.resolver));
      snackBarRef.componentInstance = contentRef.instance;
    }

    // Subscribe to the breakpoint observer and attach the sf-snack-bar-handset class as
    // appropriate. This class is applied to the overlay element because the overlay must expand to
    // fill the width of the screen for full width snackbars.
    this.breakpointObserver.observe(Breakpoints.HandsetPortrait).pipe(
      takeUntil(overlayRef.detachments())
    ).subscribe(state => {
      const classList = overlayRef.overlayElement.classList;
      const className = 'sf-snack-bar-handset';
      state.matches ? classList.add(className) : classList.remove(className);
    });

    this.animateSnackBar(snackBarRef, config);
    this.openedSnackBarRef = snackBarRef;
    return this.openedSnackBarRef;
  }

  private createInjector<T>(
    config: SfSnackBarConfig, snackBarRef: SfSnackBarRef<T>): PortalInjector {
    const userInjector = config && config.injector;
    const injectionTokens = new WeakMap<any, any>([
      [SfSnackBarRef, snackBarRef],
      [SF_SNACK_BAR_DATA, config.data]
    ]);
    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }

  private getOverlayConfig(snackBarConfig: SfSnackBarConfig): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      panelClass: snackBarConfig.panelClass,
      disposeOnNavigation: false
    });

    overlayConfig.direction = snackBarConfig.direction;

    const positionStrategy = this.createPositionStrategy();
    // Set horizontal position.
    const isRtl = snackBarConfig.direction === 'rtl';
    const isLeft = (
      snackBarConfig.horizontalPosition === 'left' ||
      (snackBarConfig.horizontalPosition === 'start' && !isRtl) ||
      (snackBarConfig.horizontalPosition === 'end' && isRtl));
    const isRight = !isLeft && snackBarConfig.horizontalPosition !== 'center';
    if (isLeft) {
      positionStrategy.left('0');
    } else if (isRight) {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }
    // Set vertical position.
    if (snackBarConfig.verticalPosition === 'top') {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }

    overlayConfig.positionStrategy = positionStrategy;
    return overlayConfig;
  }

  private createOverlay(config: SfSnackBarConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  /** Animates the old snack bar out and the new one in. */
  private animateSnackBar(snackBarRef: SfSnackBarRef<any>, config: SfSnackBarConfig) {
    // When the snackbar is dismissed, clear the reference to it.
    snackBarRef.afterDismissed().subscribe(() => {
      // Clear the snackbar ref if it hasn't already been replaced by a newer snackbar.
      if (this.openedSnackBarRef === snackBarRef) {
        this.openedSnackBarRef = null;
      }

      if (config.announcementMessage) {
        this.live.clear();
      }
    });

    if (this.openedSnackBarRef) {
      // If a snack bar is already in view, dismiss it and enter the
      // new snack bar after exit animation is complete.
      this.openedSnackBarRef.afterDismissed().subscribe(() => {
        snackBarRef.containerInstance.enter();
      });
      this.openedSnackBarRef.dismiss();
    } else {
      // If no snack bar is in view, enter the new snack bar.
      snackBarRef.containerInstance.enter();
    }

    // If a dismiss timeout is provided, set up dismiss based on after the snackbar is opened.
    if (config.duration && config.duration > 0) {
      snackBarRef.afterOpened().subscribe(() => snackBarRef.dismissAfter(config.duration!));
    }

    if (config.announcementMessage) {
      this.live.announce(config.announcementMessage, config.politeness);
    }

  }
}
