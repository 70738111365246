
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfIconModule} from '../../../../icon/src/index';
import {SfTabIndicatorModule} from '../tab-indicator/tab-indicator.module';
import {SfTabComponent, SfTabLabel, SfTabIcon} from './tab.component';
import { SfTabContent } from './tab-content.directive';

const TAB_DECLARATIONS = [
  SfTabComponent,
  SfTabIcon,
  SfTabLabel,
  SfTabContent
];

@NgModule({
  imports: [
    CommonModule,
    SfTabIndicatorModule,
    SfIconModule
  ],
  exports: TAB_DECLARATIONS,
  declarations: TAB_DECLARATIONS
})
export class SfTabModule { }
