import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfTopAppBar} from './header.component';
import {
  SfTopAppBarActionItem,
  SfTopAppBarFixedAdjust,
  SfTopAppBarNavigationIcon,
  SfTopAppBarRow,
  SfTopAppBarSection,
  SfTopAppBarTitle
} from './header.directive';

const TOP_APP_BAR_DECLARATIONS = [
  SfTopAppBar,
  SfTopAppBarActionItem,
  SfTopAppBarFixedAdjust,
  SfTopAppBarNavigationIcon,
  SfTopAppBarRow,
  SfTopAppBarSection,
  SfTopAppBarTitle
];

@NgModule({
  imports: [CommonModule],
  exports: TOP_APP_BAR_DECLARATIONS,
  declarations: TOP_APP_BAR_DECLARATIONS
})
export class SfHeaderModule { }
