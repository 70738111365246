import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SfSnackBarService} from '@ew/shared/safire/components';
import {
    Configurator, ConfiguratorPrice, DataTypeEnum, EventData, Level, LevelRule,
    LevelTypeEnum, PackageModel, ProductFormBuilder, ProductStateEnum,
    ProductTree, ProductTreeList, StepConfig, UpdatePackage,
} from '@ew/shared/services';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {PC_COMPONENT_CONFIG} from '../../../constants/pc-stepper.constant';
import {pcRootPath} from '../../../constants/products.constant';
import {ProductFacadeService} from '../../../services/product-facade.service';
import {ProductBaseComponent} from '../../product-base/product-base.component';
import { DynamicCheckboxType } from '@ew/shared/components/dynamic-field';

@UntilDestroy()
@Component({
    selector: 'ew-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent extends ProductBaseComponent implements OnInit {
    currentProduct: ProductTree;
    configurations: StepConfig[] = [];
    edit: string;
    parentId: string;
    rootPath = pcRootPath;
    levelRule: LevelRule;
    configuratorPriceTypeId: string;
    stepperInputs = [];
    isAddon = false;
    setOutputComponentConfig: unknown = {
    	save: (event) => this.saveForm.bind(this)(event),
    };
    dynamicNamesExist: boolean;
    dynamicRequiredValuesFilled: boolean;
	invalidCheckboxSelection: boolean;

    private currentProductId: string;
    private level: Level | undefined;

    constructor(
        private productFacadeService: ProductFacadeService,
        private activateRoute: ActivatedRoute,
        private router: Router,
        private notify: SfSnackBarService,
        private cd: ChangeDetectorRef
    ) {
    	super();
    }

    ngOnInit(): void {
    	this.initializeStepper();
    	this.getAllConfig();
    	this.checkEditFromStepper();
    	this.configureForm(ProductFormBuilder.packageForm());
    	this.isAddon = this.activateRoute.snapshot.queryParamMap.get('addon') === 'true';
    	if (this.isAddon) {
    		this.rootPath = '/product-configurator/product/item-listing/';
    	}
    }

    initializeStepper(): void {
    	this.configurations = PC_COMPONENT_CONFIG;
    	this.stepperSetting(0);
    }

    setStepperInputs(): void {
    	this.stepperInputs = this.configurations.map((config) => this.setInputComponentConfig(config));
    	this.setStepIndices();
    	this.cd.markForCheck();
    }

    stepperSetting(index?: number): void {
    	this.stepperExpandOperation(index);
    	this.stepperShowOperation(index);
    }

    stepperExpandOperation(index: number): void {
    	this.configurations.forEach((componentConfig) => {
    		componentConfig.isExpanded = componentConfig.index === index;
    	});
    }

    stepperShowOperation(index: number): void {
    	switch (index) {
    	case 0:
    		this.setShowStepper([index, index + 1]);
    		break;
    	case this.configurations.length - 1:
    		this.setShowStepper([index - 1, index]);
    		break;
    	default:
    		this.setShowStepper([index - 1, index, index + 1]);
    	}
    }

    setShowStepper(selectedIndex: number[]): void {
    	this.configurations.forEach((config, index) => {
    		config.showStepper = selectedIndex.includes(index);
    	});
    }

    checkEditFromStepper(): void {
    	this.activateRoute.queryParamMap
    		.pipe(
    			tap((res) => {
    				this.edit = res.get('edit');
    				this.parentId = res.get('parentId');
    			}),
    			untilDestroyed(this)
    		)
    		.subscribe();
    }

    getAllConfig(): void {
    	this.productFacadeService
    		.getConfiguration()
    		.pipe(
    			switchMap(() => this.openStepperFromLastNode()),
    			switchMap(() => (this.currentProductId ? this.getProductById() : of())),
    			switchMap(() => this.getLevels()),
    			untilDestroyed(this)
    		)
    		.subscribe(() => {
    			this.setPriceTypeConfigurator();
    			this.setStepperInputs();
    			this.setStepIndices();
    			this.stepperSetting(0);
    			this.cd.detectChanges();
    		});
    }

    openStepperFromLastNode(): Observable<ParamMap> {
    	return this.activateRoute.paramMap.pipe(
    		tap((res) => (this.currentProductId = res?.['params']?.id)),
    		untilDestroyed(this)
    	);
    }

    getLevels(): Observable<Level[]> {
    	return this.productFacadeService.getLevels().pipe(tap((response) => this.getLevel(response)));
    }

    getLevel(levels: Level[]): void {
    	this.level = levels?.find((level) => level.type === (this.isAddon ? LevelTypeEnum.Addon : LevelTypeEnum.Product));
    	this.levelRule = this.level?.levelRule;
    	this.productFacadeService.updateSpecificState(this.level, ProductStateEnum.LEVEL);
    	this.setPcDynamicConfiguration(this.level.levelRule);
    }

    setPcDynamicConfiguration(levelRule: LevelRule): void {
    	if (!levelRule?.contractDuration && !levelRule?.delivery && !levelRule?.preorder && !levelRule?.quantitySetting) {
    		this.configurations = this.configurations.filter((vo) => vo.name !== 'other_details');
    		this.setStepperInputs();
    	}
    }

    setPriceTypeConfigurator(): void {
    	const configurators = this.productFacadeService.getSpecificState<Configurator[]>(ProductStateEnum.CONFIGURATOR);
    	const pricingOptions: ConfiguratorPrice[] = configurators.find((configurator) => configurator.id === this.level.configuratorId)
    		.configuratorPriceTypes;
    	this.setPcPriceConfiguration(pricingOptions);
    	this.configuratorPriceTypeId = pricingOptions.find((priceType) => priceType.pricingType === 'normal')?.id;
    }

    setPcPriceConfiguration(configuratorPrice: ConfiguratorPrice[]): void {
    	if (configuratorPrice.length <= 1) {
    		this.configurations = this.configurations.filter((vo) => vo.name !== 'pricing_details');
    		this.setStepperInputs();
    	}
    }

    setStepIndices(): void {
    	this.configurations.forEach((vo, index) => {
    		vo.index = index;
    	});
    	this.cd.detectChanges();
    }

    getProductById(): Observable<ProductTreeList> {
    	return this.productFacadeService.getProductTreeById(this.currentProductId).pipe(
    		tap((response) => {
    			this.currentProduct = response;
    		})
    	);
    }

    setInputComponentConfig(config: StepConfig): Record<string, unknown> {
    	return {
    		config: config,
    		currentProduct: this.currentProduct,
    		edit: this.edit,
    		level: this.level,
    		length: this.configurations.length,
    		form:
                this.stepperInputs.find((stepper) => stepper.config.name === config.name)?.form ||
                this.formBuilder({
                	formModel: config.formSchema.formModel,
                	skipRequiredValidators: config.formSchema.skipRequiredValidators || [],
                }),
    	};
    }

    saveForm(eventData: EventData): void {
    	this.checkStepperLogic(eventData);
    	this.productFacadeService.updateAllProductState(eventData);
    }

    checkStepperLogic(eventData: EventData): void {
    	if (eventData.clickType === 'NEXT') {
    		this.stepperSetting(eventData.stepIndex + 1);
    	}
    	if (eventData.clickType === 'PREV') {
    		this.stepperSetting(eventData.stepIndex - 1);
    	}
    }

    submit(): void {
    	this.stepperInputs.forEach((input) => {
    		input.form.markAllAsTouched();
    		this.form.patchValue(input.form.value);
    	});
    	const payload = this.getPackageFormValue();
        this.notifyCheckboxSelectionError(payload);
    	this.notifyDynamicLanguageError(payload);
    	this.dynamicNamesExist && this.dynamicRequiredValuesFilled &&  !this.invalidCheckboxSelection &&
		this.productFacadeService
    		.createProduct(payload, this.level, this.currentProductId, this.configuratorPriceTypeId, this.isAddon)
    		.subscribe(
    			() => this.router.navigateByUrl(`${this.rootPath}${this.currentProductId}`)
    		);
    }

    saveStepper(): void {
    	this.stepperInputs.forEach((input) => this.form.patchValue(input.form.value));
    	const payload: UpdatePackage = {
    		id: this.currentProduct.packageId,
    		...this.getPackageFormValue(),
    	};
        //TODO: Might Need in the future(Zangphel: 22-03-2024)
        this.notifyCheckboxSelectionError(payload);
    	payload.dynamicValues = this.productFacadeService.modifyDynamicValues(payload?.dynamicValues);
    	this.notifyDynamicLanguageError(payload);
    	this.dynamicNamesExist && this.dynamicRequiredValuesFilled &&  !this.invalidCheckboxSelection &&
        this.productFacadeService.updatePackage(payload).subscribe(() => this.router.navigateByUrl(`${this.rootPath}${this.parentId}`));
    }

    //TODO: Might Need in the future(Zangphel: 22-03-2024)
    notifyCheckboxSelectionError(product: UpdatePackage): void {
        const getAllChecboxColumn =  this.level.dynamicColumns.filter(res => res.dataType === DataTypeEnum.Checkbox);
        this.invalidCheckboxSelection = getAllChecboxColumn.some(res => 
            (product.dynamicValues.find(resp => resp.dynamicColumnId === res.id).dynamicOptionId as 
				unknown as DynamicCheckboxType[]).every(r => !r.selected));
        this.invalidCheckboxSelection && this.productFacadeService.openErrorNotification('ERROR_CHECKBOX_SELECT');
    }

    notifyDynamicLanguageError(product: UpdatePackage): void {
        //Check package Name if it exists or not for both languages
    	this.dynamicNamesExist = product?.dynamicValues.every((dynamicValue, i) =>
    		(dynamicValue?.name?.en || dynamicValue?.name?.de) ? (!!dynamicValue?.name?.en && !!dynamicValue?.name?.de) : true);
    	!this.dynamicNamesExist && this.productFacadeService.openErrorNotification('ERROR_DESCRIPTION_IN_BOTH_LANGUAGES');
    	this.dynamicRequiredValuesFilled = product?.dynamicValues.every((dynamicValue) =>
            this.level.dynamicColumns.find(res => res.id === dynamicValue.dynamicColumnId)?.required ?
    			(!!dynamicValue?.dynamicOptionId || !!dynamicValue?.value || !!dynamicValue?.name?.en) : true
    	);
    	!this.dynamicRequiredValuesFilled && this.productFacadeService.openErrorNotification('ERROR_CUSTOMER_TYPE_MANDATORY');
    }

    cancel(): void {
    	this.router.navigateByUrl(`${this.rootPath}${this.edit ? this.parentId : this.currentProductId}`).then();
    }

    private getPackageFormValue(): PackageModel {
    	return this.stepperInputs.reduce(
    		(accumulatedStepFormValues, currentStep) => ({
    			...accumulatedStepFormValues,
    			...currentStep.form.value,
    		}),
            {} as PackageModel
    	);
    }

    private findStepFormInInputs(config: StepConfig): FormGroup {
    	return this.stepperInputs.find((stepper) => stepper.config.name === config.name)?.form;
    }
}
