export const removeNullishValues = <T>(object: T): T => {
    Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === 'object') {
            removeNullishValues(v);
        }
        if ((v && typeof v === 'object' && !Object.keys(v).length) || v == null) {
            if (Array.isArray(object)) {
                object.splice(+k, 1);
            } else {
                delete object[k];
            }
        }
    });
    return object;
};
