import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { DeleteProductTreePayload } from '@ew/shared/services';

export const DELETE_PRODUCT_TREE_MUTATION: TypedDocumentNode<{ deleteProductTree: DeleteProductTreePayload }> = gql`
  mutation deleteProductTree($input: DeleteProductTreeInput!) {
    deleteProductTree (input: $input) {
      status
    }
  }
`;
