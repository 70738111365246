import { AnimationEvent } from '@angular/animations';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayRef,
  ScrollStrategy,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { HAMMER_LOADER, HammerLoader } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { sfTooltipAnimations } from './tooltip-animations';


export type TooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';

/** Time in ms to throttle repositioning after scroll events. */
export const SCROLL_THROTTLE_MS = 20;

/** CSS class that will be attached to the overlay panel. */
export const TOOLTIP_PANEL_CLASS = 'sf-tooltip-panel';

/**
 * Creates an error to be thrown if the user supplied an invalid tooltip position.
 * @ignore
 */
export function getSfTooltipInvalidPositionError(position: string) {
  return Error(`Tooltip position "${position}" is invalid.`);
}

/** Injection token that determines the scroll handling while a tooltip is visible. */
export const SF_TOOLTIP_SCROLL_STRATEGY =
  new InjectionToken<() => ScrollStrategy>('sf-tooltip-scroll-strategy');

/** @ignore */
export function SF_TOOLTIP_SCROLL_STRATEGY_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition({ scrollThrottle: SCROLL_THROTTLE_MS });
}

/** @ignore */
export const SF_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: SF_TOOLTIP_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: SF_TOOLTIP_SCROLL_STRATEGY_FACTORY
};

/** Default `sfTooltip` options that can be overridden. */
export interface SfTooltipDefaultOptions {
  showDelay: number;
  hideDelay: number;
  touchendHideDelay: number;
  position?: TooltipPosition;
}

/** Injection token to be used to override the default options for `sfTooltip`. */
export const SF_TOOLTIP_DEFAULT_OPTIONS =
  new InjectionToken<SfTooltipDefaultOptions>('sf-tooltip-default-options', {
    providedIn: 'root',
    factory: SF_TOOLTIP_DEFAULT_OPTIONS_FACTORY
  });

/**
 * @ignore
 */
export function SF_TOOLTIP_DEFAULT_OPTIONS_FACTORY(): SfTooltipDefaultOptions {
  return {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500
  };
}

/**
 * Directive that attaches a  tooltip to the host element. Animates the showing and
 * hiding of a tooltip provided position (defaults to below the element).
 */
@Directive({
  selector: '[sfTooltip]',
  exportAs: 'sfTooltip',
  host: {
    '(longpress)': 'show()',
    '(touchend)': 'handleTouchend()'
  }
})
export class SfTooltip implements OnDestroy, OnInit {
  overlayRef: OverlayRef | null;
  tooltipInstance: SfTooltipComponent | null;
  /** The default delay in ms before showing the tooltip after show is called */
  @Input() showDelay = this.defaultOptions.showDelay;
  /** The default delay in ms before hiding the tooltip after hide is called */
  @Input() hideDelay = this.defaultOptions.hideDelay;


  private portal: ComponentPortal<SfTooltipComponent>;
  private scrollStrategy: () => ScrollStrategy;
  private manualListeners = new Map<string, EventListenerOrEventListenerObject>();
  /** Emits when the component is destroyed. */
  private readonly destroyed = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    platform: Platform,
    private ariaDescriber: AriaDescriber,
    private focusMonitor: FocusMonitor,
    @Inject(SF_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() private directionality: Directionality,
    @Optional() @Inject(SF_TOOLTIP_DEFAULT_OPTIONS)
    private defaultOptions: SfTooltipDefaultOptions,
    @Optional() @Inject(HAMMER_LOADER) hammerLoader?: HammerLoader) {

    this.scrollStrategy = scrollStrategy;
    const element: HTMLElement = elementRef.nativeElement;
    const hasGestures = typeof window === 'undefined' || (window as any).Hammer || hammerLoader;

    // The mouse events shouldn't be bound on mobile devices, because they can prevent the
    // first tap from firing its click event or can cause the tooltip to open for clicks.
    if (!platform.IOS && !platform.ANDROID) {
      this.manualListeners
        .set('mouseenter', () => this.show())
        .set('mouseleave', () => this.hide());
    } else if (!hasGestures) {
      // If Hammerjs isn't loaded, fall back to showing on `touchstart`, otherwise
      // there's no way for the user to trigger the tooltip on a touch device.
      this.manualListeners.set('touchstart', () => this.show());
    }

    this.manualListeners.forEach((listener, event) => element.addEventListener(event, listener));

    focusMonitor.monitor(elementRef).pipe(takeUntil(this.destroyed)).subscribe(origin => {
      // Note that the focus monitor runs outside the Angular zone.
      if (!origin) {
        ngZone.run(() => this.hide(0));
      } else if (origin === 'keyboard') {
        ngZone.run(() => this.show());
      }
    });

    if (defaultOptions && defaultOptions.position) {
      this.position = defaultOptions.position;
    }
  }

  private _position: TooltipPosition = 'below';

  /** Allows the user to define the position of the tooltip relative to the parent element */
  @Input('sfTooltipPosition')
  get position(): TooltipPosition {
    return this._position;
  }

  set position(value: TooltipPosition) {
    if (value !== this._position) {
      this._position = value;

      if (this.overlayRef) {
        this.updatePosition();

        if (this.tooltipInstance) {
          this.tooltipInstance!.show(0);
        }

        this.overlayRef.updatePosition();
      }
    }
  }

  private _disabled = false;

  /** Disables the display of the tooltip. */
  @Input('sfTooltipDisabled')
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);

    // If tooltip is disabled, hide immediately.
    if (this._disabled) {
      this.hide(0);
    }
  }

  private _tooltipClass: string | string[] | Set<string> | { [key: string]: any };

  /** Classes to be passed to the tooltip. Supports the same syntax as `ngClass`. */
  @Input('sfTooltipClass')
  get tooltipClass() {
    return this._tooltipClass;
  }

  set tooltipClass(value: string | string[] | Set<string> | { [key: string]: any }) {
    this._tooltipClass = value;
    if (this.tooltipInstance) {
      this.setTooltipClass(this._tooltipClass);
    }
  }

  private _message = '';

  /** The message to be displayed in the tooltip */
  @Input('sfTooltip')
  get message() {
    return this._message;
  }

  set message(value: string) {
    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this._message);

    // If the message is not a string (e.g. number), convert it to a string and trim it.
    this._message = value != null ? `${value}`.trim() : '';

    if (!this._message && this.isTooltipVisible()) {
      this.hide(0);
    } else {
      this.updateTooltipMessage();
      this.ngZone.runOutsideAngular(() => {
        // The `AriaDescriber` has some functionality that avoids adding a description if it's the
        // same as the `aria-label` of an element, however we can't know whether the tooltip trigger
        // has a data-bound `aria-label` or when it'll be set for the first time. We can avoid the
        // issue by deferring the description by a tick so Angular has time to set the `aria-label`.
        Promise.resolve().then(() => {
          this.ariaDescriber.describe(this.elementRef.nativeElement, this.message);
        });
      });
    }
  }

  /**
   * Setup styling-specific things
   */
  ngOnInit() {
    const element = this.elementRef.nativeElement;
    const elementStyle = element.style as CSSStyleDeclaration & { webkitUserDrag: string };

    if (element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA') {
      // When we bind a gesture event on an element (in this case `longpress`), HammerJS
      // will add some inline styles by default, including `user-select: none`. This is
      // problematic on iOS and in Safari, because it will prevent users from typing in inputs.
      // Since `user-select: none` is not needed for the `longpress` event and can cause unexpected
      // behavior for text fields, we always clear the `user-select` to avoid such issues.
      // @ts-ignore
      elementStyle.webkitUserSelect = elementStyle.userSelect = elementStyle.msUserSelect = '';
    }

    // Hammer applies `-webkit-user-drag: none` on all elements by default,
    // which breaks the native drag&drop. If the consumer explicitly made
    // the element draggable, clear the `-webkit-user-drag`.
    if (element.draggable && elementStyle.webkitUserDrag === 'none') {
      elementStyle.webkitUserDrag = '';
    }
  }

  /**
   * Dispose the tooltip when destroyed.
   */
  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.tooltipInstance = null;
    }

    // Clean up the event listeners set in the constructor
    this.manualListeners.forEach((listener, event) => {
      this.elementRef.nativeElement.removeEventListener(event, listener);
    });
    this.manualListeners.clear();

    this.destroyed.next();
    this.destroyed.complete();

    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this.message);
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  /** Shows the tooltip after the delay in ms, defaults to tooltip-delay-show or 0ms if no input */
  show(delay: number = this.showDelay): void {
    if (this.disabled || !this.message || (this.isTooltipVisible() &&
      !this.tooltipInstance!.showTimeoutId && !this.tooltipInstance!.hideTimeoutId)) {
      return;
    }

    const overlayRef = this.createOverlay();

    this.detach();
    this.portal = this.portal || new ComponentPortal(SfTooltipComponent, this.viewContainerRef);
    this.tooltipInstance = overlayRef.attach(this.portal).instance;
    this.tooltipInstance.afterHidden()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.detach());
    this.setTooltipClass(this._tooltipClass);
    this.updateTooltipMessage();
    this.tooltipInstance!.show(delay);
  }

  /** Hides the tooltip after the delay in ms, defaults to tooltip-delay-hide or 0ms if no input */
  hide(delay: number = this.hideDelay): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.hide(delay);
    }
  }

  /** Shows/hides the tooltip */
  toggle(): void {
    this.isTooltipVisible() ? this.hide() : this.show();
  }

  /** Returns true if the tooltip is currently visible to the user */
  isTooltipVisible(): boolean {
    return !!this.tooltipInstance && this.tooltipInstance.isVisible();
  }

  /** Handles the keydown events on the host element. */
  @HostListener('keydown', ['$event'])
  handleKeydown(e: KeyboardEvent) {
    if (this.isTooltipVisible() && e.keyCode === ESCAPE && !hasModifierKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      this.hide(0);
    }
  }

  /** Handles the touchend events on the host element. */
  handleTouchend() {
    this.hide(this.defaultOptions.touchendHideDelay);
  }

  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  getOrigin(): { main: OriginConnectionPosition, fallback: OriginConnectionPosition } {
    const isLtr = !this.directionality || this.directionality.value === 'ltr';
    const position = this.position;
    let originPosition: OriginConnectionPosition;

    if (position === 'above' || position === 'below') {
      originPosition = { originX: 'center', originY: position === 'above' ? 'top' : 'bottom' };
    } else if (
      position === 'before' ||
      (position === 'left' && isLtr) ||
      (position === 'right' && !isLtr)) {
      originPosition = { originX: 'start', originY: 'center' };
    } else if (
      position === 'after' ||
      (position === 'right' && isLtr) ||
      (position === 'left' && !isLtr)) {
      originPosition = { originX: 'end', originY: 'center' };
    } else {
      throw getSfTooltipInvalidPositionError(position);
    }

    const { x, y } = this.invertPosition(originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y }
    };
  }

  /** Returns the overlay position and a fallback position based on the user's preference */
  getOverlayPosition(): { main: OverlayConnectionPosition, fallback: OverlayConnectionPosition } {
    const isLtr = !this.directionality || this.directionality.value === 'ltr';
    const position = this.position;
    let overlayPosition: OverlayConnectionPosition;

    if (position === 'above') {
      overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      overlayPosition = { overlayX: 'center', overlayY: 'top' };
    } else if (
      position === 'before' ||
      (position === 'left' && isLtr) ||
      (position === 'right' && !isLtr)) {
      overlayPosition = { overlayX: 'end', overlayY: 'center' };
    } else if (
      position === 'after' ||
      (position === 'right' && isLtr) ||
      (position === 'left' && !isLtr)) {
      overlayPosition = { overlayX: 'start', overlayY: 'center' };
    } else {
      throw getSfTooltipInvalidPositionError(position);
    }

    const { x, y } = this.invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y }
    };
  }

  /** Create the overlay config and position strategy */
  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const scrollableAncestors =
      this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this.overlay.position()
      .flexibleConnectedTo(this.elementRef)
      .withTransformOriginOn('.sf-tooltip')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withScrollableContainers(scrollableAncestors);

    strategy.positionChanges.pipe(takeUntil(this.destroyed)).subscribe(change => {
      if (this.tooltipInstance) {
        if (change.scrollableViewProperties.isOverlayClipped && this.tooltipInstance.isVisible()) {
          // After position changes occur and the overlay is clipped by
          // a parent scrollable then close the tooltip.
          this.ngZone.run(() => this.hide(0));
        }
      }
    });

    this.overlayRef = this.overlay.create({
      direction: this.directionality,
      positionStrategy: strategy,
      panelClass: TOOLTIP_PANEL_CLASS,
      scrollStrategy: this.scrollStrategy()
    });

    this.updatePosition();

    this.overlayRef.detachments()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.detach());

    return this.overlayRef;
  }

  /** Detaches the currently-attached tooltip. */
  private detach() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    this.tooltipInstance = null;
  }

  /** Updates the position of the current tooltip. */
  private updatePosition() {
    const position =
      this.overlayRef!.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    position.withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback }
    ]);
  }

  /** Updates the tooltip message and repositions the overlay according to the new message length */
  private updateTooltipMessage() {
    // Must wait for the message to be painted to the tooltip so that the overlay can properly
    // calculate the correct positioning based on the size of the text.
    if (this.tooltipInstance) {
      this.tooltipInstance.message = this.message;
      this.tooltipInstance._markForCheck();

      this.ngZone.onMicrotaskEmpty.asObservable().pipe(
        take(1),
        takeUntil(this.destroyed)
      ).subscribe(() => {
        if (this.tooltipInstance) {
          this.overlayRef!.updatePosition();
        }
      });
    }
  }

  /** Updates the tooltip class */
  private setTooltipClass(tooltipClass: string | string[] | Set<string> | { [key: string]: any }) {
    if (this.tooltipInstance) {
      this.tooltipInstance.tooltipClass = tooltipClass;
      this.tooltipInstance._markForCheck();
    }
  }

  /** Inverts an overlay position. */
  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }
}

export type TooltipVisibility = 'initial' | 'visible' | 'hidden';

/**
 * Internal component that wraps the tooltip's content.
 * @internal
 * @ignore
 */
@Component({
  selector: 'sf-tooltip-component',
  templateUrl: 'tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sfTooltipAnimations.tooltipState],
  host: {
    // Forces the element to have a layout in IE and Edge. This fixes issues where the element
    // won't be rendered if the animations are disabled or there is no web animations polyfill.
    '[style.zoom]': 'visibility === "visible" ? 1 : null',
    '(body:click)': 'this.handleBodyInteraction()',
    'aria-hidden': 'true'
  }
})
export class SfTooltipComponent implements OnDestroy {
  /** Message to display in the tooltip */
  message: string;

  /** Classes to be added to the tooltip. Supports the same syntax as `ngClass`. */
  tooltipClass: string | string[] | Set<string> | { [key: string]: any };

  /** The timeout ID of any current timer set to show the tooltip */
  showTimeoutId: number | null;

  /** The timeout ID of any current timer set to hide the tooltip */
  hideTimeoutId: number | null;

  /** Property watched by the animation framework to show or hide the tooltip */
  visibility: TooltipVisibility = 'initial';
  /** Stream that emits whether the user has a handset-sized display.  */
  isHandset: Observable<BreakpointState> = this._breakpointObserver.observe(Breakpoints.Handset);
  /** Whether interactions on the page should close the tooltip */
  private closeOnInteraction = false;
  /** Subject for notifying that the tooltip has been hidden from the view */
  private readonly _onHide: Subject<any> = new Subject();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _breakpointObserver: BreakpointObserver) {
  }

  /**
   * Shows the tooltip with an animation originating from the provided origin
   * @param delay Amount of milliseconds to the delay showing the tooltip.
   */
  show(delay: number): void {
    // Cancel the delayed hide if it is scheduled
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }

    // Body interactions should cancel the tooltip if there is a delay in showing.
    this.closeOnInteraction = true;
    this.showTimeoutId = window.setTimeout(() => {
      this.visibility = 'visible';
      this.showTimeoutId = null;

      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._markForCheck();
    }, delay);
  }

  /**
   * Begins the animation to hide the tooltip after the provided delay in ms.
   * @param delay Amount of milliseconds to delay showing the tooltip.
   */
  hide(delay: number): void {
    // Cancel the delayed show if it is scheduled
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
      this.showTimeoutId = null;
    }

    this.hideTimeoutId = window.setTimeout(() => {
      this.visibility = 'hidden';
      this.hideTimeoutId = null;

      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._markForCheck();
    }, delay);
  }

  /** Returns an observable that notifies when the tooltip has been hidden from view. */
  afterHidden(): Observable<void> {
    return this._onHide.asObservable();
  }

  /** Whether the tooltip is being displayed. */
  isVisible(): boolean {
    return this.visibility === 'visible';
  }

  ngOnDestroy() {
    this._onHide.complete();
  }

  _animationStart() {
    this.closeOnInteraction = false;
  }

  _animationDone(event: AnimationEvent): void {
    const toState = event.toState as TooltipVisibility;

    if (toState === 'hidden' && !this.isVisible()) {
      this._onHide.next();
    }

    if (toState === 'visible' || toState === 'hidden') {
      this.closeOnInteraction = true;
    }
  }

  /**
   * Interactions on the HTML body should close the tooltip immediately as defined in the
   */
  handleBodyInteraction(): void {
    if (this.closeOnInteraction) {
      this.hide(0);
    }
  }

  /**
   * Marks that the tooltip needs to be checked in the next change detection run.
   * Mainly used for rendering the initial text before positioning a tooltip, which
   * can be problematic in components with OnPush change detection.
   */
  _markForCheck(): void {
    this._changeDetectorRef.markForCheck();
  }
}
