import {Injectable} from '@angular/core';
import { NavItem } from '@ew/shared/services';
import { SidebarBlService } from './sidebar-bl.service';

@Injectable({
    providedIn: 'root'
})
export class SidebarFacadeService {

    constructor(private sidebarBlService: SidebarBlService) {
    }

    updateNavItem(navItem: NavItem): void {
        return this.sidebarBlService.updateNavItem(navItem);
    }

    get navigation(): NavItem[] {
        return this.sidebarBlService.getNavigation();
    }
}
