

const {FIREBASE_CONFIGURATION} = require('./scripts/firebase-config.js');

export const APP_KEYS: any = {
  googleMapsApiKey: 'AIzaSyCawiMelmKjKEY1n449B46Y54yylzRyRxw',
    rollBarApiKey: '',
    reCaptchaSiteKey: '6LfIHuwjAAAAAEQxpcBDoEWwY4j_pgsqZls2kDXI',
  firebaseConfig: FIREBASE_CONFIGURATION
};
