/* tslint:disable:no-inferrable-types */
import { ComponentFactoryResolver, Injector, ViewContainerRef } from '@angular/core';
export type sfDialogType = 'primary' | 'info' | 'success' | 'warning' | 'error'

export class SfDialogConfig<D = any> {
  id: string;
  hasBackdrop?: boolean = true;
  resolver?: ComponentFactoryResolver;
  injector?: Injector;
  viewContainerRef?: ViewContainerRef;
  data: D | null = null;
  width?: number | string = '';
  minWidth?: number | string;
  maxWidth?: number | string = '80vw';
  height?: number | string = '';
  minHeight?: number | string;
  maxHeight?: number | string;
  backdropClass?: string = '';
  panelClass?: string = '';
  severity?: sfDialogType = 'primary';

}
