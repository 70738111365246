import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit, Optional,
  ViewEncapsulation
} from '@angular/core';
import { SfStepperDirective } from '../stepper.component';
import { CdkStepper } from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'sf-vertical-stepper',
  templateUrl: './sf-vertical-stepper.component.html',
  providers: [
    { provide: SfStepperDirective, useExisting: SfVerticalStepperComponent },
    { provide: CdkStepper, useExisting: SfVerticalStepperComponent }
  ],
  host: {
    'class': 'sf-vertical-stepper',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfVerticalStepperComponent extends SfStepperDirective implements OnInit {

  labelPosition = 'end';

  @Input()
  selectedIndex: number;

  /** Whether the validity of previous steps should be checked or not. */
  @Input()
  linear: boolean;

  ngOnInit(): void {
  }

  constructor(
    @Optional() dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    elementRef?: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) _document?: any) {
    super(dir,changeDetectorRef,elementRef,_document);
  }
}
