import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {mapTo, tap} from 'rxjs/operators';
import {BaseFacadeService, Permission, SharedFacadeService, SharedStoreStateEnum, User} from '@ew/shared/services';
import {SecurityStateService} from './security-state.service';
import {SecurityStoreEnum, SecurityStoreState} from '../models/security-store.state';
import {SecurityBlService} from './security-bl.service';
import {SecurityApiService} from './security-api.service';

@Injectable({
    providedIn: 'root'
})

export class SecurityFacadeService extends BaseFacadeService<SecurityStateService, SecurityStoreState> {

    constructor(private securityStateService: SecurityStateService,
                private securityBlService: SecurityBlService,
                private sharedFacade: SharedFacadeService,
                private securityApiService: SecurityApiService) {
        super(securityStateService);
    }

    hasPermission(value: string): boolean {
        return this.securityBlService.hasPermission(value, this.getSpecificState<Permission[]>(SecurityStoreEnum.ACCESS_MATRICES));
    }

    getPermissionMatrices(): Observable<boolean> {
        return this.securityApiService.getUserPermissionMatrices().pipe(
            tap(resp => {
                (resp?.role?.name === 'user') && this.userIDTracking(resp);
                this.sharedFacade.updateSpecificState(resp, SharedStoreStateEnum.PROFILE_STATE);
                this.updateSpecificState(this.securityBlService.getActivePolicies(resp), SecurityStoreEnum.ACCESS_MATRICES)}),
            mapTo(true));
    }

    userIDTracking(user: User): void {
        gtag('event', 'login', {
            user_id: user?.id,
        });
    }

    cookieGuard(): Observable<boolean | void> {
        return window.navigator.onLine && (!this.securityBlService.isGuardRoutes() || !this.securityBlService.isOtpRoutes()) &&
        this.securityBlService.isPreviouslyLoggedIn()
            ? this.securityApiService.getUserPermissionMatrices().pipe(mapTo(true)) : EMPTY;
    }
}
