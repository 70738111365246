import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SfCheckboxChange } from '@ew/shared/safire/components';
import { ConfiguratorPrice } from '@ew/shared/services';

@Component({
    selector: 'ew-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() state: ConfiguratorPrice[];

  constructor(private fb: FormBuilder) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method,@typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

  onChange(change: SfCheckboxChange): void {
  	const dataAttr: AbstractControl = this.priceAttribute.controls.find(ctrl => ctrl.value?.priceTypeId === change.source.value);
  	if (dataAttr) {
  		// eslint-disable-next-line @typescript-eslint/naming-convention
  		dataAttr.patchValue({ _destroy: !change.checked });
  	} else if (change.checked) {
  		// eslint-disable-next-line @typescript-eslint/naming-convention
  		this.priceAttribute.push(this.fb.group({ priceTypeId: change.source.value, _destroy: false }));
  	} else {
  		const index: number = this.priceAttribute.controls?.findIndex(control => control.value?.priceTypeId === change.source.value);
  		this.priceAttribute.removeAt(index);
  	}
  	this.priceAttribute.updateValueAndValidity();
  }

  isSelected = (value: string): boolean => !!this.priceAttribute.controls.find(control => control.value.priceTypeId === value);

  get priceAttribute(): FormArray {
  	return this.form.get('configuratorPriceTypes') as FormArray;
  }
}
