import {
  Directive,
  Input
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive({
  selector: '[sfTextFieldIcon]',
  exportAs: 'sfTextFieldIcon',
  host: { 'class': 'mdc-text-field__icon' }
})
export class SfTextFieldIconDirective {
  @Input()
  get leading(): boolean { return this._leading; }
  set leading(value: boolean) {
    this._leading = coerceBooleanProperty(value);
  }
  private _leading = false;

  @Input()
  get trailing(): boolean { return this._trailing; }
  set trailing(value: boolean) {
    this._trailing = coerceBooleanProperty(value);
  }
  private _trailing = false;
}
