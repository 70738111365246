import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './guards/auth.guard';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {ErrorRestInterceptor} from './interceptors/error-rest.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

@NgModule({
    imports: [CommonModule],
    providers: [
        AuthGuard,
        ErrorRestInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        }
    ]
})
export class SharedCheckpointModule {
}
