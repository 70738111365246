<h6 sfHeadline6 class="my-20">{{'BTN_CHOOSE_EXISTING' | translate}}</h6>
<sf-dialog-content data-cy="choseFromExisting">
  <p sfSubtitle2 class="mb-12">
    {{'HINT_PACKAGE_EXISTING' | translate}}
  </p>
  <div class="input__autocomplete mt-12 mb-20">
    <ew-pc-search-bar fxFlex="60" [searchFormControl]="queryFormControl"> </ew-pc-search-bar>
  </div>

  <sf-chip-set choice fxLayout="row wrap" class="choice__chip" *ngIf="productTreesConnection$ | async as productTreesConnection">
    <ng-container *ngFor="let productTree of productTreesConnection?.collection">
      <sf-chip fxFlex="32" class="selected" (click)="selectProduct(productTree?.packageId, productTree?.packageName[translate.currentLang])">
        <sf-chip-text>{{productTree?.packageName[translate.currentLang]}}
        </sf-chip-text>
        <sf-chip-icon trailing>check_circle_outline</sf-chip-icon>
      </sf-chip>
    </ng-container>
  </sf-chip-set>
</sf-dialog-content>
<sf-dialog-action fxLayoutAlign="end" ngClass.gt-xs="pb-20" ngClass.xs="common-action__button">
  <button sf-button outlined (click)="cancel()" data-cy="cancel"> {{'BTN_CANCEL' | translate}}</button>
  <button sf-button primary [disabled]="!isDisable" (click)="addProductToExistingProductTrees()" class="ml-12" data-cy="selectExisting">{{'Add' + ' ' + (this.config.data.currentLevel.name | translateName)}}</button>
</sf-dialog-action>
