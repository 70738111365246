import {Injectable} from '@angular/core';
import {FetchResult} from '@apollo/client';
import {TranslateService} from '@ngx-translate/core';
import {SfSnackBarService} from '@ew/shared/safire/components';
import {CONFIGURATORS_QUERY, LEVEL_QUERY, LEVELS_QUERY, PRICE_TYPES_QUERY} from '@ew/pc/levels';
import {
    AddPackage,
    Configurator,
    CreateProductItemAttributes,
    DeleteProductTreePayload,
    Image,
    LevelModel,
    Package,
    Price,
    ProductItem,
    ProductTree,
    ProductTreeCreate,
    ProductTreeList,
    ProductTreeUpdate,
    QueryProductItemsArgs,
    QueryProductTreeArgs,
    QueryProductTreesArgs,
    TableDataSource,
    UpdatePackage,
    UpdateProductItemInput,
} from '@ew/shared/services';
import {removeNullishValues} from '@ew/shared/utils/form-utils';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map, mapTo} from 'rxjs/operators';

import {ADD_PACKAGE_TO_PRODUCT_TREES} from '../gql-queries/add-package-to-product-trees.mutation';
import {MAP_PRODUCT_MUTATION} from '../gql-queries/add-product.mutation';
import {CLONE_PRODUCT_ITEM_MUTATION} from '../gql-queries/clone-product-item.mutation';
import {CLONE_PRODUCT_MUTATION} from '../gql-queries/clone-product.mutation';
import {CREATE_PRODUCT_ITEM_MUTATION} from '../gql-queries/create-product-item.mutation';
import {DELETE_PRODUCT_ITEM_MUTATION} from '../gql-queries/delete-product-item.mutation';
import {DELETE_PRODUCT_TREE_MUTATION} from '../gql-queries/delete-product.mutation';
import {PRODUCT_ITEM_QUERY, PRODUCT_ITEMS_QUERY} from '../gql-queries/product-item.query';
import {PRODUCT_TREE_QUERY} from '../gql-queries/product-tree.query';
import {PRODUCT_TREES_QUERY} from '../gql-queries/product-trees.query';
import {UPDATE_PACKAGE_MUTATION} from '../gql-queries/update-package.mutation';
import {UPDATE_PRODUCT_ITEM_MUTATION} from '../gql-queries/update-product-item.mutation';
import {UPDATE_PRODUCT_TREE_MUTATION} from '../gql-queries/update-product-tree.mutation';
import {UPLOAD_IMAGE_MUTATION} from '../gql-queries/upload-image.mutation';
import {graphqlutil} from '@ew/shared/apollo-graphql';

@Injectable({
    providedIn: 'root',
})
export class ProductApiService {
    constructor(private apollo: Apollo, private notify: SfSnackBarService, private translate: TranslateService) {}

    uploadImage(file: File): Observable<Image> {
        return this.apollo.use('pc')
            .mutate({
                mutation: UPLOAD_IMAGE_MUTATION,
                variables: {
                    input: {
                        attributes: {
                            file,
                            variants: [],
                        },
                    },
                },
                context: {
                    useMultipart: true,
                },
                fetchPolicy: 'no-cache',
            })
            .pipe(map((response) => response.data.uploadImage));
    }

    getLevel(id: string): Observable<LevelModel> {
        return this.apollo.use('pc')
            .query({
                query: LEVEL_QUERY,
                variables: {
                    id,
                },
            })
            .pipe(map((response) => response.data.level as LevelModel));
    }

    getLevels(configuratorId: string): Observable<LevelModel[]> {
        return this.apollo.use('pc')
            .query({
                query: LEVELS_QUERY,
                variables: {
                    configuratorId,
                },
            })
            .pipe(map((response) => response.data.levels as LevelModel[]));
    }

    getConfiguration(): Observable<Configurator[]> {
        return this.apollo.use('pc')
            .query({
                query: CONFIGURATORS_QUERY,
            })
            .pipe(map((response) => response.data.configurators));
    }

    addPackageToProductTrees(packageData: AddPackage): Observable<ProductTree> {
        return this.apollo.use('pc')
            .mutate({
                mutation: ADD_PACKAGE_TO_PRODUCT_TREES,
                variables: {
                    input: {
                        attributes: removeNullishValues(packageData),
                    },
                },
            })
            .pipe(
                map((response) => {
                    this.notify.open(this.translate.instant('SUCCESS_PRODUCT_CREATE'), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                    return response.data.addPackageToProductTrees;
                })
            );
    }

    updatePackage(updatePackage: UpdatePackage): Observable<Package> {
        return this.apollo.use('pc')
            .mutate({
                mutation: UPDATE_PACKAGE_MUTATION,
                variables: {
                    input: {
                        attributes: removeNullishValues(updatePackage),
                    },
                },
            })
            .pipe(
                map((res) => {
                    this.notify.open(this.translate.instant('SUCCESS_PRODUCT_UPDATE'), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    })
                    return res.data.updatePackage
                }));
    }

    getProductTrees(productTreesArgs: QueryProductTreesArgs): Observable<TableDataSource<ProductTreeList>> {
        const args = Object.keys(productTreesArgs).reduce(
            (acc: QueryProductTreeArgs, key: string) => ({
                ...acc,
                ...(productTreesArgs[key] !== null && productTreesArgs[key] !== '' ? { [key]: productTreesArgs[key] } : {}),
            }),
      {} as QueryProductTreeArgs
        );
        return this.apollo.use('pc')
            .query({
                query: PRODUCT_TREES_QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    ...graphqlutil.formatPaginationParams(args)
                },
            })
            .pipe(map((response) => graphqlutil.formatResponse(response?.data?.productTrees, args) as TableDataSource<ProductTreeList>));
    }

    getProductItems(productItemsArgs: QueryProductItemsArgs): Observable<TableDataSource<ProductItem>> {
        const args = Object.keys(productItemsArgs).reduce(
            (acc: QueryProductItemsArgs, key: string) => ({
                ...acc,
                ...(productItemsArgs[key] !== null && productItemsArgs[key] !== '' ? { [key]: productItemsArgs[key] } : {}),
            }),
      {} as QueryProductItemsArgs
        );
        return this.apollo.use('pc')
            .query({
                query: PRODUCT_ITEMS_QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    ...graphqlutil.formatPaginationParams(args)
                },
            })
            .pipe(map((response) => graphqlutil.formatResponse(response.data.productItems, args) as TableDataSource<ProductItem>));
    }

    getProductTreeById(productTreeId: string): Observable<ProductTree> {
        return this.apollo.use('pc')
            .query({
                query: PRODUCT_TREE_QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    id: productTreeId,
                },
            })
            .pipe(map((response) => response.data.productTree));
    }

    updateProductTreeById(updatePayload: ProductTreeUpdate): Observable<ProductTree> {
        return this.apollo.use('pc')
            .mutate({
                mutation: UPDATE_PRODUCT_TREE_MUTATION,
                variables: {
                    input: {
                        attributes: updatePayload,
                    },
                },
            })
            .pipe(
                map((response) => {
                    this.notify.open(this.translate.instant('SUCCESS_PRODUCT_UPDATE'), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                    return response.data.updateProductTree;
                })
            );
    }

    updateProductItem(updateProductItemInput: UpdateProductItemInput): Observable<boolean> {
        return this.apollo.use('pc')
            .mutate({
                mutation: UPDATE_PRODUCT_ITEM_MUTATION,
                variables: {
                    input: removeNullishValues(updateProductItemInput),
                },
            })
            .pipe(
                map(() => {
                    this.notify.open(this.translate.instant('SUCCESS_PRODUCT_ITEM_UPDATE'), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                }),
                mapTo(true));
    }

    removeProductItemById(itemId: string): Observable<boolean> {
        return this.apollo.use('pc')
            .mutate({
                mutation: DELETE_PRODUCT_ITEM_MUTATION,
                variables: {
                    input: {
                        id: itemId,
                    },
                },
            })
            .pipe(mapTo(true));
    }

    mapProductToProductTrees(productTreeCreate: ProductTreeCreate, newName: string, existingName: string): Observable<ProductTree> {
        return this.apollo.use('pc')
            .mutate({
                mutation: MAP_PRODUCT_MUTATION,
                variables: {
                    input: {
                        attributes: productTreeCreate,
                    },
                },
            })
            .pipe(
                map((response) => {
                    this.notify.open(this.translate.instant(`${newName} successfully added to ${existingName} `), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                    return response.data.mapPackageToProductTree;
                })
            );
    }

    cloneProductById(productTreeId: string): Observable<ProductTree> {
        return this.apollo.use('pc')
            .mutate({
                mutation: CLONE_PRODUCT_MUTATION,
                variables: {
                    input: {
                        id: productTreeId,
                    },
                },
            })
            .pipe(
                map((response) => {
                    this.notify.open(this.translate.instant('SUCCESS_PRODUCT_CLONED'), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                    return response.data.cloneProductTree;
                })
            );
    }

    cloneProductItemById(itemId: string): Observable<ProductItem> {
        return this.apollo.use('pc')
            .mutate({
                mutation: CLONE_PRODUCT_ITEM_MUTATION,
                variables: {
                    input: {
                        id: itemId,
                    },
                },
            })
            .pipe(map((res: FetchResult<{ cloneProductItem: ProductItem }>) => res.data.cloneProductItem));
    }

    deleteProductById(productTreeId: string): Observable<DeleteProductTreePayload> {
        return this.apollo.use('pc')
            .mutate({
                mutation: DELETE_PRODUCT_TREE_MUTATION,
                variables: {
                    input: {
                        id: productTreeId,
                    },
                },
            })
            .pipe(
                map((response) => {
                    this.notify.open(this.translate.instant('SUCCESS_PRODUCT_DELETE'), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                    return response.data.deleteProductTree;
                })
            );
    }

    getPriceTypes(): Observable<Price[]> {
        return this.apollo.use('pc')
            .query({
                query: PRICE_TYPES_QUERY,
                fetchPolicy: 'cache-first',
            })
            .pipe(map((result) => result.data.priceTypes));
    }

    createProductItem(productItemCreate: CreateProductItemAttributes): Observable<ProductTree> {
        return this.apollo.use('pc')
            .mutate({
                mutation: CREATE_PRODUCT_ITEM_MUTATION,
                variables: {
                    input: {
                        attributes: removeNullishValues(productItemCreate),
                    },
                },
            })
            .pipe(
                map((response) => {
                    this.notify.open(this.translate.instant(`Item has been created successfully`), undefined, 'success', {
                        duration: 2000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                    });
                    return response.data.createProductItem;
                })
            );
    }

    getProductItemById(id: string): Observable<ProductItem> {
        return this.apollo.use('pc')
            .query({
                query: PRODUCT_ITEM_QUERY,
                variables: {
                    id,
                },
                fetchPolicy: 'no-cache'
            })
            .pipe(map((response) => response.data.productItem));
    }

    // [REVISIT Neeraj: 12/10/2021] Use only when contract duration is required for CRUD operations
    // createContractionDuration(contractDuration: CreateContractDuration): Observable<ContractDuration> {
    //   return this.apollo.use('pc')
    //     .mutate({
    //       mutation: CREATE_CONTRACT_DURATION_MUTATION,
    //       variables: {
    //         input: {
    //           attributes: removeNullishValues(contractDuration),
    //         },
    //       },
    //     })
    //     .pipe(map((response) => response.data.createContractDuration));
    // }
    //
    // updateContractDuration(contractDuration: UpdateContractDuration): Observable<ContractDuration> {
    //   return this.apollo.use('pc')
    //     .mutate({
    //       mutation: UPDATE_CONTRACT_DURATION_MUTATION,
    //       variables: {
    //         input: {
    //           attributes: removeNullishValues(contractDuration),
    //         },
    //       },
    //     })
    //     .pipe(map((response) => response.data.updateContractDuration));
    // }
    //
    // fetchContractDurations(configuratorId: string): Observable<ContractDuration[]> {
    //   return this.apollo.use('pc')
    //     .query({
    //       query: CONTRACT_DURATIONS_QUERY,
    //       variables: {
    //         configuratorId,
    //       },
    //       fetchPolicy: 'network-only',
    //     })
    //     .pipe(map((response) => response.data.contractDurations));
    // }
    //
    // removeContractDurationById(id: string): Observable<DeletePayload> {
    //   return this.apollo.use('pc')
    //     .mutate({
    //       mutation: DELETE_CONTRACT_DURATION_MUTATION,
    //       variables: {
    //         input: {
    //           id,
    //         },
    //       },
    //     })
    //     .pipe(map((res: FetchResult<{ deleteContractDuration }>) => res.data.deleteContractDuration));
    // }
}
