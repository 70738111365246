import { Observable, Subject } from 'rxjs';

import { OverlayRef } from '@angular/cdk/overlay';
import { SfSnackBarContainerComponent } from './snack-bar-container/snack-bar-container.component';

/** Event that is emitted when a snack bar is dismissed. */
export interface SfSnackBarDismiss {
  /** Whether the snack bar was dismissed using the action button. */
  dismissedByAction: boolean;
}

let uniqueId = 0;

export class SfSnackBarRef<T> {
  componentInstance: T;
  openedSnackBarRef: SfSnackBarRef<T>;
  containerInstance: SfSnackBarContainerComponent;
  /** Subject for notifying the user that the snack bar action was called. */
  private readonly _onAction = new Subject<void>();
  /**
   * Timeout ID for the duration setTimeout call. Used to clear the timeout if the snackbar is
   * dismissed before the duration passes.
   */
  private durationTimeoutId: number;
  /** Whether the snack bar was dismissed using the action button. */
  private dismissedByAction = false;

  /** Subject for notifying the user that the snack bar has opened and appeared. */
  private readonly _afterOpened = new Subject<void>();

  /** Subject for notifying the user that the snack bar has been dismissed. */
  private readonly _afterDismissed = new Subject<SfSnackBarDismiss>();

  constructor(
    private overlayRef: OverlayRef,
    containerInstance: SfSnackBarContainerComponent,
    readonly id: string = `sf-snack-bar-${uniqueId++}`) {
    this.containerInstance = containerInstance;
    this.containerInstance.id = id;
    this.onAction().subscribe(() => this.dismiss());
    containerInstance.onExit.subscribe(() => this.finishDismiss());
  }

  /** Gets an observable that is notified when the snack bar action is called. */
  onAction(): Observable<void> {
    return this._onAction.asObservable();
  }

  /** Marks the snackbar action clicked. */
  dismissWithAction(): void {
    if (!this._onAction.closed) {
      this.dismissedByAction = true;
      this._onAction.next();
      this._onAction.complete();
    }
  }

  /** Gets an observable that is notified when the snack bar is finished closing. */
  afterDismissed(): Observable<SfSnackBarDismiss> {
    return this._afterDismissed.asObservable();
  }

  /** Gets an observable that is notified when the snack bar has opened and appeared. */
  afterOpened(): Observable<void> {
    return this.containerInstance.onEnter;
  }

  /** Dismisses the snack bar after some duration */
  dismissAfter(duration: number): void {
    this.durationTimeoutId = window.setTimeout(() => this.dismiss(), duration);
  }


  /** Dismisses the snack bar. */
  dismiss(): void {
    if (!this._afterDismissed.closed) {
      this.containerInstance.exit();
    }
    clearTimeout(this.durationTimeoutId);
  }

  /** Marks the snackbar as opened */
  open(): void {
    if (!this._afterOpened.closed) {
      this._afterOpened.next();
      this._afterOpened.complete();
    }
  }

  /** Cleans up the DOM after closing. */
  private finishDismiss(): void {
    this.overlayRef.dispose();

    if (!this._onAction.closed) {
      this._onAction.complete();
    }

    this._afterDismissed.next({ dismissedByAction: this.dismissedByAction });
    this._afterDismissed.complete();
    this.dismissedByAction = false;
  }
}
