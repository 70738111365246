
/* eslint-disable @typescript-eslint/ban-types */
import { HttpParams } from '@angular/common/http';

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace QueryParam {
  export function setQueryParams(
      parameters: Object,
      acceptedParams: Array<string>,
      httpParams?: HttpParams
  ) {
      let params = httpParams || new HttpParams();
      acceptedParams.forEach(param => {
          params = parameters[param]
              ? params.append(param, parameters[param])
              : params;
      });
      return params;
  }
}

export { QueryParam as SfQueryParamUtil };
