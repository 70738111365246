import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {
    Configurator,
    ConfiguratorPrice,
    Country,
    DataTypeEnum,
    LevelFormBuilder,
    LevelModel,
    LevelStateEnum,
    LevelTypeEnum,
    SharedBlService,
    UpdateConfigurator,
} from '@ew/shared/services';
import {ReactiveFormSchema, ReactiveFormUtil} from '@ew/shared/utils/form-utils';
import {isArray, omit} from 'lodash-es';
import {LevelStateService} from './level-state.service';

@Injectable({
    providedIn: 'root',
})
export class LevelBlService extends SharedBlService {
    constructor(private levelStateService: LevelStateService) {
        super();
    }

    get levelData(): LevelModel {
        return this.levelStateService.getSpecificState(LevelStateEnum.level);
    }

    get levelConfigurator(): Configurator {
        return this.levelStateService.getSpecificState(LevelStateEnum.configurator);
    }

    formatData(): LevelModel {
        const data: LevelModel = this.levelData;
        data.configuratorId = this.levelConfigurator.id;
        if (data.type === LevelTypeEnum.ProductNode) {
            delete data.levelRule;
        }
        return data;
    }

    formatConfiguratorData(configData: Configurator): UpdateConfigurator {
        this.formatConfiguratorDynamicColumns(configData);
        const countries = configData.countries.map(country => omit(country, ['currencyCode', 'name']))
        return {
            ...configData,
            countries,
            dynamicColumns: configData.dynamicColumns.map((col) => ({
                ...col,
                multilingual: [DataTypeEnum.String, DataTypeEnum.Text].includes(col.dataType),
            }))
        } as UpdateConfigurator;
    }

    private formatConfiguratorDynamicColumns(configData: Configurator): void {
        if (!configData.dynamicColumns.length) {
            delete configData.dynamicColumns;
        } else {
            configData.dynamicColumns.forEach((column) => {
                if (column.dataType !== 'options') {
                    if (column.dynamicOptions?.length) {
                        column.dynamicOptions.forEach((option) => (option['_destroy'] = true));
                    } else {
                        delete column.dynamicOptions;
                    }
                }
            });
        }
    }

    patchConfigControls(configurator: Configurator, type: string, attribute: FormArray, fb: FormBuilder): void {
        let form: FormGroup;
        if (type === 'dynamicColumns') {
            configurator.dynamicColumns.forEach((column) => {
                form = this.dynamicFormData(column, 'dynamicColumnInitializer');
                attribute.push(form);
            });
        } else {
            const data: ConfiguratorPrice[] | Country[] = configurator[type];
            if (isArray(data)) {
                data.forEach((datum) => {
                    form = this.buildConfigPriceCountryForm(form, fb, datum, type, attribute);
                });
            }
        }
        attribute.updateValueAndValidity();
    }

    private buildConfigPriceCountryForm(form: FormGroup, fb: FormBuilder, datum: any, type: string, attribute: FormArray): FormGroup {
        form = fb.group({
            id: datum.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _destroy: false,
            ...(type === 'configuratorPriceTypes'
                ? {priceTypeId: datum.priceTypeId}
                : {
                    countryCode: datum.countryCode,
                    name: datum.name,
                    currencyCode: datum.currencyCode,
                    default: datum.default,
                }),
        });
        attribute.push(form);
        return form;
    }

    dynamicFormData(data: Configurator, formInitializer: string): FormGroup {
        const level: ReactiveFormSchema = LevelFormBuilder[formInitializer]();
        const form: FormGroup = ReactiveFormUtil.buildForm(new level.formModel(), level);
        form.patchValue({
            ...data,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _destroy: false,
        });
        return form;
    }
}
