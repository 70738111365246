import { FormControl, ValidatorFn } from '@angular/forms';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Validator {
  export const email = /\S+@\S+\.\S+/;
  export const internationalNumber = '^[+0-9]+$';

  export const passwordValidator = (otherControl: FormControl): ValidatorFn => {
      return (control: FormControl) => {
          if (!control.value) {
              return null;
          }
          return control.value === otherControl.value ? null : { password: true };
      };
  };

  export const emailValidator = (module?: string): ValidatorFn => {
      return (control: FormControl) => {
          if (
              !control.value ||
        (module === 'login' && !control.value.includes('@'))
          ) {
              return null;
          }
          return email.test(control.value) ? null : { email: true };
      };
  };

  export const validateIntlPhoneNumber = (country?: string): ValidatorFn => {
      return (control: FormControl) => {
          try {
              const number = phoneUtil.parseAndKeepRawInput(control.value);
              return phoneUtil.isValidNumber(number) ? null : { phoneNumber: true };
          } catch (e) {
              return control.value ? { phoneNumber: true } : null;
          }
      };
  };
}

export { Validator as SfCustomValidators };
