import {Component, Input} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Country, CountryAttributes} from '@ew/shared/services';

@Component({
    selector: 'ew-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent {
    @Input() form: FormGroup;
    @Input() filteredCountries: Country[];

    @Input() countrySearchControl: FormControl;

    constructor(private fb: FormBuilder) {
    }

    selectCurrency(country: Country): void {
    	const hasCountryCode: AbstractControl = this.countryAttribute.controls.find(control => control.value?.countryCode
            === country.countryCode);
    	if (!hasCountryCode) {
    		this.countryAttribute.controls?.push(this.fb.group({
    			...country,
    			...this.setDefault()
    		}));
    	} else if (hasCountryCode.value?._destroy) {
    		hasCountryCode.patchValue({
    			// eslint-disable-next-line @typescript-eslint/naming-convention
    			...this.setDefault()
    		})
    	}
    	this.countryAttribute.updateValueAndValidity();
    	this.countrySearchControl.setValue(undefined);
    	this.filteredCountries = [];
    }

    setDefault(): CountryAttributes {
    	// eslint-disable-next-line @typescript-eslint/naming-convention
    	return {_destroy: false, default: this.assignDefaultCurrency()}
    }

    assignDefaultCurrency(): boolean {
    	if (this.countryAttribute.controls.length) {
    		return this.countryAttribute.controls.every(control => control.value?._destroy);
    	}
    	return true;
    }

    setDefaultCurrency(selectControl: AbstractControl): void {
    	this.countryAttribute.controls.forEach(control => {
    		control.patchValue({default: selectControl === control});
    	});
    	this.countryAttribute.updateValueAndValidity();
    }

    removeCurrency = (index: number, selectControl: AbstractControl): void => {
    	// eslint-disable-next-line @typescript-eslint/naming-convention
    	!selectControl.value?.id ? this.countryAttribute.removeAt(index) : selectControl.patchValue({_destroy: true});
    	const controls: AbstractControl[] = this.countryAttribute.controls.filter(control => !control.value._destroy);
    	if (controls.length >= 1) {
    		controls[0].patchValue({default: true})
    	}
    	this.countryAttribute.updateValueAndValidity();
    };

    get countryAttribute(): FormArray {
    	return this.form.get('countries') as FormArray;
    }
}
