import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { ProductTreesConnection } from '@ew/shared/services';

export const PRODUCT_TREES_QUERY: TypedDocumentNode<{ productTrees: ProductTreesConnection }> = gql`
  query productTrees(
    $active: Boolean,
    $after: String,
    $ancestry: String,
    $ancestryDepth: Int,
    $before: String,
    $direction: DirectionOptionsEnum,
    $filter: [PackageRegionEnum!],
    $first: Int,
    $last: Int,
    $query: String,
    $sort: SortOptionsEnum
  ) {
    productTrees(
      active: $active,
      after: $after,
      ancestry: $ancestry,
      ancestryDepth: $ancestryDepth,
      before: $before,
      direction: $direction,
      filter: $filter,
      first: $first,
      last: $last,
      query: $query,
      sort: $sort
    ) {
      nodes {
        active,
        ancestry
        ancestryDepth
        deliveryTime
        dynamicColumns
        dynamicValues
        id
        images
        meta
        packageConfiguratorPriceTypeId
        packageContractDurations
        packageId
        packageMeta
        packageLevelId
        packageName
        packageSource
        packageType
        preorder
        productLimit
      }
    }
  }`;
