import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { ProductItem } from '@ew/shared/services';

export const CLONE_PRODUCT_ITEM_MUTATION: TypedDocumentNode<{ cloneProductItem: ProductItem }> = gql`
  mutation cloneProductItem($input: CloneProductItemInput!) {
    cloneProductItem(input: $input) {
      id
    }
  }
`;
