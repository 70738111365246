import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
    BaseFacadeService,
    Configurator,
    ConfiguratorPrice,
    Country,
    CountryList,
    CreateLevelInput,
    DeleteLevelPayload,
    DynamicColumnAttributes,
    Level,
    Price,
    UpdateLevelInput,
    LevelModel,
    LevelsStoreState,
    LevelStateEnum
} from '@ew/shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LevelApiService } from './level-api.service';
import { LevelBlService } from './level-bl.service';
import { LevelStateService } from './level-state.service';

@Injectable({
    providedIn: 'root',
})
export class LevelFacadeService extends BaseFacadeService<
  LevelStateService,
  LevelsStoreState
> {
    constructor(
    private levelApiService: LevelApiService,
    private levelStateService: LevelStateService,
    private levelBlService: LevelBlService
    ) {
        super(levelStateService);
    }

    getCountries(): Observable<CountryList[]> {
        return this.levelApiService.getCountries().pipe(
            tap((countries) => {
                this.updateSpecificState(countries, LevelStateEnum.country);
            })
        );
    }

    getPriceTypes(): Observable<Price[]> {
        return this.levelApiService.getPriceTypes().pipe(
            tap((priceTypes) => {
                this.updateSpecificState(priceTypes, LevelStateEnum.price);
            })
        );
    }

    updateLevelState(data: LevelModel): void {
        this.updateSpecificState(data, LevelStateEnum.level);
    }

    updateNewLevelState(data: boolean): void {
        this.updateSpecificState(data, LevelStateEnum.newLevel);
    }

    getLevels(): Observable<LevelModel[]> {
        return this.levelApiService.getLevels(this.levelConfig.id);
    }

    getConfigurators(): Observable<Configurator[]> {
        return this.levelApiService.getConfigurators().pipe(
            tap((configs) => {
                // REVISIT_DAKPA: Aug-26-2021 - Supposed to select one configurator somehow, but that flow is not there yet.
                this.updateSpecificState(configs[0], LevelStateEnum.configurator);
            })
        );
    }

    createUpdateLevel(): Observable<LevelModel> {
        const payload: LevelModel = this.levelBlService.formatData();
        return payload.id
            ? this.levelApiService.updateLevel(this.makeUpdateLevelInput(payload))
            : this.levelApiService.createLevel(this.makeCreateLevelInput(payload));
    }

    private makeUpdateLevelInput(level: LevelModel): UpdateLevelInput {
        return {
            attributes: {
                id: level.id,
                name: level.name,
                description: level.description,
                meta: level.meta,
                type: level.type,
                selectionRule: level.selectionRule,
                imageable: level.imageable,
                levelRule: level.levelRule,
                dynamicColumns: level.dynamicColumns as DynamicColumnAttributes[],
            },
        };
    }

    private makeCreateLevelInput(level: LevelModel): CreateLevelInput {
        return {
            attributes: {
                configuratorId: level.configuratorId,
                name: level.name,
                description: level.description,
                meta: level.meta,
                type: level.type,
                selectionRule: level.selectionRule,
                imageable: level.imageable,
                levelRule: level.levelRule,
                dynamicColumns: level.dynamicColumns as DynamicColumnAttributes[],
            },
        };
    }

    updateLevelConfig(data: Configurator): Observable<Configurator> {
        return this.levelApiService.updateConfiguration(
            this.levelBlService.formatConfiguratorData(data)
        );
    }

    getLevelById(id: string): Observable<Level> {
        return this.levelApiService.getLevelById(id);
    }

    deleteLevelById(id: string): Observable<DeleteLevelPayload> {
        return this.levelApiService.deleteLevelById(id);
    }

    patchConfigControls(
        configurator: Configurator,
        type: string,
        attribute: FormArray,
        fb: FormBuilder
    ): void {
        return this.levelBlService.patchConfigControls(
            configurator,
            type,
            attribute,
            fb
        );
    }

    dynamicFormData(data: Configurator, formInitializer: string): FormGroup {
        return this.levelBlService.dynamicFormData(data, formInitializer);
    }

    get levelState(): LevelModel {
        return this.getSpecificState(LevelStateEnum.level);
    }

    get levelConfig(): Configurator {
        return this.getSpecificState(LevelStateEnum.configurator);
    }

    get countryState(): Country[] {
        return this.getSpecificState(LevelStateEnum.country);
    }

    get priceTypeState(): ConfiguratorPrice[] {
        return this.getSpecificState(LevelStateEnum.price);
    }

    get newLevel(): boolean {
        return this.getSpecificState(LevelStateEnum.newLevel);
    }
}
