import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { DeleteLevelPayload, Level } from '@ew/shared/services';

export const LEVEL_QUERY: TypedDocumentNode<{ level: Level }> = gql`
  query level($id: ID!) {
    level(id: $id) {
      configuratorId
      description
      id
      type
      levelRule {
        contractDuration
        delivery
        id
        preorder
        quantitySetting
        stockManagement
      }
      imageable
      meta
      name
      rank
      selectionRule
      dynamicColumns {
        dataType
        id
        multilingual
        name
        required
        dynamicOptions {
          hexValue
          id
          name
          value
        }
      }
    }
  }
`;

export const LEVEL_CREATE_MUTATION: TypedDocumentNode<Level> = gql`
  mutation createLevel($input: CreateLevelInput!) {
    createLevel(input: $input) {
      configuratorId
      description
      dynamicColumns {
        dynamicOptions {
          id
          name
        }
        id
      }
      id
      type
      levelRule {
        contractDuration
        delivery
        id
        preorder
        quantitySetting
        stockManagement
      }
      meta
      name
      rank
      selectionRule
    }
  }
`;

export const LEVEL_UPDATE_MUTATION: TypedDocumentNode<Level> = gql`
  mutation updateLevel($input: UpdateLevelInput!) {
    updateLevel(input: $input) {
      configuratorId
      type
    }
  }
`;

export const LEVEL_DELETE_MUTATION: TypedDocumentNode<DeleteLevelPayload> = gql`
  mutation deleteLevel($input: DeleteLevelInput!) {
    deleteLevel(input: $input) {
      status
    }
  }
`;
