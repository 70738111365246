<div class="sf-breadcrumb">
  <ng-container *ngIf="breadcrumbs.length < 4">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <a class="sf-breadcrumb__link" (click)="goToRoute(breadcrumb)">{{breadcrumb.label}}</a>
        <sf-icon class="sf-breadcrumb__icon" *ngIf="!last">arrow_right</sf-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="breadcrumbs.length > 3">
      <a class="sf-breadcrumb__link" (click)="goToRoute(breadcrumbs[0])">{{breadcrumbs[0].label}}</a>
      <sf-icon class="sf-breadcrumb__icon">arrow_right</sf-icon>
    <div sfMenuSurfaceAnchor #breadcrumbAnchor>
      <button
        sf-icon-button
        icon="more_horiz"
        (click)="showMore.open = !showMore.open"
        sfTooltip="Show more"
        [sfTooltipPosition]="'below'"
        class="sf-breadcrumb__button"
      >
      </button>
      <sf-icon class="sf-breadcrumb__icon">arrow_right</sf-icon>
      <sf-menu #showMore [anchorElement]="breadcrumbAnchor" anchorCorner="bottomStart">
        <sf-list>
          <ng-container *ngFor="let breadcrumb of breadcrumbs | slice: 1: breadcrumbs.length - 1">
            <sf-list-item class="sf-breadcrumb__list" (click)="goToRoute(breadcrumb)">{{breadcrumb.label}}</sf-list-item>
          </ng-container>
        </sf-list>
      </sf-menu>
    </div>
      <a class="sf-breadcrumb__link sf-breadcrumb__active" (click)="goToRoute(breadcrumbs[breadcrumbs.length - 1])">{{breadcrumbs[breadcrumbs.length - 1].label}}</a>
  </ng-container>
</div>
