import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfColorPickerComponent } from './color-picker.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorTwitterModule } from 'ngx-color/twitter';
import {
    SfElevationModule,
    SfFabModule,
    SfIconModule,
    SfListModule,
    SfMenuModule
} from '@ew/shared/safire/components';

@NgModule({
    imports: [
        CommonModule,
        ColorSketchModule,
        ColorTwitterModule,
        SfMenuModule,
        SfListModule,
        SfFabModule,
        SfIconModule,
        SfElevationModule
    ],
    declarations: [SfColorPickerComponent],
    exports: [SfColorPickerComponent]
})
export class SfColorPickerModule {}
