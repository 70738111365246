import { Component, OnInit } from '@angular/core';
import { SharedFacadeService, SharedStoreStateEnum, User } from '@ew/shared/services';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'ew-impersonation-mode',
    templateUrl: './impersonation-mode.component.html',
    styleUrls: ['./impersonation-mode.component.scss']
})
export class ImpersonationModeComponent implements OnInit {

    constructor(private sharedFacade: SharedFacadeService) { }
    user: User;
    name: string;

    ngOnInit(): void {
        this.sharedFacade.specificStateChange(SharedStoreStateEnum.PROFILE_STATE).subscribe(
            (res: User) => {
                this.user = res;
                const val =  `${res.profile?.firstName} ${res.profile?.lastName}`
                this.name = val !== ' ' ? val : res.profile.companyName !== ''? res?.profile?.companyName :  res?.clientNumber
            }
        );

    }

    endSwitchUser(): void {
        this.sharedFacade.ImpersonationEnd().pipe(
            tap(() =>  window.location.reload())
        ).subscribe();
    }
}
