import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SfImageListComponent,
  SfImageListImage,
  SfImageListImageAspect,
  SfImageListItem,
  SfImageListLabel,
  SfImageListSupporting
} from './image-list.component';


const IMAGE_LIST_DECLARATIONS = [
  SfImageListItem,
  SfImageListComponent,
  SfImageListImageAspect,
  SfImageListImage,
  SfImageListSupporting,
  SfImageListLabel
];

@NgModule({
  imports: [CommonModule],
  declarations: IMAGE_LIST_DECLARATIONS,
  exports: IMAGE_LIST_DECLARATIONS
})
export class SfImageListModule {
}
