<div class="sf-autocomplete"
     [ngClass]="{ 'sf-active': isOpen}">
    <div class="sf-autocomplete__input">
        <input #searchInput
               type="text"
               placeholder={{placeholder}}
               [(ngModel)]=query
               (input)="onChange($event)"
               (focus)=handleFocus($event)
               (blur)=onTouched($event)
               [disabled]="disabled"
               autocomplete="off"
        >
        <div class="sf-autocomplete__icon" *ngIf="query && !isLoading && !disabled" (click)="remove($event)">
            <sf-icon>close</sf-icon>
        </div>
    </div>
    <!--FilteredList items-->
    <div class="sf-autocomplete__menu"
         [ngClass]="{ 'sf-is-hidden': isHistoryListVisible, 'sf-is-visible': !isHistoryListVisible}">
        <!--FilteredList heading-->
        <div class="sf-autocomplete__menu-heading" *ngIf="filteredList.length > 0 && heading">
            <p>{{heading}}</p>
        </div>

        <ul #filteredListElement>
            <li *ngFor="let item of filteredList; let idx = index">
                <!--string logic-->
                <div [class.sf-complete-selected]="idx === selectedIdx" *ngIf='isTypeString(item)'
                     (click)="select(item)">
                    <ng-container
                        *ngTemplateOutlet="itemTemplate;  context: { $implicit: item | highlight: toHighlight }">
                    </ng-container>
                </div>
                <!--object logic-->
                <div [class.sf-complete-selected]="idx === selectedIdx" *ngIf='!isTypeString(item)'
                     (click)="select(item)">
                    <ng-container
                        *ngTemplateOutlet="itemTemplate; context: { $implicit: item | highlight: toHighlight : searchKeyword }">
                    </ng-container>
                </div>
            </li>
        </ul>
        <div *ngIf="isLoading ? !isLoading && notFound : notFound" class="sf-autocomplete__menu-not-found">
            <ng-container
                *ngTemplateOutlet="notFoundTemplate;  context: { $implicit: notFoundText  }">
            </ng-container>
        </div>
    </div>

    <!--HistoryList items-->
    <div class="sf-autocomplete__menu"
         [ngClass]="{ 'sf-is-hidden': !isHistoryListVisible, 'sf-is-visible': isHistoryListVisible}">
        <!--HistoryList heading-->
        <div class="sf-autocomplete__menu-heading" *ngIf="historyList.length > 0 && historyHeading">
            <p>{{historyHeading}}</p>
            <div class="sf-autocomplete__icon" (click)="resetHistoryList($event)">
                <sf-icon>delete</sf-icon>
            </div>
        </div>

        <ul #historyListElement>
            <li *ngFor="let item of historyList; let idx = index" class="item">
                <!--string logic-->
                <div [class.sf-complete-selected]="idx === selectedIdx" *ngIf='isTypeString(item)' (click)="select(item)">
                    <ng-container
                        *ngTemplateOutlet="itemTemplate;  context: { $implicit: item }">
                    </ng-container>
                </div>
                <!--object logic-->
                <div [class.sf-complete-selected]="idx === selectedIdx" *ngIf='!isTypeString(item)' (click)="select(item)">
                    <ng-container
                        *ngTemplateOutlet="itemTemplate; context: { $implicit: item }">
                    </ng-container>
                </div>
                <div class="sf-autocomplete__icon" (click)="removeHistoryItem(idx, $event)">
                    <sf-icon>close</sf-icon>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="sf-autocomplete-overlay" *ngIf="overlay" (click)="handleOverlay()"></div>
