// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {Permission, User} from '../../../../services/src/lib/models/graphql';

export interface SecurityStoreState {
	currentUserState?: User;
	accessMatrices?: Permission[];
}

export enum SecurityStoreEnum {
	CURRENTUSER = 'currentUserState',
	ACCESS_MATRICES = 'accessMatrices'
}
