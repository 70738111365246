<div [fxLayout]="isRowLayout ? 'row' : 'column'" fxLayoutAlign="space-between" class="image-uploader width--full">
    <div [fxFlex]="isRowLayout ? '58' : '100'" fxFlex.xs="100" fxLayoutAlign="center center"
         class="cursor--pointer"
         [ngClass]="isRowLayout ? 'image-uploader__container--row' : 'image-uploader__container--column'">
        <div fxLayout="column" fxLayoutAlign="center center"
             class="image-uploader__height--full py-32"
             (click)="filePicker.click(); $event.stopPropagation()" data-cy="bannerImage">
            <input
                    class="product-create__image-uploader"
                    type="file"
                    [hidden]="true"
                    [accept]="acceptedFileTypes"
                    [multiple]="pickMultiple"
                    (change)="pickFiles($event.target.files)"
                    #filePicker>
            <img src="https://images.selise.club/ui-assets/images/icons/upload.svg" alt="upload file icon"
                 class="mb-12"/>
            <p sfSubtitle2 class="color--black-87 px-16" [innerHtml]="'HINT_DROP_FILE_HERE' | translate"></p>
            <p sfCaption
               class="color--black-60">{{'HINT_UPLOAD_FILE_LIMIT' | translate: {maxSizeLimit: ((maxFileSizeLimit / 1000000) | number: '1.0-1') + ' MB'} }}</p>
        </div>

    </div>
    <div [fxFlex]="isRowLayout ? '40' : '100'" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="start start" class="image-container"
             [ngClass]="!isRowLayout ? 'mt-24 width--full' : ''">
            <ng-container *ngFor="let imageUrl of previewImageUrls; let i = index;">
                <div fxLayoutAlign="center center" class="image-container__image mt-16" data-cy="preview">
                    <img [src]="imageUrl" alt="uploaded image preview"/>
                    <sf-icon class="cursor--pointer" (click)="removeFileAt(i)">highlight_off</sf-icon>
                </div>

                <!--      <div class="progress-bar mb-20 mt-32">
                      <span class="progress-bar__progress"></span>
                     </div>-->
            </ng-container>
        </div>
    </div>
</div>
