<div fxLayout.xs='column' fxLayout.gt-xs='row wrap' fxLayoutAlign='space-between start' class="mt-24">
  <sf-card fxHide.lt-sm fxFlex.gt-xs='26' class='p-20' sfElevation='0'>
    <ew-vertical-stepper (stepperChange)='stepperSetting($event.selectedIndex)'
                         [stepper]='configurations'></ew-vertical-stepper>
  </sf-card>
  <div fxFlex.gt-xs='72'>
    <ng-container *ngFor='let config of configurations; let i = index'>
      <ng-container *ngIf="stepperInputs[i]?.form">
        <ndc-dynamic
            [ndcDynamicComponent]="config.component"
            [ndcDynamicInputs]="stepperInputs[i]"
            [ndcDynamicOutputs]="setOutputComponentConfig">
        </ndc-dynamic>
      </ng-container>
    </ng-container>
    <div fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between" class="p-24">
      <button sf-button outlined ngClass.xs="width--full mb-12" (click)='cancel()' data-cy="cancel"> {{'BTN_CANCEL' | translate}}</button>
      <button sf-button primary ngClass.xs="width--full" ngClass.gt-xs="ml-12" [attr.data-cy]="(edit ? 'BTN_SAVE' : 'BTN_FINISH') | translate" (click)='edit ? saveStepper(): submit()'>{{(edit ? 'BTN_SAVE' : 'BTN_FINISH') | translate}}</button>
  </div>
</div>
</div>
