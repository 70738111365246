import { Component, Input, Output, EventEmitter } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';

import { LevelModel, LevelFormBuilder, LevelsStoreState } from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';

@Component({
    selector: 'ew-level-base',
    templateUrl: './level-base.component.html',
    styleUrls: ['./level-base.component.scss']
})
export class LevelBaseComponent extends FormBaseComponent {
  @Input() state: LevelsStoreState;
  @Output() saveState: EventEmitter<LevelModel> = new EventEmitter<LevelModel>();

  constructor() {
  	super();
  	this.configureForm(LevelFormBuilder.levelFormInitializer());
  }

  valueChange(): void {
  	this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value: LevelModel) => this.saveState.emit(value));
  }

}
