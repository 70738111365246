import { ProductTreeList } from '../../graphql';
import { MultiLanguages } from '../../shared.model';

export class ProductTreeModel implements ProductTreeList {
  id: string;
  packageName: MultiLanguages;
  images: any;

  constructor() {
  	this.id = null;
  	this.packageName = new MultiLanguages();
  }
}
