import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PascalToUppercasePipe } from './pipe/pascal-to-uppercase.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        PascalToUppercasePipe
    ],
    providers: [PascalToUppercasePipe],
    exports: [PascalToUppercasePipe]
})
export class SharedPipePascalToUppercaseModule {}
