import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {Image} from '@ew/shared/services';
import {SharedHelper} from '@ew/shared/helper';

@Component({
    selector: 'ew-image-picker',
    templateUrl: './image-picker.component.html',
    styleUrls: ['./image-picker.component.scss']
})

export class ImagePickerComponent {

	pickedFiles: File[] = [];
	maxFileSizeLimitValue = 1000000;

	/**
	 * Key: index
	 * Value: complete or no
	 */
	fileUploadProgress: Record<number, boolean> = {};

	/**
	 * File size limit in bytes.
	 */
	@Input() maxFileSizeLimit = this.maxFileSizeLimitValue;

	@Input() label = 'LABEL_UPLOAD_FILE';
	@Input() hint = 'HINT_UPLOAD_FILE';
	@Input() uploadIcon = 'upload';

	@Input() uploadFileFn: (file: File) => Observable<Image>;

	/**
	 * Image urls to preview. Example use case: preview already-uploaded images which were not picked in this instance.
	 */
	@Input() previewImageUrls: (string | ArrayBuffer)[] = [];

	/**
	 * Set true to allow picking multiple images.
	 */
	@Input() pickMultiple = false;

	/**
	 * Unique file type specifier. Defaults to all files.
	 * Examples: `image/*` OR `.doc` OR `image/jpeg, application/msword`
	 */
	@Input() acceptedFileTypes = 'image/*';

	@Input() isRowLayout: boolean;

	/**
	 * Emits files on any files changes (remove and add).
	 * Always emits an array of `File` regardless of `pickMultiple` value.
	 */
	@Output() filesChanged: EventEmitter<File[]> = new EventEmitter<File[]>();

	/**
	 * Emits files after the user picks files, but only emits the files the user picked currently (ignoring previously picked files).
	 */
	@Output() currentFilesChanged: EventEmitter<File[]> = new EventEmitter<File[]>();

	/**
	 * Emits index of removed file.
	 */
	@Output() fileRemoved: EventEmitter<number> = new EventEmitter<number>();

	constructor(private cd: ChangeDetectorRef) {
	}

	pickFiles(files: FileList): void {
	    if (!this.pickMultiple) {
	        this.pickedFiles.length = 0;
	        this.previewImageUrls.length = 0;
	    }
	    const filesArray: File[] = Array.from(files) || [];

	    if (this.uploadFileFn) {
	        let fileIndex: number;
	        filesArray.forEach((file, index) => {
	            fileIndex = this.previewImageUrls.length - 1 + index;
	            this.fileUploadProgress[fileIndex] = true;
	        });
	        forkJoin(filesArray.map(file => this.uploadFileFn(file))).subscribe((progresses) => {
	            progresses.forEach((progressEvent, index) => {
	                SharedHelper.readFileDataUrl(files[index], (url) => {
	                    this.previewImageUrls.push(url);
	                    this.fileUploadProgress[fileIndex] = false;
	                    this.cd.markForCheck();
	                });
	            });
	        });
	    } else {
	        this.pickedFiles.push(...filesArray);
	        this.currentFilesChanged.emit(filesArray);
	        filesArray.forEach(file => {
	            SharedHelper.readFileDataUrl(file, (dataUrl => {
	                this.previewImageUrls.push(dataUrl);
	                this.cd.markForCheck();
	            }));
	        });
	        this.emitFileChanges();
	    }
	}

	removeFileAt(index: number): void {
	    this.fileRemoved.emit(index);
	    this.pickedFiles.splice(this.previewImageUrls.length - this.pickedFiles.length + index, 1);
	    this.previewImageUrls.splice(index, 1);
	    this.emitFileChanges();
	    this.cd.detectChanges();
	}

	private emitFileChanges(): void {
	    this.filesChanged.emit(this.pickedFiles);
	}
}
