import { ComponentRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { ComponentType, GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { DialogRef } from './dialog-ref';
import { SfDialogConfig } from './dialog-config';
import { ConfirmDialogData, SfConfirmationComponent } from './confirmation/confirmation.component';
import { DialogContainer } from './dialog-container/dialog-container.component';

@Injectable()
export class SfDialogService {
  constructor(private overlay: Overlay, private injector: Injector) {
  }

  open<T = any, R = any>(content: TemplateRef<any> | ComponentType<any>,
                         config?: Partial<SfDialogConfig<T>>): DialogRef<T, R> {

    const dialogConfig = { ...new SfDialogConfig(), ...config };
    const overlayRef = this.createOverlay(dialogConfig);
    const dialogContainer = this._attachDialogContainer(overlayRef, dialogConfig);
    return this._attachDialogContent<T, R>(content, dialogContainer, overlayRef, dialogConfig);
  }

  openConfirmDialog<T extends ConfirmDialogData>(data: T) {
    return this.open(SfConfirmationComponent,{data});
  }

  protected _attachDialogContainer(overlayRef: OverlayRef, config: Partial<SfDialogConfig>): DialogContainer {
    const userInjector = config && config.injector;
    const injector = new PortalInjector(userInjector || this.injector,
      new WeakMap([[SfDialogConfig, config]]));
    const containerPortal = new ComponentPortal(DialogContainer, config.viewContainerRef, injector);
    const containerRef: ComponentRef<DialogContainer> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  protected createPositionStrategy(): GlobalPositionStrategy {
    return this.overlay.position()
      .global()
      .centerVertically()
      .centerHorizontally();
  }

  private _attachDialogContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogContainer: DialogContainer,
    overlayRef: OverlayRef,
    config: Partial<SfDialogConfig>): DialogRef<T, R> {

    // Create a reference to the dialog we're creating in order to give the user a handle
    // to modify and close it.
    const dialogRef = new DialogRef<T, R>(overlayRef, dialogContainer, config.id);

    // When the dialog backdrop is clicked, we want to close it.
    if (config.hasBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        if (!dialogRef.disableClose) {
          dialogRef.close();
        }
      });
    }

    if (componentOrTemplateRef instanceof TemplateRef) {
      dialogContainer.attachTemplatePortal(
        new TemplatePortal(componentOrTemplateRef, config.viewContainerRef!, <any>{
          $implicit: config.data,
          dialogRef
        }));
    } else {
      const injector = this.createInjector(config, dialogRef, dialogContainer);
      const contentRef = dialogContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, undefined, injector, config.resolver));
      dialogRef.componentInstance = contentRef.instance;
    }
    return dialogRef;
  }

  private createInjector<T>(
    config: Partial<SfDialogConfig>, dialogRef: DialogRef<T>, dialogContainer: DialogContainer): PortalInjector {
    const userInjector = config && config.injector;
    const injectionTokens = new WeakMap<any, any>([
      [DialogRef, dialogRef],
      [SfDialogConfig, config]
    ]);
    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }

  private getOverlayConfig(dialogConfig: SfDialogConfig): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.createPositionStrategy(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      panelClass: dialogConfig.panelClass,
      hasBackdrop: dialogConfig.hasBackdrop,
      minWidth: dialogConfig.minWidth,
      width: dialogConfig.width,
      maxWidth: dialogConfig.maxWidth,
      minHeight: dialogConfig.minHeight,
      maxHeight: dialogConfig.maxHeight,
      height: dialogConfig.height,
      disposeOnNavigation: true
    });

    if (dialogConfig.backdropClass) {
      overlayConfig.backdropClass = dialogConfig.backdropClass;
    }
    return overlayConfig;
  }

  private createOverlay(config: SfDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }
}
