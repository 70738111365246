import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonationModeComponent } from './impersonation-mode/impersonation-mode.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SfButtonModule, SfIconModule} from '@ew/shared/safire/components';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports:
        [CommonModule,
            FlexLayoutModule,
            SfIconModule,
            SfButtonModule,
            TranslateModule],
    declarations: [
        ImpersonationModeComponent
    ],

    exports: [
        ImpersonationModeComponent
    ]
})
export class SharedComponentsImpersonationModeModule {}
