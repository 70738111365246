import {Injectable} from '@angular/core';
import {ObservableStore} from '@codewithdan/observable-store';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BaseStateService<T> extends ObservableStore<T> {
    constructor() {
        super({trackStateHistory: true, logStateChanges: false});
    }

    specificStateChange<U>(stateKey: string, allowFilter: boolean = true): Observable<U> {
        return this.stateWithPropertyChanges.pipe(
            filter(stateChange => !allowFilter || (allowFilter && !!stateChange.stateChanges[stateKey])),
            map(stateChange => stateChange.stateChanges[stateKey]));
    }

    updateSpecificState<U>(data: U, stateKey: string): T {
        return this.setState({[stateKey]: data} as unknown as Partial<T>, 'UPDATE_' + stateKey.toUpperCase());
    }

    getSpecificState = <U>(state?: string): U => state ? this.getState()[state] : this.getState();
}
