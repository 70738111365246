import {DeliveryTimeModel, MultiLanguages, Package, PreorderModel, StepConfig} from '@ew/shared/services';

import {OtherDetailsStepComponent} from '../components/stepper/steps/other-details-step/other-details-step.component';
import {
    PricingDetailsStepComponent,
} from '../components/stepper/steps/pricing-details-step/pricing-details-step.component';
import {
    ProductDetailsStepComponent,
} from '../components/stepper/steps/product-details-step/product-details-step.component';

class ProductDetailsStep implements Partial<Package> {
    name = new MultiLanguages();
    images = [];
    imageIds = [];
    dynamicValues = [];
}

class ProductOtherDetailsStep implements Partial<Package> {
    deliveryTime = new DeliveryTimeModel();
    preorder = new PreorderModel();
    packageContractDurations = [];
}

class ProductPricingStep implements Partial<Package> {
    configuratorPriceTypeId = undefined;
}

export const PC_COMPONENT_CONFIG: StepConfig[] = [
    {
        index: 0,
        label: 'LABEL_PRODUCT_DETAILS',
        component: ProductDetailsStepComponent,
        name: 'product_details',
        formSchema: {
            formModel: ProductDetailsStep,
        },
        skip: false,
        isExpanded: true,
        showStepper: true,
        valid: false,
    },
    {
        index: 1,
        label: 'LABEL_OTHER_DETAILS',
        component: OtherDetailsStepComponent,
        formSchema: {
            formModel: ProductOtherDetailsStep,
            skipRequiredValidators: ['preorder.id', 'deliveryTime.id'],
        },
        name: 'other_details',
        skip: false,
        isExpanded: false,
        showStepper: false,
        valid: false,
    },
    {
        index: 2,
        label: 'LABEL_PRICING_DETAILS',
        component: PricingDetailsStepComponent,
        formSchema: {
            formModel: ProductPricingStep,
        },
        name: 'pricing_details',
        skip: false,
        isExpanded: false,
        showStepper: false,
        valid: false,
    },
    // [REVISIT: Neeraj 02/10/2021] Removed due to BA's request
    // {
    //   index: 3,
    //   label: 'LABEL_OPTION_SET',
    //   component: OptionSetStepComponent,
    //   formSchema: {
    //     formModel: ProductOptionSetsStep,
    //     skipRequiredValidators: ['dynamicColumns'],
    //   },
    //   name: 'option_details',
    //   skip: true,
    //   showStepper: false,
    //   isExpanded: false,
    //   valid: false,
    // },
];
