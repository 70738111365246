<div title fxLayoutAlign.xs="start center">
  <sf-icon fxHide.gt-xs class="s-16 cursor--pointer mr-12 color--black-60" data-cy="back" (click)="cancel()">arrow_back_ios_new</sf-icon>
  <h6 sfHeadline6 class="my-20">{{data?.title | translate}}</h6>
</div>

<sf-dialog-content>
  <p sfSubtitle2 class="mb-12">{{data?.message | translate}}</p>
<!--  REVISIT_DAKPA: Aug 27, 2021 - List existing children of package and warn about deletion because of it -->
<!--  <ng-container *ngIf="data.actionType === 'delete'">-->
<!--    <p sfCaption class="my-12 delete-category__warning">{{'HINT_DELETE_CATEGORY' | translate}}</p>-->
<!--    <div fxLayout="row" fxLayoutAlign="start center" class="product p-12 mb-16">-->
<!--      <img src="https://images.selise.club/ui-assets/images/icons/mobile_icon.svg" alt="product preview"-->
<!--           class="product__image" />-->
<!--      <span class="color&#45;&#45;black-black-87 ml-8">Mobile</span>-->
<!--    </div>-->
<!--  </ng-container>-->
</sf-dialog-content>
<sf-dialog-action fxLayoutAlign="end" ngClass.gt-xs="pb-20">
  <button sf-button outlined ngClass.xs="width--full mb-12" (click)="cancel()" data-cy="Cancel"> {{'BTN_CANCEL' | translate}}</button>
  <button sf-button primary ngClass.xs="width--full" (click)="appendAction()" ngClass.gt-xs="ml-12" data-cy="update">{{data?.button | translate}}</button>
</sf-dialog-action>
