import {Component, Input, OnInit} from '@angular/core';
import { DynamicField } from '../../models/dynamic-field';
import { DynamicColumn, MultiLanguages } from '@ew/shared/services';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ew-dynamic-string-field',
    templateUrl: './dynamic-string-field.component.html',
    styleUrls: ['./dynamic-string-field.component.scss']
})
export class DynamicStringFieldComponent implements DynamicField, OnInit {
  readonly langKeys: string[] = Object.keys(new MultiLanguages());
  isFieldDisabled: boolean;

  @Input() activeLangKey: string;
  @Input() dynamicColumn: DynamicColumn;
  @Input() dynamicValueFormGroup: FormGroup;

  ngOnInit(): void {
  	this.isFieldDisabled = this.dynamicColumn.name[this.activeLangKey] === 'IDMS ID' &&
        !!this.dynamicValueFormGroup.get('value').value;
  }
}
