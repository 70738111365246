import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

import {SfRippleService} from '../../../ripple/src/index';
import {SfIconComponent} from '../../../icon/src/index';

@Directive({
  selector: 'sf-fab-label, [sfFabLabel]',
  host: {'class': 'mdc-fab__label'}
})
export class SfFabLabel {}

@Component({
  selector: 'button[sf-fab], a[sf-fab]',
  host: {
    '[attr.tabindex]': 'exited ? -1 : 0',
    'class': 'mdc-fab',
    '[class.sf-fab--primary]': 'primary',
    '[class.sf-fab--secondary]': 'secondary',
    '[class.mdc-fab--mini]': 'mini',
    '[class.mdc-fab--exited]': 'exited',
    '[class.mdc-fab--extended]': 'extended',
    '[class.sf-fab-extended--fluid]': 'fluid'
  },
  template: `
  <ng-content></ng-content>
  <sf-icon class="mdc-fab__icon" *ngIf="icon">{{icon}}</sf-icon>
  <span class="mdc-fab__label" *ngIf="label">{{label}}</span>
  `,
  providers: [SfRippleService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfFabComponent implements AfterContentInit, OnDestroy {
  private _primary = false;

  @Input()
  get primary(): boolean {
    return this._primary;
  }

  set primary(value: boolean) {
    this._primary = coerceBooleanProperty(value);
  }


  private _secondary = false;

  @Input()
  get secondary(): boolean {
    return this._secondary;
  }

  set secondary(value: boolean) {
    this._secondary = coerceBooleanProperty(value);
  }

  @Input()
  get mini(): boolean {
    return this._mini;
  }
  set mini(value: boolean) {
    this._mini = coerceBooleanProperty(value);
  }
  private _mini = false;

  @Input()
  get exited(): boolean {
    return this._exited;
  }
  set exited(value: boolean) {
    this._exited = coerceBooleanProperty(value);
    this._changeDetectionRef.markForCheck();
  }
  private _exited = false;

  @Input()
  get extended(): boolean {
    return this._extended;
  }
  set extended(value: boolean) {
    this._extended = coerceBooleanProperty(value);
  }
  private _extended = false;

  @Input()
  get fluid(): boolean {
    return this._fluid;
  }
  set fluid(value: boolean) {
    this._fluid = coerceBooleanProperty(value);
  }
  private _fluid = false;

  @Input()
  get position(): string | null {
    return this._position;
  }
  set position(value: string | null) {
    if (this._position) {
      this._getHostElement().classList.remove(`sf-mdc-fab--${this._convertPosition(this._position)}`);
    }
    if (value) {
      this._getHostElement().classList.add(`sf-mdc-fab--${this._convertPosition(value)}`);
    }
    this._position = value;
  }
  private _position: string | null = null;

  @Input() label?: string;
  @Input() icon?: string;

  @ContentChild(SfIconComponent, {static: false}) fabIcon!: SfIconComponent;

  constructor(
    private _changeDetectionRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    private _ripple: SfRippleService) {}

  ngAfterContentInit(): void {
    if (this.fabIcon) {
      this.fabIcon.elementRef.nativeElement.classList.add('mdc-fab__icon');
    }
    this._ripple = new SfRippleService(this.elementRef);
    this._ripple.init();
  }

  private _convertPosition(position: string): string | null {
    switch (position) {
      case 'bottomLeft': {
        return 'bottom-left';
      }
      case 'bottomRight': {
        return 'bottom-right';
      }
      default: {
        return null;
      }
    }
  }

  ngOnDestroy(): void {
    this._ripple.destroy();
  }

  toggleExited(exited?: boolean): void {
    this._exited = exited ? exited : !this._exited;
  }

  /** Focuses the button. */
  focus(): void {
    this._getHostElement().focus();
  }

  private _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
