import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    LevelModel,
    LevelTypeEnum,
    ProductsStoreState,
    ProductStateEnum,
    ProductTree,
    ProductTreesQueryModel
} from '@ew/shared/services';
import {Observable} from 'rxjs';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';

import {ProductFacadeService} from '../../../services/product-facade.service';

@UntilDestroy()
@Component({
    selector: 'ew-level-resolver',
    templateUrl: './level-resolver.component.html',
    styleUrls: ['./level-resolver.component.scss']
})
export class LevelResolverComponent implements OnInit {
    readonly levelType: typeof LevelTypeEnum = LevelTypeEnum;
    currentProductAncestry: string;
    currentProductId: string;
    currentAncestryDepth: number;
    currentProduct: ProductTree;
    currentLevel: LevelModel;
    productLevelState$: Observable<ProductsStoreState> = this.productFacadeService.stateChange().pipe(
        untilDestroyed(this),
        debounceTime(200));

    constructor(private activateRoute: ActivatedRoute, private productFacadeService: ProductFacadeService) {
    }

    ngOnInit(): void {
    	this.productFacadeService.initialize();
    	this.getAllConfig();
    	this.handleOnProductTreesStateChanges();
    }

    private handleOnProductTreesStateChanges(): void {
    	this.productFacadeService
    		.specificStateChange(ProductStateEnum.PRODUCT_TREES_QUERY)
    		.pipe(
    			debounceTime(200),
    			tap(() => this.fetchProductTrees()),
    			untilDestroyed(this)
    		)
    		.subscribe();
    }

    getAllConfig(): void {
    	this.getProductId()
    		.pipe(
    			switchMap(() => this.getProductDetailById()),
    			switchMap(() => this.getLevels()),
    			untilDestroyed(this)
    		)
    		.subscribe();
    }

    getProductId(): Observable<ParamMap> {
    	return this.activateRoute.paramMap.pipe(
    		untilDestroyed(this),
    		tap((res) => {
    			this.currentProductId = res?.['params']?.id;
    		})
    	);
    }

    getProductDetailById(): Observable<ProductTree> {
    	return this.productFacadeService.getProductTreeById(this.currentProductId).pipe(
    		filter((res) => !!res),
    		tap((response) => {
    			this.getProductDetail(response);
    		})
    	);
    }

    getProductDetail(product: ProductTree): void {
    	this.setProductAncestry(product);
    	this.currentProduct = product;
    }

    setProductAncestry(product: ProductTree): void {
    	this.setCurrentProductAncestry(
    		['0', undefined, null].includes(product.ancestry)
    			? this.currentProductId?.toString() : product.ancestry + '/' + this.currentProductId
    	);
    	this.currentAncestryDepth = +product?.ancestryDepth + 1;
    	const productTreesQueryState: ProductTreesQueryModel = {
    		...this.productFacadeService.getSpecificState(ProductStateEnum.PRODUCT_TREES_QUERY),
    		ancestry: this.currentProductAncestry,
    		ancestryDepth: this.currentAncestryDepth
    	};
    	this.productFacadeService.updateSpecificState(productTreesQueryState, ProductStateEnum.PRODUCT_TREES_QUERY);
    }

    setCurrentProductAncestry(path: string): void {
    	this.currentProductAncestry = path;
    }

    getLevels(): Observable<LevelModel[]> {
    	this.productFacadeService.initializeState();
    	return this.productFacadeService.getConfiguration().pipe(
    		switchMap(() => this.productFacadeService.getLevels()),
    		tap((response) => {
    			this.checkWhichComponentToLoad(response);
    		})
    	);
    }

    checkWhichComponentToLoad(levels: LevelModel[]): void {
    	this.currentLevel = levels[this.currentProduct?.ancestryDepth + this.checkLevelSetting()];
    	if (this.currentLevel) {
    		this.productFacadeService.setCurrentLevel(this.currentLevel?.id).subscribe(
                () => this.fetchProductTrees());
    	}
    }

    checkLevelSetting(): number {
    	return this.currentProductId && +(this.currentProduct.ancestryDepth != null);
    }

    fetchProductTrees(): void {
    	this.productFacadeService.getProductTressByAncestryData(
    		this.currentProductAncestry,
    		this.currentLevel?.rank || this.currentProduct.ancestryDepth || 0
    	);
    }
}
