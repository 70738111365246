import {Injectable} from '@angular/core';
import {CanActivate, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SecurityFacadeService} from '@ew/shared/security';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SecurityStoreEnum} from '../../../../security/src/lib/models/security-store.state';
import {mapTo} from 'rxjs/operators';
import {Permission} from '@ew/shared/services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private securityFacade: SecurityFacadeService) {
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const matrices = this.securityFacade.getSpecificState<Permission[]>(SecurityStoreEnum.ACCESS_MATRICES)
        return matrices ? true : this.securityFacade.getPermissionMatrices().pipe(mapTo(true))
    }
}
