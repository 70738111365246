import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonModalComponent} from './common-modal/common-modal.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SfDialogModule, SfTypographyModule} from '@ew/shared/safire/components';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, SfDialogModule, SfTypographyModule],
    declarations: [
        CommonModalComponent
    ],
    exports: [
        CommonModalComponent
    ]
})
export class SharedComponentsCommonModalModule {
}
