import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {get} from 'lodash-es';
import {catchError, tap} from 'rxjs/operators';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { HttpStatusCodes, NotificationService } from '@ew/shared/services';

@Injectable()
export class ErrorGraphqlInterceptor implements HttpInterceptor {

    constructor(private router: Router, private notify: NotificationService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                tap((event: HttpEvent<unknown>) => {
                    this.handleResponse(event);
                }),
                catchError((err: HttpErrorResponse) => {
                    this.handleErrorResponse(err);
                    if (this.router.url.includes('/auth') && (
                        err.status === HttpStatusCodes.UNPROCESSABLE_CONTENT 
                         || err.status === HttpStatusCodes.BAD_REQUEST )) {
                        return of(err as unknown as HttpEvent<unknown>)
                    }
                    return throwError(err)
                })
            );
    }

    handleResponse(event: HttpEvent<unknown>): void {
        if (event instanceof HttpResponse) {
            if (event.status >= 200 && event.status <= 400 && get(event, 'body.errors.length') && event.url.includes('graphql')) {
                this.handleExtensionError(event, get(event, 'body.errors').map(error => error?.extensions));
            }
        }
    }

    handleExtensionError(event: HttpEvent<unknown>, extension: { code: string }[]): void {
        const err = extension.filter(error => error?.code === 'API_ACCESS_ERROR').length;
        if (err && this.router.url.includes('/auth')) {
            return;
        }
        if (err) {
            this.router.navigate(['/auth/login']);
            return;
        }
        const messages: string[] = get(event, 'body.errors').map(error => error?.message || error);
        messages && this.notify.show(messages, 'error');
    }

    handleErrorResponse(err: HttpErrorResponse): Promise<boolean> | void {
        if (err?.error?.redirect_to) return this.router.navigate(['/auth/login/otp']);
        const {error: {error: innerError, errors}, message} = err;
        if (err.url.includes('sign_in')) return;
        if (errors?.length) {
            this.displayError(errors[0]);
            return;
        }
        if (innerError) {
            this.displayError(innerError);
            return;
        }
        if (message) {
            this.displayError(message);
            return;
        }
    }

    displayError = (err: string): void => this.notify.beSnackBar(err, 'error');
}
