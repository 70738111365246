import {Component, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {filter, distinctUntilChanged, tap} from 'rxjs/operators';
import {NavigationStart, Router } from '@angular/router';
import { GAConsentPermissionEnum, GoogleAnalyticsEcommerceEventType, SharedFacadeService,
    SharedStoreStateEnum, PushNotificaionService,
    SubscriptionTypeEnum, User } from '@ew/shared/services';
import { CookieService } from 'ngx-cookie-service';

@UntilDestroy()
@Component({
    selector: 'ew-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    updateAvailable = false;
    isOnline: boolean;
    displayCookieBar!: boolean;

    constructor(private router: Router,
                public switcher : TranslateService,
                private swUpdate: SwUpdate,
                private translate: TranslateService,
                private titleService: Title,
                private notificationService: PushNotificaionService,
                private sharedFacadeService: SharedFacadeService,
                private cookieService: CookieService
    ) {
    }

    ngOnInit(): void {
        this.initializer();
        this.handleRouteEvents();
        this.isOnline = window?.navigator?.onLine;
        this.listenToUserProfileChange();
        this.sharedFacadeService.specificStateChange<boolean>(SharedStoreStateEnum.COOKIE_BAR_MODAL_DISPLAY).pipe(
            untilDestroyed(this),
            tap((value: boolean) => this.displayCookieBar = value)
        ).subscribe();        
        this.pushNotificationServices();
    }

    pushNotificationServices(): void {
        this.listenToUserChange();
        this.notificationService.receiveMessage();
        this.sharedFacadeService.getNotificationsCount().subscribe();
        this.listenToLanguageChange();
    }

    listenToUserChange(): void {
        this.sharedFacadeService.specificStateChange<User>(SharedStoreStateEnum.PROFILE_STATE).pipe(
            untilDestroyed(this),
            distinctUntilChanged(),
            tap(userDetail => {
                if ((userDetail?.role?.name.toLocaleLowerCase()) === 'user' && !userDetail?.impersonated) {
                    this.notificationService.listenToTokenValidaty();
                    this.checkMobileFCM();
                }
            })
        ).subscribe();
        this.sharedFacadeService.getUserDetails().subscribe();
    }

    checkMobileFCM(): void {
        //use this to get mobile FCM token navigator.userAgent.split('FCM:')[1]
        const mobileFCMToken = navigator.userAgent.split('FCM:')[1];
        !!mobileFCMToken && this.notificationService.sendRegistrationFirebaseToken({mobileFCMToken, isMobileFCMToken: true});
    }

    initializer(): void {
        this.titleService.setTitle(this.translate.instant('LABEL_PORTAL_TITLE'));
    	this.swUpdate.available.pipe(untilDestroyed(this)).subscribe(() => {
    		this.updateAvailable = true;
    	});
        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            (event instanceof NavigationStart) && (this.swUpdate.isEnabled) && this.swUpdate.checkForUpdate()});
    }

    listenToLanguageChange(): void {
        this.switcher.onLangChange.pipe(untilDestroyed(this),
            tap(() => this.titleService.setTitle( this.translate.instant('LABEL_PORTAL_TITLE')))
        ).subscribe();
    }

    listenToUserProfileChange(): void {
        this.sharedFacadeService.specificStateChange<User>(SharedStoreStateEnum.PROFILE_STATE).pipe(
            untilDestroyed(this),
            tap(user => {
                if ((user?.role?.name === 'user') && !user?.impersonated ) {
                    //To Check if cookie consent present or not in order to see whether cookiebar modal should be displayed or not
                    this.checkAnalyticsCookiePresentOrNot();
                    //Don't send Custom Data being sent to GA4 if GA cookie is not accepted
                    this.checkGoogleAnalyticsPermissionGranted && this.sendLoginDataToAnalytics(user);
                }
            }),
        ).subscribe();
    }

    //Value Emitted from cookie preference modal to reopen the cookie modal
    cookieBarEmittedValue(event: boolean): void {
        this.displayCookieBar = event;
    }

    checkAnalyticsCookiePresentOrNot(): void {
        const cookieConsentFormAccepted = this.cookieService.get('_cookie_consent_accepted');
        const isCurrentAuthPath = this.containsAuthRoute;
        if (!cookieConsentFormAccepted && !isCurrentAuthPath) {
            //display cookie bar by removing hide visibility css
            window.document.getElementById('cookie-bar-container')?.classList.remove('hide-visibility');
            //Since it gets updated when you click save preference in cookie preference modal so to revert it if cookie consent not accepted
            this.sharedFacadeService.updateGoogleAnalyticsConsentData(GAConsentPermissionEnum.denied);
            //to display cookie modal if no cookie consent cookie value
            this.displayCookieBar = true;
        }
    }

    sendLoginDataToAnalytics(user: User): void {
        if (this.cookieService.get('_cookie_consent_accepted')) {
            gtag('event', 'login', { user_id: user?.id });
            this.setUserDetailsInGA4(user);
            this.sentEcommercePurchaseDataToAnalytics(user);
        }
    }

    //TODO: Refactor in the Future
    setUserDetailsInGA4(user: User): void {
        const subscribePackages = user?.subscribedPackages?.map(res => res?.packageName);
        gtag('set', 'user_properties', {
            client_id: user?.clientNumber || 'Data Not Found',
            username: `${user?.profile?.firstName} ${user?.profile?.lastName}` || 'Data Not Found',
            date_of_birth: user?.profile?.dateOfBirth || 'Data Not Found',
            user_id: user?.id || 'Data Not Found',
            subscribed_packages: subscribePackages.length ? subscribePackages : 'Data Not Found'
        });
    }

    sentEcommercePurchaseDataToAnalytics(user: User): void {
        //Sending analytics data for both old subcription packages and new subscription packages
        [SubscriptionTypeEnum.oldSubscribedPackages, SubscriptionTypeEnum.subscribedPackages].forEach(subscriptionType => {
            const currentSubscribedPackages = this.sharedFacadeService.mapGoogleAnalyticsEcommerceEvent(user, subscriptionType);
            currentSubscribedPackages?.length && gtag('event', 'purchase',
                {transaction_id: this.sharedFacadeService.getTransactionId(user, subscriptionType),
                    value: this.sharedFacadeService.getTotalRevenue(user, 
                        subscriptionType), currency: 'CHF', items: currentSubscribedPackages});
            //change the status of that package so that next time it wont go to analytics
            currentSubscribedPackages?.length && this.changeValueWhenSentToAnalytics(currentSubscribedPackages, subscriptionType);
        });
    }

    changeValueWhenSentToAnalytics(packages: GoogleAnalyticsEcommerceEventType[], type: SubscriptionTypeEnum): void {
        const subscribedPackagesIds = packages?.map(pack => pack?.package_id);
        this.sharedFacadeService?.[type === SubscriptionTypeEnum.subscribedPackages ? 'sentCurrentSubscriptionPackagesToAnalytics' :
            'sentOldSubscriptionPackagesToAnalytics']?.({packageIds: subscribedPackagesIds}).subscribe();
    }

    update(): void {
    	this.updateAvailable = false;
    	window.location.reload();
    }
    reload(): void {
        window.location.reload();
    }

    //Send Router URL to Google Analytics
    handleRouteEvents(): void {
        this.router.events.pipe(untilDestroyed(this), filter((event) => event instanceof NavigationStart)).subscribe(() => {
            const routeName = this.router?.routerState?.snapshot?.url;
            const containsAuthRoute = this.containsAuthRoute;
            const userDetails = this.sharedFacadeService.getSpecificState<User>(SharedStoreStateEnum.PROFILE_STATE);
            !containsAuthRoute && (userDetails?.role?.name === 'user') && gtag('event', 'page_view', {
                page_title: routeName,
                user_id: userDetails?.id
            });
        });
    }

    private get checkGoogleAnalyticsPermissionGranted(): boolean {
        return this.cookieService.get('_google_analytics_accepted') === GAConsentPermissionEnum.granted;
    };

    private get containsAuthRoute(): boolean {
        const routeName = this.router?.routerState?.snapshot?.url;
        return ['auth', 'login', 'register', 'otp'].some(res => routeName.includes(res));
    }
}
