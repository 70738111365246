import {Injectable, Inject, InjectionToken, ErrorHandler} from '@angular/core';
import * as Rollbar from 'rollbar';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '@ew/env/environment'

const rollBarConfig: Rollbar.Configuration = {
    accessToken: environment.rollBarApiKey,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: environment.rollBarEnv
    }
};

export const rollBarFactory = (): Rollbar => new Rollbar(rollBarConfig);
export const RollBarService: InjectionToken<Rollbar> = new InjectionToken<Rollbar>('rollbar');

@Injectable({
    providedIn: 'root'
})
export class RollbarErrorHandlerService implements ErrorHandler {
    constructor(@Inject(RollBarService) private rollbar: Rollbar) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(err: any): void {
        //hide the display of error in console if the error.Error type is undefined
        if (err.Error || err?.originalError) {
            environment?.rollBarEnable ? this.rollbar?.error(err?.originalError || err.Error) : console?.error(err.Error);    
        }
    }
}
