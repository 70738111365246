import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationLandingComponent } from './notification-landing/notification-landing.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    SfButtonModule,
    SfChipsModule,
    SfDialogModule,
    SfFormsModule,
    SfIconModule,
    SfInputModule,
    SfRadioModule,
    SfSwitchModule
} from '@ew/shared/safire/components';
import { TranslateModule } from '@ngx-translate/core';
import { SharedPipeAmountOfTimePassedModule } from '@ew/shared/pipe/amount-of-time-passed';
import { NotificationSettingComponent } from './notification-landing/notification-setting/notification-setting.component';
import { SharedComponentsLanguageSwitcherModule}  from '@ew/shared/components/language-switcher';
import { ReactiveFormsModule } from '@angular/forms';
import { AddNotificationEmailComponent } from './notification-landing/add-notification-email/add-notification-email.component';
import { SharedComponentsCommonModalModule } from '@ew/shared/components/common-modal';
import {SharedComponentsOneTimePasswordModule} from '@ew/shared/components/one-time-password';
import {SharedPipeTranslateMonthModule} from '@ew/shared/pipe/translate-month';
import {SfAngularUtilsModule} from '@ew/shared/safire/angular-utils';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        SfChipsModule,
        TranslateModule,
        SfIconModule,
        SharedPipeAmountOfTimePassedModule,
        SfButtonModule,
        SfFormsModule,
        SfSwitchModule,
        SfRadioModule,
        SfDialogModule,
        SharedComponentsLanguageSwitcherModule,
        ReactiveFormsModule,
        SfInputModule,
        SharedComponentsCommonModalModule,
        SharedComponentsOneTimePasswordModule,
        SharedPipeTranslateMonthModule
    ],
    declarations: [
        NotificationLandingComponent,
        NotificationSettingComponent,
        AddNotificationEmailComponent,
    ],
    exports: [
        NotificationLandingComponent
    ]
})
export class SharedComponentsNotificationModule {}
