<sf-card fxLayout="row wrap" class="service-details p-24 my-24" sfElevation="0">
  <label fxLayoutAlign="center center" class="service-details__icon mr-16">
    <img *ngIf="(currentProduct?.package?.images && currentProduct?.package?.images[0]?.urls[0])" [src]="currentProduct?.package.images[0]?.urls[0]" class="" alt="package_image">
    <sf-icon *ngIf="!(currentProduct?.package?.images && currentProduct?.package?.images[0]?.urls[0])">dashboard_customize</sf-icon>
  </label>

  <div fxFlex.lt-sm="65">
    <p sfBody1 class="bold">{{currentProduct?.package.name | translateName}}</p>
    <div fxLayout="row wrap">
    <p class="color--black-60 mr-12">{{((currentProduct?.package.name | translateName) + ' category') | titlecase}}</p>
    <sf-chip class="service-details__list mr-8" [ngClass]="currentProduct?.active ? 'active' : 'inactive'">
      {{(currentProduct?.active ? 'LABEL_ACTIVE' : 'LABEL_INACTIVE') | translate}}
    </sf-chip>
  </div>
  </div>

  <p sfSubtitle2 fxFlex="100" class="mt-24">
    {{currentProduct?.package | translateName}}
  </p>
</sf-card>

<sf-card class="package-list" ngClass.gt-xs="p-24" ngClass.xs="px-24 pt-4 pb-24" sfElevation="0">
  <ew-search-filter
    [currentProduct]="currentProduct"
    [currentLevel]="currentLevel"
    [currentAncestryDepth]="currentAncestryDepth"
    [currentProductAncestry]="currentProductAncestry"
    [buttonMenu]="'BTN_ADD_PACKAGE'"
    [queryForm]="form"
    (gridViewChange)="productTreesViewMode = $event"
    [shouldDisplayCheckboxFilter] = "true"  
  >
  </ew-search-filter>
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="12px" class="my-40"
        *ngIf="!currentProductTreesConnection?.collection?.length">
    <div fxFlex.gt-xs="50" fxLayoutAlign="center">
      <img src="/assets/images/no-data.svg" alt="no data" />
    </div>
    <div fxFlex.gt-xs="55" class="text--center">
      <h6 sfHeadline6 class="bold color--black-87">
        {{'LABEL_NOT_ADDED_PACKAGES' | translate}}
      </h6>
      <p sfSubtitle2 class="mt-8 color--black-60">
        {{'HINT_NOT_ADDED_PACKAGES' | translate}}
      </p>
    </div>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="mt-24" *ngIf="currentProductTreesConnection?.collection?.length">
      <ew-package-list
        [viewMode]='productTreesViewMode' [showAllData]="true"
        [subProductTreesConnection]='currentProductTreesConnection'
        [productTree]="currentProduct"
        [level]='currentLevel'
        [currentProductAncestry]="currentProductAncestry"
        [currentAncestryDepth]="currentAncestryDepth"
      ></ew-package-list>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div class="mt-24">
        <img fxHide.lt-md src="/assets/images/pc-loader.svg" alt="ticketing loader" class="pc-loader" />
        <img fxHide.gt-sm src="/assets/images/pc-loader-mobile.svg" alt="ticketing loader" class="pc-loader" />
    </div>
  </ng-container>
</sf-card>

