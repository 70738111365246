import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Observable, Subject } from 'rxjs';
import { SfSnackBarConfig } from '../snack-bar-config';
import { AnimationEvent } from '@angular/animations';
import { take } from 'rxjs/operators';
import { sfSnackBarAnimations } from '../snack-bar-animations';

@Component({
  selector: 'sf-snack-bar-container',
  templateUrl: './snack-bar-container.component.html',
  styleUrls: ['./snack-bar-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [sfSnackBarAnimations.snackBarState],
  host: {
    '[attr.role]': 'role',
    class: 'sf-snack-bar__container',
    '[@state]': 'animationState',
    '(@state.done)': 'onAnimationEnd($event)'
  }

})
export class SfSnackBarContainerComponent extends BasePortalOutlet implements OnDestroy {
  id: string;
  /** The portal outlet inside of this container into which the snack bar content will be loaded. */
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;
  /** Subject for notifying that the snack bar has exited from view. */
  readonly onExit: Subject<any> = new Subject();
  /** Subject for notifying that the snack bar has finished entering the view. */
  readonly onEnter: Subject<any> = new Subject();
  /** The state of the snack bar animations. */
  animationState = 'void';
  /** ARIA role for the snack bar container. */
  role: 'alert' | 'status' | null;
  /** Whether the component has been destroyed. */
  private destroyed = false;
  $event: AnimationEvent;

  constructor(private ngZone: NgZone,
              private elementRef: ElementRef<HTMLElement>,
              private cdRef: ChangeDetectorRef,
              public snackBarConfig: SfSnackBarConfig) {
    super();
    // Based on the ARIA spec, `alert` and `status` roles have an
    // implicit `assertive` and `polite` politeness respectively.
    if (snackBarConfig.politeness === 'assertive' && !snackBarConfig.announcementMessage) {
      this.role = 'alert';
    } else if (snackBarConfig.politeness === 'off') {
      this.role = null;
    } else {
      this.role = 'status';
    }
  }

  /** Attach a component portal as content to this snack bar container. */
  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this.assertNotAttached();
    this.applySnackBarClasses();
    return this.portalOutlet.attachComponentPortal(portal);
  }

  /** Attach a template portal as content to this snack bar container. */
  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this.assertNotAttached();
    this.applySnackBarClasses();
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  /** Handle end of animations, updating the state of the snackbar. */
  onAnimationEnd(event: AnimationEvent) {
    const { fromState, toState } = event;

    if ((toState === 'void' && fromState !== 'void') || toState === 'hidden') {
      this.completeExit();
    }

    if (toState === 'visible') {
      // Note: we shouldn't use `this` inside the zone callback,
      // because it can cause a memory leak.
      const onEnter = this.onEnter;

      this.ngZone.run(() => {
        onEnter.next();
        onEnter.complete();
      });
    }
  }

  /** Begin animation of snack bar entrance into view. */
  enter(): void {
    if (!this.destroyed) {
      this.animationState = 'visible';
      this.cdRef.detectChanges();
    }
  }

  /** Begin animation of the snack bar exiting from view. */
  exit(): Observable<void> {
    // Note: this one transitions to `hidden`, rather than `void`, in order to handle the case
    // where multiple snack bars are opened in quick succession (e.g. two consecutive calls to
    // `SfSnackBarService.open`).
    this.animationState = 'hidden';
    return this.onExit;
  }

  /** Makes sure the exit callbacks have been invoked when the element is destroyed. */
  ngOnDestroy() {
    this.destroyed = true;
    this.completeExit();
  }

  /**
   * Waits for the zone to settle before removing the element. Helps prevent
   * errors where we end up removing an element which is in the middle of an animation.
   */
  private completeExit() {
    this.ngZone.onMicrotaskEmpty.asObservable().pipe(take(1)).subscribe(() => {
      this.onExit.next();
      this.onExit.complete();
    });
  }

  /** Applies the various positioning and user-configured CSS classes to the snack bar. */
  private applySnackBarClasses() {
    const element: HTMLElement = this.elementRef.nativeElement;
    const panelClasses = this.snackBarConfig.panelClass;

    if (panelClasses) {
      if (Array.isArray(panelClasses)) {
        // Note that we can't use a spread here, because IE doesn't support multiple arguments.
        panelClasses.forEach(cssClass => element.classList.add(cssClass));
      } else {
        element.classList.add(panelClasses);
      }
    }

    if (this.snackBarConfig.horizontalPosition === 'center') {
      element.classList.add('sf-snack-bar__center');
    }

    if (this.snackBarConfig.verticalPosition === 'top') {
      element.classList.add('sf-snack-bar__top');
    }
  }

  /** Asserts that no content is already attached to the container. */
  private assertNotAttached() {
    if (this.portalOutlet.hasAttached()) {
      throw Error('Attempting to attach snack bar content after content is already attached');
    }
  }
}
