<sf-card class="service-details p-24 my-32" sfElevation="0">
    <p sfBody1 class="bold">{{ currentProduct?.package.name | translateName }}</p>
    <div fxLayout="row wrap" fxLayoutAlign="start center">
        <p class="color--black-60 mr-12">{{ level?.name | translateName }}</p>
        <sf-chip class="service-details__list mr-8"
                 [ngClass]="currentProduct?.active && 'active'">{{ 'LABEL_' + (currentProduct?.active ? 'ACTIVE' : 'INACTIVE') | translate }}
        </sf-chip>
    </div>
    <p class="color--black-60 mt-8">{{ 'LABEL_REGION' | translate }}:
        <span class="color--black-87"> {{ currentProduct?.regions }}</span>
    </p>
</sf-card>

<sf-card class="package-list p-24" sfElevation="0">
    <sf-tab-bar fade #tabBar="sfTabBar" useAutomaticActivation class="item-tab mb-16">
        <sf-tab-scroller>
            <sf-tab *ngFor="let tab of displayedTabs" [label]="tab.label | translateName | translate" fxFlex.lt-sm="50" fxFlex.gt-md="25"
                    [id]="tab.id" [attr.data-cy]="tab.id">
            </sf-tab>
        </sf-tab-scroller>
    </sf-tab-bar>
    <div class="tab-content"
         *sfTabContent="tabBar; tabId: 'product_items'">
        <div fxLayout="row wrap" fxLayout.xs="row reverse" fxLayoutAlign="space-between center" class="search-container my-16">
            <div fxLayout="row wrap" fxFlex="60" fxFlex.xs="100" fxLayoutAlign="start center"
                 fxLayoutAlign.lt-md="space-between center" ngClass.xs="mt-12">
                <form fxFlex="100">
                    <ew-pc-search-bar [searchFormControl]="props('query')"></ew-pc-search-bar>
                </form>

                <!-- [REVISIT: Neeraj 10/05/2021] BA's request -->
                <!--        <button sf-button ngClass.gt-xs="ml-12" class="search-container__filter" ngClass.xs="mt-12 width&#45;&#45;full"-->
                <!--                data-cy="Filters">-->
                <!--          <sf-icon>tune</sf-icon>-->
                <!--          {{'BTN_FILTERS' | translate}}-->
                <!--        </button>-->
            </div>
            <div fxLayout="row wrap" fxFlex.xs="100" fxFlex="40" fxLayoutAlign="end center">
                <button sf-button primary ngClass.gt-xs="ml-12 pull--right" ngClass.xs="width--full"
                        (click)="createEditItem()"
                        [attr.data-cy]="'Add ' + ('LABEL_PRODUCT_ITEMS'| translate)"
                        *ngIf="displayAddItemsButton">
                    <sf-icon>add</sf-icon>
                    {{'Add ' + ('LABEL_PRODUCT_ITEMS'| translate)}}
                </button>
            </div>
        </div>

        <div fxLayout="row wrap" class="package">
            <ew-tabular-view class="width--full" *ngIf="currentProductItems?.collection?.length"
                             [dataSource]="currentProductItems"
                             [tableConfig]="productItemsTableConfig"
                             [setPageEvent]="searchParam?.page - 1"
                             (updateSearchParam)="updateProductItemsTableQuery($event)"
                             (action)="handleProductItemsTableAction($event)" [menuConfig]="productItemMenuConfig">
            </ew-tabular-view>

            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="12px" class="my-40"
                 *ngIf="!currentProductItems?.collection?.length">
                <div fxFlex.gt-xs="50" fxLayoutAlign="center">
                    <img src="/assets/images/no-data.svg" alt="no data"/>
                </div>
                <div fxFlex.gt-xs="55" class="text--center">
                    <h6 sfHeadline6 class="bold color--black-87">
                        {{'LABEL_NOT_ADDED_PACKAGES' | translate}}
                    </h6>
                    <p sfSubtitle2 class="mt-8 color--black-60">
                        {{'HINT_NOT_ADDED_PACKAGES' | translate}}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="tab-content" *sfTabContent="tabBar; tabId: 'addons'">
        <ew-search-filter
                [currentLevel]="addonLevel"
                [currentProduct]="currentProduct"
                [currentProductAncestry]="currentProduct.ancestry + '/' + currentProduct.id"
                [currentAncestryDepth]="addonLevel?.rank"
                [buttonMenu]="'LABEL_ADDON'"
                [queryForm]="addonQueryForm"
                [displayAddAddonsButton]="displayAddAddonsButton"
                (gridViewChange)="productTreesViewMode = $event">
        </ew-search-filter>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="12px" class="my-40"
             *ngIf="!addonLevelProductTrees?.collection?.length">
            <div fxFlex.gt-xs="50" fxLayoutAlign="center">
                <img src="/assets/images/no-data.svg" alt="no data"/>
            </div>
            <div fxFlex.gt-xs="55" class="text--center">
                <h6 sfHeadline6 class="bold color--black-87">
                    {{'LABEL_NOT_ADDED_PACKAGES' | translate}}
                </h6>
                <p sfSubtitle2 class="mt-8 color--black-60">
                    {{'HINT_NOT_ADDED_PACKAGES' | translate}}
                </p>
            </div>
        </div>
        <div class="mt-40" *ngIf="addonLevelProductTrees?.collection?.length">
            <ew-package-list
                    [viewMode]="true"
                    [subProductTreesConnection]="addonLevelProductTrees"
                    [level]="addonLevel"></ew-package-list>
        </div>
    </div>
</sf-card>

