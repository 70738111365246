import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ew-prev-next',
    templateUrl: './prev-next.component.html',
    styleUrls: ['./prev-next.component.scss'],
})
export class PrevNextComponent {
    @Input() stepIndex: number;
    @Input() stepperLength: number;
    @Input() showSkip: boolean;
    @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

    emitClickEvent(event: string): void {
    	this.clickEvent.emit(event);
    }
}
