import { ControlOptions } from '@ew/shared/services';

export const MULTI_LANG_FIELD_CONFIG: ControlOptions[] = [
    { key: 'name', type: 'input', label: 'LABEL_ITEM_NAME' }
];

export const SPECIFICATIONS_FIELD_CONFIG: ControlOptions[] = [
    { key: 'title', type: 'input', label: 'LABEL_SPECIFICATION_TITLE' },
    { key: 'value', type: 'input', label: 'LABEL_SPECIFICATION_DETAILS' },
];
