import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { ProductTree } from '@ew/shared/services';

export const CLONE_PRODUCT_MUTATION: TypedDocumentNode<{ cloneProductTree: ProductTree }> = gql`
    mutation cloneProductTree($input: CloneProductTreeInput!) {
      cloneProductTree(input: $input) {
        id
        active
      }
    }
`;
