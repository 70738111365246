import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ColorEvent } from 'ngx-color';

@Component({
    selector: 'sf-color-picker',
    template: `
    <color-twitter [colors]="colors" [triangle]="'hide'"
                   (onChangeComplete)="colorChanged.next($event)"></color-twitter>
    <div sfMenuSurfaceAnchor #colorPickerAnchor class="sf-display__inline sf-theme-palette">
      <button sf-fab (click)="colorPicker.open = !colorPicker.open" class="sf-theme-palette__button" [sfElevation]="0">
        <sf-icon class="sf-text-white font-size-12">color_lens</sf-icon>
      </button>
      <sf-menu #colorPicker [anchorElement]="colorPickerAnchor" class="sf-theme-palette__width" anchorCorner="bottomEnd">
        <sf-list>
          <color-sketch (onChangeComplete)="colorChanged.next($event)" [presetColors]="[]"></color-sketch>
        </sf-list>
      </sf-menu>
    </div>
  `,
    styleUrls: ['./color-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SfColorPickerComponent {
  @Input() colors = ['#f44336', '#e91e63', '#673ab7',
      '#2196f3', '#00bcd4', '#009688', '#8bc34a', '#cddc39', '#ffeb3b',
      '#ffc107', '#ff9800', '#795548', '#9e9e9e'
  ];
  @Output() colorChanged = new EventEmitter<ColorEvent>();

}
