import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {SecurityFacadeService} from '../services/security-facade.service';

@Directive({
    selector: '[ewAuthorized]'
})
export class AuthorizedDirective implements OnInit {
	@Input() permission: string;

	constructor(
		private el: ElementRef,
		private securityFacadeService: SecurityFacadeService
	) {
	}

	ngOnInit(): void {
	    if (!this.securityFacadeService.hasPermission(this.permission)) {
	        const elt: HTMLElement = this.el.nativeElement;
	        elt.parentNode.removeChild(elt);
	    }
	}
}
