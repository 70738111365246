import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SfRippleService} from '../../../ripple/src/index';

@Directive({
  selector: 'sf-card-media-content, [sfCardMediaContent]',
  exportAs: 'sfCardMediaContent',
  host: {'class': 'mdc-card__media-content'}
})
export class SfCardMediaContent {
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: 'sf-card-header, [sfCardHeader]',
  exportAs: 'sfCardHeader',
  host: {'class': 'sf-card__header'}
})
export class SfCardHeader {
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}


@Directive({
  selector: 'sf-card-content, [sfCardContent]',
  exportAs: 'sfCardContent',
  host: {'class': 'sf-card__content'}
})
export class SfCardContent {
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}
@Component({
  selector: 'sf-card-media, [sfCardMedia]',
  exportAs: 'sfCardMedia',
  host: {
    'class': 'mdc-card__media',
    '[class.mdc-card__media--square]': 'square',
    '[class.mdc-card__media--16-9]': 'wide'
  },
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfCardMedia {
  @Input()
  get square(): boolean {
    return this._square;
  }
  set square(value: boolean) {
    this._square = coerceBooleanProperty(value);
  }
  private _square = false;

  @Input()
  get wide(): boolean {
    return this._wide;
  }
  set wide(value: boolean) {
    this._wide = coerceBooleanProperty(value);
  }
  private _wide = false;

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Component({
  selector: 'sf-card-primary-action, [sfCardPrimaryAction]',
  exportAs: 'sfCardPrimaryAction',
  host: {'class': 'mdc-card__primary-action'},
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SfRippleService]
})
export class SfCardPrimaryAction implements OnDestroy {
  constructor(
    private _ripple: SfRippleService,
    public elementRef: ElementRef<HTMLElement>) {
    this._ripple = new SfRippleService(this.elementRef);
    this._ripple.init();
  }

  ngOnDestroy(): void {
    this._ripple.destroy();
  }
}

@Component({
  selector: 'sf-card-actions, [sfCardActions]',
  template: '<ng-content></ng-content>',
  exportAs: 'sfCardActions',
  host: {
    'class': 'mdc-card__actions',
    '[class.mdc-card__actions--full-bleed]': 'fullBleed',
    '[class.sf-card__actions--end]': 'flexEnd'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfCardActions {
  @Input()
  get fullBleed(): boolean {
    return this._fullBleed;
  }
  set fullBleed(value: boolean) {
    this._fullBleed = coerceBooleanProperty(value);
  }
  private _fullBleed = false;

  // Its is use for align the card actions towards right. By default card action will be on left
  @Input()
  get flexEnd(): boolean {
    return this._flexEnd;
  }
  set flexEnd(value: boolean) {
    this._flexEnd = coerceBooleanProperty(value);
  }
  private _flexEnd = false;

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: 'sf-card-action-buttons, [sfCardActionButtons]',
  exportAs: 'sfCardActionButtons',
  host: {'class': 'mdc-card__action-buttons'}
})
export class SfCardActionButtons {
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: 'sf-card-action-icons, [sfCardActionIcons]',
  exportAs: 'sfCardActionIcons',
  host: {'class': 'mdc-card__action-icons'}
})
export class SfCardActionIcons {
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: '[sfCardAction]',
  host: {'class': 'mdc-card__action'}
})
export class SfCardAction {
  @Input('sfCardAction')
  get action(): string {
    return this._action;
  }
  set action(action: string) {
    // If the directive is set without a name (updated programatically), then this setter will
    // trigger with an empty string and should not overwrite the programatically set value.
    if (!action) {
      return;
    }

    if (action === 'button') {
      this.elementRef.nativeElement.classList.remove('mdc-card__action--icon');
      this.elementRef.nativeElement.classList.add('mdc-card__action--button');
    } else if (action === 'icon') {
      this.elementRef.nativeElement.classList.remove('mdc-card__action--button');
      this.elementRef.nativeElement.classList.add('mdc-card__action--icon');
      this.elementRef.nativeElement.setAttribute('tabIndex', '0');
      this.elementRef.nativeElement.setAttribute('role', 'button');
    }
    this._action = action;
  }
  private _action = '';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Component({
  selector: 'sf-card',
  exportAs: 'sfCard',
  host: {
    'class': 'mdc-card sf-card',
    '[class.mdc-card--outlined]': 'outlined'
  },
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfCard {
  @Input()
  get outlined(): boolean {
    return this._outlined;
  }
  set outlined(value: boolean) {
    this._outlined = coerceBooleanProperty(value);
  }
  private _outlined = false;

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}
