import {NgModule} from '@angular/core';

import {
  SfCard,
  SfCardAction,
  SfCardActionButtons,
  SfCardActionIcons,
  SfCardActions,
  SfCardMedia,
  SfCardMediaContent,
  SfCardPrimaryAction,
  SfCardHeader,
  SfCardContent
} from './card.component';

const CARD_DECLARATIONS = [
  SfCard,
  SfCardAction,
  SfCardActionButtons,
  SfCardActionIcons,
  SfCardActions,
  SfCardMedia,
  SfCardMediaContent,
  SfCardPrimaryAction,
  SfCardHeader,
  SfCardContent
];

@NgModule({
  exports: CARD_DECLARATIONS,
  declarations: CARD_DECLARATIONS,
})
export class SfCardModule { }
