import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfElevationDirective } from './elevation.directive';

@NgModule({
  imports: [CommonModule],
  exports: [
    SfElevationDirective
  ],
  declarations: [SfElevationDirective]
})
export class SfElevationModule {}
