
import {Component, OnInit} from '@angular/core';
import {DialogRef} from '../dialog-ref';
import {SfDialogConfig} from '../dialog-config';

export interface ConfirmDialogData {
  title?: string;
  message?: string;
}

@Component({
  selector: 'sf-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class SfConfirmationComponent implements OnInit {
  data?: ConfirmDialogData | null;

  constructor(private ref: DialogRef<string>, private config: SfDialogConfig<ConfirmDialogData>) {
    this.data = config.data;
  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.ref.close(false);
  }

  ok(): void {
    this.ref.close(true);
  }
}
