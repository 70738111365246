<form [formGroup]="form">
  <p sfSubtitle1 class="mt-32 color--black-87">
    {{'QUEST_PRODUCT_PRICES' | translate}} </p>
  <p sfSubtitle2 class="my-8 color--black-60">
    {{'HINT_PRODUCT_PRICING_TYPES' | translate}}
  </p>
  <div fxLayout="column" data-cy="priceType">
    <sf-form-field *ngFor="let price of state">
      <sf-checkbox class="level-config__checkbox" [value]="price?.id" (changed)="onChange($event)"
                   [checked]="isSelected(price?.id)" [attr.data-cy]="price?.name"></sf-checkbox>
      <label sfSubtitle2>{{price?.name}}</label>
    </sf-form-field>
    <p sfCaption class="custom-error" *ngIf="priceAttribute.getError('isRequired') && priceAttribute?.touched">
      <span> {{'ERROR_REQUIRED' | translate}} </span>
    </p>
  </div>

</form>
