import {Inject, Injectable} from '@angular/core';
import { ENV_STORAGE, Environment } from '@ew/shared/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageSwitcherBlService {

    constructor(@Inject(ENV_STORAGE) private environment: Environment, private translate: TranslateService) {
    }

    setCurrentLang(lang: string): void {
        localStorage.setItem('language', lang);
    }

    getCurrentLang(): string {
        return localStorage.getItem('language');
    }

    setDefaultLanguage(): void {
        localStorage.setItem('language', this.environment.defaultLanguage);
        this.translate.use(this.environment.defaultLanguage);
    }
}
