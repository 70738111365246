import { ReactiveFormSchema } from '@ew/shared/utils/form-utils';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import {ContractDurationModel, ItemsQueryModel, ProductTreesQueryModel} from '../../shared.model';
import { DynamicColumnModel } from '../dynamic-column.model';
import { DynamicOptionModel } from '../dynamic-option.model';
import { PackageModel } from './package.model';
import { ProductTreeModel } from './product-tree.model';
import { ProductItemModel } from './product-item.model';

export class ProductFormBuilder {
  static packageForm = (): ReactiveFormSchema => ({
  	formModel: PackageModel,
  	skipRequiredValidators: [
  		'id',
  		'configuratorPriceTypeId',
  		'imageIds',
  		'images',
  		'deliveryTime',
  		'deliveryTime.id',
  		'deliveryTime.minimum',
  		'deliveryTime.maximum',
  		'deliveryTime._destroy',
  		'preorder',
  		'preorder.id',
  		'preorder._destroy',
  		'packageContractDurations',
  		'productLimit',
  		'type'
  	],
  });

  static productTreeForm = (): ReactiveFormSchema => ({
  	formModel: ProductTreeModel,
  	skipRequiredValidators: [],
  });

  static queryFormInitializer = (): ReactiveFormSchema => ({
  	formModel: ProductTreesQueryModel,
  	skipRequiredValidators: [...Object.keys(new ProductTreesQueryModel())],
  });

  static queryItemInitializer = (): ReactiveFormSchema => ({
  	formModel: ItemsQueryModel,
  	skipRequiredValidators: [...Object.keys(new ItemsQueryModel())],
  });

  static productItemInitializer = (): ReactiveFormSchema => ({
  	formModel: ProductItemModel,
  	skipRequiredValidators: ['id', 'active', 'meta', '_destroy', 'imageIds', 'images', 'specifications', 'dynamicValues', 'prices'],
  });

  static dynamicColumnInitializer = (): ReactiveFormSchema => ({
  	formModel: DynamicColumnModel,
  	skipRequiredValidators: ['dataType', 'id', 'multilingual', 'meta', 'required', '_destroy'],
  });

  static dynamicOptionInitializer = (): ReactiveFormSchema => ({
  	formModel: DynamicOptionModel,
  	skipRequiredValidators: ['hexValue', 'id', 'meta', 'value', '_destroy'],
  });

  static productTreesQueryFormSchema(): ReactiveFormSchema {
  	return {
  		formModel: ProductTreesQueryModel,
  		skipRequiredValidators: [],
  	};
  }

  static contractDurationFormInitializer = (): ReactiveFormSchema => ({
  	formModel: ContractDurationModel,
  	skipRequiredValidators: ['id'],
  });
}

export const customMinDelivery = (minControlName: string, maxControlName: string): ValidatorFn | null =>
    (group: FormGroup): ValidationErrors | null => {
        const minControl: FormControl = group.get(minControlName) as FormControl;
        const maxControl: FormControl = group.get(maxControlName) as FormControl;
        return (maxControl.value || minControl.value)
    && ((+minControl.value > +maxControl.value) ||
      ['', undefined, null].includes(minControl.value)) ? { isMore: { controlName: minControlName } } : null;
    }

export const customMaxDelivery = (minControlName: string, maxControlName: string): ValidatorFn | null =>
    (group: FormGroup): ValidationErrors | null => {
        const minControl: FormControl = group.get(minControlName) as FormControl;
        const maxControl: FormControl = group.get(maxControlName) as FormControl;
        return (maxControl.value || minControl.value)
    && ((+minControl.value > +maxControl.value) ||
      ['', undefined, null].includes(minControl.value)) ? { isLess: { controlName: maxControlName } } : null;
    }

export const preOrderStartDate =
  (releaseControl: AbstractControl): ValidatorFn | null =>
  	(control: FormControl): ValidationErrors | null => releaseControl.value &&
    (new Date(control.value) > new Date(releaseControl.value)) ? { isMoreDate: true } : null

export const preOrderReleaseDate =
  (startControl: AbstractControl): ValidatorFn | null =>
  	(control: FormControl): ValidationErrors | null =>
  		(new Date(control.value) < new Date(startControl.value)) ? { isLessDate: true } : null

export const markUpPriceCheck = (dynamicPriceCtrl: AbstractControl): ValidatorFn | null =>
    (control: FormControl): ValidationErrors | null =>
        +control.value && (+dynamicPriceCtrl.value >= +control.value) ? { isLessPrice: true } : null
