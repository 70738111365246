import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'ew-pc-search-bar',
    templateUrl: './pc-search-bar.component.html',
    styleUrls: ['./pc-search-bar.component.scss']
})
export class PcSearchBarComponent {

	@Input() searchFormControl: FormControl;
}
