import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfAccordionComponent } from './accordion.directive';
import {
  SfAccordionItemActionRow,
  SfAccordionItemComponent,
  SfAccordionItemContent,
  SfAccordionItemLazyContent
} from './accordion-item.component';
import { SfAccordionItemHeader, SfAccordionItemTitle } from './accordion-item-header';
import { SfIconModule } from '../../../icon/src/index';

const DECLARATIONS = [
  SfAccordionComponent,
  SfAccordionItemComponent,
  SfAccordionItemComponent,
  SfAccordionItemHeader,
  SfAccordionItemTitle,
  SfAccordionItemContent,
  SfAccordionItemLazyContent,
  SfAccordionItemActionRow
];

@NgModule({
  imports: [CommonModule, SfIconModule],
  exports: DECLARATIONS,
  declarations: DECLARATIONS
})
export class SfAccordionModule {
}
