import { NgModule } from "@angular/core";
import { APP_BASE_HREF, CommonModule } from '@angular/common';

import { SfBreadcrumbComponent } from './breadcrumb.component';
import { SfIconModule } from '../../../icon/src/index';
import { SfListModule } from '../../../list/src/index';
import { SfIconButtonModule } from '../../../icon-button/src/index';
import { SfTooltipModule } from '../../../tooltip/src/index';
import { SfMenuModule } from '../../../menu/src/index';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule,
    SfIconModule,
    SfListModule,
    SfIconButtonModule,
    SfTooltipModule,
    SfMenuModule,
    RouterModule
  ],
  exports: [SfBreadcrumbComponent],
  declarations: [SfBreadcrumbComponent],
})
export class SfBreadcrumbModule {
}
