import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[ewSpaceRemoval]'
})
export class SpaceRemovalFromInputDirective {

  @HostListener('input', ['$event'])
    onInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        input.value = input.value.replace(/\s/g, '');
    }
}