import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    Configurator,
    CountryList,
    NotificationService,
    DeleteLevelPayload,
    UpdateLevelInput,
    CreateLevelInput, UpdateConfigurator, LevelTypeEnum
} from '@ew/shared/services';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { COUNTRIES_QUERY } from '../gql-queries/countries.query';
import { LEVELS_QUERY } from '../gql-queries/levels.query';
import {
    LEVEL_QUERY,
    LEVEL_DELETE_MUTATION,
    LEVEL_UPDATE_MUTATION,
} from '../gql-queries/level.query';
import { CONFIGURATORS_QUERY } from '../gql-queries/configurations.query';
import { CONFIGURATION_MUTATION } from '../gql-queries/configuration.query';
import { LEVEL_CREATE_MUTATION } from '../gql-queries/level.query';
import { PRICE_TYPES_QUERY } from '../gql-queries/price-types.query';
import { Price, LevelModel } from '@ew/shared/services';

@Injectable({
    providedIn: 'root',
})
export class LevelApiService {
    constructor(private apollo: Apollo, private notify: NotificationService) {}

    getCountries(): Observable<CountryList[]> {
        return this.apollo.use('pc')
            .query({
                query: COUNTRIES_QUERY,
                fetchPolicy: 'cache-first',
            })
            .pipe(
                map(
                    (result: ApolloQueryResult<{ countries: CountryList[] }>) =>
                        result.data.countries
                )
            );
    }

    getPriceTypes(): Observable<Price[]> {
        return this.apollo.use('pc')
            .query({
                query: PRICE_TYPES_QUERY,
                fetchPolicy: 'cache-first',
            })
            .pipe(
                map(
                    (result) =>
                        result.data.priceTypes
                )
            );
    }

    getLevels(configuratorId: string): Observable<LevelModel[]> {
        return this.apollo.use('pc')
            .query({
                query: LEVELS_QUERY,
                variables: {
                    configuratorId,
                },
                fetchPolicy: 'network-only',
            })
            .pipe(map((result) => result.data.levels as LevelModel[]));
    }

    getConfigurators(): Observable<Configurator[]> {
        return this.apollo.use('pc')
            .query({
                query: CONFIGURATORS_QUERY,
                fetchPolicy: 'cache-first',
            })
            .pipe(
                map(
                    (result: ApolloQueryResult<{ configurators: Configurator[] }>) =>
                        result.data.configurators
                )
            );
    }

    updateConfiguration(data: UpdateConfigurator): Observable<Configurator> {
        return this.apollo.use('pc')
            .mutate({
                mutation: CONFIGURATION_MUTATION,
                variables: {
                    input: {
                        attributes: data
                    },
                },
            })
            .pipe(
                map((response: FetchResult<Configurator>) => {
                    this.notify.show('SUCCESS_PRICING_UPDATE');
                    return response.data;
                })
            );
    }

    createLevel(data?: CreateLevelInput): Observable<LevelModel> {
        return this.apollo.use('pc')
            .mutate({
                mutation: LEVEL_CREATE_MUTATION,
                variables: { input: data },
            })
            .pipe(
                map((result: FetchResult<LevelModel>) => {
                    this.notify.show(result.data?.type === LevelTypeEnum.Addon ? 'SUCCESS_ADDON_CREATE' : 'SUCCESS_LEVEL_CREATE');
                    return result.data;
                })
            );
    }

    updateLevel(data?: UpdateLevelInput): Observable<LevelModel> {
        return this.apollo.use('pc')
            .mutate({
                mutation: LEVEL_UPDATE_MUTATION,
                variables: { input: data },
            })
            .pipe(
                map((result: FetchResult<LevelModel>) => {
                    this.notify.show(result.data?.type === LevelTypeEnum.Addon ? 'SUCCESS_ADDON_UPDATE' : 'SUCCESS_LEVEL_UPDATE');
                    return result.data;
                })
            );
    }

    getLevelById(id: string): Observable<LevelModel> {
        return this.apollo.use('pc')
            .query({
                query: LEVEL_QUERY,
                variables: { id },
            })
            .pipe(map((result) => result.data.level as LevelModel));
    }

    deleteLevelById(id: string): Observable<DeleteLevelPayload> {
        return this.apollo.use('pc')
            .mutate({
                mutation: LEVEL_DELETE_MUTATION,
                variables: {
                    input: {
                        id,
                    },
                },
            })
            .pipe(
                map((response: FetchResult<DeleteLevelPayload>) => {
                    this.notify.show('SUCCESS_LEVEL_DELETE');
                    return response.data;
                })
            );
    }
}
