export enum ServicesEnum {
    mobile = 'mobile',
    internet = 'internet',
    tv = 'tv',
    combi = 'combi',
    landline = 'landline',
    basic_household_connection = 'basic_household_connection'
}

export enum GAConsentPermissionEnum {
    denied = 'denied',
    granted = 'granted'
}

export enum SubscriptionTypeEnum {
    oldSubscribedPackages = 'oldSubscribedPackages',
    subscribedPackages = 'subscribedPackages'
}

export enum NotificationSortEnum {
    ALL = 'all',
    READ = 'read',
    UNREAD = 'unread',
}

export enum UserRoleEnum {
    USER = 'user',
    GROUP_ADMIN = 'group-admin',
    ADMIN = 'admin'
}