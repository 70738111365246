import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    LevelModel, MultiLanguages, ProductFormBuilder, ProductStateEnum,
    ProductTree, ProductTreeList, ProductTreesQueryModel, TableDataSource,
} from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import { ProductFacadeService } from '../../../services/product-facade.service';

@UntilDestroy()
@Component({
    selector: 'ew-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent extends FormBaseComponent implements OnInit {
    private level: LevelModel;
    private levels: LevelModel[];

    // [REVISIT: Neeraj (12/10/2021)] Use when addons and contract duration is required in this level
    // contractDurationForm: FormGroup;
    displayedTabs: { label: string | MultiLanguages; id: string }[] = [];

    // [REVISIT: Neeraj (12/10/2021)] Use when addons and contract duration is required in this level
    /*  editingContractDuration: ContractDuration;

      addonLevel: LevelModel;
      addonLevelProductTree: ProductTree;
      addonLevelProductTrees: ProductTreesConnection;
      addonProductTreesSearchControl = new FormControl();
      contractDurations$: Observable<ContractDuration[]>;*/

    @Input() set currentLevel(level: LevelModel) {
    	this.resolveLevelTab(level);
    	this.level = level;
    }
    @Input() set allLevel(levels: LevelModel[]) {
    	// [REVISIT: Neeraj 10/05/2021] Addons required only for items
    	/*this.setAddonsTab();
        this.setContractDurationTab(levels.find((level) => level?.levelRule?.contractDuration));*/
    	this.levels = levels;
    }
    @Input() productState: TableDataSource<ProductTreeList>;
    @Input() currentProductAncestry: string;
    @Input() currentProduct: ProductTree;
    @Input() currentAncestryDepth: number;

    constructor(private productFacade: ProductFacadeService) {
    	super();
    	this.configureForm(ProductFormBuilder.queryFormInitializer());
    }

    ngOnInit(): void {
    	// [REVISIT: Neeraj (12/10/2021)] Use when addons and contract duration is required in this level
    	/*    this.contractDurations$ = this.productFacade.specificStateChange<ContractDuration[]>(ProductStateEnum.CONTRACT_DURATIONS);
            this.buildContractDurationForm();
            this.productFacade.fetchContractDurations().subscribe();*/
    	this.queryChanges();
    }

    queryChanges(): void {
    	this.props('query').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
    		this.productFacade.updateSpecificState<ProductTreesQueryModel>(
    			{query: value, ancestry: this.currentProductAncestry, ancestryDepth: this.currentAncestryDepth || 0},
    			ProductStateEnum.PRODUCT_TREES_QUERY);
    	})
    }

    private resolveLevelTab(level: LevelModel): void {
    	if (this.displayedTabs.every((tab) => tab.id !== 'level')) {
    		this.displayedTabs.unshift({
    			label: level.name,
    			id: 'level',
    		});
    	}
    }

    // [REVISIT: Neeraj (12/10/2021)] Use when addons and contract duration is required in this level
    /*private buildContractDurationForm(): void {
      this.contractDurationForm = this.formBuilder(ProductFormBuilder.contractDurationFormInitializer());
    }

    private setAddonsTab(): void {
      if (this.allLevel?.find((level) => level.type === LevelTypeEnum.Addon) && this.displayedTabs.every((tab) => tab.id !== 'addons')) {
        this.displayedTabs.push({
          label: 'LABEL_ADDON',
          id: 'addons',
        });
        this.loadAddonDeps();
      }
    }

    private setContractDurationTab(level: LevelModel): void {
      if (level?.levelRule?.contractDuration && this.displayedTabs.every((tab) => tab.id !== 'contractDuration')) {
        this.displayedTabs.push({
          label: 'LABEL_CONTRACT_DURATION',
          id: 'contractDuration',
        });
      }
    }

    private loadAddonDeps(): void {
      this.addonLevel = this.levels.find((level) => level.type === LevelTypeEnum.Addon);
      this.productFacade
        .getProductTrees(
          {
            ancestryDepth: this.addonLevel.rank,
          },
          false
        )
        .subscribe((productTrees) => {
          this.addonLevelProductTrees = productTrees;
        });
    }

    saveContractDurationToConfigurator(): void {
      this.contractDurationForm.patchValue({
        configuratorId: this.productFacade.getSpecificState<Configurator[]>(ProductStateEnum.CONFIGURATOR)[0].id,
      });
      this.contractDurationForm.markAllAsTouched();
      if (this.contractDurationForm.valid) {
        this.productFacade
          .createUpdateContractDuration(this.contractDurationForm.value)
          .pipe(switchMapTo(this.productFacade.fetchContractDurations()))
          .subscribe(() => {
            this.contractDurationForm.reset();
            this.editingContractDuration = null;
          });
      }
    }

    editContractDuration(durationId: string): void {
      this.editingContractDuration = this.productFacade
        .getSpecificState<ContractDuration[]>(ProductStateEnum.CONTRACT_DURATIONS)
        .find((contractDuration) => contractDuration.id === durationId);
      this.contractDurationForm.patchValue(this.editingContractDuration);
    }

    removeContractDuration(durationId: string): void {
      this.productFacade.removeContractDuration(durationId).pipe(switchMapTo(this.productFacade.fetchContractDurations())).subscribe();
    }*/

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    get currentLevel(): LevelModel {
    	return this.level;
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    get allLevel(): LevelModel[] {
    	return this.levels;
    }
}
