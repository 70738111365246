import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[ewMobileNumberValidator]'
})
export class MobileNumberValidatorDirective {

	private regex: RegExp = new RegExp(/^[\d +]+$/);
	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

	@HostListener('keydown', ['$event'])

	onKeyDown(event: KeyboardEvent) {

	    if (this.specialKeys.indexOf(event.key) !== -1 ||
			((event.ctrlKey || event.metaKey) && +event.key === 67) ||
			((event.ctrlKey || event.metaKey) && +event.key === 86)) {
	        return;
	    }

	    const next: string = event.key;

	    if (next && !String(next).match(this.regex)) {
	        event.preventDefault();
	    }

	}
}
