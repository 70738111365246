import { NotificationModeEnum } from '@ew/shared/services';
import { PushNotificationLanguageType, PushNotificationModeType } from '../models/push-notification.model';

export const NOTIFICAITON_MODE_CONST: PushNotificationModeType[] = [
    {label: 'LABEL_EMAIL', value: NotificationModeEnum.Mail}, 
    {label:'LABEL_CUSTOMER_PORTAL', value: NotificationModeEnum.Portal},
    {label: 'LABEL_NOTIFICATION_OFF', value: NotificationModeEnum.Off}
]

export const NOTIFICATION_MODE_LANGUAGE_CONST: PushNotificationLanguageType[] = [
    {label: 'LABEL_GERMAN', image: '/assets/images/de.png', value: 'de'},
    {label: 'LABEL_ENGLISH', image: '/assets/images/en.png', value: 'en'}
]