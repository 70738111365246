import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { SfSnackBarRef } from '../snack-bar-ref';
import { SF_SNACK_BAR_DATA } from '../snack-bar-config';

export const severityIcons = {
  info: 'info',
  success: 'check_circle',
  warning: 'warning',
  error: 'error'
};


interface SimpleSnackBarData {
  message: string,
  action: string,
  severity: string
}

/**
 * A component used to open as the default snack bar, matching material design spec.
 * This should only be used internally by the snack bar service.
 */
@Component({
  templateUrl: 'default-snack-bar.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'sf-simple-snackbar'
  }
})
export class SfDefaultSnackBarComponent {
  /** Data that was injected into the snack bar. */
  data: { message: string, action: string, severity: string, icon };


  constructor(
    public snackBarRef: SfSnackBarRef<SfDefaultSnackBarComponent>,
    @Inject(SF_SNACK_BAR_DATA) data: SimpleSnackBarData) {
    this.data = { ...data, icon: severityIcons[data.severity] };
    snackBarRef.afterDismissed().subscribe(() => {
      console.log('The snack-bar was dismissed');
    });
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.data.action;
  }

  /** Performs the action on the snack bar. */
  action(): void {
    this.snackBarRef.dismissWithAction();
  }

  /* Dismiss the snack bar */
  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
