import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfFloatingLabelModule} from '../../../floating-label/src/index';
import {SfLineRippleModule} from '../../../line-ripple/src/index';
import {SfNotchedOutlineModule} from '../../../notched-outline/src/index';
import {SfFormsModule} from '../../../forms/src/index';

import {SfInputComponent} from './input.component';
import {SfTextareaComponent} from './textarea.component';
import {SfTextFieldIconDirective} from './input-icon.directive';

const TEXTFIELD_DECLARATIONS = [
  SfTextareaComponent,
  SfInputComponent,
  SfTextFieldIconDirective
];

@NgModule({
  imports: [
    CommonModule,
    SfFormsModule,
    SfLineRippleModule,
    SfFloatingLabelModule,
    SfNotchedOutlineModule
  ],
  exports: [
    SfFormsModule,
    TEXTFIELD_DECLARATIONS
  ],
  declarations: TEXTFIELD_DECLARATIONS
})
export class SfInputModule { }
