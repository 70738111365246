<div class="sf-paginator__outer-container">
    <div class="sf-paginator__container">
        <div class="sf-paginator__page-size">
            <div class="sf-paginator__page-size_label">
                {{_intl.itemsPerPageLabel}}
            </div>
            <sf-form-field data-cy="pageView">
                <sf-select class="sf-paginator__page-size_select" [attr.aria-label]="_intl.itemsPerPageLabel" [ngModel]="pageSize"
                (selectionChange)="changePageSize($event.value)">
                    <sf-menu>
                        <sf-list>
                            <sf-list-item *ngFor="let pageSizeOption of displayedPageSizeOptions" [value]="pageSizeOption">
                                {{pageSizeOption}}
                            </sf-list-item>
                        </sf-list>
                    </sf-menu>
                </sf-select>
            </sf-form-field>
            <div *ngIf="displayedPageSizeOptions.length <= 1">{{pageSize}}</div>
        </div>
        <div class="sf-paginator__range-actions">
            <div class="sf-paginator__range-label">
                {{_intl.getRangeLabel(pageIndex, pageSize, length)}}
            </div>
            <button sf-icon-button type="button" class="sf-paginator-nav-first" (click)="firstPage()"
                    [disabled]="previousButtonsDisabled()" *ngIf="showFirstLastButtons">
                <svg class="sf-paginator-icon" viewBox="0 0 24 24" focusable="false">
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"/>
                </svg>
            </button>
            <button sf-icon-button type="button" class="sf-paginator-nav-previous" (click)="previousPage()"
             [disabled]="previousButtonsDisabled()" data-cy="previousPage">
                <svg class="sf-paginator-icon" viewBox="0 0 24 24" focusable="false">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                </svg>
            </button>
<!--
            <span class="sf-paginator__range">1-8 of 50</span>
-->
            <button sf-icon-button type="button" class="sf-paginator-nav-next" (click)="nextPage()"
            [disabled]="nextButtonsDisabled()" data-cy="nextPage">
                <svg class="sf-paginator-icon" viewBox="0 0 24 24" focusable="false">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
                </svg>
            </button>
            <button sf-icon-button type="button" class="sf-paginator-nav-last" (click)="lastPage()"
                    [disabled]="nextButtonsDisabled()" *ngIf="showFirstLastButtons">
                <svg class="sf-paginator-icon" viewBox="0 0 24 24" focusable="false">
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"/>
                </svg>
            </button>
        </div>
    </div>
</div>
