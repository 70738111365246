import { NgModule } from '@angular/core';

import { SfTabIndicatorModule } from './tab-indicator/tab-indicator.module';
import { SfTabModule } from './tab/tab.module';
import { SfTabScrollerModule } from './tab-scroller/tab-scroller.module';
import { SfTabBarComponent } from './tab-bar.component';

@NgModule({
  imports: [
    SfTabIndicatorModule,
    SfTabModule,
    SfTabScrollerModule
  ],
  exports: [
    SfTabBarComponent,
    SfTabIndicatorModule,
    SfTabModule,
    SfTabScrollerModule
  ],
  declarations: [SfTabBarComponent]
})
export class SfTabBarModule {
}
