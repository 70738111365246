<ng-container *ngIf="{
  configurator: configuratorState$ | async,
  productTree: productTreeState$ | async
} as itemCreationDepStates">
  <form [formGroup]="form" class="width--full">
    <div class="item-creation p-24 my-24">
      <h5 sfHeadline5 class="mb-12">{{'LABEL_ADD_ITEM' | translate}}</h5>
      <p sfSubtitle2 class="mb-8 color--black-60">{{'HINT_SET_NAME' | translate}}</p>

      <div fxLayout="row" class="width--full">
        <div fxFlex="60" fxFlex.lt-lg="100" fxLayout="row wrap">
        <ew-multi-lang fxFlex="49" fxFlex.lt-lg="70" fxFlex.lt-md="100" [form]="form" [copyControls]="multiLangFieldConfigs"
                       (copyValue)="copyContent($event)" [selectedLang]="selectedLanguageControl"></ew-multi-lang>
        </div>
      </div>

      <div fxLayout="row" *ngIf="configuratorDynamicValueForms?.length">
        <div fxFlex="60" fxFlex.lt-lg="100" fxLayout="row wrap" fxLayoutAlign="space-between start"
             formArrayName="dynamicValues">
          <ng-container *ngFor="let dynamicColumnGroup of configuratorDynamicValueForms; let i = index">
            <ew-dynamic-field [fxFlex]="itemCreationDepStates.configurator?.dynamicColumns[i].dataType==='text' ? '100' : '49'" fxFlex.xs="100"
                              [dynamicColumn]="itemCreationDepStates.configurator?.dynamicColumns[i]"
                              [dynamicValueFormGroup]="dynamicColumnGroup"
                              [activeLangKey]="selectedLanguageControl.value"></ew-dynamic-field>
          </ng-container>
        </div>
      </div>

      <div *ngIf="packageDynamicValueForms.length" class="pb-16 mt-20">
        <p sfSubtitle1 class="mt-20">{{'LABEL_VARIANT_OPTIONS' | translate}}</p>
        <div fxLayout="row">
          <p fxFlex.gt-md="75" sfSubtitle2
             class="mt-8 mb-12 color--black-60">{{'HINT_CREATE_UNIQUE_VARIANT' | translate}}</p>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div fxFlex="60" fxFlex.lt-lg="100" fxLayout="row wrap" fxFlex.xs="100" fxLayoutAlign="space-between center">
            <ng-container *ngFor="let dynamicColumnGroup of packageDynamicValueForms; let i = index">
                <ew-dynamic-field fxFlex="49" fxFlex.xs="100"
                  [dynamicColumn]="itemCreationDepStates.productTree?.package.dynamicColumns[i]"
                  [dynamicValueFormGroup]="dynamicColumnGroup"
                  [activeLangKey]="selectedLanguageControl.value"></ew-dynamic-field>
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngFor="let pricingGroup of pricesFormArray.controls; let i = index"
           [formGroup]="pricingGroup" fxLayout="row" class="my-16">
        <ew-pricing [form]="pricingGroup"></ew-pricing>
      </div>

      <div *ngIf="itemCreationDepStates.productTree?.package.productLimit?.id" formGroupName="productLimit"
           class="pb-16">
        <p sfSubtitle1 class="mt-16">{{'LABEL_QUANTITY_SETTING' |translate}}</p>
        <p sfSubtitle2 class="mt-8 mb-8 color--black-60">{{'HINT_QUANTITY_SETTING' | translate}}</p>
          <sf-form-field>
            <sf-checkbox class="item-creation__checkbox"></sf-checkbox>
            <label>{{'LABEL_MIN_QUANTITY' | translate}}</label>
          </sf-form-field>
          <div fxLayout="row">
            <sf-form-field fxFlex="40" fxFlex.xs="100" class="pl-32 mb-20 mt-12" fluid>
                <sf-input [label]="'LABEL_OTC' | translate" outlined></sf-input>
              </sf-form-field>
          </div>
          <sf-form-field>
            <sf-checkbox class="item-creation__checkbox"></sf-checkbox>
            <label>{{'LABEL_MAX_QUANTITY' | translate}}</label>
          </sf-form-field>
          <div fxLayout="row">
            <sf-form-field fxFlex="40" fxFlex.xs="100" class="pl-32 mb-20 mt-12" fluid>
                <sf-input [label]="'LABEL_OTC' | translate" outlined></sf-input>
              </sf-form-field>
          </div>
      </div>

      <!--upload-section-->
      <!-- [REVISIT: Jimba 09-11-2021] Removal request from BA -->
<!--      <p sfSubtitle1 class="mt-16">{{'LABEL_ITEM_IMAGE' | translate}}</p>-->
<!--      <p sfSubtitle2 class="mt-8 mb-8 color&#45;&#45;black-60">{{'HINT_UPLOAD_IMAGE' | translate}}</p>-->

<!--      <div fxLayout="row wrap" fxLayoutAlign="space-between" class="width&#45;&#45;full">-->
<!--        <ew-image-picker fxFlex="100" [uploadFileFn]="uploadImage.bind(this)" [pickMultiple]="true"-->
<!--                         [previewImageUrls]="itemImages" [isRowLayout]="true"-->
<!--                         (fileRemoved)="removeImage($event)"></ew-image-picker>-->
<!--      </div>-->
      <!--upload-section-end-->

      <!-- Specifications -->
      <!-- [REVISIT: Neeraj 10-06-2021] Removal request from BA -->
<!--      <div formArrayName="specifications">-->
<!--        <p sfSubtitle1 class="mt-24">{{'LABEL_SPECIFICATION' | translate}}</p>-->
<!--        <p sfSubtitle2 class="my-8 color&#45;&#45;black-60">{{'HINT_SPECIFICATION' | translate}}</p>-->
<!--        <p sfSubtitle2 class="mb-12 color&#45;&#45;black-60">{{'HINT_SET_TITLE_DESCRIPTION' | translate}}</p>-->

<!--        <div *ngFor="let specificationGroup of specificationsFormArray.controls; let i = index"-->
<!--             [formGroup]="specificationGroup" fxLayout="row" class="my-16">-->
<!--          <ew-multi-lang fxFlex="40" fxFlex.lt-md="80" [form]="specificationGroup"-->
<!--                         [copyControls]="specificationsFieldConfigs"></ew-multi-lang>-->
<!--          <button sfIconButton class="item-creation__delete mt-24 ml-32" (click)="removeSpecification(i)">-->
<!--            <sf-icon>delete_outlined</sf-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--        <button sf-button (click)="pushSpecFormGroupToFormArray()" type="button">-->
<!--          <sf-icon class="color&#45;&#45;primary">add</sf-icon>-->
<!--          {{'BTN_ADD_SPECIFICATION' | translate}}-->
<!--        </button>-->
<!--      </div>-->

      <div fxLayoutAlign="end" class="mt-16">
        <button sf-button ngClass.gt-xs="ml-12" outlined type="button" ngClass.xs="mt-12 mr-12 width--full"
                (click)="cancel()" data-cy="cancelBtn">
          {{'BTN_CANCEL' | translate}}
        </button>
        <button sf-button primary ngClass.gt-xs="ml-12" type="submit" ngClass.xs="mt-12 width--full"
                (click)="createProductItem()" data-cy="addBtn">
          {{productItemIdRouteParam ? 'Edit Item' : 'BTN_ADD_ITEM' | translate}}
        </button>
      </div>
    </div>
  </form>
</ng-container>

