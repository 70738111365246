import { Injectable } from '@angular/core';
import { BaseStateService, LevelsStoreState } from '@ew/shared/services';

@Injectable({
    providedIn: 'root'
})
export class LevelStateService extends BaseStateService<LevelsStoreState>{

    constructor() {
        super();
        this.initialState();
    }

    initialState(): void {
        const initialState: LevelsStoreState = {
            levelsConfigurator: undefined,
            levelState: undefined,
            countries: undefined,
            priceTypes: undefined,
            newLevel: undefined,
        }
        this.setState(initialState, 'INIT_STATE');
    }
}
