<div class="cookie-bar color--white bg--primary pt-16 font--600" data-cy="cookieBar">
    <div fxLayout="row" fxLayoutAlign="start center" class="mx-16">
        <sf-icon class="s-20">cookie</sf-icon>
        <p class="ml-8 font-size-16" ngClass.gt-xs="font-size-20">{{'LABEL_COOKIES_CONSENT' |translate}}</p>
    </div>
    <p ngClass.gt-xs="m-16" ngClass.xs="mx-16 mb-6 font-size-12">{{'HINT_COOKIES_TO_IMPROVE_USER_EXPERIENCE' | translate}}</p>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" class="mx-16">
        <button aria-label="open constant preferences modal" sf-button outlined (click)="showConstantModal()" class="color--white" ngClass.xs="width--full" data-cy="managePreferences">{{'BTN_MANAGE_PREFERENCES' | translate}}</button>
       <div fxLayoutGap="8px" fxLayout.xs="column" ngClass.xs="width--full mt-12">
           <button aria-label="reject to open modal" sf-button outlined class="color--white" data-cy="reject" (click)="rejectCookieBar()">{{'BTN_REJECT' | translate}}</button>
           <button aria-label="accept to open modal" sf-button primary data-cy="accept" (click)="acceptCookieBar()">{{'BTN_ACCEPT' | translate}}</button>
       </div>
    </div>
    <div class="width--full bg--black-10 mt-10" ngClass.gt-xs="mt-32">
        <p class="px-16 py-4">{{'HINT_MORE_INFORMATION' |translate}}:
            <span class="cursor--pointer" (click)="navigateToExternalPrivacyPolicy()">{{'LABEL_PRIVACY_POLICY' | translate}}</span>
        </p>
    </div>
</div>
