import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuConfig} from '@ew/shared/services';


@Component({
    selector: 'ew-menu-modal',
    templateUrl: './menu-modal.component.html',
    styleUrls: ['./menu-modal.component.scss']
})
export class MenuModalComponent {

	@Input() row: unknown;
	@Input() config: MenuConfig[];
	@Output() action: EventEmitter<{ actionKey: string, row: unknown }> = new EventEmitter<{ actionKey: string, row: unknown }>();
}
