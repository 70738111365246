import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationSettingFormBuilder } from '@ew/shared/models';
import { NotificationModeEnum, SharedFacadeService, SharedStoreStateEnum, User } from '@ew/shared/services';
import { FormBaseComponent } from '@ew/shared/utils/form-utils';
import { DialogRef, SfDialogService } from '@ew/shared/safire/components';
import { switchMap, tap } from 'rxjs/operators';
import { AddNotificationEmailComponent } from '../add-notification-email/add-notification-email.component';
import { PushNotificationLanguageType, PushNotificationModeType } from '../../models/push-notification.model';
import { NOTIFICAITON_MODE_CONST, NOTIFICATION_MODE_LANGUAGE_CONST } from '../../misc/push-notification.constants';

@Component({
    selector: 'ew-notification-setting',
    templateUrl: './notification-setting.component.html',
    styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent extends FormBaseComponent implements OnInit {

    notificationSettingsForm!: FormGroup;
    emailPresent!: boolean;
    notificationLanguages: PushNotificationLanguageType[] = NOTIFICATION_MODE_LANGUAGE_CONST;
    notificationMode: PushNotificationModeType[] = NOTIFICAITON_MODE_CONST;

    protected readonly close = close;

    constructor(private ref: DialogRef<NotificationSettingComponent>, private sharedFacadeService: SharedFacadeService,
        private dialogService: SfDialogService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializer();
    }

    initializer(): void {
        this.configureForm(NotificationSettingFormBuilder.NotificationSettingFormInitializer());
        const {notificationSetting, email} = this.sharedFacadeService.getSpecificState<User>(SharedStoreStateEnum.PROFILE_STATE);
        this.form.patchValue(notificationSetting)      
        this.emailPresent = !!email;  
    }

    saveNotificationSetting(): void {
        ((this.form.get('mode')?.value === NotificationModeEnum.Mail) && !this.emailPresent) ? this.openAddNotificationEmailComponent() :
            this.setNotification();
    }

    setNotification(): void {
        this.sharedFacadeService.setNotificationSettings(this.form.value).pipe(switchMap(() => 
            this.sharedFacadeService.getUserDetails())).subscribe(() => {
            this.closeModal();
        });
    }

    openAddNotificationEmailComponent(): void {
        this.dialogService.open(AddNotificationEmailComponent, {
            width: '556px'
        }).afterClosed.pipe(
            tap(value => !!value?.data && this.setNotification())
        ).subscribe();
    }

    closeModal = (): void  => this.ref.close();
}
