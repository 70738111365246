<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="search-container">

  <!--mobile-view-->
  <div fxHide.gt-xs fxFlex.xs="100" fxFlex.gt-sm="30" fxLayoutAlign.gt-sm="end center" ngClass.xs="mb-12" class="addon-menu">
    <!--<button sf-button outlined class='icon-button p-0' ngClass.xs='width--full'>
      <sf-icon class='font-size-20 mr-0'>settings</sf-icon>
    </button>-->
    <button sf-button primary ngClass.xs="width--full" data-cy="addItem"
            (click)="currentLevel.type === levelType.ProductNode ? openServiceModal('create') : createPackage()"
    >
      <!--        TODO: Jimba-->
      <!--        Need it back when choose from existing feature is added-->
      <!--              *ngIf="(currentLevel.type !== levelType.Addon)"-->
      <sf-icon>add</sf-icon>
      {{'Add ' + (currentLevel?.name | translateName)}}
    </button>
    <!-- TODO: Jimba Remove request from BA -->
    <!--      <span sfMenuSurfaceAnchor #customButtonMenu *ngIf="(currentLevel.type === levelType.Addon ? addonsDisplay : false) && buttonMenu">-->
    <!--        <button sf-button primary class="ml-12" (click)="customButton.open = !customButton.open" data-cy="createItem">-->
    <!--        <sf-icon>keyboard_arrow_down</sf-icon>-->
    <!--          {{buttonMenu | translate}}-->
    <!--         </button>-->
    <!--        <sf-menu #customButton [anchorElement]="customButtonMenu">-->
    <!--          <sf-list>-->
    <!--            <sf-list-item (click)="currentLevel.type === levelType.ProductNode ? openServiceModal('create') : createPackage()"-->
    <!--                          data-cy="createNew">-->
    <!--              <sf-icon class="mr-8">add</sf-icon>-->
    <!--              {{'BTN_CREATE_NEW' | translate}}-->
    <!--            </sf-list-item>-->
    <!--            <sf-list-item (click)="openPackageListModal()" data-cy="addExisting">-->
    <!--              <sf-icon class="mr-8">save_alt</sf-icon>-->
    <!--              {{'BTN_CHOOSE_EXISTING' | translate}}-->
    <!--            </sf-list-item>-->
    <!--          </sf-list>-->
    <!--        </sf-menu>-->
    <!--      </span>-->
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.lt-md="space-between center" fxFlex.lt-md="100"
       fxFlex.gt-sm="55">
    <div fxLayout="row" fxLayoutAlign="start center">
      <ew-pc-search-bar fxFlex.lt-sm="{{(currentLevel?.type === levelType.Addon) ? 100 : 82 }}" [searchFormControl]="$any(queryForm.get('query'))"></ew-pc-search-bar>
      <span *ngIf="currentLevel?.type !== levelType.Addon" role="button" aria-label="open filter modal" fxFlex.xs="10" class="search-container__filter cursor--pointer color--black-20 mt-4"
              (click)="openFiltersModal()" data-cy="filter">
        <sf-icon>tune</sf-icon>
      </span>
    </div>
  </div>
  <div *ngIf="currentLevel?.type === levelType.Product" fxFlex.xs="100" fxFlex.gt-sm="14" fxLayoutAlign.gt-sm="center center" fxLayoutAlign.xs="end" class="toggle-button" ngClass.gt-xs="ml-8" ngClass.lt-md="mt-12">
    <button sf-button outlined class="icon-button p-0 mr-8"
            [ngClass]="select? 'toggle-button--selected' : 'toggle-button--de-select'" (click)="viewConfig(true)">
      <sf-icon class="material-icons-outlined">view_module</sf-icon>
    </button>
    <button sf-button outlined class="icon-button p-0"
            [ngClass]="select? 'toggle-button--de-select' : 'toggle-button--selected' " (click)="viewConfig(false)">
      <sf-icon class="material-icons-outlined">view_list</sf-icon>
    </button>
<!--    <p class="color&#45;&#45;primary">dkfj</p>-->
  </div>
  <div fxHide.xs fxFlex.xs="100" fxFlex.gt-sm="30" fxLayoutAlign.gt-sm="end center" ngClass.lt-md="mt-12"
       class="addon-menu">
    <!--<button sf-button outlined class='icon-button p-0' ngClass.xs='width--full'>
      <sf-icon class='font-size-20 mr-0'>settings</sf-icon>
    </button>-->
    <button sf-button primary ngClass.xs="width--full" data-cy="addItem"
            (click)="currentLevel.type === levelType.ProductNode ? openServiceModal('create') : createPackage()"
    >
      <!--        TODO: Jimba-->
      <!--        Need it back when choose from existing feature is added-->
      <!--              *ngIf="(currentLevel.type !== levelType.Addon)"-->
      <sf-icon>add</sf-icon>
      {{'Add ' + (currentLevel?.name | translateName)}}
    </button>
    <!-- TODO: Jimba Remove request from BA -->
    <!--      <span sfMenuSurfaceAnchor #customButtonMenu *ngIf="(currentLevel.type === levelType.Addon ? addonsDisplay : false) && buttonMenu">-->
    <!--        <button sf-button primary class="ml-12" (click)="customButton.open = !customButton.open" data-cy="createItem">-->
    <!--        <sf-icon>keyboard_arrow_down</sf-icon>-->
    <!--          {{buttonMenu | translate}}-->
    <!--         </button>-->
    <!--        <sf-menu #customButton [anchorElement]="customButtonMenu">-->
    <!--          <sf-list>-->
    <!--            <sf-list-item (click)="currentLevel.type === levelType.ProductNode ? openServiceModal('create') : createPackage()"-->
    <!--                          data-cy="createNew">-->
    <!--              <sf-icon class="mr-8">add</sf-icon>-->
    <!--              {{'BTN_CREATE_NEW' | translate}}-->
    <!--            </sf-list-item>-->
    <!--            <sf-list-item (click)="openPackageListModal()" data-cy="addExisting">-->
    <!--              <sf-icon class="mr-8">save_alt</sf-icon>-->
    <!--              {{'BTN_CHOOSE_EXISTING' | translate}}-->
    <!--            </sf-list-item>-->
    <!--          </sf-list>-->
    <!--        </sf-menu>-->
    <!--      </span>-->
  </div>
</div>
