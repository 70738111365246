import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { Price } from '@ew/shared/services';

export const PRICE_TYPES_QUERY: TypedDocumentNode<{ priceTypes: Price[] }> = gql`
  query priceTypes{
    priceTypes {
      id
      name
      meta
      description
      pricingType
    }
  }
`;
