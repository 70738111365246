import {NgModule} from '@angular/core';

import {
  SfTypography,
  SfBody1,
  SfBody2,
  SfTypographyButton,
  SfCaption,
  SfHeadline1,
  SfHeadline2,
  SfHeadline3,
  SfHeadline4,
  SfHeadline5,
  SfHeadline6,
  SfOverline,
  SfSubtitle1,
  SfSubtitle2
} from './typography.directive';

const TYPOGRAPHY_DECLARATIONS = [
  SfTypography,
  SfBody1,
  SfBody2,
  SfTypographyButton,
  SfCaption,
  SfHeadline1,
  SfHeadline2,
  SfHeadline3,
  SfHeadline4,
  SfHeadline5,
  SfHeadline6,
  SfOverline,
  SfSubtitle1,
  SfSubtitle2
];

@NgModule({
  exports: TYPOGRAPHY_DECLARATIONS,
  declarations: TYPOGRAPHY_DECLARATIONS
})
export class SfTypographyModule { }
