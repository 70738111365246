import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as lpn from 'google-libphonenumber';

export const phoneNumberValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    const phoneUtil = lpn.PhoneNumberUtil.getInstance();
    let value = ((control?.value && control?.value?.toString()) || '').trim();
    try {
        //TODO: Might need in the future
        // if (/^00|^[+]/g.test(value)) {
        //     value = value.replace('00', '+');
        // }
        value = (value.startsWith('00') && !value.includes('+')) ? value.replace('00', '+') : value;
        if (value.startsWith('++') || value.endsWith('+')) return {invalidPhone: true};
        if ((/[a-zA-Z]/).test(value)) return {invalidPhone: true};
        const parsedNumber = value.startsWith('+') || value.startsWith('00') ? phoneUtil.parse(value) : phoneUtil.parse(value, 'CH');
        return parsedNumber && !phoneUtil.isValidNumber(parsedNumber) ? {invalidPhone: true} : null;
    } catch (err) {
        return {invalidPhone: true};
    }
};

export function validatePhoneNumber(value: string): boolean {
    const phoneUtil = lpn.PhoneNumberUtil.getInstance();
    let tempValue = ((value && value?.toString()) || '').trim();
    try { 
        tempValue = (tempValue.startsWith('00') && !tempValue.includes('+')) ? tempValue.replace('00', '+') : tempValue;
        if (tempValue.startsWith('++') || tempValue.endsWith('+')) return true;
        if ((/[a-zA-Z]/).test(tempValue)) return true;
        const parsedNumber = tempValue.startsWith('+') || 
            tempValue.startsWith('00') ? phoneUtil.parse(tempValue) : phoneUtil.parse(tempValue, 'CH');
        return parsedNumber && !phoneUtil.isValidNumber(parsedNumber) ? true : false;
    } catch (err) {
        return false;
    }
}

export function validateSpace(control: FormControl) {
    const SPACE_REGEXP = /^[^\s]+(\s+[^\s]+)*$/;
    return SPACE_REGEXP.test(control.value) ? null : {
        validateSpace: {
            valid: false
        }
    };
}
