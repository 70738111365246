<div class="service-list mt-24" data-cy="display">
  <ng-container>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="12px" *ngIf="!products?.length" class="my-40">
      <div fxFlex.gt-xs="50" fxLayoutAlign="center">
        <img src="/assets/images/no-data.svg" alt="no data"/>
      </div>
      <div fxFlex.gt-xs="55" class="text--center">
        <h6 sfHeadline6 class="bold color--black-87">
          {{'LABEL_NOT_ADDED_SERVICES' | translate}}
        </h6>
        <p sfSubtitle2 class="mt-8 color--black-60">
          {{'HINT_NOT_ADDED_SERVICES' | translate}}
        </p>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign.lt-md="space-between center"
         class="service-product">
      <ng-container *ngFor="let product of products">
          <sf-card outlined fxFlex.xs="100" fxFlex.lt-lg="0 0 calc((100% /3) - 16px)" fxFlex.lt-md="0 0 calc(50% - 12px)"
                   fxFlex.gt-md="0 0 calc(25% - 18px)" fxFlex.gt-lg="0 0 calc(20% - (24px-(24px/5))"
                   class="p-12 mb-24 service-product__card" ngClass.gt-sm="mr-24"
                   (click)="enterInsideLevel(product.id); $event.stopPropagation()" [attr.data-cy]="product?.packageName | translateName">
            <div fxLayout="row" fxLayoutAlign="space-between" data-cy="status">
              <sf-chip class="service-product__chip"
                       [ngClass]='product?.active ? "active": "inactive px-14"'>{{(product?.active ? 'LABEL_ACTIVE' : 'LABEL_INACTIVE') | translate}}</sf-chip>
              <ew-menu-modal [row]="product" [config]="productNodeMenuConfig"
                             (action)="emitAction($event)"></ew-menu-modal>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="service-product__image">
                <ng-container *ngIf="product?.images[0]?.urls[0]">
                  <img [src]="product?.images[0]?.urls[0]" alt="product"
                       class="service-product__image--size" />
                </ng-container>
              </div>
              <p fxLayoutAlign="center center" class="mt-12" [ngClass]="!product?.images[0]?.urls[0] ? 'service-product__card-name' : ''">
                <span>{{product?.packageName | translateName}}</span></p>
            </div>
          </sf-card>
      </ng-container>
    </div>
  </ng-container>

  <div class="text--center view-more-container cursor--pointer shadow mt-12 mb-16" *ngIf="viewMoreProducts">
    <p class="mt-4 mb-0 color--primary view-text">View More Products</p>
    <sf-icon class="font-size-48 view-more color--primary" data-cy="viewMore">
      expand_more
    </sf-icon>
    <div class="shadow"></div>
  </div>
</div>
