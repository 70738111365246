import { DataTypeEnum, DynamicColumn } from '../graphql';
import { MultiLanguages } from '../shared.model';
import { DynamicOptionModel } from './dynamic-option.model';

export class DynamicColumnModel implements DynamicColumn {
  dataType: DataTypeEnum = DataTypeEnum.Options;
  dynamicOptions: DynamicOptionModel[] = [];
  id: string = undefined;
  meta: Record<string, unknown>;
  multilingual: boolean = true;
  name: MultiLanguages = new MultiLanguages();
  required: boolean = undefined;
  _destroy: boolean = undefined;
}
