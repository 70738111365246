import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { ProductTree } from '@ew/shared/services';

export const PRODUCT_TREE_QUERY: TypedDocumentNode<{productTree: ProductTree}> = gql`
  query productTree($id: ID!) {
    productTree(id: $id) {
      id
      active
      packageId
      ancestry
      ancestryDepth
      package {
        configuratorPriceTypeId
        name
        levelId
        id
        packageContractDurations {
          contractDurationId
          id
        }
        deliveryTime {
          id
          maximum
          minimum
        }
        productLimit {
          id
          minimum
          maximum
        }
        dynamicColumns {
          dataType
          id
          multilingual
          name
          required
          dynamicOptions {
            id
            hexValue
            value
            name
            meta
          }
        }
        dynamicValues {
          dynamicColumnId
          dynamicOptionId
          id
          name
          valuableId
          valuableType
          value
        }
        images {
          id
          urls
        }
      }
    }
  }`
