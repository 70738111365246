<div class="main-layout__position">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="sw-update px-10 py-8 br--8 bg--white"
         *ngIf="updateAvailable">
        <div fxFlex="24" fxFlex.xs="20" fxLayoutAlign="center center">
            <img src="/assets/images/update.svg" alt="update svg" class="sw-update__icon"/>
        </div>
        <div fxLayout="column" fxFlex="76" fxFlex.xs="80" fxLayoutAlign="start end" class="py-8 px-16">
            <p class="mt-0 mb-20">{{ 'HINT_NEW_VERSION_UPDATE' | translate }}</p>
            <button sf-button primary (click)="update()">{{ 'BTN_UPDATE' | translate }}</button>
        </div>
    </div>
    <div *ngIf="isOnline">
        <router-outlet></router-outlet>
    </div>
    <div *ngIf="!isOnline" fxLayout="column" fxLayoutAlign="center center" class="no-internet text--center"
         data-cy="noInternet">
        <svg width="200" height="157" viewBox="0 0 200 157" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M194.112 72.2671H139.484C139.484 72.2671 144.901 57.39 153.236 57.39C161.571 57.39 164.182 67.6771 164.182 67.6771C164.182 67.6771 169.809 62.0976 174.412 62.0976C179.016 62.0976 183.049 69.4455 183.049 69.4455C183.049 69.4455 189.592 65.4874 194.112 72.2671Z"
                  fill="#EBEBEB"/>
            <path d="M174.125 9.12885H148.593C148.593 9.12885 154.298 0.80127 158.794 0.80127C163.29 0.80127 164.615 6.34156 164.615 6.34156C164.615 6.34156 167.787 3.75511 170.724 4.21558C173.662 4.67605 174.125 9.12885 174.125 9.12885Z"
                  fill="#EBEBEB"/>
            <path d="M55.9965 90.2593H30.465C30.465 90.2593 36.1695 81.9316 40.6659 81.9316C45.1623 81.9316 46.5117 87.472 46.5117 87.472C46.5117 87.472 49.683 84.8855 52.6205 85.346C55.558 85.8064 55.9965 90.2593 55.9965 90.2593Z"
                  fill="#EBEBEB"/>
            <path d="M55.4263 56.0969H5.70312C5.70312 56.0969 12.5232 40.8085 19.7963 39.8532C27.0694 38.898 30.5672 51.213 30.5672 51.213C30.5672 51.213 35.2633 46.8582 38.9607 46.8582C42.6582 46.8582 45.5031 52.5895 45.5031 52.5895C45.5031 52.5895 52.8931 48.6658 55.4263 56.0969Z"
                  fill="#EBEBEB"/>
            <path d="M71.8965 19.4211H38.2834C38.2834 19.4211 44.0561 8.34543 50.3159 8.18378C56.5758 8.02212 59.8835 16.5898 59.8835 16.5898C59.8835 16.5898 69.6215 8.71283 71.8965 19.4211Z"
                  fill="#EBEBEB"/>
            <path d="M113.782 21.5814C112.446 24.9957 111.66 28.6022 111.453 32.2652H102.041V32.0006C102.066 31.7606 102.095 31.5108 102.114 31.2757C102.234 30.0182 102.443 28.7708 102.738 27.5429C103.227 25.5301 103.929 23.5758 104.833 21.7136C104.849 21.6685 104.88 21.6304 104.921 21.6054C104.962 21.5805 105.01 21.5703 105.057 21.5765H113.782V21.5814Z"
                  fill="#EBEBEB"/>
            <path d="M113.494 45.4567H104.847C104.802 45.4622 104.756 45.4515 104.718 45.4264C104.68 45.4013 104.651 45.3635 104.638 45.3196C103.64 43.2317 102.918 41.0217 102.49 38.7457C102.278 37.6456 102.136 36.533 102.066 35.4147C102.066 35.3804 102.066 35.3412 102.041 35.302V35.0424H102.183H111.292C111.458 35.0424 111.453 35.0424 111.463 35.2138C111.521 35.9731 111.57 36.7324 111.643 37.4916C111.765 38.6958 111.959 39.8915 112.223 41.0725C112.536 42.5182 112.948 43.9405 113.455 45.3294C113.47 45.3588 113.48 45.4029 113.494 45.4567Z"
                  fill="#EBEBEB"/>
            <path d="M149.87 48.2098L149.797 48.342C149.066 49.4666 148.251 50.5342 147.361 51.5359C146.278 52.7644 145.075 53.8803 143.771 54.8669C142.488 55.8413 141.118 56.694 139.679 57.4141C138.29 58.1078 136.842 58.6734 135.353 59.1041C134.17 59.445 132.966 59.7036 131.748 59.8781L131.397 59.9369H131.188L130.988 59.8095L131.217 59.6479C133.853 57.8409 136.16 55.5906 138.037 52.9957C139.098 51.5284 140.03 49.9714 140.823 48.342C140.839 48.2991 140.869 48.2628 140.908 48.2389C140.947 48.2149 140.992 48.2047 141.038 48.2098H149.884H149.87Z"
                  fill="#EBEBEB"/>
            <path d="M141.217 32.2645H129.068V21.5856H138.553C138.594 21.578 138.636 21.5865 138.672 21.6092C138.707 21.6319 138.732 21.6672 138.743 21.708C139.621 23.7139 140.275 25.812 140.691 27.9635C140.914 29.0838 141.077 30.2154 141.178 31.3534C141.169 31.6473 141.193 31.9461 141.217 32.2645Z"
                  fill="#EBEBEB"/>
            <path d="M141.145 35.1099C141.145 35.3597 141.145 35.5997 141.111 35.8642C141.057 36.457 141.013 37.0546 140.931 37.6424C140.819 38.4605 140.692 39.2786 140.531 40.0917C140.169 41.8744 139.642 43.6193 138.958 45.3038C138.945 45.3526 138.916 45.3952 138.874 45.424C138.833 45.4527 138.783 45.4657 138.734 45.4606H128.991V35.1099H141.145Z"
                  fill="#EBEBEB"/>
            <path d="M126.331 21.6483V32.3419H114.464C114.464 32.0676 114.493 31.803 114.517 31.5385C114.581 30.9409 114.629 30.3433 114.722 29.7456C114.868 28.8443 115.024 27.938 115.209 27.0367C115.607 25.2306 116.182 23.4684 116.924 21.7756C116.938 21.7339 116.966 21.6984 117.003 21.6752C117.041 21.652 117.085 21.6425 117.128 21.6483H126.326H126.331Z"
                  fill="#EBEBEB"/>
            <path d="M126.34 35.253V45.4567H116.86C116.815 45.4622 116.769 45.4514 116.731 45.4263C116.693 45.4013 116.664 45.3635 116.651 45.3195C115.93 43.584 115.39 41.7783 115.038 39.9311C114.858 38.9808 114.722 38.0207 114.605 37.0655C114.527 36.4385 114.507 35.8065 114.464 35.1746C114.464 35.1746 114.464 35.1403 114.464 35.1061H126.214C126.316 35.0963 126.34 35.1354 126.34 35.253Z"
                  fill="#EBEBEB"/>
            <path d="M154.103 32.3374H144.145C143.947 28.644 143.164 25.006 141.827 21.5606H151.082C151.128 21.554 151.174 21.563 151.214 21.5861C151.254 21.6092 151.285 21.6451 151.302 21.6879C152.365 23.805 153.137 26.0578 153.596 28.3843C153.841 29.6228 154.004 30.8763 154.083 32.1366C154.103 32.2101 154.103 32.264 154.103 32.3374Z"
                  fill="#EBEBEB"/>
            <path d="M154.108 35.0316C154.108 35.2716 154.108 35.5215 154.074 35.7272C153.87 38.735 153.141 41.6833 151.92 44.4369C151.784 44.7455 151.638 45.0541 151.497 45.3628C151.487 45.3929 151.467 45.4186 151.44 45.4356C151.413 45.4525 151.382 45.4597 151.35 45.4558H142.202H142.104L142.431 44.5153C143.352 41.7642 143.91 38.9035 144.092 36.0064C144.092 35.7223 144.126 35.4333 144.136 35.1443C144.136 35.0708 144.136 35.0316 144.243 35.0414H154.108V35.0316Z"
                  fill="#EBEBEB"/>
            <path d="M149.597 18.857C149.598 18.87 149.598 18.8832 149.597 18.8962H140.745C140.701 18.9013 140.657 18.8916 140.619 18.8685C140.581 18.8455 140.552 18.8104 140.536 18.7688C139.377 16.4448 137.925 14.2813 136.215 12.3321C135.207 11.2005 134.096 10.1657 132.897 9.24105C132.303 8.78058 131.689 8.3495 131.08 7.90863L130.978 7.83026L131.085 7.77147C131.085 7.71759 131.051 7.6637 131.027 7.58533C134.743 8.01167 138.324 9.23883 141.527 11.1831C144.729 13.1275 147.476 15.7432 149.582 18.8521L149.597 18.857Z"
                  fill="#EBEBEB"/>
            <path d="M124.645 59.8187C124.562 59.8405 124.477 59.8537 124.392 59.8579C123.435 59.7325 122.486 59.5476 121.552 59.3043C117.799 58.3386 114.312 56.5335 111.351 54.0237C110.227 53.0803 109.191 52.0377 108.252 50.9082C107.547 50.0541 106.896 49.1559 106.304 48.2189L106.265 48.1356H114.522C114.566 48.1319 114.61 48.1429 114.647 48.1668C114.684 48.1907 114.712 48.2262 114.727 48.2678C116.326 51.5688 118.519 54.5434 121.196 57.0412C122.244 58.0343 123.368 58.9433 124.557 59.7599C124.557 59.7599 124.596 59.7746 124.645 59.8187Z"
                  fill="#EBEBEB"/>
            <path d="M124.533 7.8404C124.167 8.11472 123.729 8.42333 123.305 8.74664C121.898 9.85713 120.589 11.088 119.393 12.4255C117.769 14.2074 116.384 16.1951 115.272 18.3381C115.204 18.4752 115.14 18.6124 115.062 18.7447C115.032 18.7866 114.987 18.8148 114.936 18.823C114.79 18.823 114.639 18.823 114.493 18.823H106.552C110.97 12.5528 116.899 8.78093 124.445 7.62976L124.391 7.73263L124.533 7.8404Z"
                  fill="#EBEBEB"/>
            <path d="M137.593 48.2195C136.476 50.1925 135.128 52.0244 133.579 53.6765C132.213 55.1606 130.675 56.4762 129 57.5954V48.2195H137.593Z"
                  fill="#EBEBEB"/>
            <path d="M126.325 48.2195V57.4093C122.896 55.0419 120.072 51.8931 118.083 48.2195H126.325Z" fill="#EBEBEB"/>
            <path d="M137.32 18.818H129.068V10.0642C130.764 11.1777 132.319 12.4936 133.701 13.9831C135.072 15.4634 136.285 17.0838 137.32 18.818Z"
                  fill="#EBEBEB"/>
            <path d="M126.331 10.3381V18.8911H118.419C120.353 15.4559 123.063 12.5261 126.331 10.3381Z" fill="#EBEBEB"/>
            <path d="M22.5579 139.735C20.1221 139.823 17.399 139.96 15.3773 138.373C13.5115 136.903 11.8503 134.713 12.0939 132.2C12.167 131.485 12.3326 131.044 13.0926 131.348C14.2568 131.838 15.1532 133.107 15.9619 134.013C16.9843 135.193 18.1164 136.272 19.3427 137.236C20.2098 137.907 21.1451 138.485 22.0317 139.132C22.1438 139.215 22.2558 139.294 22.363 139.382L22.4897 139.475H22.5579C22.578 139.475 22.5979 139.48 22.6158 139.489C22.6336 139.498 22.6489 139.512 22.6602 139.529V139.568C22.6661 139.586 22.668 139.605 22.6659 139.623C22.6638 139.642 22.6576 139.659 22.6478 139.675C22.6381 139.691 22.6249 139.705 22.6094 139.715C22.5938 139.725 22.5763 139.732 22.5579 139.735Z"
                  fill="#A3A6AC"/>
            <path d="M22.6797 139.573C20.5314 139.538 18.2174 139.636 16.5027 138.172C15.7149 137.467 15.0439 136.64 14.5151 135.723C14.2666 135.316 13.5408 134.106 13.8282 133.626C14.3884 132.754 16.9411 136.305 17.2967 136.648C18.638 137.863 20.2694 138.706 22.0318 139.098C22.1438 139.181 22.2559 139.259 22.363 139.347C21.1115 139.087 19.9104 138.624 18.8069 137.976C17.287 137.06 16.4393 135.713 15.2458 134.454C14.9925 134.18 14.2131 133.263 13.9208 133.832C13.7308 134.209 14.4469 135.394 14.6028 135.649C15.1109 136.51 15.7448 137.289 16.4832 137.961C18.1931 139.46 20.4973 139.431 22.6602 139.499L22.6797 139.573Z"
                  fill="white"/>
            <path d="M24.6042 135.003C24.6487 133.858 24.5306 132.714 24.2534 131.603C24.156 131.221 23.6786 129.379 23.0599 129.428C22.6556 129.428 22.5728 130.246 22.5728 130.511C22.4364 131.623 22.792 132.759 23.0112 133.846C23.4349 136.05 23.697 138.281 23.7955 140.523L23.7371 140.949L23.6932 141.302V141.425C23.6906 141.455 23.6906 141.487 23.6932 141.518C23.7047 141.54 23.7237 141.558 23.7468 141.568C23.77 141.578 23.7958 141.579 23.8199 141.571C23.8199 141.478 23.8199 141.385 23.8589 141.297C23.8589 141.371 23.8589 141.444 23.8589 141.518L23.9027 141.287V141.233C23.9027 141.106 23.9027 140.979 23.9027 140.861C24.2524 138.924 24.4866 136.968 24.6042 135.003ZM23.8637 140.033C23.8053 138.721 23.6737 137.413 23.4886 136.114C23.3425 135.066 23.1476 134.028 22.9284 132.994C22.7857 132.412 22.6912 131.819 22.6459 131.221C22.6459 130.765 22.6897 129.075 23.5568 130.124C24.1804 130.883 24.3314 132.499 24.4093 133.43C24.4832 134.465 24.462 135.505 24.346 136.536C24.2291 137.726 24.0391 138.892 23.8637 140.033Z"
                  fill="white"/>
            <path d="M25.2176 147.964H25.1299C22.8988 147.851 19.9272 148.062 18.0711 146.577C16.7023 145.48 15.3577 143.315 15.7913 141.478C16.2784 139.406 19.0649 141.889 19.8005 142.492C21.7198 144.18 23.543 145.976 25.2614 147.871L25.2907 147.91C25.315 147.929 25.2663 147.949 25.2176 147.964Z"
                  fill="#A3A6AC"/>
            <path d="M25.2906 147.896L24.9058 147.656C23.4443 146.725 22.0121 145.736 20.6384 144.673C20.2925 144.408 17.7788 142.16 17.4037 142.963C17.1747 143.453 18.0029 144.433 18.266 144.771C18.7432 145.369 19.3161 145.884 19.9612 146.294C21.4227 147.225 23.2495 147.426 24.935 147.876L25.1737 147.935L25.2127 147.96C25.2663 147.95 25.3101 147.93 25.2906 147.896ZM20.9063 146.617C20.1854 146.3 19.5258 145.857 18.9577 145.309C18.5826 144.957 17.2624 143.615 17.6132 143.061C17.7983 142.767 17.9542 142.924 18.3098 143.1C18.4786 143.18 18.6414 143.271 18.7969 143.375C19.172 143.619 19.5131 143.908 19.8638 144.188C20.4744 144.681 21.0963 145.158 21.7296 145.618C22.7672 146.382 23.8341 147.088 24.9204 147.773C23.5661 147.381 22.1534 147.195 20.8819 146.617H20.9063Z"
                  fill="white"/>
            <path d="M29.2707 143.34C28.687 145.911 27.3558 148.249 25.4466 150.056L25.4076 150.081C25.386 150.087 25.3628 150.087 25.3417 150.079C25.3205 150.071 25.3026 150.056 25.2907 150.037C25.2746 150.019 25.2656 149.997 25.2656 149.973C25.2656 149.949 25.2746 149.927 25.2907 149.909L25.4027 149.792C25.773 146.745 26.1773 143.713 26.4891 140.656C26.5621 139.936 27.1224 135.351 28.5253 136.095C29.3925 136.556 29.4509 138.437 29.4996 139.279C29.6283 140.637 29.5511 142.006 29.2707 143.34Z"
                  fill="#A3A6AC"/>
            <path d="M28.4815 139.254C28.4036 138.999 28.2721 138.74 27.9944 138.916C27.4488 139.259 27.2344 141.414 27.1419 142.012C26.7668 144.461 26.1676 146.837 25.5976 149.242L25.4515 149.855L25.3151 150.036C25.327 150.055 25.3449 150.07 25.3661 150.078C25.3872 150.086 25.4104 150.087 25.432 150.08V150.016C25.4953 149.938 25.5586 149.855 25.6171 149.771C26.5719 148.454 27.3165 146.994 27.8239 145.446C28.1444 144.466 28.3615 143.454 28.4718 142.428C28.5887 141.503 28.7836 140.18 28.4815 139.254ZM25.6171 149.605L25.5343 149.727C25.5635 149.644 25.5879 149.561 25.6122 149.478C26.1858 147.644 26.6414 145.776 26.9762 143.883C27.137 142.904 27.2393 141.948 27.4342 140.998C27.5219 140.591 27.7167 139.44 28.048 139.254C28.3792 139.068 28.3744 139.519 28.4085 139.773C28.4203 140.007 28.4203 140.241 28.4085 140.474C28.4613 143.738 27.484 146.934 25.6171 149.605Z"
                  fill="white"/>
            <path d="M25.8611 156.629H25.374V155.448C25.3204 153.856 25.2571 152.26 25.1645 150.667C25.1109 149.761 25.0476 148.86 24.9501 147.963C24.9477 147.934 24.9477 147.905 24.9501 147.875C24.9501 147.802 24.9501 147.728 24.9209 147.655C24.8917 147.581 24.9209 147.498 24.8917 147.415C24.6887 145.395 24.1725 143.42 23.362 141.561C23.0795 140.944 22.7758 140.336 22.4511 139.739L22.3585 139.567L22.3293 139.518C22.3001 139.46 22.266 139.401 22.2319 139.342L22.1588 139.21C21.126 137.383 19.9423 135.639 18.8267 133.88C18.5052 133.39 18.1837 132.876 17.8524 132.366C17.8328 132.338 17.8149 132.309 17.7988 132.278C17.7717 132.243 17.7472 132.205 17.7257 132.166C16.3179 129.937 14.9344 127.683 13.4778 125.494C13.4193 125.415 13.5509 125.342 13.6045 125.42C14.5788 126.89 15.6115 128.305 16.6248 129.741C16.7612 129.932 16.8927 130.128 17.0291 130.314L17.828 131.45C18.359 132.215 18.8803 132.974 19.3869 133.743C20.8902 135.884 22.2617 138.115 23.4936 140.425C23.5813 140.601 23.6641 140.777 23.7469 140.954C23.8297 141.13 23.8541 141.174 23.8979 141.287C23.9418 141.399 24.0197 141.541 24.0733 141.669C24.9845 143.916 25.4925 146.307 25.5737 148.733C25.5737 148.899 25.5737 149.071 25.6029 149.222C25.6029 149.301 25.6029 149.379 25.6029 149.458C25.6005 149.5 25.6005 149.542 25.6029 149.585C25.6029 149.639 25.6029 149.688 25.6029 149.747V149.869C25.6029 150.011 25.6029 150.158 25.6029 150.305C25.6711 151.897 25.7101 153.489 25.7442 155.081C25.7978 155.61 25.8173 156.12 25.8611 156.629Z"
                  fill="#A3A6AC"/>
            <path d="M12.5227 127.787C11.3049 126.136 9.73626 122.727 11.5484 120.958C11.8755 120.615 12.3171 120.406 12.7884 120.371C13.2596 120.335 13.7272 120.477 14.1011 120.767C15.0754 121.458 15.4749 122.766 15.7818 123.848C16.6099 126.734 17.1555 129.678 18.1834 132.509C18.1834 132.509 18.2711 132.754 18.2955 132.813C18.3198 132.872 18.2273 132.896 18.2029 132.842L18.1006 132.563C16.1471 131.133 13.9744 129.746 12.5227 127.787Z"
                  fill="#A3A6AC"/>
            <path d="M18.1928 132.504C18.1813 132.489 18.1715 132.472 18.1636 132.455C17.7402 131.765 17.358 131.05 17.0188 130.314C16.34 128.775 15.7543 127.197 15.2651 125.587C15.0702 124.965 14.1934 121.933 13.1119 123.138C12.6589 123.628 12.7222 124.549 12.7953 125.171C12.9191 126.172 13.2387 127.14 13.7355 128.017C14.7098 129.766 16.2053 131.093 17.7885 132.274L18.0029 132.435L18.0467 132.514L18.0759 132.563C18.0759 132.563 18.0759 132.563 18.1052 132.563C18.1136 132.567 18.123 132.57 18.1327 132.571C18.1424 132.571 18.1521 132.57 18.161 132.566C18.17 132.562 18.1779 132.556 18.1843 132.549C18.1907 132.542 18.1953 132.533 18.1977 132.523C18.1975 132.517 18.1958 132.51 18.1928 132.504ZM17.7057 132.166C16.3904 131.193 15.2306 130.023 14.2664 128.698C13.7832 127.994 13.4072 127.221 13.1509 126.405C13.0381 126.041 12.9566 125.667 12.9073 125.288C12.8664 125.006 12.8501 124.721 12.8586 124.436C12.8586 123.814 13.1363 123.133 13.5016 123.069C13.9547 122.986 14.1057 123.251 14.4029 123.775C14.5035 123.953 14.5945 124.136 14.6757 124.323C14.8754 124.813 15.0264 125.278 15.1628 125.759C15.4161 126.562 15.6499 127.36 15.9568 128.154C16.1517 128.688 16.3612 129.222 16.5853 129.741C16.9702 130.643 17.4179 131.518 17.9249 132.357C17.8762 132.298 17.7934 132.234 17.7154 132.166H17.7057Z"
                  fill="white"/>
            <path d="M184.101 132.386C183.897 134.023 183.59 135.644 183.258 137.256C183.073 138.172 182.879 139.088 182.693 140.004C182.489 141.018 182.206 142.046 182.464 143.065C182.469 143.113 182.469 143.16 182.464 143.207C182.45 143.224 182.432 143.237 182.411 143.244C182.39 143.251 182.368 143.253 182.346 143.249C182.325 143.245 182.304 143.236 182.287 143.222C182.271 143.207 182.258 143.189 182.25 143.168V143.1C182.103 142.829 181.985 142.543 181.899 142.247C181.586 141.475 181.316 140.685 181.091 139.881C180.619 138.239 180.354 136.544 180.301 134.836C180.263 133.305 180.427 131.777 180.789 130.29C181.081 129.099 181.49 127.326 182.372 126.415C183.171 125.597 183.833 126.905 184.067 127.581C184.54 129.095 184.291 130.838 184.101 132.386Z"
                  fill="#A3A6AC"/>
            <path d="M182.645 128.913C182.051 128.913 181.617 130.838 181.408 131.671C181.083 132.985 180.919 134.334 180.921 135.688C180.925 138.118 181.34 140.53 182.148 142.82C182.177 142.913 182.211 143.006 182.245 143.099C182.244 143.122 182.244 143.145 182.245 143.168C182.253 143.188 182.266 143.206 182.282 143.22C182.299 143.234 182.319 143.243 182.34 143.246C182.362 143.25 182.383 143.248 182.404 143.24C182.424 143.232 182.441 143.219 182.455 143.202C182.416 143.001 182.387 142.805 182.362 142.604C182.338 142.404 182.323 142.242 182.314 142.066C182.229 140.089 182.35 138.109 182.674 136.158C182.976 133.895 183.429 128.918 182.645 128.913ZM182.236 142.482C182.265 142.746 182.309 143.011 182.358 143.271C182.294 143.08 182.236 142.889 182.177 142.698C181.525 140.661 181.15 138.545 181.062 136.408C181.021 135.202 181.108 133.995 181.32 132.807C181.435 132.171 181.59 131.543 181.783 130.926C181.866 130.667 182.294 129.055 182.664 129.138C182.918 129.192 182.981 130.647 182.986 130.676C182.986 130.965 182.986 131.259 182.986 131.548C182.986 132.151 182.937 132.753 182.888 133.351C182.776 134.644 182.591 135.938 182.435 137.231C182.165 138.967 182.098 140.73 182.236 142.482Z"
                  fill="white"/>
            <path d="M193.44 136.908C192.353 139.044 190.517 140.68 188.622 142.086C187.604 142.836 186.556 143.556 185.524 144.266C184.563 144.862 183.665 145.555 182.844 146.333L182.742 146.446C182.531 146.682 182.347 146.94 182.191 147.215C182.186 147.224 182.178 147.232 182.169 147.238C182.159 147.244 182.149 147.248 182.138 147.249C182.129 147.281 182.117 147.312 182.104 147.342H182.06V147.24C182.051 147.228 182.046 147.213 182.046 147.198C182.046 147.183 182.051 147.168 182.06 147.156C182.058 147.145 182.058 147.133 182.06 147.122C182.058 147.088 182.058 147.053 182.06 147.019C182.372 144.521 184.111 141.993 185.577 140.073C187.043 138.153 188.861 136.448 191.131 135.566C191.701 135.346 192.997 134.738 193.566 135.204C194.136 135.669 193.679 136.453 193.44 136.908Z"
                  fill="#A3A6AC"/>
            <path d="M191.764 136.918C191.243 136.394 189.723 137.683 189.328 137.981C188.192 138.918 187.138 139.95 186.177 141.068C184.534 142.801 183.174 144.785 182.148 146.946C182.131 146.97 182.113 146.993 182.094 147.014V147.122C182.092 147.134 182.092 147.145 182.094 147.157C182.085 147.169 182.08 147.183 182.08 147.198C182.08 147.213 182.085 147.228 182.094 147.24L182.172 147.152C182.357 146.917 182.562 146.691 182.762 146.466L182.864 146.353C184.345 144.859 185.921 143.462 187.58 142.17C188.719 141.247 189.77 140.219 190.717 139.098C190.941 138.79 192.198 137.354 191.764 136.918ZM191.277 138.06C189.991 140.205 187.765 141.773 185.875 143.336C184.571 144.36 183.369 145.509 182.284 146.765C183.292 144.852 184.548 143.082 186.021 141.504C186.824 140.574 187.678 139.689 188.578 138.853C189.065 138.4 189.585 137.982 190.132 137.604C190.46 137.374 190.813 137.183 191.184 137.036C191.905 136.806 191.491 137.692 191.287 138.075L191.277 138.06Z"
                  fill="white"/>
            <path d="M181.85 152.304C181.838 152.323 181.821 152.338 181.802 152.349C181.783 152.36 181.761 152.367 181.738 152.367L181.768 152.407C181.774 152.414 181.778 152.424 181.778 152.434C181.778 152.444 181.774 152.453 181.768 152.461C181.76 152.467 181.751 152.471 181.741 152.471C181.731 152.471 181.721 152.467 181.714 152.461C179.702 150.398 177.763 147.895 176.76 145.162C176.326 144.012 176.083 142.799 176.039 141.571C176.014 140.728 175.971 139.034 176.687 138.421C178.148 137.192 179.439 142.418 179.634 143.202C179.99 144.642 180.223 146.102 180.555 147.547C180.915 149.125 181.388 150.673 181.86 152.221C181.867 152.249 181.863 152.278 181.85 152.304Z"
                  fill="#A3A6AC"/>
            <path d="M181.851 152.304C181.768 152.171 181.695 152.034 181.622 151.902C180.404 149.71 179.53 147.342 179.03 144.882C178.809 143.639 178.483 142.416 178.056 141.228C177.939 140.939 177.568 139.822 177.106 140.385C176.857 140.679 177.062 142.791 177.159 143.325C177.404 144.565 177.796 145.77 178.328 146.915C179.202 148.843 180.312 150.653 181.631 152.304L181.743 152.451C181.75 152.443 181.754 152.434 181.754 152.424C181.754 152.414 181.75 152.404 181.743 152.397L181.714 152.358C181.74 152.363 181.766 152.361 181.791 152.352C181.815 152.342 181.836 152.325 181.851 152.304ZM178.928 147.812C178.343 146.704 177.879 145.537 177.544 144.329C177.412 143.846 177.311 143.355 177.242 142.859C177.181 142.473 177.147 142.084 177.14 141.693C177.14 140.738 177.223 140.493 177.383 140.459C177.544 140.425 177.617 140.542 177.9 141.11C178.071 141.484 178.206 141.873 178.304 142.271C178.45 142.835 178.577 143.403 178.699 143.971C178.947 145.108 179.186 146.254 179.503 147.366C179.967 148.97 180.641 150.505 181.505 151.931C180.532 150.634 179.67 149.255 178.928 147.812Z"
                  fill="white"/>
            <path d="M188.31 147.292C188.072 148.298 187.622 149.24 186.99 150.055C185.792 151.716 184.262 153.097 182.956 154.665C182.761 154.9 182.571 155.14 182.386 155.385C182.386 155.409 182.221 155.757 182.221 155.728C182.221 155.698 182.221 155.591 182.289 155.37C182.498 154.273 183.185 150.961 184.043 149.252C184.555 148.177 185.195 147.169 185.947 146.249C186.318 145.833 187.19 144.922 187.842 145.117C188.495 145.313 188.412 146.768 188.31 147.292Z"
                  fill="#A3A6AC"/>
            <path d="M187.243 146.623C186.547 146.187 184.739 149.425 184.501 149.846C183.604 151.394 182.913 153.054 182.445 154.784C182.386 154.975 182.338 155.171 182.289 155.366C182.24 155.562 182.221 155.724 182.221 155.729C182.221 155.734 182.372 155.415 182.391 155.386L182.484 155.259C182.64 155.058 182.8 154.857 182.971 154.671C183.755 153.49 184.559 152.324 185.319 151.124C185.878 150.269 186.367 149.37 186.78 148.435C186.853 148.219 187.55 146.814 187.243 146.623ZM187.043 147.539C186.069 150.355 184.018 152.574 182.469 155.077L182.391 155.195C182.416 155.126 182.435 155.048 182.459 154.975C182.878 153.556 183.427 152.18 184.101 150.865C184.516 150.064 184.978 149.288 185.485 148.543C185.745 148.122 186.033 147.72 186.347 147.338C186.493 147.181 186.902 146.755 187.043 146.848C187.185 146.941 187.107 147.348 187.043 147.539Z"
                  fill="white"/>
            <path d="M195.33 124.04C193.255 128.845 187.799 131.079 185.436 135.708V135.733C185.363 135.875 185.295 136.017 185.227 136.164C185.217 136.176 185.206 136.186 185.192 136.192C185.179 136.199 185.164 136.203 185.149 136.203C185.133 136.203 185.119 136.199 185.105 136.192C185.091 136.186 185.08 136.176 185.071 136.164C185.062 136.151 185.058 136.137 185.058 136.122C185.058 136.107 185.062 136.093 185.071 136.08C185.085 136.045 185.101 136.011 185.119 135.977C185.588 133.023 186.578 130.176 188.042 127.571C189.236 125.362 190.873 123.29 193.138 122.124C193.693 121.835 194.784 121.228 195.408 121.703C196.032 122.178 195.588 123.452 195.33 124.04Z"
                  fill="#A3A6AC"/>
            <path d="M193.44 124.25C193.177 124.049 192.777 124.289 192.548 124.406C191.982 124.705 191.453 125.068 190.97 125.489C189.99 126.362 189.143 127.376 188.456 128.497C187.226 130.382 186.231 132.412 185.494 134.542C185.334 135.031 185.192 135.521 185.08 136.011C185.08 136.075 185.056 136.134 185.041 136.197C185.05 136.21 185.062 136.219 185.076 136.226C185.089 136.233 185.104 136.237 185.119 136.237C185.134 136.237 185.149 136.233 185.163 136.226C185.176 136.219 185.188 136.21 185.197 136.197C185.261 136.055 185.329 135.918 185.397 135.781C185.397 135.781 185.397 135.756 185.397 135.742C185.793 134.783 186.283 133.866 186.858 133.003C187.833 131.686 188.892 130.434 190.03 129.256C190.449 128.766 194.375 124.921 193.44 124.25ZM193.108 125.357C192.475 126.488 191.496 127.409 190.644 128.364C189.791 129.32 188.909 130.295 188.062 131.274C187.384 132.053 186.775 132.889 186.24 133.773C185.794 134.508 185.414 135.282 185.105 136.085C185.33 135.261 185.609 134.453 185.938 133.665C186.523 132.277 187.196 130.929 187.954 129.628C187.961 129.609 187.969 129.591 187.979 129.574C188.615 128.41 189.386 127.326 190.278 126.346C190.771 125.821 191.32 125.353 191.915 124.95C192.597 124.504 193.196 124.215 193.376 124.436C193.557 124.656 193.318 125.029 193.128 125.337L193.108 125.357Z"
                  fill="white"/>
            <path d="M188.081 129.668C187.458 131.025 186.863 132.397 186.269 133.773C185.991 134.42 185.714 135.067 185.446 135.708C185.446 135.708 185.446 135.733 185.446 135.748C185.344 135.978 185.241 136.213 185.144 136.443C184.247 138.55 183.254 140.71 182.708 142.939C182.435 144.124 182.271 145.333 182.221 146.549C182.221 146.671 182.221 146.799 182.221 146.926V147.132C182.218 147.171 182.218 147.21 182.221 147.249C182.182 148.303 182.197 149.356 182.221 150.385C182.245 151.815 182.367 153.24 182.503 154.656C182.499 154.698 182.499 154.741 182.503 154.783C182.503 154.847 182.503 154.911 182.503 154.974C182.501 155.009 182.501 155.043 182.503 155.077V155.259C182.547 155.714 182.591 156.17 182.63 156.63H182.192C181.912 155.236 181.738 153.823 181.67 152.403C181.668 152.373 181.668 152.344 181.67 152.315C181.67 152.177 181.67 152.035 181.646 151.903C181.622 151.771 181.646 151.56 181.622 151.413C181.622 151.002 181.597 150.595 181.588 150.189C181.472 147.818 181.635 145.442 182.075 143.11L182.119 142.919C182.119 142.895 182.119 142.87 182.119 142.841L182.148 142.704L182.236 142.483C182.27 142.356 182.309 142.224 182.348 142.101L182.377 141.983C183.046 139.988 183.848 138.041 184.778 136.154L185.266 135.125C185.358 134.934 185.446 134.743 185.538 134.557L185.962 133.695C186.625 132.343 187.316 131.011 187.979 129.659C187.998 129.556 188.081 129.605 188.081 129.668Z"
                  fill="#A3A6AC"/>
            <path d="M0.407837 156.63L25.2865 156.512L50.1603 156.468L99.9078 156.385L149.655 156.468L174.534 156.512L199.408 156.63L174.534 156.752L149.655 156.792L99.9078 156.875L50.1603 156.792L25.2865 156.748L0.407837 156.63Z"
                  fill="#A3A6AC"/>
            <path d="M144.861 88.0888C143.492 89.4569 142.122 90.8251 140.756 92.1902C139.976 92.9685 139.205 93.7559 138.428 94.5433C127.392 83.7746 114.258 78.0346 98.8806 78.0285C83.5033 78.0224 70.3663 83.7564 59.2521 94.5889C58.5204 93.8198 57.7857 93.0323 57.0359 92.2601C55.6914 90.898 54.3399 89.5319 52.9814 88.1618C52.9437 88.1325 52.9019 88.109 52.8574 88.0919V87.9915C52.9602 87.9094 53.063 87.8304 53.1598 87.7422C54.8892 86.2008 56.5733 84.6016 58.3541 83.121C66.4646 76.5414 76.0507 72.0535 86.2788 70.0477C88.9092 69.5643 91.579 69.312 94.2336 68.9867C95.1406 68.8742 101.015 68.8529 101.188 68.8681C103.002 69.0019 104.816 69.084 106.612 69.2786C111.609 69.8425 116.519 71.0187 121.233 72.781C124.652 74.0443 127.956 75.5995 131.111 77.4296C134.45 79.3452 137.606 81.5669 140.538 84.0665C142.019 85.3373 143.431 86.6781 144.877 87.9915L144.861 88.0888Z"
                  fill="#FFD6D3"/>
            <path d="M72.2801 107.52L65.7887 101.047C73.1811 93.7503 81.9189 89.1139 92.1926 87.7184C107.549 85.6298 120.728 90.3179 131.906 100.956L125.39 107.477C118.046 100.311 109.206 96.438 98.8442 96.444C88.4828 96.4501 79.6755 100.317 72.2801 107.52Z"
                  fill="#FFD6D3"/>
            <path d="M118.908 114.031L112.465 120.519C108.728 116.871 104.19 114.846 98.8598 114.852C93.5295 114.858 89.0245 116.865 85.2542 120.556L78.8082 114.077C89.0638 103.257 107.543 102.378 118.908 114.031Z"
                  fill="#EF9D98"/>
            <path d="M104.68 126.419C104.681 127.579 104.339 128.714 103.698 129.679C103.057 130.644 102.146 131.396 101.079 131.84C100.013 132.284 98.8395 132.4 97.7076 132.173C96.5757 131.946 95.5362 131.387 94.7208 130.565C93.9053 129.744 93.3505 128.698 93.1266 127.559C92.9026 126.421 93.0197 125.241 93.4629 124.17C93.9061 123.098 94.6555 122.183 95.6163 121.54C96.577 120.896 97.706 120.554 98.8601 120.557C100.405 120.559 101.885 121.178 102.976 122.277C104.067 123.376 104.68 124.866 104.68 126.419Z"
                  fill="#EF9D98"/>
        </svg>
        <p class="color--black-87 font--600 font-size-20 mt-32">{{ 'LABEL_NO_INTERNET' | translate }}</p>
        <p class="color--black-60 font-size-16 my-16 mx-32">{{ 'HINT_NO_INTERNET_CONNECTION' | translate }}</p>
        <button (click)="reload()" sf-button outlined class="width--half"
                data-cy="reload">{{ 'BTN_RELOAD' | translate }}
        </button>
    </div>
    <ng-container *ngIf="displayCookieBar">
          <div id="cookie-bar-container">
                <ew-cookie-bar (cookieBarEmittedValue)="cookieBarEmittedValue($event)"></ew-cookie-bar>
          </div>
    </ng-container>
</div>