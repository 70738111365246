import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfFormFieldComponent} from './form-field.component';
import {SfHelperText} from './helper-text.component';
import {SfCharacterCounterComponent} from './character-counter.component';

const FORM_FIELD_DECLARATIONS = [
  SfCharacterCounterComponent,
  SfFormFieldComponent,
  SfHelperText
];

@NgModule({
  imports: [CommonModule],
  exports: [FORM_FIELD_DECLARATIONS],
  declarations: [FORM_FIELD_DECLARATIONS]
})
export class SfFormsModule { }
