import { TypedDocumentNode } from '@apollo/client/core';
import { ProductTree } from '@ew/shared/services';
import { gql } from 'apollo-angular';

export const UPDATE_PRODUCT_TREE_MUTATION: TypedDocumentNode<{ updateProductTree: ProductTree }> = gql`
  mutation updateProductTree($input: UpdateProductTreeInput!) {
    updateProductTree(input: $input) {
      id
      packageId
    }
  }
`;
