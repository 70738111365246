import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { ProductItem } from '@ew/shared/services';

export const UPDATE_PRODUCT_ITEM_MUTATION: TypedDocumentNode<{ updateProductItem: ProductItem }> = gql`
  mutation updateProductItem($input: UpdateProductItemInput!) {
    updateProductItem(input: $input) {
      id
    }
  }
`;
