import { gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';

export const CONFIGURATION_MUTATION: DocumentNode = gql`
  mutation updateConfigurator($input: UpdateConfiguratorInput!) {
    updateConfigurator(input: $input) {
      chargeType
      configuratorPriceTypes {
        id
        priceTypeDescription
        priceTypeId
        priceTypeName
        pricingType
      }
      configured
      countries {
        countryCode
        currencyCode
        name
      }
      dynamicColumns {
        dataType
        dynamicOptions {
          hexValue
          id
          meta
          name
          value
        }
        id
        meta
        multilingual
        name
        required
      }
      id
    }
  }
`;
