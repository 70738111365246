import { MenuConfig, TabConfig, TableConfig } from '@ew/shared/services';

export const configTabs: TabConfig[] = [
    { label: 'Service', icon: 'info_outline', id: 1 },
    { label: 'Add-On', icon: 'info_outline', id: 2 },
    { label: 'Contract Duration', icon: 'info_outline', id: 3 },
];

export const PRODUCT_MENU_CONFIG: MenuConfig[] = [
    // {
    //   label: 'BTN_CLONE',
    //   icon: 'content_copy',
    //   actionKey: 'clone',
    //   cypressKey: 'clone',
    // },
    {
        label: 'BTN_EDIT_DETAILS',
        icon: 'edit',
        actionKey: 'edit',
        cypressKey: 'editDetails',
    },
    {
        label: 'BTN_ACTIVATE',
        icon: 'verified_user',
        actionKey: 'changeStatus',
        cypressKey: 'activateOrDeactivate',
        conditionBased: true,
        conditionalKey: 'active',
        negationLabel: 'BTN_DEACTIVATE',
    },
    {
        label: 'BTN_DELETE',
        icon: 'delete_outline',
        actionKey: 'delete',
        cypressKey: 'delete',
    },
];

export const PRODUCT_ITEM_MENU_CONFIG: MenuConfig[] = [
    // {
    //   label: 'BTN_CLONE',
    //   icon: 'content_copy',
    //   actionKey: 'clone',
    //   cypressKey: 'clone',
    // },
    {
        label: 'BTN_EDIT_DETAILS',
        icon: 'edit',
        actionKey: 'edit',
        cypressKey: 'editDetails',
    },
    {
        label: 'BTN_ACTIVATE',
        icon: 'verified_user',
        actionKey: 'changeStatus',
        cypressKey: 'activateOrDeactivate',
        conditionBased: true,
        conditionalKey: 'active',
        negationLabel: 'BTN_DEACTIVATE',
    },
    {
        label: 'BTN_DELETE',
        icon: 'delete_outline',
        actionKey: 'delete',
        cypressKey: 'delete',
    },
];

export const PRODUCT_ITEMS_TABLE_CONFIG: TableConfig[] = [
    {
        name: 'name',
        type: 'avatar',
        key: 'name',
        valueTranslate: true,
        image: 'images[0].urls[0]',
    },
    {
        name: 'mrc',
        type: 'text',
        key: 'prices[0].mrc'
    },
    {
        name: 'status',
        type: 'chip',
        key: 'active',
    },
    {
        name: 'action',
        ignoreHeader: true,
        type: 'menu_toggle',
    },
];

export const PRODUCT_TABLE_LIST_CONFIG: TableConfig[] = [
    {
        name: 'name',
        type: 'avatar',
        key: 'packageName',
        valueTranslate: true,
        image: 'images[0].urls[0]',
    },
    {
        name: 'region',
        type: 'avatar',
        key: 'regions'
    },
    
    {
        name: 'status',
        type: 'chip',
        key: 'active',
    },
    {
        name: 'action',
        ignoreHeader: true,
        type: 'menu_toggle',
    },
];

export const pcRootPath = '/product-configurator/product/';

export const columnConstant: string[] = ['OptionSet', 'options', 'action'];

export const staticColor: string[] = ['#f44336', '#e91e63', '#673ab7', '#2196f3', '#00bcd4', '#009688'];

export const dynamicControls = [
    {
        label: 'Name',
        key: 'name',
        type: 'input',
    },
];

