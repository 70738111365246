 <ng-container *ngIf="step === stepPage.UPDATE_PAGE">
    <ew-common-modal class="email-redirection__modal" data-cy="addNotificationEmail">
        <div class="color--black-87 font-size-22"> 
            <h6 class="font-size-22 color--black-87">{{'LABEL_ADD_EMAIL_ADDRESS' | translate}}</h6>
            <p sfSubtitle2 class="my-12 font-size-14 color--black-60"
               [innerHTML]="'HINT_ADD_NOTIFICATION_EMAIL_ADDRESS' | translate"></p>
            <sf-form-field class="mt-16" data-cy="addEmailAddressInputField">
                <sf-input outlined label="{{'LABEL_EMAIL_ADDRESS' | translate}}"
                          [formControl]="$any(form.get('unconfirmedEmail'))"
                          class="width--full mb-8 color--black-60" ></sf-input>
            </sf-form-field>
            <div fxLayout.xs="column" fxLayoutAlign="end" class="mt-16">
                <button sf-button outlined ngClass.gt-xs="mr-12" ngClass.xs="width--full mb-12" data-cy="cancel" (click)="cancel()">{{'BTN_CANCEL' | translate}}</button>
                <button sf-button primary ngClass.xs="width--full"
                        data-cy="save" (click)="subimt()" [disabled]="isLoading || 
                            form.get('unconfirmedEmail')?.invalid || !form.get('unconfirmedEmail').value">{{ 'BTN_SEND_PIN'| translate}}</button>
            </div>
        </div>
    </ew-common-modal>
</ng-container>
<div class="phone-authentication">
    <ew-common-modal *ngIf="step === stepPage.OTP_PAGE">
        <div title>
            <div fxLayoutAlign="space-between center">
                <h6 class="color--black-87 font-size-22 py-8">{{'LABEL_TWO_FACTOR_AUTHENTICATION' | translate}}</h6>
                <sf-icon class="color--primary font-size-22 cursor--pointer" (click)="goToPrevPage()" clickable>close
                </sf-icon>
            </div>

            <p class="color--black-60 font-size-14 mt-12">{{'HINT_SEND' | translate }} {{ ' ' + '"' + (props('unconfirmedEmail')?.value) + '"'}} 
            <p class="color--black-60 font-size-14 mb-12">{{'HINT_ENTER_PIN' | translate }}</p>

            <ew-timer [timeStartEvent]="restartTimerSubject$.asObservable()"></ew-timer>
            <ew-one-time-password (submitEvent)="update()" [otpForm]="form" (otpNumber)="getOtpNumbers($event)"></ew-one-time-password>
        </div>
        <div action>
            <button sf-button outlined class="common-modal__button" ngClass.gt-xs="mr-12" ngClass.xs="width--full mb-12" (click)="resendPin()"
                    data-cy="resendPin">{{'BTN_RESEND_PIN' | translate}}</button>
            <button sf-button ngClass.xs="width--full" ngClass.gt-xs="ml-12" primary (click)="update()" data-cy="update" 
                [disabled]="isLoading || (otpVals?.length !== 6)"
            >{{'BTN_UPDATE' | translate}}</button>
        </div>
    </ew-common-modal>
</div>
