import {
    ProductTreesQueryModel,
    ConfiguratorPrice,
    Configurator,
    ProductTreeList,
    Package,
    ProductItem,
    ContractDuration,
    LevelModel, ItemsQueryModel, TableDataSource, ProductItemsList
} from '@ew/shared/services';

export interface PCBreadcrumb {
    url?: string;
    label?: string;
}

export interface ProductsStoreState {
  productTrees: TableDataSource<ProductTreeList>;
  queryProductTrees: ProductTreesQueryModel;
  levels: LevelModel[];
  level: LevelModel;
  productTree: ProductTreeList;
  productItem: ProductItem,
  productItems: TableDataSource<ProductItemsList>;
  configurator: Configurator;
  contractDurations: ContractDuration[];
  price: ConfiguratorPrice[];
  package: Package;
  queryItems: ItemsQueryModel;
  breadcrumbs: PCBreadcrumb[];
}

export enum ProductStateEnum {
  PRODUCT_TREES = 'productTrees',
  PRODUCT_TREES_QUERY = 'queryProductTrees',
  LEVELS = 'levels',
  PRODUCT = 'productTree',
  CONFIGURATOR = 'configurator',
  CONTRACT_DURATIONS = 'contractDurations',
  PRICE = 'price',
  LEVEL = 'level',
  PACKAGE = 'packageState',
  PRODUCT_ITEM = 'productItem',
  PRODUCT_ITEMS = 'productItems',
  ITEMS_QUERY = 'queryItems',
  BREADCRUMBS = 'breadcrumbs'
}
