import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DialogRef, SfDialogConfig} from '@ew/shared/safire/components';
import {ADMIN_ROLE_SELECT_CONST, AdminRoleType, FILTER_RADIO_OPTIONS, 
    filterOptions, SharedFacadeService,
    SharedStoreStateEnum} from '@ew/shared/services';
import { cloneDeep } from '@apollo/client/utilities';

@Component({
    selector: 'ew-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    filterForm = new FormControl();
    filterRadioOptions: filterOptions[] = FILTER_RADIO_OPTIONS;
    isAdminListingTable!: boolean;
    roleOptions: AdminRoleType[] = cloneDeep(ADMIN_ROLE_SELECT_CONST);
    adminRoleFilterStateValue!: string[];


    constructor(private ref: DialogRef<FilterComponent>, private dialogConfig: SfDialogConfig, private sharedFacade: SharedFacadeService ) {
    	this.filterForm = dialogConfig.data.form;
    }

    ngOnInit(): void {
        this.initializer();
    }

    initializer(): void {
        this.isAdminListingTable = this.dialogConfig.data?.isAdminListingTable;
        (this.isAdminListingTable) && this.checkAndPatchValue();
    }

    checkAndPatchValue(): void {
        this.adminRoleFilterStateValue = this.sharedFacade.getSpecificState(SharedStoreStateEnum.CURRENT_ADMIN_ROLE_FILTER);
        this.adminRoleFilterStateValue?.length && this.patchRoleEnumValues();
    }

    patchRoleEnumValues(): void {
        this.roleOptions = this.roleOptions.map(roleOption => ({
            ...roleOption,
            selected: this.adminRoleFilterStateValue.includes(roleOption?.id)
        }))
    }

    applyFilter(): void {
        this.updateValueToState(true);
    	this.ref.close(
            {
                applyFilter: true,
                paramsValue: this.isAdminListingTable ? {
                    statusValue: this.filterForm.value,
                    roleOptionsValue : this.selectedAdminRoledIds
                } : this.filterForm.value
            });
    }

    updateValueToState(updateValue: boolean): void {
        this.sharedFacade.updateSpecificState(updateValue ? 
            this.selectedAdminRoledIds : null, SharedStoreStateEnum.CURRENT_ADMIN_ROLE_FILTER);
    }

    onChecked(event: {checked: boolean}, roleAdminId: string): void {
        this.roleOptions.find(roleAdmin => roleAdmin?.id === roleAdminId).selected = event.checked;
    }

    resetFilter(): void{
        this.filterForm.reset();
        this.roleOptions =  this.roleOptions.map(roleOption => ({...roleOption, selected: false}));
    };

    cancel(): void {
        this.ref.close({applyFilter: false});
    };

    private get selectedAdminRoledIds(): string[] {
        return this.roleOptions.filter(roleOption => roleOption?.selected).map(roleOption => roleOption?.id);
    }
}
