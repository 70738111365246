import { ModuleWithProviders, NgModule } from '@angular/core';
import { I18N_CONFIG, I18nConfig } from './models';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from './i18n.service';

@NgModule({
    imports: [],
    exports: [TranslateModule]
})
export class I18nModule {

    static forRoot(config?: I18nConfig): ModuleWithProviders<I18nModule> {
        return {
            ngModule: I18nModule,
            providers: [
                { provide: I18N_CONFIG, useValue: { ...new I18nConfig(), ...config } },
                I18nService
            ]
        };
    }
}
