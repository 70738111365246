import { ReactiveFormSchema } from '@ew/shared/utils/form-utils';
import { LEVEL_RULE_KEYS } from '../../../misc/level.constants';

import { ConfiguratorModel } from '../configurator.model';
import { DynamicColumnModel } from '../dynamic-column.model';
import { DynamicOptionModel } from '../dynamic-option.model';
import { LevelModel } from './level.model';

export class LevelFormBuilder {
  static levelFormInitializer = (): ReactiveFormSchema => ({
  	formModel: LevelModel,
  	skipRequiredValidators: ['id', 'meta', 'levelRule', 'dynamicColumns', 'rank', 
  		...LEVEL_RULE_KEYS.map(ruleKey => `levelRule.${ruleKey}`)]
  });

  static configFormInitializer = (): ReactiveFormSchema => ({
  	formModel: ConfiguratorModel,
  	skipRequiredValidators: ['id', 'dynamicColumns'],
  	customValidators: {
  		configuratorPriceTypes: [],
  		countries: []
  	}
  });

  static dynamicColumnInitializer = (): ReactiveFormSchema => ({
  	formModel: DynamicColumnModel,
  	skipRequiredValidators: ['id', 'meta', '_destroy', 'required', 'dynamicOptions']
  });

  static dynamicOptionInitializer = (): ReactiveFormSchema => ({
  	formModel: DynamicOptionModel,
  	skipRequiredValidators: ['id', 'meta', '_destroy', 'value', 'hexValue']
  });
}
