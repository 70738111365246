import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SfCustomValidators } from './validator';

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Form {
  export function markAllFormFieldsAsTouched(formGroup: FormGroup) {
      formGroup.markAsTouched({ onlySelf: true });
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              control.markAsTouched({ onlySelf: true });
              // control.markAsDirty({onlySelf: true});
              control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
          } else if (control instanceof FormGroup) {
              control.markAsTouched({ onlySelf: true });
              markAllFormFieldsAsTouched(control);
          } else if (control instanceof FormArray) {
              control.markAsTouched({ onlySelf: true });
              for (const ctrl of control.controls) {
                  if (ctrl instanceof FormGroup) {
                      markAllFormFieldsAsTouched(ctrl);
                  } else if (ctrl instanceof FormControl) {
                      ctrl.markAsTouched({ onlySelf: true });
                      ctrl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
                  }
              }
          }
      });
  }

  export function checkForUntouchedFields(formGroup: FormGroup) {
      resetErrorNull(formGroup);
      Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
              resetErrorNull(control);
          } else if (control instanceof FormGroup) {
              resetErrorNull(control);
              checkForUntouchedFields(control);
          } else if (control instanceof FormArray) {
              resetErrorNull(control);
              for (const ctrl of control.controls) {
                  if (ctrl instanceof FormGroup) {
                      checkForUntouchedFields(ctrl);
                  } else if (ctrl instanceof FormControl) {
                      resetErrorNull(ctrl);
                  }
              }
          }
      });
  }

  export function resetErrorNull(control: any) {
      control.setErrors(null);
      control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  export function updateValidations(
      form: FormGroup,
      neglect: Array<string> = []
  ) {
      const currentControl = form['controls'];
      Object.keys(currentControl).forEach(key => {
          if (!neglect.includes(key)) {
              formControlValidator(currentControl[key] as FormControl, key);

              if (currentControl[key] instanceof FormGroup) {
                  updateValidations(currentControl[key] as FormGroup, neglect);
              }

              if (currentControl[key] instanceof FormArray) {
                  for (const ctrl of currentControl[key]['controls']) {
                      if (ctrl instanceof FormGroup) {
                          updateValidations(currentControl[key] as FormGroup, neglect);
                      } else if (ctrl instanceof FormControl) {
                          formControlValidator(currentControl[key] as FormControl, key);
                      }
                  }
              }
          }
      });
  }

  export function formControlValidator(control: FormControl, key: string) {
      control.setValidators(Validators.required);
      if (key === 'email') {
          control.setValidators([
              SfCustomValidators.emailValidator(),
              Validators.required
          ]);
      }
      control.updateValueAndValidity();
  }

  // noinspection JSMethodCanBeStatic
  export function focusOnErrorElement(errorElement: string = 'sf-error') {
      const firstError = document.querySelectorAll(errorElement) as any;

      if (firstError.length > 0) {
          if (firstError[0].scrollIntoViewIfNeeded) {
              // console.log('scrollIntoViewIfNeeded available');
              firstError[0].scrollIntoViewIfNeeded();
          } else {
              // console.log('scrollIntoViewIfNeeded not available');
              firstError[0].focus();
          }
      }
  }

  export function formatIntlPhoneNumber(control: FormControl, type: string = 'INTERNATIONAL', selectedCountry?: string): void {
      try {
          const number = phoneUtil.parseAndKeepRawInput(control.value, selectedCountry);
          // tslint:disable-next-line:no-unused-expression
          number && control.setValue(phoneUtil.format(number, PNF[type]));
      } catch (e) {
      }
  }
}

export { Form as SfFormUtil };
