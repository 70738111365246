import {Injectable} from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {BaseStateService} from '../../../../services/src/lib/services/base/base-state.service';
import {SecurityStoreState} from '../models/security-store.state';

@Injectable({
    providedIn: 'root'
})

export class SecurityStateService extends BaseStateService<SecurityStoreState> {

    constructor() {
        super();
        this.initialState();
    }

    initialState(): void {
        this.setState({
            currentUserState: undefined,
            accessMatrices: undefined
        }, 'INIT_STATE');
    }
}
