import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OneTimePasswordComponent} from './one-time-password/one-time-password.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SfFormsModule, SfInputModule, SfTypographyModule} from '@ew/shared/safire/components';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {TimerComponent} from './timer/timer.component';
import { SharedHelperModule } from '@ew/shared/helper';

@NgModule({
    imports: [
        CommonModule, 
        ReactiveFormsModule, 
        SfFormsModule, 
        FlexLayoutModule, 
        SfInputModule, 
        SfTypographyModule, 
        TranslateModule, 
        SharedHelperModule],
    declarations: [
        OneTimePasswordComponent,
        TimerComponent
    ],
    exports: [
        OneTimePasswordComponent,
        TimerComponent
    ]
})
export class SharedComponentsOneTimePasswordModule {
}
