<ng-container *ngIf="levelState$ | async as state">
  <form [formGroup]="form" autocomplete="off">
    <h6 sfHeadline6 class="my-20">{{'BTN_SETUP_PRICING' | translate}}</h6>
    <sf-dialog-content class="level-config">
      <p sfSubtitle1 class="color--black-87">{{'QUEST_PRODUCT_COSTING' | translate}}</p>
      <p sfSubtitle2 class="mt-8 color--black-60">{{'HINT_PRODUCT_COSTING' | translate}}</p>
      <sf-chip-set choice class="level-config__primary-chip mt-8" formControlName="chargeType" data-cy="priceType">
        <sf-chip [value]="'mrc_otc'"><sf-chip-text>{{('LABEL_OTC' |translate) + " "+'and'+" " + ('LABEL_MRC' | translate)}}</sf-chip-text></sf-chip>
        <sf-chip [value]="'otc'"><sf-chip-text>{{'LABEL_OTC' | translate}}</sf-chip-text></sf-chip>
        <sf-chip [value]="'mrc'"><sf-chip-text>{{'LABEL_MRC' | translate}}</sf-chip-text></sf-chip>
      </sf-chip-set>
      <p sfCaption class="custom-error" *ngIf="form.get('chargeType')?.invalid && form.get('chargeType')?.touched">
        <span> {{'ERROR_REQUIRED' | translate}} </span>
      </p>

      <ew-currency [form]="form" [filteredCountries]="filteredCountries" [countrySearchControl]="countrySearchControl"></ew-currency>

      <ew-pricing [form]="form" [state]="state.priceTypes"></ew-pricing>

      <ew-dynamic-columns-form [parentFormGroupRef]="form"></ew-dynamic-columns-form>
    </sf-dialog-content>
    <sf-dialog-action fxLayoutAlign="end" ngClass.gt-xs="pb-20" ngClass.xs="common-action__button">
      <button sf-button outlined (click)="cancel()" data-cy="cancel"> {{'BTN_CANCEL' | translate}}</button>
      <button sf-button primary (click)="save()" class="ml-12" data-cy="save">{{'BTN_SAVE' | translate}}</button>
    </sf-dialog-action>
  </form>
</ng-container>
