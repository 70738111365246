<div class="dynamic-option-field" [ngClass]="{'mb-20':valueSelectedStyle}"
     [attr.data-cy]="dynamicColumn.name | translateName">
    <sf-select class="width--full" [formControl]="dynamicValueFormGroup.get('dynamicOptionId')"
               [placeholder]="(dynamicColumn.name | translateName) | translate" [outlined]="true" (selectionChange)="onSelect($event)">
        <sf-menu>
            <sf-list>
                <sf-list-item *ngFor="let dynamicOption of dynamicColumn.dynamicOptions" [value]="dynamicOption.id">
                    {{dynamicColumn.multilingual ? (dynamicOption.name) : dynamicOption.value | translate}}
                </sf-list-item>
            </sf-list>
        </sf-menu>
    </sf-select>

<!--    <p sfCaption class="custom-error"-->
<!--       *ngIf="dynamicValueFormGroup.get('dynamicOptionId')?.hasError('required') && dynamicValueFormGroup.get('dynamicOptionId').touched">-->
<!--        <span> {{'ERROR_REQUIRED' | translate}} </span>-->
<!--    </p>-->
</div>
