import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent} from './sidebar/sidebar.component';
import {SfDrawerModule, SfIconButtonModule, SfIconModule, SfListModule, SfTypographyModule} from '@ew/shared/safire/components';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SharedLayoutHeaderModule} from '../../../header/src';
import {SharedComponentsImpersonationModeModule} from '@ew/shared/components/impersonation-mode';
import {SharedSecurityModule} from '@ew/shared/security';

@NgModule({
    imports: [CommonModule,
        SfDrawerModule,
        SfIconModule,
        SharedLayoutHeaderModule,
        SharedSecurityModule,
        FlexLayoutModule,
        SfListModule,
        RouterModule,
        TranslateModule,
        SfTypographyModule,
        SharedComponentsImpersonationModeModule,
        SfIconButtonModule],
    declarations: [
        SidebarComponent
    ],
    providers: [
        {
            provide: 'WINDOW', useValue: window
        }
    ],
    exports: [
        SidebarComponent
    ]
})
export class SharedLayoutSidebarModule {
}
