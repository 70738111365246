import {Inject, Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ENV_STORAGE} from '@ew/shared/services';
import {Environment} from '@ew/shared/services';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private translateService: TranslateService, @Inject(ENV_STORAGE) private env: Environment) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const header = new HttpHeaders().append('Accept-Language',this.translateService.currentLang 
            || localStorage.getItem('language') || 'de')
            .append('X-Time-Zone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        request = request.clone({headers: header});

        (!/^(http|https):/i.test(request.url)) && (request = request.clone({url: this.env.serverUrl + request.url}));
        return next.handle(request);
    }
}
