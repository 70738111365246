<div class="dynamic-rich-text mb-20 mt-8">
  <p sfSubtitle1 class="mb-12">{{ dynamicColumn.name | translateName }}</p>

  <ng-container *ngIf="dynamicColumn.multilingual">
    <ng-container *ngFor="let langKey of langKeys">
      <quill-editor class="width--full"
                    *ngIf="activeLangKey === langKey && dynamicValueFormGroup.get('name')?.get(activeLangKey)"
                    [formControl]="dynamicValueFormGroup.get('name')?.get(activeLangKey)"
                    [sanitize]="true"
                    format="html"
                    theme="snow" data-cy="richText"
      ></quill-editor>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!dynamicColumn.multilingual">
    <quill-editor [formControl]="dynamicValueFormGroup.get('value')" [sanitize]="true" format="html" theme="snow"></quill-editor>
  </ng-container>

</div>
