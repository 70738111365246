<div class="sidebar">
    <div class="main-layout">
        <sf-drawer #drawer [drawer]="isMediumDevice? 'dismissible': 'mini-dismissible'"
                   class="br--8 mr-32" [open]="isDesktopView">
            <sf-drawer-header>
                <div fxLayout="row" class="main-layout__drawer-header py-16" fxLayoutAlign="space-between center">
                    <img (click)="drawer.open = !drawer.open" alt="EW logo"
                             class="main-layout__drawer-header-logo cursor--pointer"
                             src="../../assets/images/logo.svg"/>
                    <sf-icon (click)="toggleDrawer(drawer.open)" class="s-30 cursor--pointer color--primary" [ngClass]="{'p-16':drawer.open }" data-cy="openDrawer">
                        {{drawer.open ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}
                    </sf-icon>
                </div>
            </sf-drawer-header>
            <sf-drawer-content [ngClass]="{'mx-8': !drawer.open}">
                <div fxLayoutAlign="space-between" fxLayout="column" class="main-layout__drawer-content">
                <div class="main-layout__drawer-list">
                <sf-list>
                    <ng-container *ngFor="let item of navItems">
                        <ng-container *ngIf="item.type === 'item'">
                            <a (click)="navigate(item)" [activated]="item.activated"
                               [attr.data-cy]="item.label | translate"
                               ewAuthorized [permission]="item.permissionKeys"
                               [routerLink]="item.url" class="p-20"
                               sf-list-item>
                                <sf-icon *ngIf="item.icon" [ngClass]="{'pl-20': !drawer.open}"
                                         sfListItemGraphic>{{item.icon}}</sf-icon>
                                {{'Label_' + item.label | uppercase | translate}}
                            </a>
                        </ng-container>
                        <ng-container *ngIf="item.type === 'group'">
                            <a (click)="navigate(item)" [activated]="item.activated"
                               [attr.data-cy]="item.label | translate"
                               ewAuthorized [permission]="item.permissionKeys"
                               class="side-bar__group-item p-20"
                               fxLayoutAlign="space-between center" routerLinkActive="mdc-list-item--activated"
                               sf-list-item>
                                <span>
                                    <sf-icon *ngIf="item.icon" [ngClass]="{'pl-20': !drawer.open}"
                                             class="sf-side-nav__item-icon ver-bottom"
                                             sfListItemGraphic>{{item.icon}}</sf-icon>
                                    <span [ngClass]="!drawer.open && 'display--none'"
                                          class="ver-top">{{('label_' + item.label) | uppercase | translate}}</span>
                                </span>
                                <sf-icon class="side-bar__group-item--show-more side-bar__group-item-icon">
                                    {{item.activated ? 'expand_less' : 'expand_more' }}
                                </sf-icon>
                            </a>
                            <div *ngIf="item.activated && drawer.open" class="side-bar__more-item">
                                <a (click)="navigate(childNavItem)" *ngFor="let childNavItem of item.children"
                                   [attr.data-cy]="childNavItem.label | translate"
                                   ewAuthorized [permission]="item.permissionKeys"
                                   [routerLink]="childNavItem.url"
                                   class="side-bar__more-item--list"
                                   routerLinkActive="mdc-list-item--activated"
                                   sf-list-item>
                                    <sf-icon *ngIf="childNavItem.icon"
                                             sfListItemGraphic>{{childNavItem.icon}}</sf-icon>
                                    {{ ('label_' + childNavItem.label) | uppercase | translate}}
                                </a>
                            </div>
                        </ng-container>
                    </ng-container>
                </sf-list>
                </div>
                <div *ngIf="drawer.open" class="main-layout__drawer-side-bar__footer p-32 br--8 bg--white">
                    <div class="pb-16">
                        <p class="color--black-60 mb-8" sfCaption>{{'LABEL_SOCIAL_MEDIA' | translate}}</p>
                        <ng-container *ngFor="let list of iconClass">
                            <a [href]="list.url" [ngClass]="list.class" class="social-icon px-16 pb-8" target="_blank"></a>
                        </ng-container>
                    </div>
                    <p class="color--black-60 font-size-10">&copy;{{currentYear}} {{'LABEL_COPYRIGHT' | translate}}</p>
                </div>
                </div>
            </sf-drawer-content>
        </sf-drawer>
        <div class="main-layout__container width--full" fxFlex.gt-md>
            <div class="ew-drawer" fxHide.gt-md>
                <div fxLayout="row">
                    <!--TODO MIGHT NEED IN FUTURE-->
<!--                    <div (click)="drawer.open = !drawer.open" fxLayoutAlign="center center" class="ew-drawer__menu">
                        <sf-icon class="cursor&#45;&#45;pointer color&#45;&#45;primary-60">menu</sf-icon>
                    </div>-->
                    <div fxFlex></div>
                </div>
            </div>

            <ew-header (menuToggle)="toggleMenu($event)"></ew-header>
            <div ngClass.sm="layout-container">
                <ew-impersonation-mode></ew-impersonation-mode>
                <div class="main-layout__container-content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
