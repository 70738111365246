<div class="resource-list width--full">
  <sf-table class="width--full resource-list__table" [dataSource]="dataSource?.collection" sfTableSort>
    <sf-table-header fxLayoutAlign="space-between center" class="resource-list__heading"
                     *ngIf="selection.selected.length">
      <p>{{'LABEL_PRODUCT_SELECTED' | translate: { count: selection.selected.length } }}</p>
      <button sfIconButton class="level-config__delete mt-4" ngClass.xs="mr-8" ngClass.gt-xs="mr-32"
              (click)="action.emit({actionKey: 'deleteMultiple', row: selection.selected}); $event.stopPropagation()">
        <sf-icon>delete_outlined</sf-icon>
      </button>
    </sf-table-header>
    <ng-container *ngIf="hasMultiple" sfTableColumnDef="select">
      <th sf-table-header-cell *sfTableHeaderCellDef class="resource-list__content">
        <sf-checkbox class="resource-list__content--checkbox" ngClass.xs="m-N8" [checked]="selection.hasValue() && isAllSelected()"
                     [indeterminate]="selection.hasValue() && !isAllSelected()"
                     (change)="$event ? masterToggle() : null">
        </sf-checkbox>
      </th>
      <td sf-table-cell *sfTableCellDef="let row" class="resource-list__content py-8">
        <sf-checkbox class="resource-list__content--checkbox" [checked]="selection.isSelected(row)"
                     (click)="$event.stopPropagation()"
                     (changed)="$event ? toggleSingle(row) : null">
        </sf-checkbox>
      </td>
    </ng-container>

    <ng-container *ngFor="let config of tableConfig" sfTableColumnDef="{{config.name}}">
      <th sf-table-header-cell *sfTableHeaderCellDef>
        <ng-container *ngIf="!config.ignoreHeader">
          <sf-form-field *ngIf="config.search">
            <sf-input type="text"
                      [(ngModel)]="config.filterValue"
                      [value]="config.filterValue || ''"
                      (inputChanged)="loadData()"
                      [placeholder]="('label_' + config.name) | uppercase | translate"
                      outlined>
              <sf-icon sfTextFieldIcon leading>search</sf-icon>
            </sf-input>
          </sf-form-field>

          <span class="dropdown-status hand-cursor" *ngIf="config.dropDownList">
						<sf-form-field>
    						<sf-select [placeholder]="('label_' + config.name) | uppercase | translate" fxFlex="80"
                           class="ew-select" outlined>
								<sf-menu>
            						<sf-list>
                						<sf-list-item *ngFor="let option of config.dropDownList"
                                          [value]="config.keyDropDownId ? option[config.keyDropDownId] :  option">
                 				 			<span
                                *ngIf="config.translate">{{ (config.keyDropDownName ? option[config.keyDropDownName] : option) | uppercase | translate}}</span>
											<span
                        *ngIf="!config.translate">{{ (config.keyDropDownName ? option[config.keyDropDownName] : option)}}</span>
                						</sf-list-item>
           							 </sf-list>
								</sf-menu>
    						</sf-select>
						</sf-form-field>
          			</span>
          <span
            *ngIf="!config.dropDownList && !config.search">{{config.currencyRequired ? ((('label_' + config.name) | uppercase) | translate:{ currencyCode: config.currencyCode }) : ((('label_' + config.name) | uppercase) | translate)}}</span>
        </ng-container>
      </th>
      <ng-container>
        <td sf-table-cell *sfTableCellDef="let row" [attr.datatype]="'label_' + config.name | uppercase | translate">
          <ng-container>

            <ng-container *ngIf="config.type === 'avatar'">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayoutAlign="center center" class="resource-list__image py-10 mr-12" *ngIf="get(row, config.image)">
                  <img class="resource-list__image--size"
                       [src]="get(row, config.image)" alt="product" />
                </div>
                {{config.translate ? ('LABEL_' + (get(row, config.key)) | uppercase | translate) : (config.valueTranslate ? (get(row, config.key) | translateName) : get(row, config.key))}}
              </div>
            </ng-container>
            <span *ngIf="config.type === 'text'">
                			{{config.translate ? ('LABEL_' + (get(row, config.key)) | uppercase | translate) : (config.valueTranslate ? (get(row, config.key) | translateName) : get(row, config.key))}}
            </span>

            <span *ngIf="config.type === 'object'">
            				{{config.translate ? (config.objectParams[get(row, config.key)] | uppercase | translate) : config.objectParams[get(row, config.key)]}}
          				</span>

            <span *ngIf="config.type === 'boolean'">
							{{config.dropDownList[row[config.key] ? 1 : 0]['name'] | uppercase | translate}}
						</span>

            <div *ngIf="config.type == 'action'">
              <div *ngFor="let iconType of config.icons; let i = index"
                   (click)="actionEvent(iconType.action, row, $event); $event.preventDefault()">
                <ng-container
                  *ngIf="(config.conditionalAction && row[iconType.key]) || !config.conditionalAction">
                  <sf-icon class="s-24 m-4">{{iconType.icon}}</sf-icon>
                  <p class="mt-4 mb-0 font-size-12">{{iconType.text | uppercase | translate }}</p>
                </ng-container>
              </div>
            </div>
            <div *ngIf="config.type === 'array'">
            				<span *ngFor="let key of config.key; let i=index"
                          [ngClass]="{'inline-block': config.nextLine, 'ml-4': i > 0 && config.nextLine}">
              					{{config.translate && config.translate[i] ? 'LABEL_' + (get(row, key) | uppercase | translate) : get(row, key)}}
           					 </span>
            </div>

            <div *ngIf="config.type === 'nested_array'">
              <ng-container *ngFor="let key of get(row, config.key); let i=index; let last = last">
                <ng-container *ngIf="!!key[config.optionalKey] && key[config.optionalKey] !== null">
                  <span [ngClass]="{'inline-block': config.nextLine, 'ml-4': i > 0 && config.nextLine}">
                  {{key[config.optionalKey]}}{{(!last ? ' ' : '')}}
                  </span>
                </ng-container>
              </ng-container>
            </div>

            <div *ngIf="config.type === 'list'">
              <ng-container *ngFor="let key of get(row, config.key); let i=index; let last = last">
                <ng-container *ngIf="!!key[config.optionalKey] && key[config.optionalKey] !== null">
                  <span [ngClass]="{'inline-block': config.nextLine, 'ml-4': i > 0 && config.nextLine}">
                  {{config.translate ? (key[config.optionalKey][config.optionalKeyValue] | translateName) : ''}}{{(!last ? ' ' : '')}}
                  </span>
                </ng-container>
              </ng-container>
            </div>

            <div class="text-center" *ngIf="config.type === 'toggle'">
              <sf-switch [checked]="row[config.key]" [(ngModel)]="row[config.key]"
                         (ngModelChange)="actionEvent(config.actionMethod, row)"></sf-switch>
            </div>

            <div *ngIf="config.type === 'chip'" class="package" [ngClass]="{'active-package':get(row, config.key)}">
              <sf-chip class="package__status" ngClass.gt-xs="mr-8" [attr.data-cy]="(get(row, config.key) ? 'LABEL_ACTIVE' : 'LABEL_INACTIVE') | translate">
                {{(get(row, config.key) ? 'LABEL_ACTIVE' : 'LABEL_INACTIVE') | translate}}</sf-chip>
            </div>

            <div class="text-center" *ngIf="config.type === 'menu_toggle'">
              <ng-container *ngTemplateOutlet="menuList;context:{row:row}"></ng-container>
            </div>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <tr sf-table-header-row *sfTableHeaderRowDef="displayedColumns"></tr>
    <tr class="outline--none" sf-table-row *sfTableRowDef="let row; columns: displayedColumns;"
        (click)="route(row); $event.preventDefault()"></tr>
  </sf-table>

  <sf-pagination class="paginator pt-12" *ngIf="!showAllData" [pageIndex]="pageEvent.pageIndex"
                 (page)="pageChange($event)"
                 showFirstLastButtons
                 [length]="dataSource?.metaData?.total"
                 [pageSize]="pageEvent.pageSize"></sf-pagination>
</div>

<ng-template #menuList let-row="row">
  <ew-menu-modal [row]="row" (action)="action.emit($event)" [config]="menuConfig"></ew-menu-modal>
</ng-template>
