import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';

import { SfRippleService } from '../../../ripple/src/index';
import { SfFloatingLabelDirective } from '../../../floating-label/src/index';
import { SfLineRippleDirective } from '../../../line-ripple/src/index';
import { SfNotchedOutlineComponent } from '../../../notched-outline/src/index';
import {
  CanUpdateErrorState,
  CanUpdateErrorStateCtor,
  ErrorStateMatcher,
  mixinErrorState,
  SfFormFieldComponent,
  SfFormFieldControl,
  SfHelperText
} from '../../../forms/src/index';

import { SfTextFieldIconDirective } from './input-icon.directive';

import {
  MDCTextFieldFoundation,
  MDCTextFieldHelperTextFoundation,
  MDCTextFieldLabelAdapter,
  MDCTextFieldLineRippleAdapter,
  MDCTextFieldOutlineAdapter,
  MDCTextFieldRootAdapter
} from '@material/textfield';

/**
 * Represents the default options for mdc-text-field that can be configured
 * using an `SF_TEXT_FIELD_DEFAULT_OPTIONS` injection token.
 */
export interface SfTextFieldDefaultOptions {
  outlined?: boolean;
}

/**
 * Injection token that can be used to configure the default options for all
 * mdc-text-field usage within an app.
 */
export const SF_TEXT_FIELD_DEFAULT_OPTIONS =
  new InjectionToken<SfTextFieldDefaultOptions>('SF_TEXT_FIELD_DEFAULT_OPTIONS');

class SfTextFieldBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl) {
  }
}

const _SfTextFieldMixinBase: CanUpdateErrorStateCtor & typeof SfTextFieldBase =
  mixinErrorState(SfTextFieldBase);

let nextUniqueId = 0;

/**
 * Time in milliseconds for which to ignore mouse events, after
 * receiving a touch event. Used to avoid doing double work for
 * touch devices where the browser fires fake mouse events, in
 * addition to touch events.
 */
const MOUSE_EVENT_IGNORE_TIME = 800;

@Component({
  selector: 'sf-input',
  exportAs: 'sfInput',
  host: {
    'class': 'mdc-text-field sf-text-field',
    '[class.mdc-text-field--disabled]': 'disabled',
    '[class.mdc-text-field--outlined]': 'outlined',
    '[class.mdc-text-field--dense]': 'dense',
    '[class.mdc-text-field--fullwidth]': 'fullwidth',
    '[class.mdc-text-field--with-leading-icon]': 'leadingIcon',
    '[class.mdc-text-field--with-trailing-icon]': 'trailingIcon',
    '[class.mdc-text-field--no-label]': '!label || label && fullwidth',
    '[class.mdc-text-field--invalid]': 'errorState',
    '(click)': 'onTextFieldInteraction()',
    '(keydown)': 'onTextFieldInteraction()'
  },
  template: `
    <ng-content *ngIf="leadingIcon"></ng-content>
    <input #inputElement class="mdc-text-field__input"
           [id]="id"
           [type]="type"
           [tabindex]="tabIndex"
           [attr.aria-invalid]="errorState"
           [attr.autocomplete]="autocomplete"
           [attr.pattern]="pattern"
           [attr.placeholder]="placeholder"
           [attr.maxlength]="maxlength"
           [attr.minlength]="minlength"
           [attr.max]="max"
           [attr.min]="min"
           [attr.size]="size"
           [attr.step]="step"
           [disabled]="disabled"
           [readonly]="readonly"
           [required]="required"
           (mousedown)="onInputInteraction($event)"
           (focus)="onFocus()"
           (change)="onChange($event)"
           (blur)="onBlur()"/>
    <ng-content></ng-content>
    <label sfFloatingLabel [for]="id" *ngIf="!this.placeholder && !outlined">{{label}}</label>
    <sf-line-ripple *ngIf="!this.outlined && !this.textarea"></sf-line-ripple>
    <sf-notched-outline *ngIf="outlined" [label]="label" [for]="id"></sf-notched-outline>`,
  providers: [
    SfRippleService,
    { provide: SfFormFieldControl, useExisting: SfInputComponent }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfInputComponent extends _SfTextFieldMixinBase implements AfterContentInit, DoCheck,
  OnDestroy, ControlValueAccessor, SfFormFieldControl<any>, CanUpdateErrorState {
  controlType = 'mdc-text-field';
  @Input() label: string | null = null;
  @Input() maxlength?: number;
  @Input() minlength?: number;
  @Input() pattern?: string;
  @Input() autocomplete?: string;
  @Input() max?: number;
  @Input() min?: number;
  @Input() size?: number;
  @Input() step?: number;
  @Input() placeholder: string | null = null;
  @Input() tabIndex = 0;
  /** An object used to control when error messages are shown. */
  @Input() errorStateMatcher?: ErrorStateMatcher;
  @Output() readonly valueChanged = new EventEmitter<any>();
  @Output() readonly inputChanged = new EventEmitter<any>();
  @Output() readonly blurChanged = new EventEmitter<any>();
  @ViewChild('inputElement', { static: true }) _input!: ElementRef<HTMLInputElement | HTMLTextAreaElement>;
  @ViewChild(SfLineRippleDirective, { static: false }) _lineRipple?: SfLineRippleDirective;
  @ViewChild(SfNotchedOutlineComponent, { static: false }) _notchedOutline?: SfNotchedOutlineComponent;
  @ViewChild(SfFloatingLabelDirective, { static: false }) _floatingLabel?: SfFloatingLabelDirective;
  @ContentChildren(SfTextFieldIconDirective, { descendants: true }) _icons!: QueryList<SfTextFieldIconDirective>;
  private _uid = `sf-input-${nextUniqueId++}`;
  private _initialized = false;
  /** Time in milliseconds when the last touchstart event happened. */
  private _lastTouchStartEvent = 0;
  private _foundation: {
    readonly shouldFloat: boolean,
    init(): void,
    destroy(): void,
    setDisabled(disabled: boolean): void,
    setValid(isValid: boolean): void,
    setValue(value: any): void,
    notchOutline(openNotch: boolean): void,
    setUseNativeValidation(useNativeValidation: boolean): void,
    setTransformOrigin(evt: MouseEvent | TouchEvent): void,
    handleTextFieldInteraction(): void,
    activateFocus(): void,
    deactivateFocus(): void,
    handleInput(): void
  } = new MDCTextFieldFoundation(this._createAdapter());

  constructor(
    private _platform: Platform,
    private _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() private _parentFormField: SfFormFieldComponent,
    @Optional() private _ripple: SfRippleService,
    @Self() @Optional() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() @Inject(SF_TEXT_FIELD_DEFAULT_OPTIONS) private _defaults: SfTextFieldDefaultOptions) {

    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    if (this._parentFormField) {
      _parentFormField.elementRef.nativeElement.classList.add('sf-form-field-text-field');
    }

    // Force setter to be called in case id was not specified.
    this.id = this.id;
  }

  private _id = '';

  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  private _type = 'text';

  /** Input type of the element. */
  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
  }

  private _outlined = false;

  @Input()
  get outlined(): boolean {
    return this._outlined;
  }

  set outlined(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._outlined) {
      this._outlined = newValue || (this._defaults && this._defaults.outlined) || false;
      this.layout();
    }
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._required) {
      this._required = newValue;

      if (this._initialized) {
        if (!this.valid) {
          this._foundation.setValid(true);
          this._changeDetectorRef.markForCheck();
        }

        if (this.ngControl) {
          this._required ? this._getInputElement().setAttribute('required', '') :
            this._getInputElement().removeAttribute('required');
        }
      }
    }
  }

  private _readonly = false;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }

  private _fullwidth = false;

  @Input()
  get fullwidth(): boolean {
    return this._fullwidth;
  }

  set fullwidth(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._fullwidth) {
      this._fullwidth = newValue;
      this.placeholder = this.fullwidth ? this.label : '';
    }
  }

  private _dense = false;

  @Input()
  get dense(): boolean {
    return this._dense;
  }

  set dense(value: boolean) {
    this._dense = coerceBooleanProperty(value);
  }

  private _helperText: SfHelperText | null = null;

  @Input()
  get helperText(): SfHelperText | null {
    return this._helperText;
  }

  set helperText(helperText: SfHelperText | null) {
    this._helperText = helperText;
    if (this._helperText) {
      this._initHelperText();
      this._helperText.characterCounter = this._characterCounter;
    }
  }

  private _valid: boolean | undefined;

  /** Sets the Text Field valid or invalid. */
  @Input()
  get valid(): boolean | undefined {
    return this._valid;
  }

  set valid(value: boolean | undefined) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._valid) {
      this._valid = value;
      this._foundation.setValid(newValue);
    }
  }

  private _useNativeValidation = true;

  /** Enables or disables the use of native validation. Use this for custom validation. */
  @Input()
  get useNativeValidation(): boolean {
    return this._useNativeValidation;
  }

  set useNativeValidation(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._useNativeValidation) {
      this._useNativeValidation = newValue;
      this._foundation.setUseNativeValidation(this._useNativeValidation);
    }
  }

  private _characterCounter = false;

  @Input()
  get characterCounter(): boolean {
    return this._characterCounter;
  }

  set characterCounter(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._characterCounter) {
      this._characterCounter = newValue;
      if (this.helperText) {
        this.helperText.characterCounter = this._characterCounter;
      }
    }
  }

  private _value: any;

  @Input()
  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    if (!this._initialized) {
      this.ngControl ? this._initializeValue() : this._initializeValue(newValue);
    } else {
      this.setValue(newValue, true);
    }
  }

  get textarea(): boolean {
    return this._getHostElement().nodeName.toLowerCase() === 'sf-textarea';
  }

  get focused(): boolean {
    return this._platform.isBrowser ?
      // tslint:disable-next-line:no-non-null-assertion
      document.activeElement! === this._getInputElement() : false;
  }

  get leadingIcon(): SfTextFieldIconDirective | undefined {
    return this._icons ?
      this._icons.find(icon => icon.leading) : undefined;
  }

  get trailingIcon(): SfTextFieldIconDirective | undefined {
    return this._icons ?
      this._icons.find(icon => icon.trailing) : undefined;
  }

  /** View to model callback called when value changes */
  _onChange: (value: any) => void = () => {
  };

  /** View to model callback called when text field has been touched */
  _onTouched = () => {
  };

  ngAfterContentInit(): void {
    this._setDefaultOptions();
    this.init();
  }

  ngOnDestroy(): void {
    this._destroy();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  init(): void {
    setTimeout(() => {
      this._foundation = new MDCTextFieldFoundation(this._createAdapter(), this._getFoundationMap());
      this._foundation.init();
    });

    if (!this.fullwidth && !this.outlined && !this.textarea) {
      this._ripple = new SfRippleService(this.elementRef);
      this._ripple.init();
    } else {
      if (this._ripple) {
        this._ripple.destroy();
      }
    }
    this._checkCustomValidity();

    this._initialized = true;
  }

  onTextFieldInteraction(): void {
    if (this._initialized) {
      this._foundation.handleTextFieldInteraction();
    }
  }

  @HostListener('touchstart', ['$event'])
  onInputInteraction(evt: MouseEvent | TouchEvent): void {
    if (evt instanceof MouseEvent) {
      const isSyntheticEvent = this._lastTouchStartEvent &&
        Date.now() < this._lastTouchStartEvent + MOUSE_EVENT_IGNORE_TIME;

      if (isSyntheticEvent) {
        return;
      }
    } else {
      this._lastTouchStartEvent = Date.now();
    }

    this._foundation.setTransformOrigin(evt);
  }

  @HostListener('input', ['$event'])
  onInput(evt: KeyboardEvent): void {
    const value = (<any>evt.target).value;
    this.setValue(value, true);
    this._foundation.handleInput();
    this.inputChanged.emit(value);
    evt.stopPropagation();
  }

  onFocus(): void {
    if (this._initialized) {
      this._foundation.activateFocus();
    }
  }

  onChange(evt: Event): void {
    const value = (<any>evt.target).value;
    this.setValue(value, true);
    this.valueChanged.emit(value);
    evt.stopPropagation();
  }

  onBlur(): void {
    this._onTouched();
    this._foundation.deactivateFocus();
    this.blurChanged.emit(this.value);
  }

  writeValue(value: any): void {
    this.setValue(value);
  }

  registerOnChange(fn: (value: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setValue(value: any, isUserInput?: boolean): void {
    const newValue = this.type === 'number' ? coerceNumberProperty(value, null) : value;
    if (this._value === newValue) {
      // Reset validity for numeric form inputs
      if (newValue === null) {
        this.valid = true;
      }
      return;
    }

    this._value = newValue !== undefined ? newValue : null;
    if (this._getInputElement().value !== this._value) {
      this._getInputElement().value = this._value;
    }

    this._foundation.setValue(this._value || '');

    if (isUserInput) {
      this._onChange(this._value);
    }
    this._changeDetectorRef.markForCheck();
  }

  isBadInput(): boolean {
    const validity = this._getInputElement().validity;
    return validity && validity.badInput;
  }

  focus(): void {
    if (!this.disabled) {
      this._getInputElement().focus();
    }
  }

  /** Implemented as part of ControlValueAccessor. */
  setDisabledState(isDisabled: boolean) {
    const newValue = coerceBooleanProperty(isDisabled);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this._foundation.setDisabled(this._disabled);
    }
    this._changeDetectorRef.markForCheck();
  }

  protected characterCounterFoundation(): any {
    return this.helperText && this.characterCounter ?
      // tslint:disable-next-line:no-non-null-assertion
      this.helperText._characterCounterElement!.getDefaultFoundation() : undefined;
  }

  private _createAdapter(): MDCTextFieldRootAdapter {
    return Object.assign({
        addClass: (className: string) => this._getHostElement().classList.add(className),
        removeClass: (className: string) => this._getHostElement().classList.remove(className),
        hasClass: (className: string) => this._getHostElement().classList.contains(className),
        // tslint:disable-next-line:no-non-null-assertion
        isFocused: () => this._platform.isBrowser ? document.activeElement! === this._getInputElement() : false
      },
      this._getInputAdapterMethods(),
      this._getLabelAdapterMethods(),
      this._getLineRippleAdapterMethods(),
      this._getOutlineAdapterMethods()
    );
  }

  private _getInputAdapterMethods(): any {
    return {
      getNativeInput: () => {
        return {
          maxLength: this.maxlength,
          type: this._type,
          value: this._platform.isBrowser ? this._input.nativeElement.value : this._value,
          disabled: this._disabled,
          validity: {
            valid: this._isValid(),
            badInput: this._platform.isBrowser ? this._input.nativeElement.validity.badInput : false
          }
        };
      }
    };
  }

  private _getLabelAdapterMethods(): MDCTextFieldLabelAdapter {
    return {
      shakeLabel: (shouldShake: boolean) => this._getFloatingLabel().shake(shouldShake),
      floatLabel: (shouldFloat: boolean) => this._getFloatingLabel().float(shouldFloat),
      hasLabel: () => this._hasFloatingLabel(),
      getLabelWidth: () => this._hasFloatingLabel() ? this._getFloatingLabel().getWidth() : 0
    };
  }

  private _getLineRippleAdapterMethods(): MDCTextFieldLineRippleAdapter {
    return {
      activateLineRipple: () => {
        if (this._lineRipple) {
          this._lineRipple.activate();
        }
      },
      deactivateLineRipple: () => {
        if (this._lineRipple) {
          this._lineRipple.deactivate();
        }
      },
      setLineRippleTransformOrigin: (normalizedX: number) => {
        if (this._lineRipple) {
          this._lineRipple.setRippleCenter(normalizedX);
        }
      }
    };
  }

  private _getOutlineAdapterMethods(): MDCTextFieldOutlineAdapter {
    return {
      hasOutline: () => !!this._notchedOutline,
      // tslint:disable-next-line:no-non-null-assertion
      notchOutline: (labelWidth: number) => this._notchedOutline!.notch(labelWidth),
      // tslint:disable-next-line:no-non-null-assertion
      closeOutline: () => this._notchedOutline!.closeNotch()
    };
  }

  /** Returns a map of all subcomponents to subfoundations.*/
  private _getFoundationMap() {
    return {
      helperText: this._helperText ? this._helperText.foundation : undefined,
      characterCounter: this.characterCounterFoundation()
    };
  }

  private _initializeValue(value?: any): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    Promise.resolve().then(() => {
      this.setValue(this.ngControl ? this.ngControl.value : value);
    });
  }

  /** Initializes Text Field's internal state based on the environment state */
  private layout(): void {
    this._destroy();
    this.init();
    this._changeDetectorRef.markForCheck();

    setTimeout(() => {
      if (this._outlined) {
        this._foundation.notchOutline(this._foundation.shouldFloat);
      }
      if (this._hasFloatingLabel()) {
        this._getFloatingLabel().float(this._foundation.shouldFloat);
      }
    });
  }

  /** Set the default options here. */
  private _setDefaultOptions(): void {
    if (this._defaults && this._defaults.outlined) {
      this._outlined = this._defaults.outlined;
    }
  }

  private _checkCustomValidity(): void {
    Promise.resolve().then(() => {
      if (this._valid !== undefined) {
        this._foundation.setValid(this._valid);
      }
    });
  }

  private _initHelperText(): void {
    const helper = this.helperText;
    if (helper) {
      helper.addHelperTextClass(this.controlType);
      helper.init(MDCTextFieldHelperTextFoundation);
    }
  }

  private _destroy(): void {
    if (this._lineRipple) {
      this._lineRipple.destroy();
    }
    if (this._ripple) {
      this._ripple.destroy();
    }
    this._foundation.destroy();
  }

  private _isValid(): boolean {
    if (this.ngControl) {
      return !this.errorState;
    }

    return this._valid ? this._valid : this._platform.isBrowser ?
      this._input.nativeElement.validity.valid : true;
  }

  private _hasFloatingLabel(): boolean {
    return this.label && (this._floatingLabel || this._notchedOutline) ? true : false;
  }

  private _getFloatingLabel(): SfFloatingLabelDirective {
    // tslint:disable-next-line:no-non-null-assertion 
    //@ts-ignore
    return this._floatingLabel || this._notchedOutline!.floatingLabel;
  }

  private _getInputElement(): HTMLInputElement | HTMLTextAreaElement {
    return this._input.nativeElement;
  }

  /** Retrieves the DOM element of the component host. */
  private _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
