import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

export function updateTreeValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
        const control: AbstractControl = group.controls[key];
        if (control instanceof FormGroup || control instanceof FormArray) {
            updateTreeValidity(control);
        } else {
            control.updateValueAndValidity();
        }
    });
}

export function checkForUnAcceptedCharacter(value: string, form: FormGroup): string {
    let uniqueCharacterValues!: string;
    if (form.get('password').hasError('unacceptedCharacterFound')) {
        const uniqueCharacters = new Set();
        value
            .split('')
            .filter(character => (/[^\x20-\x7F]/g).test(character))
            .forEach(character => !!character && uniqueCharacters.add(character))
        uniqueCharacterValues = [...uniqueCharacters].join(', ');
    }
    return uniqueCharacterValues;
}