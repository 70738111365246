import {NgModule} from '@angular/core';

import {SfFloatingLabelModule} from '../../../floating-label/src/index';
import {SfNotchedOutlineComponent} from './notched-outline.component';

@NgModule({
  imports: [SfFloatingLabelModule],
  exports: [SfNotchedOutlineComponent],
  declarations: [SfNotchedOutlineComponent]
})
export class SfNotchedOutlineModule { }
