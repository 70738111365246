<!--
<div class="sf-button-toggle">
  <div class="sf-button-toggle__element sf-button-toggle__element_active">
    <sf-icon>format_align_left</sf-icon>
  </div>
  <div class="sf-button-toggle__separator"></div>
  <div class="sf-button-toggle__element sf-button-toggle__element_disabled">
    <sf-icon>format_align_center</sf-icon>
  </div>
  <div class="sf-button-toggle__separator"></div>
  <div class="sf-button-toggle__element">
    <sf-icon>format_align_right</sf-icon>
  </div>
  <div class="sf-button-toggle__separator"></div>
  <div class="sf-button-toggle__element">
    <sf-icon>format_align_justify</sf-icon>
  </div>
</div>

-->

<button #button class="sf-button-toggle__button"
        type="button"
        [id]="buttonId"
        [attr.tabindex]="disabled ? -1 : tabIndex"
        [attr.aria-pressed]="checked"
        [disabled]="disabled || null"
        [attr.name]="name || null"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledby"
        (click)="_onButtonClick()">
  <div class="sf-button-toggle__content">
    <ng-content></ng-content>
  </div>
</button>
