<div class="bg--white br--8 pt-20 px-8">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h6 sfHeadline6 class="color--black-87 width--full">
            <ng-content select="[title]"></ng-content>
        </h6>
    </div>
    <div class="common-modal">
        <sf-dialog-content>
            <ng-content></ng-content>
        </sf-dialog-content>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
            <ng-content select="[action]"></ng-content>
        </div>
    </div>
</div>
