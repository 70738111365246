import {
    ComponentFactory,
    ComponentFactoryResolver, ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NgControl} from '@angular/forms';
import {ErrorComponent} from './error/error.component';
import {fromEvent, merge, Observable} from 'rxjs';

export const MAPPED_VALIDATIONS: unknown = {
    required: 'ERROR_FIELD_REQUIRED',
    numbersOnly: 'NUMBERS_ONLY_ERROR',
    atLeastOne: 'AT_LEAST_ONE_ERROR',
    email: 'ERROR_INVALID_EMAIL',
    invalidPhone: 'ERROR_INVALID_PHONE',
    alreadyExists: 'ALREADY_EXISTS_ERROR',
    invalidSelection: 'SELECT_FROM_LIST_ERROR',
    invalidPhoneNo: 'ERROR_INVALID_PHONE',
    invalidPhoneNoCH: 'ERROR_INVALID_PHONE_CH'
};

@Directive({
    selector: '[ewError], [formControl], [formControlName]'
})

@UntilDestroy()
export class ErrorDirective implements OnInit {
    ref: ComponentRef<ErrorComponent>;
    inputBlur: Observable<unknown>;

    @Input() ewError = true;

    constructor(private control: NgControl, private inputEl: ElementRef,
                private vcr: ViewContainerRef, private resolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
        this.inputBlur = fromEvent(this.inputEl.nativeElement, 'focusout');
        this.ewError && merge(this.inputBlur, this.control.valueChanges).pipe(untilDestroyed(this)).subscribe(() => this.markError());
        this.markError();
    }

    markError(): void {
        const controlErrors: { [key: string]: string } = this.control.errors;

        if (controlErrors && this.control.touched) {
            const firstKey: string = Object.keys(controlErrors)[0];
            const text: string = MAPPED_VALIDATIONS[firstKey];
            this.setError(text);
        } else if (this.ref) {
            this.ref.destroy();
            this.ref = undefined;
        }
    }

    setError(text: string): void {
        if (!this.ref) {
            const factory: ComponentFactory<ErrorComponent> = this.resolver.resolveComponentFactory(ErrorComponent);
            this.ref = this.vcr.createComponent(factory, 0);
        }
        this.ref.instance.text = text;
    }
}


