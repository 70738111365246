<div *ngIf="user?.impersonated" fxLayout="row wrap" fxLayoutAlign="space-between center"
     class="p-16 bg--primary-24" ngClass.gt-xs="br--8 mt-30" ngClass.xs="mb-8" data-cy="banner">
    <div fxFlex="80" fxFlex.xs="100" fxLayout="row" fxLayoutGap="10px">
        <sf-icon class="color--primary">assignment_ind</sf-icon>
        <div class="font-size-16">
            <p class="color--primary">{{'LABEL_IMPERSONATION_MODE' | translate}}</p>
            <p class="color--black-60">{{'HINT_CLICK_END_BUTTON' | translate : {name: name} }}</p>
        </div>
    </div>
    <div fxFlex.xs="100" fxLayoutAlign.xs="end center" class="mr-16 mt-8">
        <button type="button" (click)="endSwitchUser()" sf-button primary>{{'BTN_END_NOW' | translate}}</button>
    </div>
</div>

