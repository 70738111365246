import { TypedDocumentNode } from '@apollo/client/core';
import { gql } from 'apollo-angular';
import { ProductItem } from '@ew/shared/services';

export const CREATE_PRODUCT_ITEM_MUTATION: TypedDocumentNode<{ createProductItem: ProductItem}> = gql`
  mutation createProductItem($input: CreateProductItemInput!) {
    createProductItem(input: $input) {
      id
    }
  }`;
