<ng-container *ngIf='config?.showStepper'>
  <sf-accordion class='pricing-details__accordion' *ngIf='state$ | async as state'>
    <sf-accordion-item [expanded]='config?.isExpanded' disabled="true">
      <sf-accordion-item-header>
        <div class='pricing-details__title'>
          <div fxLayout='row' class='mb-4'>
            <p sfSubtitle1 class='mr-12 bold'>{{'LABEL_PRICING_DETAILS' | translate}}</p>
            <sf-icon class='color--black-60 pricing-details__done'>done</sf-icon>
          </div>
          <p class='pricing-details__basic-details'>
            <span sfSubtitle2 class='color--black-60'>Normal Pricing</span>
          </p>
          <p sfSubtitle2 class='pricing-details__info color--black-60'>
            {{'HINT_PRICING_DETAILS' | translate}}
          </p>
        </div>

        <a sfSubtitle2 class="color--primary pricing-details__accordion-icon text--uppercase">{{'BTN_CHANGE' |
          translate}}</a>
      </sf-accordion-item-header>

      <p sfSubtitle1 class='my-12'>
        {{'LABEL_TYPE_OF_PRICING' | translate}}
      </p>

      <form [formGroup]='form'>
        <sf-radio-group fxLayout='column' class='mb-12' formControlName='configuratorPriceTypeId'>
          <sf-form-field class='pricing-details__option' *ngFor='let price of priceTypes'>
            <sf-radio [value]='price?.id'></sf-radio>
            <label sfSubtitle2>{{price?.priceTypeName | translateName}}</label>
          </sf-form-field>
        </sf-radio-group>
      </form>

      <ew-prev-next [stepIndex]="config.index" [stepperLength]='length' [showSkip]='false'
                    (clickEvent)='proceed($event, config.index)'></ew-prev-next>

    </sf-accordion-item>
  </sf-accordion>
</ng-container>
