<div fxLayout="column" fxLayoutAlign="space-between" class="filter bg--white py-12">
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <h6 sfHeadline6 class="color--black-60 py-8">{{'LABEL_FILTER' | translate }}</h6>
            <sf-icon class="color--black-60 cursor--pointer" (click)="cancel()">clear</sf-icon>
        </div>
        <sf-dialog-content>
            <div fxLayout="column" fxLayoutGap="24px" class="mt-16">
                <sf-accordion class="filter__accordion" multi>
                    <sf-accordion-item>
                        <sf-accordion-item-header>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <span fxLayoutAlign="center center" class="filter__accordion-icon">
                                <sf-icon>schedule</sf-icon>
                                </span>
                                <span class="ml-12 color--light-black">{{'LABEL_STATUS' | translate }}</span>
                            </div>
                        </sf-accordion-item-header>
                        <div fxLayout="column" fxLayoutGap="24px" class="filter__accordion-details">
                            <sf-radio-group [formControl]="filterForm">
                                <sf-form-field *ngFor="let filter of filterRadioOptions">
                                    <sf-radio [value]="filter.value"></sf-radio>
                                    <label>{{'LABEL_' + filter.label | uppercase | translate }}</label>
                                </sf-form-field>
                            </sf-radio-group>
                        </div>
                    </sf-accordion-item>
                </sf-accordion>
            </div>
        </sf-dialog-content>
        <sf-dialog-content>
            <div fxLayout="column" fxLayoutGap="24px" class="mt-16">
                <sf-accordion class="filter__accordion" multi>
                    <sf-accordion-item>
                        <sf-accordion-item-header>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <span fxLayoutAlign="center center" class="filter__accordion-icon">
                                <sf-icon>info</sf-icon>
                                </span>
                                <span class="ml-12 color--light-black">{{'LABEL_ROLES' | translate }}</span>
                            </div>
                        </sf-accordion-item-header>
                        <div fxLayout="column" fxLayoutGap="24px" class="filter__accordion-details">
                            <ng-container *ngFor="let roleOption of roleOptions">
                                <sf-form-field>
                                    <sf-checkbox [checked]="roleOption.selected" (changed)="onChecked($event, roleOption?.id)"></sf-checkbox>
                                    <label>{{ ('LABEL_' + roleOption?.label) | translate }}</label>
                                </sf-form-field>
                            </ng-container>
                        </div>
                    </sf-accordion-item>
                </sf-accordion>
            </div>
        </sf-dialog-content>
    </div>

    <sf-dialog-action fxLayoutAlign="space-between">
        <button sf-button outlined tabindex="0" ngClass.xs="width--full mb-12" (click)="resetFilter()" data-cy="resetFilter">
            {{'BTN_RESET_FILTER' | translate}}
        </button>
        <button sf-button primary ngClass.xs="width--full" (click)="applyFilter()" data-cy="saveFilter">
            {{'BTN_SAVE_FILTER' | translate}}
        </button>
    </sf-dialog-action>
</div>
