<div class="phone-authentication">
    <div fxHide.lt-lg>
        <form [formGroup]="otpForm.get('multiOtpForm')" #otpsEl class="width--full">
            <div fxLayout="row" fxLayoutAlign="space-between" class="mt-20">
                <ng-container *ngFor="let digit of otpDigits; let i = index">
                    <sf-form-field class="mx-4">
                        <sf-input label=" " (paste)="onPaste($event)" [formControlName]="'val'+i" [value]="otpDigits[i]" type="number" maxlength="1"
                                  (keyup)="updateOtpDigits(otpsEl, i, otpEl, $event); $event.stopPropagation()"
                                  outlined
                                  #otpEl>
                        </sf-input>
                    </sf-form-field>
                </ng-container>
            </div>
        </form>
    </div>
    <div fxHide.gt-md class="opt-form" data-cy="otpForm">
        <form [formGroup]="otpForm?.get('singleOtpForm')">
            <sf-form-field data-cy="otpFormField">
                <sf-input class="width--full mt-16 color--black-60" formControlName="formElementControl" type="text" ewSpaceRemoval ewNonDigitRemoval maxlength="6"></sf-input>
            </sf-form-field>
        </form>
    </div>
</div>
