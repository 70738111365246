<p sfSubtitle1 class="mt-24 color--black-87">{{'QUEST_CUSTOMIZED_FIELDS' | translate}}</p>
<p sfSubtitle2 class="my-8 color--black-60">{{'HINT_CREATE_ADDITIONAL_FIELD' | translate}}</p>
<ng-container [formGroup]="parentFormGroupRef">
  <div formArrayName="dynamicColumns" class="dynamic-attribute">
    <div *ngFor="let control of dynamicColumnsFormArray.controls; let i=index" [formGroup]="control">
      <ng-container *ngIf="!control.get('_destroy')?.value">
        <div fxLayout="row" class="width--full mb-12">
          <div fxFlex.gt-md="60" fxFlex="70">
            <ew-multi-lang [form]="control" [copyControls]="translatableFieldConfigs"></ew-multi-lang>
          </div>
          <div fxFlex.gt-md="40" fxFlex="30" class="mt-16">
            <button sfIconButton class="dynamic-attribute__delete mt-4 mr-32"
                    (click)="removeDynamicField(i, control)" data-cy="delete">
              <sf-icon>delete_outlined</sf-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row" class="width--full mt-20 mb-24">

            <div fxFlex.gt-md="60" fxFlex="70" fxLayoutAlign="start center">
              <div fxLayout="column" class="width--full">
              <sf-select (selectionChange)="dataTypeChanged(i)" [placeholder]="'LABEL_SELECT_INPUT_TYPE' | translate"
                         [outlined]="true" class="width--full" formControlName="dataType" data-cy="inputType">
                <sf-menu>
                  <sf-list>
                    <sf-list-item *ngFor="let type of dataTypeList" [value]="type">{{type}}</sf-list-item>
                  </sf-list>
                </sf-menu>
              </sf-select>
              <p sfCaption class="custom-error">
              <span
                *ngIf="control.get('dataType').hasError('required') && control.get('dataType')?.touched"> {{'ERROR_REQUIRED' | translate}} </span>
              </p>
              </div>

            </div>
            <sf-form-field fxFlex.gt-md="40" fxFlex="30" class="pl-32">
              <sf-checkbox class="dynamic-attribute__checkbox" formControlName="required"
                           data-cy="required"></sf-checkbox>
              <label sfSubtitle2>{{'LABEL_IS_REQUIRED' |translate}}</label>
            </sf-form-field>
        </div>
        <div fxLayout="column" *ngIf="control?.get('dataType')?.value === 'options'">
          <div fxLayout="row" class="width--full">
            <div fxFlex.gt-md="65" fxFlex="78" fxLayoutAlign="end" formArrayName="dynamicOptions">
              <div fxFlex.gt-md="90">
                <div fxLayout="column" class="width--full"
                     *ngFor="let option of dynamicOptionsAttribute(i).controls; let j=index" [formGroup]="option">
                  <ng-container *ngIf="!option.get('_destroy')?.value">
                    <div fxLayout="row" class="width--full">
                      <ew-multi-lang fxFlex="100" [form]="option"
                                     [copyControls]="translatableFieldConfigs"></ew-multi-lang>
                      <button sfIconButton class="dynamic-attribute__delete mt-24" data-cy="delete"
                              (click)="removeOptionField(i, j, control)">
                        <sf-icon>delete_outlined</sf-icon>
                      </button>
                    </div>
                    <div fxFlex="91" fxLayout="row" class="width--full my-16"
                         *ngIf="!control.get('multilingual')?.value">
                      <sf-form-field class="mt-20" fluid>
                        <sf-input [label]="'Field Value'" outlined class="width--full"
                                  formControlName="value"></sf-input>
                      </sf-form-field>
                    </div>
                    <p sfCaption class="custom-error"
                       *ngIf="option.get('value').hasError('required') && option.get('value')?.touched">
                      <span> {{'ERROR_REQUIRED' | translate}} </span>
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="width--full">
            <div fxFlex.gt-md="60" fxFlex="70" fxLayoutAlign="end center">
              <button sf-button outlined class="mt-16 mr-36" data-cy="addOption" (click)="addOptionField(i)">
                <sf-icon class="mr-8">add</sf-icon>
                <span sfButtonLabel>{{'BTN_ADD_OPTION' | translate}}</span>
              </button>
            </div>
          </div>
        </div>
        <sf-list-divider class="my-12"></sf-list-divider>
      </ng-container>
    </div>
  </div>
  <button sf-button (click)="addDynamicField()" data-cy="addField">
    <sf-icon class="mr-8">add</sf-icon>
    {{'BTN_ADD_FIELD' | translate}}</button>
</ng-container>
