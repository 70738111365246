import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImagePickerComponent} from './image-picker/image-picker.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {SfIconModule, SfTypographyModule} from '@ew/shared/safire/components';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, TranslateModule, SfIconModule, SfTypographyModule],
    declarations: [
        ImagePickerComponent
    ],
    exports: [
        ImagePickerComponent
    ]
})
export class SharedComponentsImagePickerModule {
}
