import { ChargeTypeEnum, Configurator, ConfiguratorPrice, Country } from '../graphql';
import { DynamicColumnModel } from './dynamic-column.model';

export class ConfiguratorModel implements Configurator {
  dynamicColumns: DynamicColumnModel[] = [];
  configured: boolean = undefined;
  configuratorPriceTypes: ConfiguratorPrice[] = [];
  id: string = undefined;
  countries: Country[] = [];
  chargeType: ChargeTypeEnum = undefined;
}
