import { NgModule } from '@angular/core';
import { SfIconComponent } from './icon.component';
import { SfMaterialIcons } from './material-icons.directive';

@NgModule({
  exports: [
    SfIconComponent
  ],
  declarations: [SfIconComponent, SfMaterialIcons]
})
export class SfIconModule {
}
