import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileNumberValidatorDirective} from './misc/mobile-number-validator.directive';
import { SpaceRemovalFromInputDirective } from './misc/space-removal-from-input.directive';
import { NonDigitRemovalDirective } from './misc/non-digit-removal.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        MobileNumberValidatorDirective,
        SpaceRemovalFromInputDirective,
        NonDigitRemovalDirective
    ],
    exports: [
        MobileNumberValidatorDirective,
        SpaceRemovalFromInputDirective,
        NonDigitRemovalDirective
    ]
})
export class SharedHelperModule {
}
