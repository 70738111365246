import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfStepComponent, SfStepperDirective } from './stepper.component';
import { SfIconButtonModule } from '../../../icon-button/src/index';
import { SfStepHeaderComponent } from './step-header.component';
import { SfStepIndicatorComponent } from './step-indicator.component';
import { SfStepLabelDirective } from './step-label.directive';
import { SfHorizontalStepperComponent } from './horizontal-stepper/horizontal-stepper.component';
import { CdkStepperModule, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SF_STEPPER_INTL_PROVIDER } from './stepper.intl.service';
import { ErrorStateMatcher } from '../../../forms/src/index';
import { SfVerticalStepperComponent } from './vertical-stepper/sf-vertical-stepper.component';


const DECLARATIONS = [ SfStepComponent,
  SfStepHeaderComponent,
  SfStepperDirective,
  SfStepIndicatorComponent, SfStepLabelDirective,
  SfHorizontalStepperComponent,
SfVerticalStepperComponent];

@NgModule({
  imports: [
    CommonModule,
    SfIconButtonModule,
    CdkStepperModule
  ],
  exports: DECLARATIONS,
  declarations: DECLARATIONS,
  providers: [SF_STEPPER_INTL_PROVIDER, ErrorStateMatcher,
    {provide: STEPPER_GLOBAL_OPTIONS, useValue:{displayDefaultIndicatorType: false }}]
})
export class SfStepperModule {
}
