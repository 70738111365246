import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ContractDuration,
    customMaxDelivery,
    customMinDelivery,
    Level,
    preOrderReleaseDate,
    preOrderStartDate,
    ProductsStoreState,
    ProductTree,
    StepConfig,
    updateTreeValidity,
} from '@ew/shared/services';
import { noop, Observable } from 'rxjs';

import { ProductFacadeService } from '../../../../services/product-facade.service';
import { ProductBaseComponent } from '../../../product-base/product-base.component';

@Component({
    selector: 'ew-other-details-step',
    templateUrl: './other-details-step.component.html',
    styleUrls: ['./other-details-step.component.scss'],
})
export class OtherDetailsStepComponent extends ProductBaseComponent implements OnInit {
  state$: Observable<ProductsStoreState> = this.productsFacadeService.stateChange();
  showPreorderAttr: boolean;
  contractDurationOptions$: Observable<ContractDuration[]>;

  @Input() config: StepConfig;
  @Input() currentProduct: ProductTree;
  @Input() edit: string;
  @Input() level: Level;
  @Input() length: number;
  @Input() form: FormGroup;

  constructor(private productsFacadeService: ProductFacadeService) {
  	super();
  }

  ngOnInit(): void {
  	// [REVISIT: Neeraj 12/10/2021] When contract duration is required
  	// this.setContractDurationValidation();
  	this.setDeliveryValidation();
  	this.setPreOrderValidation();
  	this.edit && this.onEdit();
  }

  changePackageContractDuration(contractDurationOptionId: string, isSelected: boolean): void {
  	const index = this.packageContractDurations.value.findIndex((duration) => duration.contractDurationId === contractDurationOptionId);
  	if (index > -1 && !isSelected) {
  		// eslint-disable-next-line @typescript-eslint/naming-convention
  		this.packageContractDurations.at(index).patchValue({ _destroy: true });
  	} else if (isSelected && index === -1) {
  		this.packageContractDurations.push(
  			new FormGroup({
  				// eslint-disable-next-line @typescript-eslint/naming-convention
  				_destroy: new FormControl(),
  				contractDurationId: new FormControl(contractDurationOptionId),
  				id: new FormControl(),
  			})
  		);
  	}
  }

  // [REVISIT: Neeraj 12/10/2021] When contract duration is required
  // setContractDurationValidation(): void {
  //   if (this.level.levelRule?.contractDuration) {
  //     this.contractDurationOptions$ = this.productsFacadeService.fetchContractDurations();
  //   }
  // }

  setDeliveryValidation(): void {
  	this.level.levelRule?.delivery
  		? this.deliveryTimeAttributes.setValidators([customMaxDelivery('minimum', 'maximum'), customMinDelivery('minimum', 'maximum')])
  		: noop();
  }

  setPreOrderValidation(): void {
  	if (this.level.levelRule?.preorder) {
  		this.preorder.get('startDate').setValidators([Validators.required, preOrderStartDate(this.preorder.get('releaseDate'))]);
  		this.preorder.get('releaseDate').setValidators([Validators.required, preOrderReleaseDate(this.preorder.get('startDate'))]);
  	} else {
  		this.preorder.get('startDate').setValidators(null);
  		this.preorder.get('releaseDate').setValidators(null);
  	}
  }

  onEdit(): void {
  	['deliveryTime'].forEach((key) => this.patchFormData(key));
  	this.currentProduct.package.packageContractDurations?.forEach((contractDuration) => {
  		this.changePackageContractDuration(contractDuration.contractDurationId, true);
  	});
  }

  patchFormData(formGroup: string): void {
  	this.form.get(formGroup).patchValue(this.currentProduct?.package?.[formGroup]);
  }

  updatePreorderDelivery(clickType: string, stepId: number): void {
  	updateTreeValidity(this.deliveryTimeAttributes);
  	updateTreeValidity(this.preorder);
  	this.formatDeliveryAttr();
  	this.saveData(clickType, stepId);
  }

  formatDeliveryAttr(): void {
  	if (!this.deliveryTimeAttributes.get('minimum').value && !this.deliveryTimeAttributes.get('maximum').value) {
  		this.form.value['deliveryTime'] = undefined;
  	}
  }

  durationSelected(contractDurationId: string): boolean {
  	const value = this.packageContractDurations.value?.find((duration) => duration?.contractDurationId === contractDurationId);
  	return value && !value?._destroy;
  }

  get deliveryTimeAttributes(): FormGroup {
  	return this.form.get('deliveryTime') as FormGroup;
  }

  get preorder(): FormGroup {
  	return this.form.get('preorder') as FormGroup;
  }

  get packageContractDurations(): FormArray {
  	return this.form.get('packageContractDurations') as FormArray;
  }
}
