import {DocumentNode} from 'graphql';
import {TypedDocumentNode} from '@apollo/client';
import gql from 'graphql-tag';

export const SERVICES: (DocumentNode | TypedDocumentNode) = gql`
    query services {
        services {
            code
            dynamicValues {
                dynamicColumnCode
                dynamicColumnName
                dynamicOption {
                    hexValue
                    id
                    name
                    value
                }
                id
                name
                value
            }
            id
            images
            name
            subscribedPackages {
                combiPackageTypes
                endDate
                id
                monthlyCost {
                    download
                    id
                    mobile
                    subscribedPackageId
                    upload
                    voice
                    mobileDataCost
                }
                packageName
                price
                productItemId
                productTreeId
                serviceName
                startDate
                status
                subscribedAddons {
                    id
                    name
                    price
                    productItemId
                    status
                }
            }
        }
    }
`;

export const BILLS: DocumentNode | TypedDocumentNode = gql`
    query bills ($after: String, $before: String, $dateRange: [ISO8601Date!], $first: Int, $last: Int, $query: String, $skip: Int) {
        bills (after: $after, before: $before, dateRange: $dateRange, first: $first, last: $last, query: $query, skip: $skip) {
            nodes {
                amount
                creationDate
                dueDate
                invoiceNumber
                invoiceUrl
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            totalCount
        }
    }
`;

export const USER_DETAILS_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query user {
        user {
            active
            billPaymentNotificationState
            billingAddresses {
                city
                id
                relocationDate
                status
                street
                streetNo
                type
                zip
                additionalInformation
                apartmentNo
            }
            notificationLanguage
            clientNumber
            impersonated
            email
            financeState
            id
            groupId
            supportGroupAdmin
            adminRoles {
                id
                roleId
                userId
                roleName
            }
            installationAddresses {
                city
                id
                relocationDate
                status
                street
                streetNo
                type
                zip
                additionalInformation
                apartmentNo
            }
            medium {
                medium
            }
            mobileNumber
            mobileSubscriptionCount
            mobileSubscriptions {
                cost
                id
                inclusiveUnits {
                    amount
                    available
                    description
                    unitType
                }
                number
                subscribedPackages {
                    code
                    combiPackageTypes
                    endDate
                    id
                    monthlyCost {
                        download
                        id
                        mobile
                        mobileDataCost
                        subscribedPackageId
                        upload
                        voice
                    }
                    packageName
                    price
                    productItemId
                    productTreeId
                    serviceName
                    startDate
                    status
                    subscribedAddons {
                        id
                        name
                        price
                        productItemId
                        status
                    }
                }
            }
            notificationSetting
            oldSubscribedPackages {
                id
                idmsService
                name
                response
                userId
                dataSentToAnalytics
            }
            otpRequiredForLogin
            profile {
                companyName
                dateOfBirth
                designation
                email
                firstName
                id
                installationAddressesToBilling
                lastName
                mobileNumber
                salutation
                url
            }
            permissions {
                actions
                resource
            }
            role {
                id
                name
                permissions {
                    accessorId
                    accessorType
                    actions
                    id
                    resource
                }
                users {
                    active
                    billPaymentNotification
                    clientNumber
                    email
                    id
                    mobileNumber
                    name
                    profileEmail
                    profileMobileNumber
                    invitationAcceptedAt
                    url
                }
                usersCount
            }
            roleId
            subscribedPackages {
                subscriptionStatus
                code
                combiPackageTypes
                endDate
                id
                monthlyCost {
                    download
                    id
                    mobile
                    mobileDataCost
                    subscribedPackageId
                    upload
                    voice
                }
                packageName
                price
                productItemId
                productTreeId
                serviceName
                startDate
                status
                subscribedAddons {
                    id
                    name
                    price
                    productItemId
                    status
                }
            }
            supportTicketCount
            systemTicketCount
            unconfirmedEmail
            unconfirmedMobile
            userType {
                business
                combi
                id
            }
        }
    }
`;

export const CREATE_ADDRESS_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation createAddress ($input: CreateAddressInput!) {
        createAddress (input: $input) {
            id
            relocationDate
            status
            street
            streetNo
            type
            zip
            city
        }
    }
`;

export const BANNERS_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query banners ($active: Boolean, $after: String, $before: String, $first: Int, $last: Int, $query: String, $skip: Int) {
        banners (active: $active, after: $after, before: $before, first: $first, last: $last, query: $query, skip: $skip) {
            nodes {
                active
                bannerButtonText
                bannerDescription
                bannerTitle
                buttonText
                createdBy
                description
                serviceCode
                endDate
                externalButtonLink
                id
                internalButtonLink {
                    name
                    productTreeId
                }
                packageName
                productItemIds
                startDate
                target
                title
                url
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            totalCount
        }
    }
`;

export const POPUP_FOR_OLD_SUBSCRIBED_PACKAGES: (DocumentNode | TypedDocumentNode) = gql`
    mutation popupForOldSubscribedPackages ($input: PopupForOldSubscribedPackagesInput!) {
        popupForOldSubscribedPackages (input: $input) {
            clientMutationId
            status
        }
    }
`

export const RESEND_CONFIRMATION_TOKEN: (DocumentNode | TypedDocumentNode) = gql`
    mutation resendConfirmationToken ($input: ResendConfirmationTokenInput!) {
        resendConfirmationToken (input: $input) {
            clientMutationId
            status
        }
    }   
`
export const INCLUSIVE_UNIT = gql`
    query inclusiveUnits ($after: String, $before: String, $first: Int, $last: Int, $mobileSubscription: ID, $skip: Int) {
        inclusiveUnits (after: $after, before: $before, first: $first, last: $last, mobileSubscription: $mobileSubscription, skip: $skip) {
            nodes {
                amount
                available
                description
                unitType
                descriptionDetails
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            totalCount
        }
    }
`;

export const USER_LISTING_TABLE_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query users ($active: Boolean, $after: String, $before: String, $direction: DirectionsEnum, $first: Int, $last: Int, 
            $query: String, $roleIds: [String!], $skip: Int, $sort: UserSortEnum) {
        users (active: $active, after: $after, before: $before, direction: $direction, first: $first, last: $last, 
            query: $query, roleIds: $roleIds, skip: $skip, sort: $sort) {
            nodes {
                active
                billPaymentNotification
                companyName
                clientNumber
                permissions {
                    resource
                    actions
                }
                email
                id
                name
                mobileNumber
                profileEmail
                offDayEnd
                offDayStart
                profileMobileNumber
                invitationAcceptedAt
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            totalCount
        }
    }
`;

export const ADMIN_LISTING_TABLE_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query admins ($after: String, $before: String, $direction: DirectionsEnum, 
        $filter: [AdminRoleEnum!], $first: Int, $last: Int, $query: String, $skip: Int, $sort: String) {
    admins (after: $after, before: $before, direction: $direction, filter: $filter, 
        first: $first, last: $last, query: $query, skip: $skip, sort: $sort) {
            nodes {
                active
                email
                id
                mobileNumber
                invitationAcceptedAt
                name
                roles
            }
            totalCount
            }
        }
`;

export const USER_LISTING_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query roles {
        roles {
            id
            name
            users {
                active
                clientNumber
                email
                id
                name
                mobileNumber
                profileEmail
                profileMobileNumber
                invitationAcceptedAt
                url
            }
            usersCount
        }
    }
`;

export const CHANGE_SUBSCRIPTION_STATUS = gql`
    mutation subscriptionStatus ($input: SubscriptionStatusInput!) {
    subscriptionStatus (input: $input) {
        clientMutationId
        status
    }
}
`

export const SENT_TO_ANALYTICS_MUTATION = gql`
    mutation sentToAnalytics ($input: SentToAnalyticsInput!) {
        sentToAnalytics (input: $input) {
            clientMutationId
            status
        }
    }
`

export const REVERSE_GEOCODING_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query reverseGeoCoding ($latitude: String!, $longitude: String!) {
        reverseGeoCoding (latitude: $latitude, longitude: $longitude)
    }
`

export const CHANGE_PERMISSION: (DocumentNode | TypedDocumentNode ) = gql`
    mutation changePermission ($input: ChangePermissionInput!) {
        changePermission (input: $input) {
            clientMutationId
            status
        }
    }
`

export const GET_NOTIFICATIONS_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query notifications ($after: String, $before: String, $first: Int, $last: Int, $readStatus: Boolean, $skip: Int) {
        notifications (after: $after, before: $before, first: $first, last: $last, readStatus: $readStatus, skip: $skip) {
            nodes {
                createdAt
                id
                readStatus
                notification
                icon
                data
            }
            totalCount
        }
    }
`

export const GET_NOTIFICATIONS_COUNT_QUERY: (DocumentNode | TypedDocumentNode) = gql`
    query notificationStats {
        notificationStats {
            all
            read
            unread
        }
    }
`
export const ADD_NEW_ADMIN_MUTATION: (DocumentNode | TypedDocumentNode) = gql`
    mutation addAdmin ($input: AddAdminInput!) {
        addAdmin (input: $input) {
            clientMutationId
            status
        }
    }
`