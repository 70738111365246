import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, SPACE } from '@angular/cdk/keycodes';

import { MDCRippleAdapter, MDCRippleFoundation } from '@material/ripple';

import { SfRippleCapableSurface, SfRippleService } from '../../../ripple/src/index';

/** Change event that is fired whenever the selected state of an option changes. */
export class SfListSelectionChange {
  constructor(
    public source: SfListItem) {
  }
}

/**
 * Describes a parent SfListComponent component.
 * Contains properties that SfListItem can inherit.
 */
export interface SfListParentComponent {
  disableRipple: boolean;
}

/**
 * Injection token used to provide the parent SfListComponent component to SfListItem.
 */
export const SF_LIST_PARENT_COMPONENT = new InjectionToken<SfListParentComponent>('SF_LIST_PARENT_COMPONENT');

let uniqueIdCounter = 0;

@Directive({
  selector: '[sfListItemGraphic], sf-list-item-graphic',
  exportAs: 'sfListItemGraphic',
  host: {
    'role': 'presentation',
    'class': 'mdc-list-item__graphic sf-icon__color',
    '[attr.aria-hidden]': 'true'
  }
})
export class SfListItemGraphic {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Directive({
  selector: '[sfListItemMeta], sf-list-item-meta',
  exportAs: 'sfListItemMeta',
  host: { 'class': 'mdc-list-item__meta' }
})
export class SfListItemMeta {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Component({
  selector: '[sfListItemText], sf-list-item-text',
  exportAs: 'sfListItemText',
  host: { 'class': 'mdc-list-item__text' },
  template: `
    <ng-container>
      <span class="mdc-list-item__primary-text"><ng-content></ng-content></span>
      <span class="mdc-list-item__secondary-text" *ngIf="secondaryText">{{secondaryText}}</span>
    </ng-container>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfListItemText {
  @Input() secondaryText?: string;

  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Directive({
  selector: '[sfListItemSecondary], sf-list-item-secondary',
  exportAs: 'sfListItemSecondary',
  host: { 'class': 'mdc-list-item__secondary-text' }
})
export class SfListItemSecondary {
  constructor(public elementRef: ElementRef<HTMLElement>) {
  }
}

@Component({
  selector: 'sf-list-item, a[sf-list-item]',
  exportAs: 'sfListItem',
  host: {
    'role': 'listitem',
    '[id]': 'id',
    '[tabIndex]': 'tabIndex',
    'class': 'mdc-list-item sf-list-item',
    '[class.mdc-list-item--selected]': 'selected',
    '[class.mdc-list-item--activated]': 'activated',
    '[class.mdc-list-item--disabled]': 'disabled',
    '(click)': '_emitChangeEvent()'
  },
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SfRippleService]
})
export class SfListItem implements OnDestroy, SfRippleCapableSurface {
  _root!: Element;
  @Input() value: any;
  @Input() tabIndex = -1;
  @Output() readonly selectionChange: EventEmitter<SfListSelectionChange>
    = new EventEmitter<SfListSelectionChange>();

  constructor(
    public ripple: SfRippleService,
    private _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef,
    @Optional() @Inject(SF_LIST_PARENT_COMPONENT) private _parent: SfListParentComponent) {
    this._root = this.elementRef.nativeElement;
    this.ripple = this._createRipple();
    this.ripple.init();
  }

  private _id = `sf-list-item-${uniqueIdCounter++}`;

  /** The unique ID of the list item. */
  get id(): string {
    return this._id;
  }

  private _selected = false;

  /** Whether the list item is selected. */
  @Input()
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._selected) {
      this._selected = newValue;
      this._changeDetectorRef.markForCheck();
    }
  }

  private _activated = false;

  /** Whether the list item is activated. */
  @Input()
  get activated(): boolean {
    return this._activated;
  }

  set activated(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._activated) {
      this._activated = newValue;
      this._changeDetectorRef.markForCheck();
    }
  }

  private _disabled = false;

  /** Whether the list item is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this._changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.ripple.destroy();
  }

  focus(): void {
    this.getListItemElement().focus();
  }

  setRole(role: string): void {
    this.getListItemElement().setAttribute('role', role);
  }

  getListItemElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(evt: KeyboardEvent): void {
    if (evt.keyCode === ENTER || evt.keyCode === SPACE) {
      this._emitChangeEvent();
    }
  }

  /** Emits a change event if the selected state of an option changed. */
  _emitChangeEvent(): void {
    if (this._disabled) {
      return;
    }

    this.selectionChange.emit(new SfListSelectionChange(this));
  }

  private _createRipple(): SfRippleService {
    const adapter: MDCRippleAdapter = {
      ...SfRippleService.createAdapter(this),
      isSurfaceDisabled: () => this._disabled || this._parent.disableRipple
    };
    return new SfRippleService(this.elementRef, new MDCRippleFoundation(adapter));
  }
}
