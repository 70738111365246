import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ProductFacadeService} from '../../../../services/product-facade.service';
import {Configurator, ProductStateEnum} from '@ew/shared/services';

@Component({
    selector: 'ew-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {
    @Input() form: FormGroup;
    configurator: Configurator = this.productFacadeService.getSpecificState(ProductStateEnum.CONFIGURATOR)[0];

    normalPricing = true;
    volumePricing = false;
    incrementalPricing = false;
    countryPricing = false;
    count = 1;

    constructor(private productFacadeService: ProductFacadeService) {
    }
}
