import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MDCComponent } from '../../../../base/src/index';
import { SfRippleCapableSurface, SfRippleService } from '../../../../ripple/src/index';
import { SfTabIndicatorComponent } from '../tab-indicator/tab-indicator.component';

import { MDCTabAdapter, MDCTabFoundation } from '@material/tab';
import { MDCRippleAdapter, MDCRippleFoundation } from '@material/ripple';

/**
 * Describes a parent SfTabBar component.
 * Contains properties that SfTab can inherit.
 */
export interface SfTabBarParentComponent {
  activateTab(index: number): void;

  getTabIndex(tab: SfTabComponent): number;
}

/**
 * Injection token used to provide the parent SfTabBar component to SfTab.
 */
export const SF_TAB_BAR_PARENT_COMPONENT =
  new InjectionToken<SfTabBarParentComponent>('SF_TAB_BAR_PARENT_COMPONENT');

export interface SfTabInteractedEvent {
  detail: {
    tabId: string;
    tab: SfTabComponent;
  };
}

let nextUniqueId = 0;

@Directive({
  selector: 'sf-tab-label, [sfTabLabel]',
  host: { 'class': 'mdc-tab__text-label' }
})
export class SfTabLabel {
  constructor(public elementRef: ElementRef) {
  }
}

@Directive({
  selector: 'sf-tab-icon, [sfTabIcon]',
  host: { 'class': 'mdc-tab__icon' }
})
export class SfTabIcon {
  constructor(public elementRef: ElementRef) {
  }
}

@Component({
  selector: '[sfTab], sf-tab',
  exportAs: 'sfTab',
  host: {
    '[id]': 'id',
    'role': 'tab',
    'class': 'mdc-tab',
    '[class.mdc-tab--stacked]': 'stacked',
    '[class.mdc-tab--min-width]': 'fixed',
    '[class.sf-mdc-tab--disabled]': 'disabled'
  },
  template: `
    <div #content class="mdc-tab__content">
      <sf-icon class="sf-tab__icon" *ngIf="icon">{{icon}}</sf-icon>
      <ng-content select="sf-icon"></ng-content>
      <span class="mdc-tab__text-label" *ngIf="label">{{label}}</span>
      <ng-content></ng-content>
      <ng-container *ngIf="fixed">
        <ng-container *ngTemplateOutlet="indicator"></ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="!fixed">
      <ng-container *ngTemplateOutlet="indicator"></ng-container>
    </ng-container>
    <ng-template #indicator>
      <sf-tab-indicator></sf-tab-indicator>
    </ng-template>
    <div #ripplesurface class="mdc-tab__ripple"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [SfRippleService]
})
export class SfTabComponent extends MDCComponent<MDCTabFoundation> implements AfterViewInit, OnInit, OnDestroy,
  SfRippleCapableSurface {
  _root!: Element;
  @Input() label?: string;
  @Input() icon?: string;
  @Output() readonly interacted: EventEmitter<SfTabInteractedEvent> =
    new EventEmitter<SfTabInteractedEvent>();
  @ViewChild('content', { static: false }) content!: ElementRef;
  @ViewChild('ripplesurface', { static: false }) rippleSurface!: ElementRef;
  @ViewChild(SfTabIndicatorComponent, { static: false }) tabIndicator!: SfTabIndicatorComponent;
  /** Emits whenever the component is destroyed. */
  private _destroy = new Subject<void>();
  private _uniqueId = `sf-tab-${++nextUniqueId}`;
  @Input() id: string = this._uniqueId;

  constructor(
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ripple: SfRippleService,
    public elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(SF_TAB_BAR_PARENT_COMPONENT) private _parent: SfTabBarParentComponent) {
    super(elementRef);
    this._root = this.elementRef.nativeElement;
  }

  private _stacked = false;

  @Input()
  get stacked(): boolean {
    return this._stacked;
  }

  set stacked(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._stacked) {
      this._stacked = newValue;
    }
  }

  private _fixed = false;

  @Input()
  get fixed(): boolean {
    return this._fixed;
  }

  set fixed(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._fixed) {
      this._fixed = newValue;
      this._changeDetectorRef.detectChanges();
    }
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _focusOnActivate = true;

  @Input()
  get focusOnActivate(): boolean {
    return this._focusOnActivate;
  }

  set focusOnActivate(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._focusOnActivate) {
      this._focusOnActivate = newValue;
      this._foundation.setFocusOnActivate(this._focusOnActivate);
    }
  }

  /** Getter for the active state of the tab */
  get active(): boolean {
    return this._foundation.isActive();
  }

  getDefaultFoundation() {
    const adapter: MDCTabAdapter = {
      setAttr: (attr: string, value: string) => this._getHostElement().setAttribute(attr, value),
      addClass: (className: string) => this._getHostElement().classList.add(className),
      removeClass: (className: string) => this._getHostElement().classList.remove(className),
      hasClass: (className: string) => this._getHostElement().classList.contains(className),
      activateIndicator: (previousIndicatorClientRect: ClientRect) =>
        this.tabIndicator.activate(previousIndicatorClientRect),
      deactivateIndicator: () => this.tabIndicator.deactivate(),
      notifyInteracted: () => this.interacted.emit({ detail: { tabId: this.id, tab: this } }),
      getOffsetLeft: () => this._getHostElement().offsetLeft,
      getOffsetWidth: () => this._getHostElement().offsetWidth,
      getContentOffsetLeft: () => this.content.nativeElement.offsetLeft,
      getContentOffsetWidth: () => this.content.nativeElement.offsetWidth,
      focus: () => this._getHostElement().focus()
    };
    return new MDCTabFoundation(adapter);
  }

  ngOnInit(): void {
    this._foundation.init();
    this._loadListeners();
  }

  ngAfterViewInit(): void {
    this._ripple = this._createRipple();
    this._ripple.init();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();

    this._ripple.destroy();
  }

  /** Activates the tab */
  activate(computeIndicatorClientRect?: ClientRect): void {
    this._foundation.activate(computeIndicatorClientRect);
  }

  /** Deactivates the tab */
  deactivate(): void {
    this._foundation.deactivate();
  }

  /** Returns the indicator's client rect */
  computeIndicatorClientRect(): ClientRect {
    return this.tabIndicator.computeContentClientRect();
  }

  computeDimensions(): any {
    return this._foundation.computeDimensions();
  }

  getTabBarParent(): SfTabBarParentComponent {
    return this._parent;
  }

  focus(): void {
    this._getHostElement().focus();
  }

  private _createRipple(): SfRippleService {
    const rippleSurface = this.rippleSurface.nativeElement as HTMLElement;

    const adapter: MDCRippleAdapter = {
      ...SfRippleService.createAdapter(this),
      addClass: (className: string) => rippleSurface.classList.add(className),
      removeClass: (className: string) => rippleSurface.classList.remove(className),
      updateCssVariable: (varName: string, value: string) => rippleSurface.style.setProperty(varName, value)
    };
    return new SfRippleService(this.elementRef, new MDCRippleFoundation(adapter));
  }

  private _loadListeners(): void {
    this._ngZone.runOutsideAngular(() =>
      fromEvent<MouseEvent>(this._getHostElement(), 'click').pipe(takeUntil(this._destroy))
        .subscribe(() => this._ngZone.run(() => {
          if (!this.active && !this._disabled) {
            this._foundation.handleClick();
          }
        })));
  }

  /** Retrieves the DOM element of the component host. */
  private _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
