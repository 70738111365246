<div class="sf-snack-bar__content">
  <sf-icon>{{data.icon}}</sf-icon>
  <p class="sf-snack-bar__text">{{data.message}}</p>
  <button sf-button class="sf-simple-snackbar__action-btn" (click)="action()"
          *ngIf="hasAction;else dismissButton">
    {{data.action}}
  </button>
  <ng-template #dismissButton>
    <button sf-icon-button class="sf-simple-snackbar__action" icon="clear" (click)="dismiss()"></button>
  </ng-template>
</div>
