import { NgModule } from '@angular/core';
import { SfButtonModule } from '../../button/src';
import { SfFormsModule } from '../../forms/src';
import { SfIconModule } from '../../icon/src';
import { SfCheckboxModule } from '../../checkbox/src';
import { SfRadioModule } from '../../radio/src';
import { SfSelectModule } from '../../select/src';
import { SfMenuModule } from '../../menu/src';
import { SfListModule } from '../../list/src';
import { SfInputModule } from '../../input/src';
import { SfSwitchModule } from '../../switch/src';
import { SfChipsModule } from '../../chips/src';
import { SfIconButtonModule } from '../../icon-button/src';
import { SfCardModule } from '../../card/src';
import { SfFabModule } from '../../fab/src';
import { SfTabBarModule } from '../../tab-bar/src';
import { SfDataTableModule } from '../../data-table/src';
import { SfTypographyModule } from '../../typography/src';
import { SfDrawerModule } from '../../drawer/src';
import { SfElevationModule } from '../../elevation/src';
import { SfStepperModule } from '../../stepper/src';
import { SfAvatarModule } from '../../avatar/src';
import { SfAccordionModule } from '../../accordion/src';
import { SfHeaderModule } from '../../header/src';
import { SfButtonToggleModule } from '../../button-toggle/src';
import { SfTooltipModule } from '../../tooltip/src';
import { SfSnackBarModule } from '../../snack-bar/src';
import { SfDialogModule } from '../../dialog/src';
import { SfPaginationModule } from '../../pagination/src';
import { SfBadgesModule } from '../../badges/src';
import { SfSliderModule } from '../../slider/src';
import { SfImageListModule } from '../../image-list/src';
import { SfBreadcrumbModule } from '../../breadcrumb/src';
import { SfAutocompleteModule } from '../../autocomplete/src';

@NgModule({
    exports: [SfButtonModule,
        SfCheckboxModule,
        SfFormsModule,
        SfIconModule,
        SfRadioModule,
        SfSelectModule,
        SfMenuModule,
        SfListModule,
        SfInputModule,
        SfSwitchModule,
        SfChipsModule,
        SfIconButtonModule,
        SfCardModule,
        SfFabModule,
        SfTabBarModule,
        SfTypographyModule,
        SfDataTableModule,
        SfDrawerModule,
        SfElevationModule,
        SfStepperModule,
        SfAvatarModule,
        SfHeaderModule,
        SfAccordionModule,
        SfButtonToggleModule,
        SfTooltipModule,
        SfSnackBarModule,
        SfDialogModule,
        SfBadgesModule,
        SfPaginationModule,
        SfSliderModule,
        SfImageListModule,
        SfBreadcrumbModule,
        SfAutocompleteModule
    ]
})
export class SfComponentsModule {
}
