import { Configurator, CountryList, Price } from '../../graphql';
import { LevelModel } from './level.model';

export interface LevelsStoreState {
  levelsConfigurator: Configurator;
  levelState: LevelModel;
  countries: CountryList[];
  priceTypes: Price[];
  newLevel: boolean;
}

export enum LevelStateEnum {
  configurator = 'levelsConfigurator',
  level = 'levelState',
  country = 'countries',
  price = 'priceTypes',
  newLevel = 'newLevel'
}
