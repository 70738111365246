import { gql } from 'apollo-angular';
import { TypedDocumentNode } from '@apollo/client/core';
import { Configurator } from '@ew/shared/services';

export const CONFIGURATORS_QUERY: TypedDocumentNode<{ configurators: Configurator[] }> = gql`
  query configurators {
    configurators {
      chargeType
      configuratorPriceTypes {
        id
        priceTypeDescription
        priceTypeId
        priceTypeName
        pricingType
      }
      configured
      countries {
        id
        countryCode
        currencyCode
        name
        default
      }
      dynamicColumns {
        dataType
        name
        id
        multilingual
        required
        meta
        dynamicOptions {
          id
          name
          value
          hexValue
          meta
        }
      }
      id
    }
  }
`;
