import {Injectable} from '@angular/core';
import {SfSnackBarConfig, SfSnackBarService, SfSnackbarType} from '@ew/shared/safire/components';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
	private readonly snackBarConfig: SfSnackBarConfig;

	constructor(
		private snackbar: SfSnackBarService,
		private translateService: TranslateService
	) {
	    this.snackBarConfig = new SfSnackBarConfig();
	    this.snackBarConfig.verticalPosition = 'top';
	    this.snackBarConfig.horizontalPosition = 'center';
	    this.snackBarConfig.duration = 5000;
	}

	show(message: string | Array<string>, type: SfSnackbarType = 'success', config?: SfSnackBarConfig): void {
	    const snackBarConfig: SfSnackBarConfig = Object.assign({}, this.snackBarConfig, {
	        data: this.translateService.instant(message),
	        panelClass: type, ...config,
	        severity: type
	    });

	    if (Array.isArray(message)) {
	        message = message.join('\n');
	    }
	    this.snackbar.open(this.translateService.instant(message), 'X', type, snackBarConfig);
	}

	beSnackBar(message: string | Array<string>, type: SfSnackbarType = 'success', config?: SfSnackBarConfig): void {
	    const snackBarConfig: SfSnackBarConfig = Object.assign({}, this.snackBarConfig, {
	        // data: this.translateService.instant(message),
	        panelClass: type, ...config,
	        severity: type
	    });

	    if (Array.isArray(message)) {
	        message = message.join('\n');
	    }
	    this.snackbar.open(message, 'X', type, snackBarConfig);
	}
}
