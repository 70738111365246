import {Injectable} from '@angular/core';
import {LanguageSwitcherBlService} from './language-switcher-bl.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageSwitcherFacadeService {

    constructor(private langSwitcherBl: LanguageSwitcherBlService) {
    }

    setCurrentLang(lang: string): void {
        this.langSwitcherBl.setCurrentLang(lang);
    }

    getCurrentLang(): string {
        return this.langSwitcherBl.getCurrentLang();
    }

    setDefaultLanguage(): void {
        this.langSwitcherBl.setDefaultLanguage();
    }
}
