import {NgModule, Inject, Injectable} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {CoreModule} from './core/core.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@ew/env/environment';
import en from '@ew/app/core/i18n/en.json';
import de from '@ew/app/core/i18n/de.json';
import {APP_NAME_STORAGE, Environment, ENV_STORAGE} from '@ew/shared/services';
import {I18nService} from '@ew/shared/safire/i18n';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SfButtonModule, SfSnackBarModule} from '@ew/shared/safire/components';
import {SharedApolloGraphqlModule} from '@ew/shared/apollo-graphql';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SharedRollbarModule} from '../../../../libs/shared/rollbar/src/lib/shared-rollbar.module';
import {SharedCheckpointModule} from '@ew/shared/checkpoint';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import localeDE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { SharedLayoutCookiebarModule } from '@ew/shared/layout/cookiebar';
import { AngularFireModule } from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';

// By default, Angular only contains locale data for en-US, so we import locale data for other languages manually
registerLocaleData(localeDE);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        CoreModule,
        SfSnackBarModule,
        SharedRollbarModule,
        SharedCheckpointModule,
        SharedApolloGraphqlModule,
        FlexLayoutModule,
        SfButtonModule,
        RecaptchaV3Module,
        SharedLayoutCookiebarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig)
    ],
    providers: [
        {provide: APP_NAME_STORAGE, useValue: environment.applicationShortCode},
        {provide: ENV_STORAGE, useValue: environment},
        {provide: Window, useValue: window},
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey }
    ],
    bootstrap: [AppComponent]
})
@Injectable()
export class AppModule {
    constructor(private i18nService: I18nService, @Inject(ENV_STORAGE) private env: Environment) {
        this.i18nService.init();
        this.i18nService.loadTranslations({
            en, de
        });
    }
}

