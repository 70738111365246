import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SfFloatingLabelModule } from '../../../floating-label/src/index';
import { SfLineRippleModule } from '../../../line-ripple/src/index';
import { SfNotchedOutlineModule } from '../../../notched-outline/src/index';
import { SfFormsModule } from '../../../forms/src/index';
import { SfMenuModule } from '../../../menu/src/index';

import { SfSelectComponent, SfSelectOptionComponent } from './select.component';
import { SfSelectIconDirective } from './select-icon.directive';
import { SfSelectHelperTextComponent } from './select-helper-text.component';

const SELECT_DECLARATIONS = [
  SfSelectComponent,
  SfSelectHelperTextComponent,
  SfSelectIconDirective,
  SfSelectOptionComponent
];

@NgModule({
  imports: [
    CommonModule,
    SfMenuModule,
    SfFormsModule,
    SfFloatingLabelModule,
    SfNotchedOutlineModule,
    SfLineRippleModule
  ],
  exports: [
    SfMenuModule,
    SfFormsModule,
    SELECT_DECLARATIONS
  ],
  declarations: SELECT_DECLARATIONS
})
export class SfSelectModule {
}
