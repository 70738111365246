<div fxLayout="column" ngClass.xs="mt-28">
    <p sfSubtitle1 class="color--black-87">{{'LABEL_SELECT_REGION' | translate}}</p>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" class="product-base__region my-8">
        <ng-container *ngFor="let dynamicOption of dynamicCheckboxOptions">
            <sf-form-field class="mr-12" data-cy="checkbox">
                <sf-checkbox [checked]="dynamicOption?.selected"
                             (changed)="regionCheck($event, dynamicOption.optionId)"></sf-checkbox>
                <label class="mt-4">{{ dynamicOption.checkBoxName }}</label>
            </sf-form-field>
        </ng-container>
    </div>
</div>
