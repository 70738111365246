
import {InjectionToken} from '@angular/core';
import {CdkAccordion} from '@angular/cdk/accordion';

/** SfAccordion's display modes. */
export type SfAccordionDisplayMode = 'default' | 'flat';

/** SfAccordion's toggle positions. */
export type SfAccordionTogglePosition = 'before' | 'after';

/**
 * Base interface for a `SfAccordion`.
 * @docs-private
 */
export interface SfAccordionBase extends CdkAccordion {
  /** Whether the expansion indicator should be hidden. */
  hideToggle: boolean;

  /** Display mode used for all accordion items in the accordion. */
  displayMode: SfAccordionDisplayMode;

  /** The position of the toggle indicator. */
  togglePosition: SfAccordionTogglePosition;

  /** Handles keyboard events coming in from the accordion item headers. */
  _handleHeaderKeydown: (event: KeyboardEvent) => void;

  /** Handles focus events on the accordion item headers. */
  _handleHeaderFocus: (header: any) => void;
}


/**
 * Token used to provide a `SfAccordion` to `SfAccordionItem`.
 * Used primarily to avoid circular imports between `SfAccordion` and `SfAccordionItem`.
 */
export const Sf_ACCORDION = new InjectionToken<SfAccordionBase>('SF_ACCORDION');
