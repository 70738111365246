import {NgModule} from '@angular/core';

import {SfFormsModule} from '../../../forms/src/index';
import {SfSwitchComponent} from './switch.component';

@NgModule({
  imports: [SfFormsModule],
  exports: [
    SfFormsModule,
    SfSwitchComponent
  ],
  declarations: [SfSwitchComponent]
})
export class SfSwitchModule { }
