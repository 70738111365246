import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {keys} from 'lodash-es';
import {ControlOptions, MultiLanguages} from '@ew/shared/services';
import {TranslateService} from '@ngx-translate/core';
import {SharedFacadeService} from '@ew/shared/services';
import {NotificationService} from '@ew/shared/services';

@Component({
    selector: 'ew-multi-lang',
    templateUrl: './multi-lang.component.html',
    styleUrls: ['./multi-lang.component.scss']
})
export class MultiLangComponent {
	@Input() form: FormGroup;
	@Input() copyControls: ControlOptions[];
	@Input() selectedLang: FormControl;
	@Output() copyValue: EventEmitter<string> = new EventEmitter<string>();

	languages: string[] = keys(new MultiLanguages());
	selectedLanguage: FormControl = new FormControl(this.languages[0]);

	constructor(private translateService: TranslateService, private sharedFacadeService: SharedFacadeService,
	            private notify: NotificationService, private translate: TranslateService) {
	}

	copyContent(): void {
	    const data: Record<string, unknown> = this.form?.value;
	    this.copyControls.map(data => data.key).forEach(key => {
	        const content: { [keys: string]: string } = {
	            [key]: data[key][this.selectedLang.value ? this.selectedLang.value : this.selectedLanguage.value]
	        };
	        keys(new MultiLanguages()).forEach(lang => {
	            data[key][lang] = content[key];
	        });
	    });
	    this.copyValue.emit('productDetail');
	    this.form.patchValue(data);
	    this.notify.show('SUCCESS_COPIED');
	}

	selectLang = (lang: string): void => {
	    this.selectedLanguage.setValue(lang);
	    this.selectedLang.setValue(lang);
	}

	getControl = (type: string, lang: string): FormControl => this.form.get(type + '.' + lang) as FormControl;

	errorLanguages(type: string): string[] {
	    return this.languages.filter(lang => this.getControl(type, lang)?.invalid && this.getControl(type, lang)?.touched);
	}

}
