import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    SfAccordionModule,
    SfAvatarModule,
    SfBreadcrumbModule,
    SfButtonModule,
    SfcMenuSurfaceModule,
    SfDialogModule,
    SfIconModule,
    SfMenuModule,
    SfTypographyModule
} from '@ew/shared/safire/components';
import {TranslateModule} from '@ngx-translate/core';
import {SharedComponentsLanguageSwitcherModule} from '@ew/shared/components/language-switcher';
import {SharedPipeTruncateModule} from '@ew/shared/pipe/truncate';
import {RouterModule} from '@angular/router';
import {SharedComponentsNotificationModule} from '@ew/shared/components/notification';

@NgModule({
    imports: [CommonModule,
        FlexLayoutModule,
        SfTypographyModule,
        SfcMenuSurfaceModule,
        SharedPipeTruncateModule,
        SfAvatarModule,
        SfIconModule,
        SfMenuModule,
        SfButtonModule,
        SfDialogModule,
        SharedComponentsLanguageSwitcherModule,
        TranslateModule,
        SfBreadcrumbModule,
        RouterModule,
        SharedComponentsNotificationModule,
        SfAccordionModule
    ],
    declarations: [
        HeaderComponent
    ],
    exports: [
        HeaderComponent
    ]
})
export class SharedLayoutHeaderModule {
}
