import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  SfDataTableComponent,
  SfDataTableContentDirective,
  SfTableElementDirective,
  SfTableHeaderDirective,
} from './data-table.component';
import { SfIconModule } from '../../../icon/src/index';
import {
  SfTableCellDefDirective,
  SfTableCellDirective,
  SfTableColumnDefDirective,
  SfTableFooterCellDefDirective,
  SfTableFooterCellDirective,
  SfTableHeaderCellDefDirective,
  SfTableHeaderCellDirective
} from './cell.directives';

import {
  SfFooterRowDefDirective,
  SfHeaderRowDefDirective,
  SfNoDataRowDirective,
  SfTableFooterRowComponent,
  SfTableHeaderRowComponent,
  SfTableRowComponent,
  SfTableRowDefDirective
} from './row.directives';
import { CdkTableModule } from '@angular/cdk/table';
import { SfTableSortHeaderComponent } from './sort-header.component';
import { SfTableSortDirective } from './sort.directive';

const DECLARATIONS = [

  /*Table*/
  SfDataTableComponent,
  SfDataTableContentDirective,
  SfTableSortHeaderComponent,
  SfTableHeaderDirective,
  SfTableElementDirective,

  /*Templates*/
  SfHeaderRowDefDirective,
  SfFooterRowDefDirective,
  SfTableRowDefDirective,

  SfTableHeaderCellDefDirective,
  SfTableFooterCellDefDirective,
  SfTableColumnDefDirective,
  SfTableCellDefDirective,

  /*Cells*/
  SfTableHeaderCellDirective,
  SfTableFooterCellDirective,
  SfTableCellDirective,

  /*Rows*/
  SfTableHeaderRowComponent,
  SfTableFooterRowComponent,
  SfTableRowComponent,
  SfNoDataRowDirective,

  SfTableSortDirective
];


@NgModule({
  imports: [
    CommonModule,
    SfIconModule,
    CdkTableModule
  ],
  exports: DECLARATIONS,
  declarations: DECLARATIONS
})
export class SfDataTableModule {
}
