import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {SfDialogService} from '@ew/shared/safire/components';
import {ComponentType} from '@angular/cdk/overlay';
import {FilterComponent} from '@ew/shared/components/filter';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';

@UntilDestroy()
@Component({
    selector: 'ew-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})

export class SearchBarComponent implements OnInit {

    @Input() filterComponent: ComponentType<undefined>;
    @Input() label: string;
    @Input() searchVisibility:boolean;
    @Input() searchControl = new FormControl(undefined);
    @Input() filterControl = new FormControl(undefined);
    @Input() isAdminListingTable!: boolean;

    @Output() filterChange = new EventEmitter();
    @Output() searchChange = new EventEmitter<string>();

    constructor(private dialogService: SfDialogService,  private lang: TranslateService,
                private dateTime: DateTimeAdapter<string> ) {
    }

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(untilDestroyed(this))
            .subscribe(query => this.searchChange.emit(query));
        this.datePickerLang();
    }

    datePickerLang(): void {
        this.dateTime.setLocale(this.lang.currentLang);
        this.lang.onLangChange.pipe(untilDestroyed(this), tap(
            ({lang}) => this.dateTime.setLocale(lang))).subscribe();
    }

    openFilterModal(): void {
        this.dialogService.open(this.filterComponent || FilterComponent, {
            panelClass: 'filter-add-user-modal',
            data: {
                form: this.filterControl,
                isAdminListingTable: this.isAdminListingTable,
            }
        }).afterClosed.pipe(
            tap(({data}) => data?.applyFilter && this.filterChange.emit(data?.paramsValue)),
            untilDestroyed(this)
        ).subscribe();
    }
}
