import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { 
    InAppNotificationType,
    NOTIFICAION_SORT_OPTION_VALUE,
    NotificationListingParam, 
    NotificationSortEnum, 
    NotificationSortHeader, 
    NotificationSortOptionValue, 
    SharedFacadeService, 
    SharedStoreStateEnum 
} from '@ew/shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, SfChipSetChange, SfDialogService } from '@ew/shared/safire/components';
import { switchMap, tap } from 'rxjs/operators';
import { NotificationSettingComponent } from './notification-setting/notification-setting.component';

@UntilDestroy()
@Component({
    selector: 'ew-notification-landing',
    templateUrl: './notification-landing.component.html',
    styleUrls: ['./notification-landing.component.scss']
})
export class NotificationLandingComponent implements OnInit {

    sortItems: NotificationSortHeader[];
    notificationLists: InAppNotificationType[];
    perPage = 10;
    stillMoreNotification!: boolean;
    currentNotificationFilterValue!: boolean;
    optionValue: NotificationSortOptionValue = NOTIFICAION_SORT_OPTION_VALUE;
    currentSelectedValue = NotificationSortEnum.ALL;
    loading = false;
    isDeleteNotification!: boolean;
    currentLanguage!: string;
    isDeleting!: boolean;
    notification = false;

    constructor(
        private ref: DialogRef<NotificationLandingComponent>,
        private sharedFacadeService: SharedFacadeService,
        private lang: TranslateService,
        private router: Router,
        private dialogService: SfDialogService,
    ) { }

    ngOnInit(): void {
        this.initializer();
    }

    initializer(): void {
        this.listenToNotificationState();
        this.listenToNotificationReceivedStateChange();
        this.fetchNotifications();
        this.currentLanguage = this.lang.currentLang;
    }

    //listening to state change so that when new in app notification is received, it will be displayed with current filter
    listenToNotificationReceivedStateChange(): void {
        this.sharedFacadeService.specificStateChange(SharedStoreStateEnum.NEW_IN_APP_NOTIFICATION_RECEIVED).pipe(
            untilDestroyed(this),
            tap(() => this.fetchNotifications(this.currentNotificationFilterValue))
        ).subscribe();
    }

    //get the notification sort header count value dynamically on each change
    listenToNotificationState(): void {
        this.sharedFacadeService.specificStateChange<NotificationSortHeader[]>(SharedStoreStateEnum.NOTIFICATIONS_LISTS).pipe(
            untilDestroyed(this),
            tap(notificationSortHeaderOpt => this.sortItems = notificationSortHeaderOpt)
        ).subscribe();
    }

    previous():void {
        this.ref.close();
    }

    //fetch notifications
    fetchNotifications(readStatus?: boolean, perPage?: number): void {
        this.sharedFacadeService.getNotifications(new NotificationListingParam(readStatus, perPage)).pipe(tap(
            (resp) => {
                this.notificationLists = resp.nodes.map(noti => ({...noti, data: JSON.parse(noti?.data)}));
                this.stillMoreNotification = resp.totalCount > resp?.nodes?.length;
            }
        ),
        switchMap(() => this.sharedFacadeService.getNotificationsCount())).subscribe(() => this.loading = this.isDeleting = false);
    }

    viewMoreNotificaiton(): void {
        this.perPage += 10;
        this.loading = true;
        this.fetchNotifications(this.currentNotificationFilterValue, this.perPage);
    }

    filterNotifications(event: SfChipSetChange): void {
        this.currentNotificationFilterValue = this.optionValue[event?.value?.value];
        this.currentSelectedValue = event?.value?.value;
        this.fetchNotifications(this.currentNotificationFilterValue);
    }

    //isDeleteNotification: so that readNotification api wont get triggered when clicking on disgard notification
    //isRead: calling this api only if the message is unread
    readNotification(id: string, isRead: boolean, routeValue: string): void {
        !this.isDeleteNotification && !isRead && this.sharedFacadeService.readNotification({id}).pipe(
            tap(() => this.fetchNotifications(this.currentNotificationFilterValue))
        ).subscribe();
        !this.isDeleteNotification && this.notificaitonRedirection(routeValue);
    }

    notificaitonRedirection(routeValue: string): void {
        const decodedValue = decodeURIComponent(routeValue);
        //billings and banner notification doesn't have query params so we can directly navigate
        decodedValue.includes('?') ?
            this.redirectToTicketingModal(decodedValue) :
            this.router.navigate([decodedValue]).finally(() => this.ref.close());
    }

    redirectToTicketingModal(decodedValue: string): void {
        const routerValue = this.determineRouteValue(decodedValue);
        !this.isDeleteNotification && this.router.navigate([routerValue.routerValue],
            {queryParams: {id: routerValue.routerQueryParams}}).finally(() => this.ref.close());
    }

    //since the routeValue comes in string value, thus to separate route value and query params
    determineRouteValue(routeValue: string): {routerValue: string, routerQueryParams: string} {
        const routerValue = routeValue.split('?')?.[0];
        const routerQueryParams = routeValue.split('?')?.[1].split('=')?.[1];
        return {routerValue,routerQueryParams}
    }

    //setting this isDeleteNotification to true so that readNotification api wont get called
    deleteNotification(id: string): void {
        this.isDeleteNotification = true;
        this.isDeleting = true;
        this.sharedFacadeService.deleteNotification({id}).pipe(
            tap(() => this.fetchNotifications(this.currentNotificationFilterValue))
        ).subscribe(() => this.isDeleteNotification = false);
    }

    readAllNotifications(): void {
        this.sharedFacadeService.readAllNotifications().pipe(tap(() =>
            this.fetchNotifications(this.currentNotificationFilterValue))
        ).subscribe();
    }


    notificationSetting(): void {
        this.dialogService.open(NotificationSettingComponent, {
            width: 400,
            panelClass: 'notification-landing'
        })
    }
    notificationStatus(): void {
        this.notification = !this.notification;
    }
}