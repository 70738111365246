<div fxFlexFill fxLayout="row" class="search">
    <form class="width--full" (submit)="$event.preventDefault()">
        <sf-form-field class="mt-20" data-cy="searchField">
            <sf-input sfBody2 autocomplete="service" type="service"
                      label="{{('HINT_' + label) | translate}}"
                      class="br--4" [formControl]="searchControl">
                <sf-icon sfTextFieldIcon leading>search</sf-icon>
                <sf-icon fxLayoutAlign="end center" trailing (click)="openFilterModal()"
                         class="color--black-20 cursor--pointer icon-hover" *ngIf="searchVisibility" data-cy="statusFilter">tune
                </sf-icon>
            </sf-input>
        </sf-form-field>
    </form>
</div>
