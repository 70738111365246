import {
  ChangeDetectionStrategy,
  Component,
  ContentChild, Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SfRippleService } from '../../../ripple/src/index';
import { SfIconComponent } from '../../../icon/src/index';

@Directive({
  selector: 'sf-button-label, [sfButtonLabel]',
  exportAs: 'sfButtonLabel',
  host: {'class': 'mdc-button__label'}
})
export class SfButtonLabel {}


/**
 * This is a simple button that demonstrates various JSDoc handling in Storybook Docs for Angular.
 *
 * It supports [markdown](https://en.wikipedia.org/wiki/Markdown), so you can embed formatted text,
 * like **bold**, _italic_, and `inline code`.
 *
 * > How you like dem apples?! It's never been easier to document all your components.
 *
 * @string Hello world
 * @link [Example](http://example.com)
 * @code `ThingThing`
 * @html <span class="badge">aaa</span>
 */
@Component({
  exportAs: 'sfButton',
  selector: 'button[sf-button], a[sf-button]',
  host: {
    '[tabIndex]': 'disabled ? -1 : 0',
    'class': 'mdc-button sf-button',
    '[class.sf-button--primary]': 'primary',
    '[class.sf-button--secondary]': 'secondary',
    '[class.sf-button--warning]': 'warning',
    '[class.sf-button--danger]': 'danger',
    '[class.mdc-button--raised]': 'raised',
    '[class.mdc-button--unelevated]': 'unelevated',
    '[class.mdc-button--outlined]': 'outlined',
    '[attr.disabled]': 'disabled || null'
  },
  template: `
    <ng-content></ng-content>
  `,
  providers: [SfRippleService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfButtonComponent implements OnInit, OnDestroy {
  @ContentChild(SfIconComponent, { static: true }) private _icon!: SfIconComponent;

  constructor(
    private  elementRef: ElementRef<HTMLElement>,
    private _ripple: SfRippleService) {
    this._ripple = new SfRippleService(this.elementRef);
    this._ripple.init();
  }

  private _raised = false;

  @Input()
  get raised(): boolean {
    return this._raised;
  }

  set raised(value: boolean) {
    this._raised = coerceBooleanProperty(value);
  }

  private _primary = false;

  @Input()
  get primary(): boolean {
    return this._primary;
  }

  set primary(value: boolean) {
    this._primary = coerceBooleanProperty(value);
  }

  private _dense = false;

  @Input()
  get dense(): boolean {
    return this._dense;
  }

  set dense(value: boolean) {
    this._dense = coerceBooleanProperty(value);
  }

  private _secondary = false;

  @Input()
  get secondary(): boolean {
    return this._secondary;
  }

  set secondary(value: boolean) {
    this._secondary = coerceBooleanProperty(value);
  }


  private _warning = false;

  @Input()
  get warning(): boolean {
    return this._warning;
  }

  set warning(value: boolean) {
    this._warning = coerceBooleanProperty(value);
  }

  private _danger = false;

  @Input()
  get danger(): boolean {
    return this._danger;
  }

  set danger(value: boolean) {
    this._danger = coerceBooleanProperty(value);
  }

  private _unelevated = false;

  @Input()
  get unelevated(): boolean {
    return this._unelevated;
  }

  set unelevated(value: boolean) {
    this._unelevated = coerceBooleanProperty(value);
  }

  private _outlined = false;

  @Input()
  get outlined(): boolean {
    return this._outlined;
  }

  set outlined(value: boolean) {
    this._outlined = coerceBooleanProperty(value);

    if (this._primary && this._outlined) {
        throw new Error('outlined cannot be set on a primary button');
    }
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  ngOnInit(): void {
    if (this._icon) {
      this._icon.elementRef.nativeElement.classList.add('mdc-button__icon');
    }
  }

  ngOnDestroy(): void {
    this._ripple.destroy();
  }

  /** Focuses the button. */
  focus(): void {
    this.getHostElement().focus();
  }

  getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    // A disabled button shouldn't apply any actions
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
