import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';

import { SfMenuSurfaceBase } from './menu-surface-base';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'sf-menu-surface',
  exportAs: 'sfMenuSurface',
  host: { 'class': 'mdc-menu-surface' },
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SfMenuSurfaceComponent extends SfMenuSurfaceBase implements OnInit, OnDestroy {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public platform: Platform,
    @Optional() public ngZone: NgZone,
    public elementRef: ElementRef<HTMLElement>) {
    super(changeDetectorRef, platform, ngZone, elementRef);
  }

  ngOnInit(): void {
    this.initMenuSurface();
  }

  ngOnDestroy(): void {
    this.destroyMenuSurface();
  }
}
