<div fxLayoutAlign="space-between center">
    <h6 sfHeadline6 class="mt-12">{{'BTN_FILTERS' | translate}}</h6>
    <button sf-icon-button icon="close" class="mt-12" (click)="closeModal()" data-cy="Close"></button>
</div>

<form [formGroup]="config.data.form">
    <sf-dialog-content class="filter-content">
        <ew-pc-filter [filterIcon]="'verified_user'" [filterFormControl]="active" [filterLabel]="'LABEL_STATUS'"
                      [controlParams]="controlParams" [checkboxOptionControl]="filter" [checkboxOptions]="regioncheckboxOptions" [displayCheckboxFilter]="config?.data?.shouldDisplayCheckboxFilter"></ew-pc-filter>
    </sf-dialog-content>
</form>

<sf-dialog-action fxLayoutAlign="space-between" fxLayoutAlign.xs="center center" ngClass.gt-xs="pb-20" ngClass.xs="common-action__button">
    <button sf-button outlined (click)="resetFilters()" ngClass.xs="width--full mb-12" data-cy="reset"> {{'BTN_RESET' | translate}}</button>
    <button sf-button primary (click)="applyFilter()" ngClass.gt-xs="ml-12" ngClass.xs="width--full"
            data-cy="apply">{{'BTN_APPLY_FILTER' | translate}}</button>
</sf-dialog-action>
