import {Attribute, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {HttpClient} from '@angular/common/http';
import {map, mapTo, tap} from 'rxjs/operators';
import {
    BANNERS_QUERY, BILLS,
    CREATE_ADDRESS_MUTATION,
    POPUP_FOR_OLD_SUBSCRIBED_PACKAGES,
    RESEND_CONFIRMATION_TOKEN,
    SERVICES,
    USER_DETAILS_QUERY, USER_LISTING_QUERY,
    USER_LISTING_TABLE_QUERY,
    INCLUSIVE_UNIT,
    CHANGE_SUBSCRIPTION_STATUS,
    SENT_TO_ANALYTICS_MUTATION,
    REVERSE_GEOCODING_QUERY, CHANGE_PERMISSION, GET_NOTIFICATIONS_QUERY, GET_NOTIFICATIONS_COUNT_QUERY,
    ADMIN_LISTING_TABLE_QUERY,
    ADD_NEW_ADMIN_MUTATION
} from '../../misc/shared.query';
import {Observable} from 'rxjs';
import {
    Address,
    Banners, Bill, ChangePermissionInput,
    CreateAddressAttributes,
    InclusiveUnitConnection,
    Mutation,
    Query, QueryReverseGeoCodingArgs, Role,
    SentToAnalyticsInput,
    SentToAnalyticsPayload,
    Service,
    SubscriptionStatusInput,
    SubscriptionStatusPayload,
    User, UserList,
    UserToken,
    RegistrationToken,
    CreateRegTokenAttributes,
    NotificationTypeConnection,
    DiscardNotificationAttributes,
    NotificationStats,
    MarkAsReadAttributes,
    DeleteRegTokenAttributes,
    AdminList,
    AddAdminInput,
    AddAdminPayload,
    SetCurrentLocaleAttributes,
    NotificationSettingAttributes,
    NotificationSettingPayload,
    UserEmailOrMobileAttributes,
    ToggleUserBillPaymentAttributes
} from '../../models/graphql';
import {
    BannerTableSearchParam,
    BillSearchParam,
    DeviceInfo,
    GeoLocationValueType,
    GeoLocations,
    NotificationListingParam,
    TableDataSource,
    UserListingSearchParam
} from '../../models/shared.model';
import {NotificationService} from '../notification/notification.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {graphqlutil} from '../../../../../apollo-graphql/src/lib/utils/graphql.utils';
import { GAConsentPermissionEnum } from '@ew/shared/services';
import { 
    CREATE_FIREBASE_TOKEN, 
    DELETE_FIREBASE_TOKEN, 
    DELETE_NOTIFICATION_MUTATION, 
    MARK_NOTIFICATION_AS_READ_MUTATION, 
    MARK_READ_ALL_NOTIFICATION_AS_READ_MUTATION,
    SET_CURRENT_LOCALE_MUTATION, 
    SET_NOTIFICATION_SETTINGS_MUTATION,
    UPDATE_USER_EMAIL_MOBILE_MUTATION,
    SWITCH_ROLE_MUTATION,
    TOGGLE_USER_BILLING_PAYMENT, 
} from '../../misc/shared.mutation';

@Injectable({
    providedIn: 'root'
})
export class SharedApiService {

    constructor(private apollo: Apollo, private httpClient: HttpClient, private notify: NotificationService) {
    }

    confirmation(confirmationToken: string): Observable<boolean> {
        return this.httpClient.get(`/users/confirmation?confirmation_token=${confirmationToken}`,
            {withCredentials: true}).pipe(
            mapTo(true)
        );
    }

    getServices(): Observable<Service[]> {
        return this.apollo.query<Query>({
            query: SERVICES,
            variables: {},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp.data.services)
        );
    }

    getUserDetails(): Observable<User> {
        return this.apollo.query<Query>({
            query: USER_DETAILS_QUERY,
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp.data.user)
        );
    }

    createAddress(attributes: CreateAddressAttributes): Observable<Address> {
        return this.apollo.mutate<Mutation>({
            mutation: CREATE_ADDRESS_MUTATION,
            variables: {
                input: {
                    attributes
                }
            },
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp.data.createAddress)
        );
    }

    getBanners(args: BannerTableSearchParam): Observable<TableDataSource<Banners>> {
        return this.apollo.query<Query>({
            query: BANNERS_QUERY,
            variables: {...graphqlutil.formatPaginationParams(args)},
            fetchPolicy: 'no-cache'
        }).pipe(map(resp => graphqlutil.formatResponse(resp.data.banners, args) as TableDataSource<Banners>));
    }


    logout(showNotification?: boolean): Observable<boolean> {
        return this.httpClient.delete(`/users/sign_out`, {withCredentials: true}).pipe(
            tap(() => showNotification && this.notify.show('LABEL_LOGOUT_SUCCESS')),
            mapTo(true)
        );
    }

    doNotShowNewDevicePopUp(device: DeviceInfo): Observable<boolean> {
        return this.httpClient.put<DeviceInfo>(`/devices`, {device}, {withCredentials: true}).pipe(
            tap(resp => resp?.saved && this.notify.show('SUCCESS_TWO_FACTOR_AUTHENTICATION')),
            mapTo(true)
        );
    }

    popupForOldSubscribedPackages(): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: POPUP_FOR_OLD_SUBSCRIBED_PACKAGES,
            variables: {
                input: {}
            },
            fetchPolicy: 'no-cache'
        }).pipe(mapTo(true));
    }

    resendOTPCode(attributes: UserToken): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: RESEND_CONFIRMATION_TOKEN,
            variables: {
                input: {
                    attributes
                }
            },
            fetchPolicy: 'no-cache'
        }).pipe(
            tap(() => this.notify.show('SUCCESS_RESENT_OTP_MESSAGE')),
            mapTo(true));
    }

    getUsersList(variables: UserListingSearchParam): Observable<TableDataSource<UserList>> {
        return this.apollo.query<Query>({
            query: USER_LISTING_TABLE_QUERY,
            variables: graphqlutil.formatPaginationParams(variables),
            fetchPolicy: 'no-cache'
        }).pipe(map(response => graphqlutil.formatResponse(response.data.users, variables) as TableDataSource<UserList>));
    }

    getAdminsList(variables?: UserListingSearchParam): Observable<TableDataSource<AdminList>> {
        return this.apollo.query<Query>({
            query: ADMIN_LISTING_TABLE_QUERY,
            variables: graphqlutil.formatPaginationParams(variables),
            fetchPolicy: 'no-cache'
        }).pipe(map(response => graphqlutil.formatResponse(response.data.admins, variables) as TableDataSource<AdminList>));
    }

    addAdmin(input: AddAdminInput): Observable<AddAdminPayload> {
        return this.apollo.mutate<Mutation>({
            mutation: ADD_NEW_ADMIN_MUTATION,
            variables: {input},
            fetchPolicy: 'no-cache'
        }).pipe(
            tap(() => this.notify.show('SUCCESS_RESENT_OTP_MESSAGE')),
            map(response => response?.data?.addAdmin))
    }

    getRoles(): Observable<Role[]> {
        return this.apollo.query<Query>({
            query: USER_LISTING_QUERY
        }).pipe(map(response => response.data.roles));
    }

    getBillingTable(attributes: BillSearchParam): Observable<TableDataSource<Bill[]>> {
        return this.apollo.query<Query>({
            query: BILLS,
            variables: {
                ...graphqlutil.formatPaginationParams(attributes)
            },
            fetchPolicy: 'no-cache'
        }).pipe(
            map(({data: {bills}}) => graphqlutil.formatResponse(bills, attributes) as TableDataSource<Bill[]>)
        )
    }

    getInclusiveData(mobileSubscription: string ): Observable<InclusiveUnitConnection> {
        return this.apollo.query<Query>({
            query: INCLUSIVE_UNIT,
            variables: {mobileSubscription},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp.data.inclusiveUnits )
        )
    }

    endImpersonate(): Observable<boolean> {
        return this.httpClient.post('/impersonate/stop', {}, {withCredentials: true}).pipe(
            mapTo(true)
        );
    }

    getLocations(query: string): Observable<GeoLocations> {
        return this.httpClient.get(
            `https://api3.geo.admin.ch/rest/services/api/SearchServer?searchText=${query}&type=locations`)
    }

    getReverseGeoCoding(attributes: QueryReverseGeoCodingArgs): Observable<GeoLocationValueType> {
        return this.apollo.query<Query>({
            query: REVERSE_GEOCODING_QUERY,
            variables: attributes,
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp?.data?.reverseGeoCoding)
        )
    }

    changePermissionLogin(input: ChangePermissionInput, displayCommentSucessMessage?: boolean): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: CHANGE_PERMISSION,
            variables: {
                input
            }
        }).pipe(
            tap(() => this.notify.show(!displayCommentSucessMessage ? 'SUCCESS_PERMISSION_SET' : 'SUCCESS_COMMENT_SETTING_UPDATE' )),
            mapTo(true)
        )
    }


    createFirebaseToken(attributes: CreateRegTokenAttributes): Observable<RegistrationToken> {
        return this.apollo.mutate<Mutation>({
            mutation: CREATE_FIREBASE_TOKEN,
            variables: {
                input : {attributes}
            }
        }).pipe(map(resp => resp.data.createRegistrationToken))
    }

    deleteFirebaseToken(attributes: DeleteRegTokenAttributes): Observable<RegistrationToken> {
        return this.apollo.mutate<Mutation>({
            mutation: DELETE_FIREBASE_TOKEN,
            variables: {
                input : {attributes}
            }
        }).pipe(map(resp => resp.data.deleteRegistrationToken))
    }

    getNotifications(attributes: NotificationListingParam): Observable<NotificationTypeConnection>{
        return this.apollo.query<Query>({
            query: GET_NOTIFICATIONS_QUERY,
            variables: {...graphqlutil.formatPaginationParams(attributes)},
            fetchPolicy: 'no-cache'
        }).pipe(map(response => response.data.notifications));
    }

    deleteNotification(attributes: DiscardNotificationAttributes): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: DELETE_NOTIFICATION_MUTATION,
            variables: {
                input : {attributes}
            }
        }).pipe(tap(() => this.notify.show('SUCCESS_NOTIFICATION_MESSAGE_DELETE')),mapTo(true))
    }

    readNotification(attributes: MarkAsReadAttributes): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: MARK_NOTIFICATION_AS_READ_MUTATION,
            variables: {
                input : {attributes}
            }
        }).pipe(mapTo(true))
    }

    getNotificationsCount(): Observable<NotificationStats> {
        return this.apollo.query<Query>({
            query: GET_NOTIFICATIONS_COUNT_QUERY,
            variables: {},
            fetchPolicy: 'no-cache'
        }).pipe(map(response => response.data.notificationStats))
    }

    changeSubscriptionStatus(input: SubscriptionStatusInput): Observable<SubscriptionStatusPayload> {
        return this.apollo.mutate<Mutation>({
            mutation: CHANGE_SUBSCRIPTION_STATUS,
            variables: {input},
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp.data.subscriptionStatus)
        )
    }

    sentOldSubscriptionPackagesToAnalytics(input: SentToAnalyticsInput): Observable<SentToAnalyticsPayload> {
        return this.apollo.mutate<Mutation>({
            mutation: SENT_TO_ANALYTICS_MUTATION,
            variables: {input},
            fetchPolicy: 'no-cache'
        }).pipe(map(resp => resp?.data?.sentToAnalytics))
    }


    updateGoogleAnalyticsConsentData(permission: GAConsentPermissionEnum): void {
        gtag('consent', 'update', {
            ad_user_data: permission,
            ad_personalization: permission,
            ad_storage: permission,
            analytics_storage: permission,
            functionality_storage: permission,
            personalization_storage: permission,
            security_storage: permission
        });
    }

    readAllNotifications(): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: MARK_READ_ALL_NOTIFICATION_AS_READ_MUTATION,
            variables: {
                input: {}
            }
        }).pipe(mapTo(true))
    }

    switchRole(roleId: string): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: SWITCH_ROLE_MUTATION,
            variables: {input: {roleId}},
            fetchPolicy: 'no-cache'
        }).pipe(map(response => response?.data?.switchRole?.status))
    }
    setPushNotificationLanguagePreference(attributes: SetCurrentLocaleAttributes): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: SET_CURRENT_LOCALE_MUTATION,
            variables: {input: {attributes}},
            fetchPolicy: 'no-cache',
        }).pipe(
            tap(() => this.notify.show('SUCCESS_NOTIFICATION_UPDATED')),
            map(resp => resp.data.setCurrentLocale)
        );
    }
    
    setNotificationSettings(attributes: NotificationSettingAttributes): Observable<NotificationSettingPayload> {
        return this.apollo.mutate<Mutation>({
            mutation: SET_NOTIFICATION_SETTINGS_MUTATION,
            variables: {input: {attributes}},
            fetchPolicy: 'no-cache',
        }).pipe(
            tap(() => this.notify.show('SUCCESS_NOTIFICATION_UPDATED')),
            map(resp => resp?.data?.notificationSetting)
        );
    }

    updateUserEmailOrPhone(attributes: UserEmailOrMobileAttributes): Observable<User> {
        return this.apollo.mutate<Mutation>({
            mutation: UPDATE_USER_EMAIL_MOBILE_MUTATION,
            variables: {
                input: {
                    attributes
                }
            },
            fetchPolicy: 'no-cache'
        }).pipe(
            map(resp => resp.data.updateUserEmailOrMobile)
        );
    }

    updateUserBillingNotification(attributes: ToggleUserBillPaymentAttributes): Observable<boolean> {
        return this.apollo.mutate<Mutation>({
            mutation: TOGGLE_USER_BILLING_PAYMENT,
            variables: {
                input: {
                    attributes
                }
            }
        }).pipe(tap(() => this.notify.show('SUCCESS_UPDATE_NOTIFICATION')),
            mapTo(true))
    }
}
