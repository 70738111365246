import {
  Directive,
  ElementRef
} from '@angular/core';

@Directive({
  selector: '[sfMenuSurfaceAnchor], sf-menu-surface-anchor',
  host: { 'class': 'mdc-menu-surface--anchor' }
})
export class SfMenuSurfaceAnchor {
  constructor(public elementRef: ElementRef<HTMLElement>) { }
}
