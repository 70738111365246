import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DynamicField } from '../../models/dynamic-field';
import { DynamicColumn } from '@ew/shared/services';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ew-dynamic-color-field',
    templateUrl: './dynamic-color-field.component.html',
    styleUrls: ['./dynamic-color-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicColorFieldComponent implements DynamicField {
  @Input() dynamicColumn: DynamicColumn;
  @Input() dynamicValueFormGroup: FormGroup;

}
