<div class="mt-8">
  <p sfSubtitle1>
    {{countryPricing ? (('LABEL_SET_PRICE' | translate) + ' ' + 'Switzerland(CHF)' + ' | ' + ('LABEL_DEFAULT' | translate))
    : ('LABEL_PRICING'  | translate)}}
  </p>
</div>
<p sfSubtitle2 class="mt-8 mb-12 color--black-60">{{'HINT_SET_PRICING_TYPE' | translate}}</p>

<!-- only for incremental pricing -->
<ng-container *ngIf="incrementalPricing">
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-12">
    <p sfSubtitle2 fxFlex.lt-md="40" class="mb-16 color--black-60">{{'LABEL_QUANTITY_IS' | translate}}</p>
    <div fxFlex.lt-lg="30" fxFlex.lt-md="60" class="pricing__quantity">
      <ng-container *ngTemplateOutlet="quantity"></ng-container>
    </div>
    <p sfSubtitle2 fxFlex.lt-md="40" class="mb-16 color--black-60">{{'LABEL_SET_BASIC_PRICE' | translate}}</p>
    <div fxFlex.lt-lg="30" fxFlex.lt-md="60" class="quantity__pricing">
      <ng-container *ngTemplateOutlet="price"></ng-container>
    </div>
  </div>
</ng-container>
<!-- incremental pricing end -->

<ng-container *ngTemplateOutlet="pricing"></ng-container>

<!--country-pricing-->
<ng-container *ngIf="countryPricing">
  <p sfSubtitle1 class="mt-16">{{'LABEL_COUNTRY_PRICE' |  translate}}</p>
  <p sfSubtitle2 class="mt-8 mb-12 color--black-60">{{'HINT_SET_COUNTRY_PRICE' | translate}}</p>
  <sf-form-field fxLayoutAlign="start center">
      <sf-checkbox class="product-create__checkbox"></sf-checkbox>
      <label class="font-size-16">{{count}}. {{'LABEL_SET_PRICE_FOR' | translate}} <span class="bold">Germany(EURO)</span></label>
  </sf-form-field>
  <ng-container *ngTemplateOutlet="pricing"></ng-container>
</ng-container>

<ng-template #pricing>
  <div class="width--full">
    <!--normal-pricing-->
    <div fxFlex="100" *ngIf="normalPricing">
        <ng-container *ngTemplateOutlet="price"></ng-container>
    </div>
    <!-- normal-pricing-end -->

    <!--pricing -->
    <ng-container *ngIf="!normalPricing">
    <div fxLayout="row" fxLayout.xs="column" class="width--full">

        <div [fxFlex]="incrementalPricing ? '40' : '50'">
          <p sfSubtitle2 class="mt-16 p-16 mb-8 pricing__header">{{'LABEL_QUANTITY_IS' |translate}}</p>
          <div fxLayout="column" class="mb-12 py-16">
            <ng-container *ngTemplateOutlet="quantity"></ng-container>
            <sf-list-divider class="mt-20"></sf-list-divider>
          </div>
        </div>
        <ng-container *ngIf="incrementalPricing">
          <div fxFlex="20" class="text--center">
            <p sfSubtitle2 class="mt-16 py-16 pricing__header mb-8">
              <span>{{'LABEL_INCREASE' | translate}}/{{'LABEL_DECREASE' | translate}}</span>
            </p>
            <div fxLayout="column" class="mb-12 py-16">
              <p sfSubtitle2 class="mb-6 py-16">{{'LABEL_INCREASE' | translate}}</p>
              <sf-list-divider class="mt-20"></sf-list-divider>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!normalPricing">
          <div [fxFlex]="incrementalPricing ? '40' : '50'">
            <p sfSubtitle2 class="mt-16 p-16 mb-8 pricing__header">{{'LABEL_PRICE_TYPE' | translate}}</p>
            <div fxLayout="column" class="mb-12 py-16">
              <ng-container *ngTemplateOutlet="price"></ng-container>
              <sf-list-divider class="mt-20"></sf-list-divider>
            </div>
          </div>
        </ng-container>

    </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!normalPricing">
    <button sf-button ngClass.gt-xs="ml-12" ngClass.xs="mt-12 width--full" data-cy="addSlab">
      <sf-icon>add</sf-icon>
      {{'BTN_ADD_SLAB' | translate }}
    </button>
  </ng-container>
</ng-template>

<ng-template #quantity>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-16 width--full">
    <sf-form-field fxFlex="49" class="mb-20" fluid>
      <sf-input [label]="'LABEL_MINIMUM_QUANTITY' | translate" outlined></sf-input>
    </sf-form-field>
    <sf-form-field fxFlex="49" class="mb-20" fluid>
      <sf-input [label]="'LABEL_MAXIMUM_QUANTITY' | translate" outlined></sf-input>
    </sf-form-field>
  </div>
</ng-template>
<ng-template #price>
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="width--full"
       [ngClass]="!normalPricing ? 'px-16' : ''">
    <sf-form-field fxFlex="49" class="mb-20" fluid *ngIf="['otc', 'mrc_otc'].includes(this.configurator?.chargeType)">
      <sf-input [label]="'LABEL_OTC' | translate" [formControl]="form.get('otc')" outlined></sf-input>
    </sf-form-field>
    <p sfCaption class="mt-4 custom-error" *ngIf="form.get('otc')?.hasError('required') && form.get('otc').touched">
      <span> {{'ERROR_REQUIRED' | translate}} </span>
    </p>

    <sf-form-field fxFlex="49" fxFlex.xs="100" class="mb-20" fluid *ngIf="['mrc', 'mrc_otc'].includes(this.configurator?.chargeType)" data-cy="price">
      <sf-input [label]="'LABEL_MRC' | translate" [formControl]="form.get('mrc')" outlined></sf-input>

      <p sfCaption class="mt-20 custom-error" *ngIf="form.get('mrc')?.hasError('required') && form.get('mrc').touched">
        <span> {{'ERROR_REQUIRED' | translate}} </span>
      </p>
    </sf-form-field>

    </div>
</ng-template>

