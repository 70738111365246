import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfIconModule} from '../../../icon/src/index';
import {
  SfIconButtonComponent,
  SfIconOn
} from './icon-button.component';

const ICON_BUTTON_DECLARATIONS = [
  SfIconButtonComponent,
  SfIconOn
];

@NgModule({
  imports: [
    CommonModule,
    SfIconModule
  ],
  exports: [
    ...ICON_BUTTON_DECLARATIONS,
    SfIconModule
  ],
  declarations: ICON_BUTTON_DECLARATIONS
})
export class SfIconButtonModule { }
