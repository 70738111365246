import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SfIconModule} from '../../../icon/src/index';
import {SfFabComponent, SfFabLabel} from './fab.component';

@NgModule({
  imports: [
    CommonModule,
    SfIconModule
  ],
  exports: [
    SfFabComponent,
    SfFabLabel
  ],
  declarations: [SfFabComponent, SfFabLabel]
})
export class SfFabModule { }
