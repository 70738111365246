import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterComponent} from './filter/filter.component';
import {
    SfAccordionModule,
    SfButtonModule,
    SfCheckboxModule,
    SfDialogModule,
    SfFormsModule,
    SfIconModule,
    SfRadioModule, SfTypographyModule
} from '@ew/shared/safire/components';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {PcFilterComponent} from './pc-filter/pc-filter.component';
import {OwlDateTimeModule} from '@danielmoncada/angular-datetime-picker';

@NgModule({
    imports: [
        CommonModule,
        SfDialogModule,
        SfButtonModule,
        TranslateModule,
        FlexLayoutModule,
        SfRadioModule,
        SfFormsModule,
        ReactiveFormsModule,
        SfIconModule,
        SfAccordionModule,
        SfTypographyModule,
        OwlDateTimeModule,
        SfCheckboxModule
    ],
    declarations: [
        FilterComponent,
        PcFilterComponent
    ],
    exports: [
        FilterComponent,
        PcFilterComponent
    ]
})
export class SharedComponentsFilterModule {
}
