import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizeGuard, CookieGuard} from '@ew/shared/security';
import {Shell} from '@ew/app/shell/shell.service';

const routes: Routes = [
    {path: '', redirectTo: 'auth', pathMatch: 'full'},
    {
        path: 'auth',
        loadChildren: (): Promise<unknown> => import('../auth/auth.module').then(m => m.AuthModule),
        canActivate: [CookieGuard]
    },
    Shell.childRoutes(
        [
            {
                path: 'user-listing',
                loadChildren: (): Promise<unknown> => import('../user-listing/user-listing.module').then(m => m.UserListingModule),
                data: {
                    breadcrumb: 'LABEL_USER_LISTING'
                }
            },
            {
                path: 'profile',
                loadChildren: (): Promise<unknown> => import('../profile/profile.module').then(m => m.ProfileModule),
                data: {
                    breadcrumb: 'LABEL_PROFILE'
                }
            },
            {
                path: 'installation-address',
                canActivateChild: [AuthorizeGuard],
                loadChildren: (): Promise<unknown> => import(
                    '../services/installation-address/installation-address.module').then(m => m.InstallationAddressModule),
                data: {
                    breadcrumb: 'LABEL_INSTALLATION_ADDRESS',
                    auth: 'services-read'
                }
            },
            {
                path: 'billing-address',
                canActivateChild: [AuthorizeGuard],
                loadChildren: (): Promise<unknown> => import(
                    '../services/billing-address/billing-address.module').then(m => m.BillingAddressModule),
                data: {
                    breadcrumb: 'LABEL_BILLING_ADDRESS',
                    auth: 'services-read'
                }
            },
            {
                path: 'products',
                loadChildren: (): Promise<unknown> => import('../products/products.module').then(m => m.ProductsModule),
                data: {
                    breadcrumb: 'LABEL_PRODUCTS'
                }
            },
            {
                path: 'product-configurator',
                loadChildren: (): Promise<unknown> => import('../pc/pc.module').then(m => m.PcModule),
                data: {
                    breadcrumb: 'LABEL_PRODUCT_CONFIGURATOR'
                }
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'my-activity',
                loadChildren: (): Promise<unknown> => import('../my-activity/my-activity.module').then(m => m.MyActivityModule),
                data: {
                    breadcrumb: 'LABEL_MY_ACTIVITY'
                }
            },
            {
                path: 'banners',
                loadChildren: (): Promise<unknown> => import('../banner/banner.module').then(m => m.BannerModule),
            },
            {
                path: 'billings',
                loadChildren: (): Promise<unknown> => import('../billing/billing.module').then(m => m.BillingModule),
            },
            {
                path: 'tickets',
                loadChildren: (): Promise<unknown> => import('../ticket/ticket.module').then(m => m.TicketModule),
            },
            {
                path: 'groups',
                loadChildren: (): Promise<unknown> => import('../fleet/fleet.module').then(m => m.FleetModule),
            },
            {
                path: 'network-status',
                loadChildren: (): Promise<unknown> => import('../network-status/network-status.module').then(m => m.NetworkStatusModule),
                data: {
                    breadcrumb: 'Network Status'
                }
            }
        ]
    ),
    {
        path: '**',
        loadChildren: (): Promise<unknown> => import('../page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)]
})
export class CoreRoutingModule {
}
