import { NotificationModeEnum } from '@ew/shared/services'

export interface PushNotificationModeType {
    label: string, 
    value: NotificationModeEnum
}

export interface PushNotificationLanguageType {
    label: string, 
    image: string, value: string
}

export enum EmailRedirectionAuthenticatorUpdate {
    UPDATE_PAGE = 'updatePage',
    OTP_PAGE = 'otpPage'
  }