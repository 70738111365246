import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellComponent} from './shell.component';
import {SharedLayoutSidebarModule} from '@ew/shared/layout/sidebar';

@NgModule({
    declarations: [
        ShellComponent
    ],
    imports: [
        CommonModule,
        SharedLayoutSidebarModule
    ]
})
export class ShellModule {
}
