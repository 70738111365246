import { AfterContentInit, ContentChildren, Directive, Input, OnInit, QueryList } from '@angular/core';
import { CdkAccordion } from '@angular/cdk/accordion';
import { Sf_ACCORDION, SfAccordionBase, SfAccordionDisplayMode, SfAccordionTogglePosition } from './accordion-base';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { SfAccordionItemHeader } from './accordion-item-header';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { END, hasModifierKey, HOME } from '@angular/cdk/keycodes';

/**
 * Directive for a  Accordion.
 */
@Directive({
  selector: 'sf-accordion',
  exportAs: 'sfAccordion',
  providers: [{
    provide: Sf_ACCORDION,
    useExisting: SfAccordionComponent
  }],
  host: {
    class: 'sf-accordion'
  }
})
export class SfAccordionComponent extends CdkAccordion implements SfAccordionBase, OnInit, AfterContentInit {

  /** Whether multiple accordion can remain open at a time. */
  @Input() multi = false;

  @ContentChildren(SfAccordionItemHeader, { descendants: true })
  private _headers: QueryList<SfAccordionItemHeader>;
  /**
   * Display mode used for all items  in the accordion. Currently two display
   * modes exist:
   *  default - a gutter-like spacing is placed around any expanded item, placing the expanded
   *     item at a different elevation from the rest of the accordion.
   *  flat - no spacing is placed around expanded item, showing all items at the same
   *     elevation.
   */
  @Input() displayMode: SfAccordionDisplayMode = 'default';
  /** The position of the expansion indicator. */
  @Input() togglePosition: SfAccordionTogglePosition = 'after';
  private _keyManager: FocusKeyManager<SfAccordionItemHeader>;

  constructor() {
    super();
  }

  private _hideToggle = false;

  /** Whether the expansion indicator should be hidden. */
  @Input()
  get hideToggle(): boolean {
    return this._hideToggle;
  }

  set hideToggle(show: boolean) {
    this._hideToggle = coerceBooleanProperty(show);
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this._keyManager = new FocusKeyManager(this._headers).withWrap();
  }

  /**
   * @ignore
   * Handles keyboard events coming in from the item headers.
   */
  _handleHeaderKeydown(event: KeyboardEvent) {
    const { keyCode } = event;
    const manager = this._keyManager;

    if (keyCode === HOME) {
      if (!hasModifierKey(event)) {
        manager.setFirstItemActive();
        event.preventDefault();
      }
    } else if (keyCode === END) {
      if (!hasModifierKey(event)) {
        manager.setLastItemActive();
        event.preventDefault();
      }
    } else {
      this._keyManager.onKeydown(event);
    }
  }

  /**
  * @ignore
  */
  _handleHeaderFocus(header: SfAccordionItemHeader) {
    this._keyManager.updateActiveItem(header);
  }
}
