import {ReactiveFormSchema} from '../utils/form-utils/src';
import {AddressRelocation, InstallationAddress, OTPForm, SingleElementForm, SetPasswordForm, NotificaitonSettingForm, NotificaitonAddEmailForm} from '../services/src';

export class AddressFormBuilder {
    static AddressFormInitializer = (): ReactiveFormSchema => ({
        formModel: AddressRelocation,
        skipRequiredValidators: ['additionalInformation', 'apartmentNo']
    });

    static InstallationAddressFb = (): ReactiveFormSchema => ({
        formModel: InstallationAddress,
        skipRequiredValidators: ['additionalInformation', 'apartmentNo']
    });
}

export class SharedFormBuilder {

    static otpForm(): ReactiveFormSchema {
        return {
            formModel: OTPForm
        }
    }

    static SingleElementForm = (): ReactiveFormSchema => ({
        formModel: SingleElementForm
    })

    static setPasswordForm = (): ReactiveFormSchema => ({
        formModel: SetPasswordForm,
    })
    
}

export class NotificationSettingFormBuilder {
    static NotificationSettingFormInitializer = (): ReactiveFormSchema => ({
        formModel: NotificaitonSettingForm
    })
    
    static NotificationEmailModeFormInitializer = (): ReactiveFormSchema => ({
        formModel: NotificaitonAddEmailForm
    })
}
