import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {get, isArray, isObject} from 'lodash-es';
import {AffectedControl, ReactiveFormSchema} from '../../index';
import {FormDataPrefix} from '../enum/formprefix.enum';
import {ConditionalEvaluator} from './conditionalevaluator';
import {CONDITION_FNS} from './form.constants';

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace FormUtil {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
	export function buildForm(formData: any, formConfig: ReactiveFormSchema, group: AbstractControl = new FormGroup({}), parentKey: string = ''): FormGroup {

	    Object.keys(formData).forEach(key => {
	        const applicableKey: string = parentKey ? (parentKey + '.' + key) : key;
	        if (isArray(formData[key]) || isObject(formData[key])) {
	            (group as FormGroup).addControl(key, isArray(formData[key]) ? new FormArray([]) : new FormGroup({}));
	            //group['controls'][key] = isArray(formData[key]) ? fb.array([]) : fb.group({});
	            buildForm(formData[key], formConfig, group['controls'][key], applicableKey);
	        } else {
	            (group as FormGroup).addControl(key, new FormControl(formData[key], composeValidators(formConfig, applicableKey)))
	        }
	    });
	    (group as FormGroup).updateValueAndValidity();
	    return group as FormGroup;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
	export function evaluationValueChange(formSchema: ReactiveFormSchema, valueChangeKey: string, form?: AbstractControl, state?: any): void {
	    formSchema.valueChanges[valueChangeKey].forEach(config => {
	        const result: boolean = evaluationExpressions(config, form, state);

	        config.affectedControlKeys.forEach((controlKey, index) => {
	            (config.clearValue ?? true) && form.get(controlKey).setValue(undefined);
	            // eslint-disable-next-line max-len
	            result && !!get(config.patchControlValue, controlKey) && form.get(controlKey).setValue(getPatchingValue(config.patchControlValue[controlKey], form, state));
	            index === 0 && config?.disableKeys?.forEach(key => form.get(key)[result ? 'disable' : 'enable']());

	            (config.clearValidators ?? true) && form.get(controlKey).clearValidators();
	            form.get(controlKey).setValidators(result ? getValidationFns(formSchema, controlKey) : undefined);
	            form.get(controlKey).updateValueAndValidity({emitEvent: false});
	        });
	    });
	}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export function getPatchingValue(key: string, form?: AbstractControl, state?: any): string | boolean | any[] {
	    const value: string[] = key.split(':');
	    if (value[0] === FormDataPrefix.FORM_FIELD) {
	        return form.get(value[1]).value;
	    } else if (value[0] === FormDataPrefix.STATE_DATA) {
	        return get(state, value[1]);
	    } else if (value[0] === FormDataPrefix.VALUE_ONLY) {
	        return value[1];
	    } else {
	        return undefined;
	    }
	}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export function evaluationExpressions(config: AffectedControl, form?: AbstractControl, state?: any): boolean {
	    let result: boolean = false;
	    const symbols: string[] = config.expression.split(' ').filter(text => ['&&', '||'].includes(text));
	    const expressionResults: boolean[] = config.expression.split(/\s+(?:|\|\||&&)\s+/).map(exp =>
	        evaluateExpression(exp, form, state));

	    for (let i: number = 0; i <= expressionResults.length - 1; i++) {
	        result = i === 0 ? expressionResults[0] : ConditionalEvaluator[CONDITION_FNS[symbols[i - 1]]](result, expressionResults[i]);
	    }

	    return result;
	}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export function evaluateExpression(expression: string, form?: AbstractControl, state?: any): boolean {
	    const values: string[] = expression.split(' ');
	    return values.length === 3 ?
	        ConditionalEvaluator[CONDITION_FNS[values[1]]](
	            getValueBasedAnnotation(values[0], form, state), getValueBasedAnnotation(values[2], form, state)
	        ) : getValueBasedAnnotation(values[0], form, state);
	}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export function getValueBasedAnnotation(expression: string, form?: AbstractControl, state?: any): string {
	    const value: string[] = expression.split(':');
	    if (value[0] === FormDataPrefix.FORM_FIELD) {
	        return (form.get(value[1]).value ?? '').toString();
	    } else if (value[0] === FormDataPrefix.FORM_FIELD_BOOLEAN) {
	        return form.get(value[1]).value;
	    } else if (value[0] === FormDataPrefix.STATE_DATA) {
	        return get(state, value[1], '').toString();
	    } else if (value[0] === FormDataPrefix.STATE_DATA_BOOLEAN) {
	        return get(state, value[1]);
	    } else {
	        return value[1]?.toString();
	    }
	}

	export function composeValidators(formConfig: ReactiveFormSchema, key: string): ValidatorFn[] {
	    return !formConfig?.skipRequiredValidators?.find(skip => key.indexOf(skip) > -1) && getValidationFns(formConfig, key);
	}

	export function getValidationFns(formConfig: ReactiveFormSchema, key: string): ValidatorFn[] {
	    let validatorFns: ValidatorFn[] = [];
	    const dottedKeys: string[] = key.split('.');
	    ([key, dottedKeys[dottedKeys.length - 1]].forEach(partedKey => {
	        !formConfig?.skipRequiredValidators?.includes(partedKey) && validatorFns.push(Validators.required);
	        get(formConfig?.customValidators, partedKey) && (validatorFns = [...validatorFns, ...formConfig.customValidators[key]]);
	        get(formConfig?.optionalValidators, partedKey) && (validatorFns = [...validatorFns, ...formConfig.optionalValidators[key]]);
	    }));

	    return validatorFns;
	}
}

export {FormUtil as ReactiveFormUtil};
