import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SfSnackBarService } from '@ew/shared/safire/components';
import {
    ControlOptions, DataTypeEnum, DynamicColumn, DynamicValue, DynamicValueModal,
    DynamicValueModel, Image, ImagesAttributes, Level, MultiLanguages, ProductTree, StepConfig,
} from '@ew/shared/services';
import { keys } from 'lodash-es';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProductFacadeService } from '../../../../services/product-facade.service';
import { ProductBaseComponent } from '../../../product-base/product-base.component';

@Component({
    selector: 'ew-product-details-step',
    templateUrl: './product-details-step.component.html',
    styleUrls: ['./product-details-step.component.scss'],
})
export class ProductDetailsStepComponent extends ProductBaseComponent implements OnInit {
  copyControls: ControlOptions[] = [{ label: 'Name', key: 'name', type: 'input' }];
  languages: string[] = keys(new MultiLanguages());
  imageFiles: ImagesAttributes[] = [];
  previewImageUrls: string[] = [];
  selectedLanguageControl: FormControl;
  isAddon = false;

  @Input() config: StepConfig;
  @Input() currentProduct: ProductTree;
  @Input() edit: string;
  @Input() length: number;
  @Input() form: FormGroup;
  @Input() level: Level;

  constructor(
    private productFacadeService: ProductFacadeService,
    private translate: TranslateService,
    private notify: SfSnackBarService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
  	super();
  }

  ngOnInit(): void {
  	this.selectedLanguageControl = new FormControl(this.translate.currentLang);
  	this.valueChange();
  	if (!this.level.imageable) {
  		this.form.get('images').clearValidators();
  		this.form.get('images').updateValueAndValidity();
  		this.form.get('imageIds').clearValidators();
  		this.form.get('imageIds').updateValueAndValidity();
  	}
      //Creating FormGroup for each dynamic columns (for each section image, TV options etc)
  	this.level.dynamicColumns.forEach((dynamicColumn, i) => {
  		this.pushDynamicColumnForm(dynamicColumn, i);
  	});
  	if (this.edit === 'true') this.onEdit();
  	this.isAddon = this.route.snapshot.queryParamMap.get('addon') === 'true';
  }

  private pushDynamicColumnForm(dynamicColumn: DynamicColumn, columnIndex: number): void {
  	this.dynamicValueAttr.push(
  		this.formBuilder({
  			formModel: DynamicValueModel,
  			skipRequiredValidators: ['dynamicOptionId', 'id', 'name', 'valuableId', 'value', '_destroy'],
  		})
  	);
  	this.dynamicValueAttr.at(columnIndex).patchValue(this.productFacadeService.assignDynamicArrayValues(
  		dynamicColumn, columnIndex, this.dynamicValueAttr.controls[columnIndex] as FormGroup
  	));
  }

  createMultiLangFormGroup(multiLang: boolean): FormGroup | undefined {
  	return multiLang ? this.fb.group({ ...new MultiLanguages() }) : undefined;
  }

  onEdit(): void {
  	this.patchDataToForm();
  }

  patchDataToForm(): void {
  	this.patchImages();
  	this.form.get('name').patchValue(this.currentProduct?.package?.name);
  	this.patchDynamicValue();
  }

  //Flow: Filter those dynamic values with same dynamicColumnId and then assign it to filteredDynamicColumn
  // use filteredDynamicColumn to map and assign a new dynamicOptionID value,
  // to assign a new value to it, once again use filteredDynamicColumn but this time 
  // condition: if dynamicColumn is checkbox tne just map their dynamicOptionID by converting whole DynamicOption into JSON string.
  //condition: if dynamicColumn is other than checkbox then sent their normal dynamicOptionID
  //Finally, Even though that will return multiple elements with same values but we will use first element in that array.
  //Reasion: since dyanmicOptionID accepts a string value only, thus it causes issues when sending values 
  // to dyanmicCheckBoxComponent which expects array of selected dynamicOptionIds. thus this is the reason why we join those
  //JSON string will be JSON parsed when it reaches to dynamic field checkbox component.
  patchDynamicValue(): void {
  	this.dynamicValueAttr.controls.forEach(control => {
          const getDynamicCheckBoxColumn = this.level.dynamicColumns?.find(column => column?.dataType === DataTypeEnum.Checkbox);
  		  control.patchValue(new DynamicValueModal());
		  const filteredDynamicColumn = this.currentProduct?.package?.dynamicValues.filter(value => value?.dynamicColumnId === 
		  control?.value?.dynamicColumnId);
          const modifiedDynamicColumns = filteredDynamicColumn.map(dynamicColumn => {
			    return {
			        ...dynamicColumn, 
			        dynamicOptionId: (dynamicColumn?.dynamicColumnId === getDynamicCheckBoxColumn?.id) 
				        ? JSON.stringify(filteredDynamicColumn) : filteredDynamicColumn[0]?.dynamicOptionId
			    }
          });
          const dynamicValue: DynamicValue = modifiedDynamicColumns[0];  
		  !!dynamicValue && control.patchValue(dynamicValue);
  	});
  }

  patchImages(): void {
  	this.currentProduct?.package.images.forEach((image) => {
  		this.imageFiles.push({ id: image.id });
  		this.previewImageUrls.push(image.urls[0]);
  		this.imagesFormArray.push(
  			this.fb.group({
  				id: image.id,
  				// eslint-disable-next-line @typescript-eslint/naming-convention
  				_destroy: undefined,
  			})
  		);
  		this.imageIdsFormArray.push(this.fb.control(image.id));
  		this.imagesFormArray.updateValueAndValidity();
  		this.imageIdsFormArray.updateValueAndValidity();
  	});
  }

  patchFormData(formGroup: string): void {
  	this[formGroup].patchValue(this.currentProduct?.[`${formGroup}`]);
  }

  // REVISIT TAG: TASHI DHENDUP -
  // This feature should be moved to common later, (as name, description and image are dynamically added based on level creation setting)
  uploadImage(file: File): Observable<Image> {
  	return this.productFacadeService.uploadImage(file).pipe(
  		tap((response) => {
  			this.imageFiles.push({ id: response.id });
  			this.setImageAttributes();
  		})
  	);
  }

  setImageAttributes(): void {
  	const payload = {
  		images: this.imageFiles,
  		imageIds: this.imageFiles.map((image) => image.id),
  	};
  	this.imagesFormArray.push(
  		this.fb.group({
  			id: undefined,
  			// eslint-disable-next-line @typescript-eslint/naming-convention
  			_destroy: undefined,
  		})
  	);
  	this.imageIdsFormArray.push(this.fb.control(undefined));
  	this.form.patchValue(payload);
  }

  removeImage(index: number): void {
  	if (this.form.get('images').value) {
  		if (this.imageFiles[index].id) this.imageFiles[index]['_destroy'] = true;
  		this.setImageAttributes();
  	}
  }

  copyContent(copyType: string): void {
  	this.productFacadeService.copyContent(this.form, copyType, this.selectedLanguageControl.value);
  	this.notify.open(this.translate.instant('HINT_COPY_NAME_DESCRIPTION'), undefined, 'success', {
  		duration: 1000,
  		verticalPosition: 'top',
  		horizontalPosition: 'center',
  	});
  }

  setLanguage = (language: string): void => {
  	this.selectedLanguageControl.setValue(language);
  };

  getControl = (type: string, lang: string): FormControl => this.form.get(type + '.' + lang) as FormControl;

  errorLanguages(type: string): string[] {
  	return this.languages.filter((lang) => this.getControl(type, lang)?.invalid && this.getControl(type, lang)?.touched);
  }

  get name(): FormGroup {
  	return this.form.get('name') as FormGroup;
  }

  get dynamicValueAttr(): FormArray {
  	return this.form.get('dynamicValues') as FormArray;
  }

  get imagesFormArray(): FormArray {
  	return this.form.get('images') as FormArray;
  }

  get imageIdsFormArray(): FormArray {
  	return this.form.get('imageIds') as FormArray;
  }
}
