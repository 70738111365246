import {Injectable} from '@angular/core';
import {APP_NAVIGATION, NavItem, WindowGlobalService} from '@ew/shared/services';

@Injectable({
    providedIn: 'root'
})
export class SidebarBlService {

    constructor(private window: WindowGlobalService) {
    }

    getNavigation(): NavItem[] {
        return [...APP_NAVIGATION].map(navItem => {
            const index: number = navItem?.children?.findIndex(child => child.url &&
                this.window.getLocation().pathname.includes(child.url));
            navItem.activated = (navItem.url && this.window.getLocation().pathname.includes(navItem.url)) || index > -1;
            return navItem;
        });
    }

    updateNavItem(navItem: NavItem): void {
        navItem.activated = !navItem.activated;
        navItem?.children?.forEach(child => child.activated = navItem.activated);
    }
}
