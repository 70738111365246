import {Directive} from '@angular/core';

@Directive({
  selector: '[sfDrawerTitle]',
  host: {'class': 'mdc-drawer__title'}
})
export class SfDrawerTitle {}

@Directive({
  selector: '[sfDrawerSubtitle]',
  host: {'class': 'mdc-drawer__subtitle'}
})
export class SfDrawerSubtitle {}

@Directive({
  selector: 'sf-drawer-content, [sfDrawerContent]',
  host: {'class': 'mdc-drawer__content'}
})
export class SfDrawerContent {}

@Directive({
  selector: 'sf-drawer-app-content, [sfDrawerAppContent]',
  host: {'class': 'mdc-drawer-app-content'}
})
export class SfDrawerAppContent {}
