<div fxLayout="column" fxLayoutAlign="space-between" class="notification-setting">
    <form [formGroup]="form">
        <div class="color--black-60">
            <div fxLayout="row" fxFlexAlign="start center" class="notification-setting__header p-16">
                <span aria-label="return to previous page" role="button" class="mt-4 cursor--pointer" (click)="closeModal()" data-cy="back">
               <sf-icon class="s-20" >arrow_back</sf-icon>
           </span>
                <p class="ml-10 font-size-16 font--600">{{'LABEL_NOTIFICATION_SETTINGS' | translate}}</p>
            </div>
            <sf-radio-group class="my-32" [formControl]="$any(form.get('mode'))">
                <section class="p-16">
                    <p class="pb-4">{{'HINT_GET_NOTIFICATIONS' | translate}}</p>
                    <div fxLayout="column" *ngFor="let notification of notificationMode">
                        <sf-form-field aria-label="notification setting" class="mr-12" data-cy="notificationSetting">
                            <sf-radio [value]="notification?.value" data-cy="notificationSetting"></sf-radio>
                            <label>{{notification?.label | translate}}</label>
                        </sf-form-field>
                    </div>
                    </section>
            </sf-radio-group>
            <sf-radio-group class="my-32" [formControl]="$any(form.get('language'))">
            <section class="p-16">
                <p class="pb-4">{{'HINT_CHOOSE_LANGUAGE' | translate}}:</p>
                <div fxLayout="column" *ngFor="let language of notificationLanguages">
                    <sf-form-field aria-label="language setting" class="mr-12" data-cy="languageSetting">
                            <sf-radio [value]="language?.value" data-cy="languageType"></sf-radio>
                                <img [src]="language?.image" alt="country flag" width="20px">
                                <label class="ml-8">{{ language?.label | translate}}</label>
                            </sf-form-field>
                        </div>
                    </section>
                </sf-radio-group>
        </div>
    </form>
    <div fxLayout.xs="column" fxLayoutAlign="space-between" class="m-24">
        <button aria-label="cancel the changes" outlined sf-button ngClass.xs="width--full mb-12" data-cy="cancel" (click)="closeModal()">
            {{ 'BTN_CANCEL' | translate }}
        </button>
        <button aria-label="save the changes" primary sf-button ngClass.xs="width--full" class="auth-resend-button" data-cy="save" (click)="saveNotificationSetting()">
            {{ 'BTN_SAVE' | translate }}
        </button>
    </div>
</div>
