import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfBadgesDirective } from './badges.directive';
import { SfIconButtonModule } from '../../../icon-button/src/index';
import { SfAvatarModule } from '../../../avatar/src/index';
import { SfIconModule } from '../../../icon/src/index';
@NgModule({
  imports: [CommonModule,
    SfIconButtonModule,
    SfAvatarModule,
    SfIconModule
  ],
  exports: [SfBadgesDirective],
  declarations: [SfBadgesDirective],
})
export class SfBadgesModule {}
