import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LevelModel, ProductsStoreState, ProductStateEnum } from '@ew/shared/services';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import { pcRootPath } from '../../../constants/products.constant';
import { ProductFacadeService } from '../../../services/product-facade.service';

@UntilDestroy()
@Component({
    selector: 'ew-level-setting',
    templateUrl: './level-setting.component.html',
    styleUrls: ['./level-setting.component.scss'],
})

export class LevelSettingComponent implements OnInit {
  configuratorPriceTypeId: string;
  rootPath = pcRootPath;
  productState$: Observable<ProductsStoreState> = this.productFacadeService.stateChange().pipe(debounceTime(200));

  constructor(private productFacadeService: ProductFacadeService, private router: Router) {}

  ngOnInit(): void {
  	this.initialize();
  }

  getLevels(): void {
  	this.productFacadeService
  		.getConfiguration()
  		.pipe(switchMap(() => this.productFacadeService.getLevels()))
  		.subscribe((response) => {
  			this.whichComponentToLoad(response);
  		});
  }

  whichComponentToLoad(levels: LevelModel[]): void {
  	if (levels.length === 0) {
  		this.router.navigateByUrl('product-configurator/product/product-create');
  	} else {
  		this.checkCurrentLevelToLoad(levels);
  	}
  }

  checkCurrentLevelToLoad(levels: LevelModel[]): void {
  	this.getCurrentLevel(levels.find((level) => level.rank === 0).id);
  	this.getProductTree();
  }

  getCurrentLevel(id: string): void {
  	this.productFacadeService.setCurrentLevel(id).subscribe();
  }

  getProductTree(): void {
  	this.productFacadeService.getProductTrees({}).subscribe();
  }

  private initialize(): void {
  	this.productFacadeService.initialize();
  	this.getLevels();
  	this.handleOnProductTreesStateChanges();
  }

  private handleOnProductTreesStateChanges(): void {
  	this.productFacadeService
  		.specificStateChange(ProductStateEnum.PRODUCT_TREES_QUERY)
  		.pipe(
  			untilDestroyed(this),
  			tap(() => this.getProductTree())
  		)
  		.subscribe();
  }
}
